export default{
  "patient_shoulder_pain": "Dolor",
  "patient_shoulder_pain_q1": "¿Siente dolor en el hombro?",
  "patient_shoulder_pain_q1_a1": "No",
  "patient_shoulder_pain_q1_a2": "Si",
  "patient_shoulder_pain_q2": "En una escala de 0 a 15, cual es el mayor nivel de dolor que ha sentido en su hombro al realizar actividades normales dentro de las pasadas 24 horas? (0  significa no dolor, 15 significa dolor intolerable)",
  "patient_shoulder_pain_q2_a1": "Dolor intolerable",
  "patient_shoulder_pain_q2_a2": "No dolor",
  "patient_shoulder_pain_q3": "su sueño se ve interrumpido debido a su hombro?",
  "patient_shoulder_pain_q3_a1": "Sueño ininterrumpido",
  "patient_shoulder_pain_q3_a2": "Interrupciones ocacionales",
  "patient_shoulder_pain_q3_a3": "Interrupciones todas las noches",
  "patient_shoulder_pain_q4": "¿Qué tan fuerte ha sido el dolor hoy?",
  "patient_shoulder_pain_q5": "¿Eres fumador?",
  "patient_shoulder_pain_q5_a1": "No",
  "patient_shoulder_pain_q5_a2": "Si",
  "patient_shoulder_pain_q5_a3": "Ex-fumador",
  "patient_shoulder_pain_q5_a2_q1": "¿Cuántos cigarrillos por día?",
  "patient_shoulder_pain_q5_a3_q1": "¿Hace cuánto dejó de fumar (años)?",
  "patient_shoulder_pain_q5_a3_q2": "¿Cuántos años fumaste?",
  "patient_shoulder_pain_q6": "¿Tomas analgésicos (por ejemplo, paracetamol, etc.)?",
  "patient_shoulder_pain_q6_a1": "No",
  "patient_shoulder_pain_q6_a2": "Si",
  "patient_shoulder_pain_q6_a2_q1": "¿Tomas medicamentos fuertes para el dolor (por ejemplo, codeina, tramadol, morfina)?",
  "patient_shoulder_pain_q6_a2_q1_a1": "No",
  "patient_shoulder_pain_q6_a2_q1_a2": "Si",
  "patient_shoulder_pain_q6_a2_q2": "¿Cuántas tabletas toma cada día en promedio?",
  "patient_shoulder_pain_q7": "¿Cuál de las siguientes afirmaciones describe mejor el dolor?",
  "patient_shoulder_pain_q7_a1": "Ninguno",
  "patient_shoulder_pain_q7_a2": "Ocacional y leve",
  "patient_shoulder_pain_q7_a3": "Presente durante una actividad pesada o actividad específica, uso ocacional de aspirina",
  "patient_shoulder_pain_q7_a4": "Presente durante una actividad ligera, poco dolor o nulo en reposo, uso frecuente de aspirina",
  "patient_shoulder_pain_q7_a5": "Siempre presente, soportable, medicación fuerte requerida ocasionalmente",
  "patient_shoulder_pain_q7_a6": "Siempre presente, insoportable, medicación fuerte requerida con frecuencia",
  "patient_shoulder_pain_q8": "¿Siente alfileres, agujas y/u hormigueo en el brazo?",
  "patient_shoulder_pain_q8_a1": "No",
  "patient_shoulder_pain_q8_a2": "Si",
  "patient_shoulder_pain_q9": "Marca dónde está el dolor",
  "patient_shoulder_pain_q9_a1": "Delante",
  "patient_shoulder_pain_q9_a2": "Atrás",
  "patient_shoulder_function": "Funcionamiento",
  "patient_shoulder_function_q1": "¿Cuál de las siguientes afirmaciones describe mejor el funcionamiento del hombro afectado?",
  "patient_shoulder_function_q1_a1": "Normal",
  "patient_shoulder_function_q1_a2": "Solo una ligera restricción, capaz de trabajar por encima del nivel del hombro",
  "patient_shoulder_function_q1_a3": "La mayoría de las tareas domésticas, ir de compras, conducir, peinarse, vestirse / desvestirse",
  "patient_shoulder_function_q1_a4": "Capaz de hacer tareas domésticas ligeras y la mayoría de las actividades de la vida diaria",
  "patient_shoulder_function_q1_a5": "Solo son posibles las actividades ligeras",
  "patient_shoulder_function_q1_a6": "Incapaz de usar extremidades",
  "patient_shoulder_function_instruction": "Seleccione el número que indica su capacidad para realizar las siguientes actividades",
  "patient_shoulder_function_instruction_0": "Incapaz",
  "patient_shoulder_function_instruction_1": "Muy difícil",
  "patient_shoulder_function_instruction_2": "Algo difícil",
  "patient_shoulder_function_instruction_3": "Sin dificultad",
  "patient_shoulder_function_note": "Nota: Por favor responda las siguientes preguntas en relacion al funcionamiento de su hombro",
  "patient_shoulder_function_q2": "Ponerse un abrigo/camisa",
  "patient_shoulder_function_q2_a0": "Incapaz",
  "patient_shoulder_function_q2_a1": "Muy difícil",
  "patient_shoulder_function_q2_a2": "Algo difícil",
  "patient_shoulder_function_q2_a3": "Sin dificultad",
  "patient_shoulder_function_q3": "Dormir sobre el lado dolorido o afectado",
  "patient_shoulder_function_q3_a0": "Incapaz",
  "patient_shoulder_function_q3_a1": "Muy difícil",
  "patient_shoulder_function_q3_a2": "Algo difícil",
  "patient_shoulder_function_q3_a3": "Sin dificultad",
  "patient_shoulder_function_q4": "lavarse la espalda/ajustarse el sostén",
  "patient_shoulder_function_q4_a0": "Incapaz",
  "patient_shoulder_function_q4_a1": "Muy difícil",
  "patient_shoulder_function_q4_a2": "Algo difícil",
  "patient_shoulder_function_q4_a3": "Sin dificultad",
  "patient_shoulder_function_q5": "Manejar o gestionar el uso del baño",
  "patient_shoulder_function_q5_a0": "Incapaz",
  "patient_shoulder_function_q5_a1": "Muy difícil",
  "patient_shoulder_function_q5_a2": "Algo difícil",
  "patient_shoulder_function_q5_a3": "Sin dificultad",
  "patient_shoulder_function_q6": "Peinar el cabello",
  "patient_shoulder_function_q6_a0": "Incapaz",
  "patient_shoulder_function_q6_a1": "Muy difícil",
  "patient_shoulder_function_q6_a2": "Algo difícil",
  "patient_shoulder_function_q6_a3": "Sin dificultad",
  "patient_shoulder_function_q7": "Afeitarse/maquillarse",
  "patient_shoulder_function_q7_a0": "Incapaz",
  "patient_shoulder_function_q7_a1": "Muy difícil",
  "patient_shoulder_function_q7_a2": "Algo difícil",
  "patient_shoulder_function_q7_a3": "Sin dificultad",
  "patient_shoulder_function_q8": "Cepillarse los dientes",
  "patient_shoulder_function_q8_a0": "Incapaz",
  "patient_shoulder_function_q8_a1": "Muy difícil",
  "patient_shoulder_function_q8_a2": "Algo difícil",
  "patient_shoulder_function_q8_a3": "Sin dificultad",
  "patient_shoulder_function_q9": "Llenar un vaso con una botella llena",
  "patient_shoulder_function_q9_a0": "Incapaz",
  "patient_shoulder_function_q9_a1": "Muy difícil",
  "patient_shoulder_function_q9_a2": "Algo difícil",
  "patient_shoulder_function_q9_a3": "Sin dificultad",
  "patient_shoulder_function_q10": "Beber (llevar el vaso lleno a la boca)",
  "patient_shoulder_function_q10_a0": "Incapaz",
  "patient_shoulder_function_q10_a1": "Muy difícil",
  "patient_shoulder_function_q10_a2": "Algo difícil",
  "patient_shoulder_function_q10_a3": "Sin dificultad",
  "patient_shoulder_function_q11": "Tomar sopa (cuchara llena a la boca)",
  "patient_shoulder_function_q11_a0": "Incapaz",
  "patient_shoulder_function_q11_a1": "Muy difícil",
  "patient_shoulder_function_q11_a2": "Algo difícil",
  "patient_shoulder_function_q11_a3": "Sin dificultad",
  "patient_shoulder_function_q12": "Darle la mano a alguien / abrir una puerta",
  "patient_shoulder_function_q12_a0": "Incapaz",
  "patient_shoulder_function_q12_a1": "Muy difícil",
  "patient_shoulder_function_q12_a2": "Algo difícil",
  "patient_shoulder_function_q12_a3": "Sin dificultad",
  "patient_shoulder_function_q13": "Hablar por teléfono (al nivel de la oreja)",
  "patient_shoulder_function_q13_a0": "Incapaz",
  "patient_shoulder_function_q13_a1": "Muy difícil",
  "patient_shoulder_function_q13_a2": "Algo difícil",
  "patient_shoulder_function_q13_a3": "Sin dificultad",
  "patient_shoulder_function_q14": "Escribir una carta/firmar en papel/usar un teclado",
  "patient_shoulder_function_q14_a0": "Incapaz",
  "patient_shoulder_function_q14_a1": "Muy difícil",
  "patient_shoulder_function_q14_a2": "Algo difícil",
  "patient_shoulder_function_q14_a3": "Sin dificultad",
  "patient_shoulder_function_q15": "Alcanzar un estante alto (por encima del hombro)",
  "patient_shoulder_function_q15_a0": "Incapaz",
  "patient_shoulder_function_q15_a1": "Muy difícil",
  "patient_shoulder_function_q15_a2": "Algo difícil",
  "patient_shoulder_function_q15_a3": "Sin dificultad",
  "patient_shoulder_function_q16": "Levantar 0,5 kg (1 lb) por encima del hombro",
  "patient_shoulder_function_q16_a0": "Incapaz",
  "patient_shoulder_function_q16_a1": "Muy difícil",
  "patient_shoulder_function_q16_a2": "Algo difícil",
  "patient_shoulder_function_q16_a3": "Sin dificultad",
  "patient_shoulder_function_q17": "Levantar 4,5 kg (10 libras) por encima del hombro",
  "patient_shoulder_function_q17_a0": "Incapaz",
  "patient_shoulder_function_q17_a1": "Muy difícil",
  "patient_shoulder_function_q17_a2": "Algo difícil",
  "patient_shoulder_function_q17_a3": "Sin dificultad",
  "patient_shoulder_function_q18": "¿Puede llevar 10 kg (22 lbs) a su lado del hombro afectado?",
  "patient_shoulder_function_q18_a0": "Incapaz",
  "patient_shoulder_function_q18_a1": "Muy difícil",
  "patient_shoulder_function_q18_a2": "Algo difícil",
  "patient_shoulder_function_q18_a3": "Sin dificultad",
  "patient_shoulder_function_q19": "Lanzar una pelota por debajo (al menos 3 metros)",
  "patient_shoulder_function_q19_a0": "Incapaz",
  "patient_shoulder_function_q19_a1": "Muy difícil",
  "patient_shoulder_function_q19_a2": "Algo difícil",
  "patient_shoulder_function_q19_a3": "Sin dificultad",
  "patient_shoulder_function_q20": "Lanzar una pelota por encima (al menos 6 metros)",
  "patient_shoulder_function_q20_a0": "Incapaz",
  "patient_shoulder_function_q20_a1": "Muy difícil",
  "patient_shoulder_function_q20_a2": "Algo difícil",
  "patient_shoulder_function_q20_a3": "Sin dificultad",
  "patient_shoulder_function_q21": "Hacer su trabajo habitual completo",
  "patient_shoulder_function_q21_a0": "Incapaz",
  "patient_shoulder_function_q21_a1": "Muy difícil",
  "patient_shoulder_function_q21_a2": "Algo difícil",
  "patient_shoulder_function_q21_a3": "Sin dificultad",
  "patient_shoulder_function_q22": "Cuanta de su actividad normal de trabajo le permite realizar su hombro?",
  "patient_shoulder_function_q22_a0": "Ninguna",
  "patient_shoulder_function_q22_a1": "Toda",
  "patient_shoulder_function_q23": "Hacer pasatiempos/deportes habituales completos",
  "patient_shoulder_function_q23_a0": "Incapaz",
  "patient_shoulder_function_q23_a1": "Muy difícil",
  "patient_shoulder_function_q23_a2": "Algo difícil",
  "patient_shoulder_function_q23_a3": "Sin dificultad",
  "patient_shoulder_function_q24": "Cuanta de su actividad recreativa le permite realizar su hombro?",
  "patient_shoulder_function_q24_a0": "Ninguna",
  "patient_shoulder_function_q24_a1": "Toda",
  "patient_shoulder_function_q25": "Por favor enumere sus actividades laborales",
  "patient_shoulder_function_q26": "Por favor enumere pasatiempos / actividades deportivas",
  "patient_shoulder_feeling_instability": "Sensación de inestabilidad",
  "patient_shoulder_feeling_instability_q1": "¿Sientes tu hombro inestable (como si se fuese a dislocar)?",
  "patient_shoulder_feeling_instability_q1_a1": "No",
  "patient_shoulder_feeling_instability_q1_a2": "Si",
  "patient_shoulder_feeling_instability_q1_a2_q1": "Indique qué tan inestable es su hombro",
  "patient_shoulder_wosi": "Índice de inestabilidad del hombro Western Ontario",
  "patient_shoulder_wosi_instruction": "Instrucciones: Se le pide que indique en esta parte del cuestionario la cantidad de síntomas que ha experimentado en la última semana relacionados con su hombro afectado. Simplemente mueva el control deslizante a lo largo de la línea para que se corresponda con precisión con sus síntomas.",
  "patient_shoulder_wosi_note": "Nota: Cuanto más mueva el control deslizante hacia la derecha, mayor será el sintoma experimentado. Cuanto más mueva el control deslizante hacia la izquierda, menos será el sintoma experimentado. Si tiene alguna duda en particular, por favor pregunte.",
  "patient_shoulder_wosi_sectionA": "Sección A: Síntomas físicos",
  "patient_shoulder_wosi_q1": "¿Cuánto dolor siente en el hombro al realizar actividades por encima de la cabeza?",
  "patient_shoulder_wosi_q1_a1": "Sin dolor",
  "patient_shoulder_wosi_q1_a2": "Dolor extremo",
  "patient_shoulder_wosi_q2": "¿Cuánto dolor o palpitaciones siente en el hombro?",
  "patient_shoulder_wosi_q2_a1": "Sin dolor / palpitaciones",
  "patient_shoulder_wosi_q2_a2": "Dolor extremo / palpitante",
  "patient_shoulder_wosi_q3": "¿Cuánta debilidad o falta de fuerza experimenta en su hombro?",
  "patient_shoulder_wosi_q3_a1": "Ninguna debilidad",
  "patient_shoulder_wosi_q3_a2": "Debilidad extrema",
  "patient_shoulder_wosi_q4": "¿Cuánta fatiga o falta de resistencia experimenta en su hombro?",
  "patient_shoulder_wosi_q4_a1": "Sin fatiga",
  "patient_shoulder_wosi_q4_a2": "Fatiga extrema",
  "patient_shoulder_wosi_q5": "¿Cuánto nivel de chasquido experimenta en su hombro?",
  "patient_shoulder_wosi_q5_a1": "Sin hacer clic",
  "patient_shoulder_wosi_q5_a2": "Clic extremo",
  "patient_shoulder_wosi_q6": "¿Cuánta rigidez experimenta en su hombro?",
  "patient_shoulder_wosi_q6_a1": "Sin rigidez",
  "patient_shoulder_wosi_q6_a2": "Rigidez extrema",
  "patient_shoulder_wosi_q7": "¿Cuánta molestia siente en los músculos del cuello como resultado de su hombro?",
  "patient_shoulder_wosi_q7_a1": "Sin molestias",
  "patient_shoulder_wosi_q7_a2": "Incomodidad extrema",
  "patient_shoulder_wosi_q8": "¿Cuánta sensación de inestabilidad o aflojamiento experimenta en su hombro?",
  "patient_shoulder_wosi_q8_a1": "Sin inestabilidad",
  "patient_shoulder_wosi_q8_a2": "Inestabilidad extrema",
  "patient_shoulder_wosi_q9": "¿Cuánto compensas tu hombro con otros músculos?",
  "patient_shoulder_wosi_q9_a1": "En absoluto",
  "patient_shoulder_wosi_q9_a2": "Extremo",
  "patient_shoulder_wosi_q10": "¿Cuánta pérdida de rango de movimiento tiene en su hombro?",
  "patient_shoulder_wosi_q10_a1": "Sin pérdida",
  "patient_shoulder_wosi_q10_a2": "Pérdida extrema",
  "patient_shoulder_wosi_sectionB": "Sección B: Deportes / Recreación / Trabajo",
  "patient_shoulder_wosi_q11": "¿Cuánto ha limitado su hombro la cantidad que puede participar en deportes o actividades recreativas?",
  "patient_shoulder_wosi_q11_a1": "No limitado",
  "patient_shoulder_wosi_q11_a2": "Extremadamente limitado",
  "patient_shoulder_wosi_q12": "¿Cuánto ha afectado su hombro su capacidad para realizar las habilidades específicas requeridas para su deporte o trabajo?",
  "patient_shoulder_wosi_q12_note": "Si tu hombro afecta tanto al deporte como al trabajo considera la zona que está más afectada.",
  "patient_shoulder_wosi_q12_a1": "No afectado",
  "patient_shoulder_wosi_q12_a2": "Extremadamente afectado",
  "patient_shoulder_wosi_q13": "¿Cuánta necesidad siente de proteger su brazo durante las actividades?",
  "patient_shoulder_wosi_q13_a1": "En absoluto",
  "patient_shoulder_wosi_q13_a2": "Extremo",
  "patient_shoulder_wosi_q14": "¿Cuánta dificultad experimenta para levantar objetos pesados por debajo del nivel de los hombros?",
  "patient_shoulder_wosi_q14_a1": "Sin dificultad",
  "patient_shoulder_wosi_q14_a2": "Dificultad extrema",
  "patient_shoulder_wosi_sectionC": "Sección C: Estilo de vida",
  "patient_shoulder_wosi_q15": "¿Cuánto miedo tienes de caer sobre tu hombro?",
  "patient_shoulder_wosi_q15_a1": "Sin miedo",
  "patient_shoulder_wosi_q15_a2": "Miedo extremo",
  "patient_shoulder_wosi_q16": "¿Cuánta dificultad experimenta para mantener el nivel deseado de condición física?",
  "patient_shoulder_wosi_q16_a1": "Sin dificultad",
  "patient_shoulder_wosi_q16_a2": "Dificultad extrema",
  "patient_shoulder_wosi_q17": "¿Cuánta dificultad tiene para “jugar o bromear” con familiares o amigos?",
  "patient_shoulder_wosi_q17_a1": "Sin dificultad",
  "patient_shoulder_wosi_q17_a2": "Dificultad extrema",
  "patient_shoulder_wosi_q18": "¿Cuánta dificultad tiene para dormir debido a su hombro?",
  "patient_shoulder_wosi_q18_a1": "Sin dificultad",
  "patient_shoulder_wosi_q18_a2": "Dificultad extrema",
  "patient_shoulder_wosi_sectionD": "Sección D: Emociones",
  "patient_shoulder_wosi_q19": "¿Qué tan consciente eres de tu hombro?",
  "patient_shoulder_wosi_q19_a1": "No consciente",
  "patient_shoulder_wosi_q19_a2": "Extremadamente consciente",
  "patient_shoulder_wosi_q20": "¿Qué tan preocupado está por el empeoramiento de su hombro?",
  "patient_shoulder_wosi_q20_a1": "Ninguna preocupación",
  "patient_shoulder_wosi_q20_a2": "Extremadamente preocupado",
  "patient_shoulder_wosi_q21": "¿Cuánta frustración siente por su hombro?",
  "patient_shoulder_wosi_q21_a1": "Sin frustración",
  "patient_shoulder_wosi_q21_a2": "Extremadamente frustrado",
  "patient_shoulder_ac_joint": " \nArticulación acromioclavicular (AC)",
  "patient_shoulder_ac_joint_q1": "¿Tienes inestabilidad en tu articulación AC?",
  "patient_shoulder_ac_joint_q1_note": "Nota: LA articulación AC se encuentra en la parte superior del hombro que conecta la clavícula y la punta delantera del omóplato.",
  "patient_shoulder_ac_joint_q1_a1": "No",
  "patient_shoulder_ac_joint_q1_a2": "Si",
  "patient_shoulder_sacs": "Puntuación específica AC (SACS)",
  "patient_shoulder_sacs_explanation": "Explicación: Para cada pregunta, se le pedirá que califique su nivel de dolor o dificultad para realizar diferentes tareas. La escala de calificación está diseñada para que 0 no refleje ningún dolor o dificultad, mientras que 10 refleje la mayor cantidad de dolor o dificultad.",
  "patient_shoulder_sacs_note": "Nota: una calificación de 2 representaría niveles mínimos de dolor. Si tiene alguna duda con respecto a la intención de alguna frase en particular, por favor pregunte.",
  "patient_shoulder_sacs_sectionA": "Sección A: Dolor",
  "patient_shoulder_sacs_a1": "Califique su nivel de dolor en el hombro cuando se encuentra en su peor, más intenso momento.",
  "patient_shoulder_sacs_a1_a1": "Sin dolor",
  "patient_shoulder_sacs_a1_a2": "El dolor más severo",
  "patient_shoulder_sacs_a2": "Califique su nivel de dolor en el hombro cuando se encuentra en su mejor momento.",
  "patient_shoulder_sacs_a2_a1": "Sin dolor",
  "patient_shoulder_sacs_a2_a2": "El dolor más severo",
  "patient_shoulder_sacs_a3": "Califique el nivel de dolor cuando presiona/toca o se aplica presión en la parte superior del hombro.",
  "patient_shoulder_sacs_a3_note": "P.ej. Correa para portátil o bolso de mano",
  "patient_shoulder_sacs_a3_a1": "Sin dolor",
  "patient_shoulder_sacs_a3_a2": "El dolor más severo",
  "patient_shoulder_sacs_a4": "Califique el nivel de tensión del dolor o la sensación de tirón que siente en el cuello y/o en la región afectada del omóplato.",
  "patient_shoulder_sacs_a4_a1": "Sin dolor",
  "patient_shoulder_sacs_a4_a2": "El dolor más severo",
  "patient_shoulder_sacs_sectionB": "Sección B: Funcionamiento",
  "patient_shoulder_sacs_b1": "Califique la cantidad de chasquidos y/o chirridos y/o ruidos metálicos que experimenta en su hombro.",
  "patient_shoulder_sacs_b1_a1": "Sin hacer clic",
  "patient_shoulder_sacs_b1_a2": "Clic extremo",
  "patient_shoulder_sacs_b2": "Indique si su brazo siente la necesidad de ser apoyado al caminar",
  "patient_shoulder_sacs_b2_note": "por ejemplo, descansar el brazo en un bolsillo o usar un cabestrillo",
  "patient_shoulder_sacs_b2_a1": "No hay necesidad de apoyo",
  "patient_shoulder_sacs_b2_a2": "Necesidad extrema de apoyo",
  "patient_shoulder_sacs_b3": "Califique su nivel de dificultad para levantar peso o inclinarse hacia su brazo",
  "patient_shoulder_sacs_b3_note": "por ejemplo, flexiones de brazo, ciclismo, yoga",
  "patient_shoulder_sacs_b3_a1": "Sin dificultad",
  "patient_shoulder_sacs_b3_a2": "Dificultad extrema o incapaz de realizar actividades",
  "patient_shoulder_sacs_b4": "Califica tu nivel de dificultad para llevar objetos pesados a tu lado por más de 5 minutos",
  "patient_shoulder_sacs_b4_note": "por ejemplo, bolsa de compras",
  "patient_shoulder_sacs_b4_a1": "Sin dificultad",
  "patient_shoulder_sacs_b4_a2": "Dificultad extrema o incapaz de realizar actividades",
  "patient_shoulder_sacs_b5": "Califique el nivel de dificultad para cruzar su cuerpo con el brazo afectado",
  "patient_shoulder_sacs_b5_note": "por ejemplo, estirar la mano y tocar el hombro no afectado",
  "patient_shoulder_sacs_b5_a1": "Sin dificultad",
  "patient_shoulder_sacs_b5_a2": "Dificultad extrema o incapaz de realizar actividades",
  "patient_shoulder_sacs_b6": "Califique su nivel de dificultad para empujar objetos pesados o pesos por encima de la cabeza",
  "patient_shoulder_sacs_b6_note": "por ejemplo, Más de cinco kilogramos",
  "patient_shoulder_sacs_b6_a1": "Sin dificultad",
  "patient_shoulder_sacs_b6_a2": "Dificultad extrema o incapaz de realizar actividades",
  "patient_shoulder_sacs_b7": "Califique el nivel de dificultad para sostener el brazo afectado frente a usted durante un período prolongado",
  "patient_shoulder_sacs_b7_note": "por ejemplo, sujetar el volante mientras se conduce",
  "patient_shoulder_sacs_b7_a1": "Sin dificultad",
  "patient_shoulder_sacs_b7_a2": "Dificultad extrema o incapaz de realizar actividades",
  "patient_shoulder_sacs_b8": "Califica el nivel de dificultad que experimentas para realizar tu ocupación habitual.",
  "patient_shoulder_sacs_b8_a1": "Sin dificultad",
  "patient_shoulder_sacs_b8_a2": "Dificultad extrema o incapaz de realizar actividades",
  "patient_shoulder_sacs_b8_a3": "No aplica",
  "patient_shoulder_sacs_b9": "Valore cuánto ha tenido que cambiar/modificar su ocupación habitual para compensar su problema de hombro.",
  "patient_shoulder_sacs_b9_a1": "Sin dificultad",
  "patient_shoulder_sacs_b9_a2": "Dificultad extrema o incapaz de realizar actividades",
  "patient_shoulder_sacs_b9_a3": "No aplica",
  "patient_shoulder_sacs_b10": "Califica el nivel de dificultad que experimentas al realizar tu actividad deportiva principal.",
  "patient_shoulder_sacs_b10_a1": "Sin dificultad",
  "patient_shoulder_sacs_b10_a2": "Dificultad extrema o incapaz de realizar actividades",
  "patient_shoulder_sacs_b10_a3": "No aplica",
  "patient_shoulder_sacs_b11": "Valore cuánto ha tenido que modificar su actividad deportiva principal para compensar su problema de hombro.",
  "patient_shoulder_sacs_b11_a1": "Sin dificultad",
  "patient_shoulder_sacs_b11_a2": "Dificultad extrema o incapaz de realizar actividades",
  "patient_shoulder_sacs_b11_a3": "No aplica",
  "patient_shoulder_sacs_sectionC": "Sección C: Calidad de vida y satisfacción",
  "patient_shoulder_sacs_c1": "Califique el nivel de diferencia en la apariencia de los huesos en la parte superior de su hombro en comparación con un hombro normal",
  "patient_shoulder_sacs_c1_note": "Incluyendo la presencia de un bulto",
  "patient_shoulder_sacs_c1_a1": "No es diferente",
  "patient_shoulder_sacs_c1_a2": "Extremadamente diferente",
  "patient_shoulder_sacs_c2": "Califique el nivel de ansiedad o estrés que le ha causado su problema en el hombro en general.",
  "patient_shoulder_sacs_c2_a1": "Sin ansiedad/estrés",
  "patient_shoulder_sacs_c2_a2": "Nivel severo de ansiedad/estrés",
  "patient_shoulder_sacs_c3": "¿Con qué frecuencia es consciente de su problema en el hombro?",
  "patient_shoulder_sacs_c3_a1": "Nunca",
  "patient_shoulder_sacs_c3_a2": "Constantemente",
  "patient_shoulder_sacs_c4": "¿Ha modificado su estilo de vida para evitar dañar o potencialmente agravar su problema de hombro?",
  "patient_shoulder_sacs_c4_a1": "En absoluto",
  "patient_shoulder_sacs_c4_a2": "Totalmente",
  "patient_shoulder_sacs_c5": "¿Qué tan infeliz o insatisfecho está con su hombro?",
  "patient_shoulder_sacs_c5_a1": "Extremadamente feliz",
  "patient_shoulder_sacs_c5_a2": "Extremadamente infeliz",
  "patient_shoulder_sacs_c6": "¿Cómo calificaría su hombro a nivel de porcentaje en comparación con sus expectativas de un hombro 'normal', donde 0% es malo y 100% es muy bueno?",
  "patient_shoulder_satisfaction": "Satisfacción",
  "patient_shoulder_satisfaction_q1": "Con respecto a tu hombro afectado, actualmente te encuentras",
  "patient_shoulder_satisfaction_q1_a1": "Satisfecho y/o mejor",
  "patient_shoulder_satisfaction_q1_a2": "No satisfecho y/o peor",
  "patient_shoulder_complete": "Fin del Cuestionario",
  "patient_shoulder_complete_text1": "Gracias por tomarse el tiempo de llenar este formulario",
  "patient_shoulder_complete_text2": "Por favor presiona enviar para completar y ver tus resultados",
  "patient_shoulder_complete_text3": "Sus respuestas serán tratadas de forma confidencial y cualquier informe solo utilizará información anonimizada",
  "patient_shoulder_results": "Resultados",
  "patient_shoulder_results_text1": "A continuación, se encuentran sus puntajes de dolor y funcionamiento del hombro, que han sido calculados a partir de su cuestionario.",
  "patient_shoulder_results_pain": "Dolor",
  "patient_shoulder_results_function": "Funcionamiento",
  "patient_shoulder_results_instability": "Inestabilidad",
  "patient_shoulder_results_selfevaluation": "Puntuación de la autoevaluación de hombro",
  "patient_shoulder_results_text2": "Su médico estará con usted en breve.",
  "patient_hip_pain": "Dolor",
  "patient_hip_pain_q1": "EN GENERAL, ¿Cómo describiría el dolor que ha sentido en la cadera EN EL ÚLTIMO MES?",
  "patient_hip_pain_q1_a1": "Ninguno",
  "patient_hip_pain_q1_a2": "Muy leve",
  "patient_hip_pain_q1_a3": "Leve",
  "patient_hip_pain_q1_a4": "Moderado",
  "patient_hip_pain_q1_a5": "Severo",
  "patient_hip_pain_q2": "¿Qué tan fuerte es el dolor de su cadera EN ESTE MOMENTO? (0 significa sin dolor y 10 significa el dolor más severo que puedas imaginar)",
  "patient_hip_pain_q2_a1": "Sin dolor",
  "patient_hip_pain_q2_a2": "El dolor más severo",
  "patient_hip_pain_q3": "¿Cuál de las siguientes opciones describen de mejor manera tu dolor?",
  "patient_hip_pain_q3_a1": "Nulo/Lo ignora",
  "patient_hip_pain_q3_a2": "Leve, ocasional, sin compromiso en la actividad",
  "patient_hip_pain_q3_a3": "Dolor suave, sin afectar actividades regulares, dolor despues de actividades, uso de aspirina",
  "patient_hip_pain_q3_a4": "Moderado, tolerable, ajustes para sobrellevar el dolor, uso ocacional de codeína",
  "patient_hip_pain_q3_a5": "Dolor marcado, limitación grave de las actividades",
  "patient_hip_pain_q3_a6": "Dolor totalmente incapacitante",
  "patient_hip_pain_q4": "¿Con qué FRECUENCIA le duele la cadera?",
  "patient_hip_pain_q4_a1": "Nunca",
  "patient_hip_pain_q4_a2": "Mensual",
  "patient_hip_pain_q4_a3": "Semanal",
  "patient_hip_pain_q4_a4": "Diario",
  "patient_hip_pain_q4_a5": "Siempre",
  "patient_hip_pain_q5": "¿Cuánto ha INTERFERIDO el dolor de su cadera CON SU TRABAJO HABITUAL (incluyendo las tareas del hogar)?",
  "patient_hip_pain_q5_a1": "En absoluto",
  "patient_hip_pain_q5_a2": "Un poco",
  "patient_hip_pain_q5_a3": "Moderadamente",
  "patient_hip_pain_q5_a4": "Enormemente",
  "patient_hip_pain_q5_a5": "Totalmente",
  "patient_hip_pain_instruction": "¿Qué CANTIDAD DE DOLOR DE CADERA ha experimentado durante las siguientes actividades?",
  "patient_hip_pain_instruction_0": "Ninguno",
  "patient_hip_pain_instruction_1": "Leve",
  "patient_hip_pain_instruction_2": "Moderado",
  "patient_hip_pain_instruction_3": "Severo",
  "patient_hip_pain_instruction_4": "Extremo",
  "patient_hip_pain_note": "Nota: Por favor responder las siguientes preguntas en relacion a las actividades que involucren la cadera.",
  "patient_hip_pain_q6": "Enderezar la cadera completamente",
  "patient_hip_pain_q6_a1": "Ninguno",
  "patient_hip_pain_q6_a2": "Leve",
  "patient_hip_pain_q6_a3": "Moderado",
  "patient_hip_pain_q6_a4": "Severo",
  "patient_hip_pain_q6_a5": "Extremo",
  "patient_hip_pain_q7": "Doblar la cadera completamente",
  "patient_hip_pain_q7_a1": "Ninguno",
  "patient_hip_pain_q7_a2": "Leve",
  "patient_hip_pain_q7_a3": "Moderado",
  "patient_hip_pain_q7_a4": "Severo",
  "patient_hip_pain_q7_a5": "Extremo",
  "patient_hip_pain_q8": "Caminar sobre una superficie plana",
  "patient_hip_pain_q8_a1": "Ninguno",
  "patient_hip_pain_q8_a2": "Leve",
  "patient_hip_pain_q8_a3": "Moderado",
  "patient_hip_pain_q8_a4": "Severo",
  "patient_hip_pain_q8_a5": "Extremo",
  "patient_hip_pain_q9": "¿Cuánto tiempo puede caminar antes de que el dolor de su cadera se vuelva severo? (con o sin bastón)",
  "patient_hip_pain_q9_a1": "Sin dolor/Más de 30 minutos/Ilimitado",
  "patient_hip_pain_q9_a2": "16-30 minutos/6 cuadras",
  "patient_hip_pain_q9_a3": "5-15 minutos/2-3 cuadras",
  "patient_hip_pain_q9_a4": "Alrededor de la casa / Solo en el interior",
  "patient_hip_pain_q9_a5": "Sin poder caminar/Dolor severo al caminar/Solo se puede estar en cama o sentado",
  "patient_hip_pain_q10": "Caminar sobre una superficie dura (hormigón, asfálto, etc.)",
  "patient_hip_pain_q10_a1": "Ninguno",
  "patient_hip_pain_q10_a2": "Leve",
  "patient_hip_pain_q10_a3": "Moderado",
  "patient_hip_pain_q10_a4": "Severo",
  "patient_hip_pain_q10_a5": "Extremo",
  "patient_hip_pain_q11": "Caminar sobre una superficie irregular",
  "patient_hip_pain_q11_a1": "Ninguno",
  "patient_hip_pain_q11_a2": "Leve",
  "patient_hip_pain_q11_a3": "Moderado",
  "patient_hip_pain_q11_a4": "Severo",
  "patient_hip_pain_q11_a5": "Extremo",
  "patient_hip_pain_q12": "Subir o bajar escaleras",
  "patient_hip_pain_q12_a1": "Ninguno",
  "patient_hip_pain_q12_a2": "Leve",
  "patient_hip_pain_q12_a3": "Moderado",
  "patient_hip_pain_q12_a4": "Severo",
  "patient_hip_pain_q12_a5": "Extremo",
  "patient_hip_pain_q13": "Por la noche, mientras está en la cama",
  "patient_hip_pain_q13_a1": "Ninguno",
  "patient_hip_pain_q13_a2": "Leve",
  "patient_hip_pain_q13_a3": "Moderado",
  "patient_hip_pain_q13_a4": "Severo",
  "patient_hip_pain_q13_a5": "Extremo",
  "patient_hip_pain_q14": "¿Con qué frecuencia le ha preocupado el dolor de la cadera en la cama por la noche?",
  "patient_hip_pain_q14_a1": "Ninguna noche",
  "patient_hip_pain_q14_a2": "Sólo 1 o 2 noches",
  "patient_hip_pain_q14_a3": "Algunas noches",
  "patient_hip_pain_q14_a4": "La mayoría de las noches",
  "patient_hip_pain_q14_a5": "Cada noche",
  "patient_hip_pain_q15": "Sentado o acostado",
  "patient_hip_pain_q15_a1": "Ninguno",
  "patient_hip_pain_q15_a2": "Leve",
  "patient_hip_pain_q15_a3": "Moderado",
  "patient_hip_pain_q15_a4": "Severo",
  "patient_hip_pain_q15_a5": "Extremo",
  "patient_hip_pain_q16": "Estando de pie",
  "patient_hip_pain_q16_a1": "Ninguno",
  "patient_hip_pain_q16_a2": "Leve",
  "patient_hip_pain_q16_a3": "Moderado",
  "patient_hip_pain_q16_a4": "Severo",
  "patient_hip_pain_q16_a5": "Extremo",
  "patient_hip_pain_q17": "Levantarse de una silla",
  "patient_hip_pain_q17_a1": "Ninguno",
  "patient_hip_pain_q17_a2": "Leve",
  "patient_hip_pain_q17_a3": "Moderado",
  "patient_hip_pain_q17_a4": "Severo",
  "patient_hip_pain_q17_a5": "Extremo",
  "patient_hip_pain_q18": "Después de una actividad",
  "patient_hip_pain_q18_a1": "Ninguno",
  "patient_hip_pain_q18_a2": "Leve",
  "patient_hip_pain_q18_a3": "Moderado",
  "patient_hip_pain_q18_a4": "Severo",
  "patient_hip_pain_q18_a5": "Extremo",
  "patient_hip_other_symptoms": "Otros síntomas",
  "patient_hip_other_symptoms_instruction1": "Por favor elija la respuesta que mejor corresponda con sus síntomas de cadera",
  "patient_hip_other_symptoms_instruction2": "Si no está seguro de como responder una pregunta, dé la mejor respuesta que pueda",
  "patient_hip_other_symptoms_instruction3": "¿Tienes alguno de los siguientes síntomas?",
  "patient_hip_other_symptoms_instruction_0": "Ninguno",
  "patient_hip_other_symptoms_instruction_1": "Leve",
  "patient_hip_other_symptoms_instruction_2": "Moderado",
  "patient_hip_other_symptoms_instruction_3": "Severo",
  "patient_hip_other_symptoms_instruction_4": "Extremo",
  "patient_hip_other_symptoms_q1": "Rechinamiento, chasquidos o cualquier otro tipo de ruido de la cadera",
  "patient_hip_other_symptoms_q1_a1": "Nunca",
  "patient_hip_other_symptoms_q1_a2": "Raramente",
  "patient_hip_other_symptoms_q1_a3": "A veces",
  "patient_hip_other_symptoms_q1_a4": "Frecuentemente",
  "patient_hip_other_symptoms_q1_a5": "Siempre",
  "patient_hip_other_symptoms_q2": "Enganche o bloqueo",
  "patient_hip_other_symptoms_q2_a1": "Ninguno",
  "patient_hip_other_symptoms_q2_a2": "Leve",
  "patient_hip_other_symptoms_q2_a3": "Moderado",
  "patient_hip_other_symptoms_q2_a4": "Severo",
  "patient_hip_other_symptoms_q2_a5": "Extremo",
  "patient_hip_other_symptoms_q3": "Rigidez después de despertarse por la mañana",
  "patient_hip_other_symptoms_q3_a1": "Ninguno",
  "patient_hip_other_symptoms_q3_a2": "Leve",
  "patient_hip_other_symptoms_q3_a3": "Moderado",
  "patient_hip_other_symptoms_q3_a4": "Severo",
  "patient_hip_other_symptoms_q3_a5": "Extremo",
  "patient_hip_other_symptoms_q4": "Rigidez después de sentarse, acostarse o descansar más tarde en el día",
  "patient_hip_other_symptoms_q4_a1": "Ninguno",
  "patient_hip_other_symptoms_q4_a2": "Leve",
  "patient_hip_other_symptoms_q4_a3": "moderado",
  "patient_hip_other_symptoms_q4_a4": "Severo",
  "patient_hip_other_symptoms_q4_a5": "Extremo",
  "patient_hip_other_symptoms_q5": "Cojear",
  "patient_hip_other_symptoms_q5_a1": "Nunca/Raramente",
  "patient_hip_other_symptoms_q5_a2": "Leve, a veces o solo en un comienzo",
  "patient_hip_other_symptoms_q5_a3": "Moderado, seguido",
  "patient_hip_other_symptoms_q5_a4": "Severo, la mayoria del tiempo",
  "patient_hip_other_symptoms_q5_a5": "Todo el tiempo o incapaz de caminar",
  "patient_hip_other_symptoms_q6": "Dolor intenso y repentino: \"punzadas\" o \"espasmos\" en la cadera",
  "patient_hip_other_symptoms_q6_a1": "Ningún día",
  "patient_hip_other_symptoms_q6_a2": "Solo 1 o 2 días",
  "patient_hip_other_symptoms_q6_a3": "Algunos días",
  "patient_hip_other_symptoms_q6_a4": "La mayoría de los días",
  "patient_hip_other_symptoms_q6_a5": "Todos los días",
  "patient_hip_other_symptoms_q7": "Cedimiento de la cadera",
  "patient_hip_other_symptoms_q7_a1": "Ninguno",
  "patient_hip_other_symptoms_q7_a2": "Leve",
  "patient_hip_other_symptoms_q7_a3": "Moderado",
  "patient_hip_other_symptoms_q7_a4": "Severo",
  "patient_hip_other_symptoms_q7_a5": "Extremo",
  "patient_hip_other_symptoms_q8": "Movimiento disminuido",
  "patient_hip_other_symptoms_q8_a1": "Ninguno",
  "patient_hip_other_symptoms_q8_a2": "Leve",
  "patient_hip_other_symptoms_q8_a3": "Moderado",
  "patient_hip_other_symptoms_q8_a4": "Severo",
  "patient_hip_other_symptoms_q8_a5": "Extremo",
  "patient_hip_other_symptoms_q9": "¿Utiliza algún apoyo para caminar?",
  "patient_hip_other_symptoms_q9_a1": "Ninguno",
  "patient_hip_other_symptoms_q9_a2": "Bastón para largas caminatas",
  "patient_hip_other_symptoms_q9_a3": "Bastón la mayor parte del tiempo",
  "patient_hip_other_symptoms_q9_a4": "Una muleta",
  "patient_hip_other_symptoms_q9_a5": "Dos bastones",
  "patient_hip_other_symptoms_q9_a6": "Dos muletas o incapaz de caminar",
  "patient_hip_function": "Funcionamiento",
  "patient_hip_function_instruction": "Indique el GRADO DE DIFICULTAD que ha experimentado debido a su cadera al realizar las siguientes actividades",
  "patient_hip_function_instruction_0": "Ninguno",
  "patient_hip_function_instruction_1": "Leve",
  "patient_hip_function_instruction_2": "Moderado",
  "patient_hip_function_instruction_3": "Severo",
  "patient_hip_function_instruction_4": "Extremo/Incapaz de realizar",
  "patient_hip_function_note": "Nota: Si hay ciertas actividades que no realizas, imagina como se sentiría tu cadera al intentarlo.",
  "patient_hip_function_q1": "Bajar las escaleras",
  "patient_hip_function_q1_a1": "Ninguno",
  "patient_hip_function_q1_a2": "Leve",
  "patient_hip_function_q1_a3": "Moderado",
  "patient_hip_function_q1_a4": "Severo",
  "patient_hip_function_q1_a5": "Extremo",
  "patient_hip_function_q2": "Subir las escaleras",
  "patient_hip_function_q2_a1": "Ninguno",
  "patient_hip_function_q2_a2": "Leve",
  "patient_hip_function_q2_a3": "Moderado",
  "patient_hip_function_q2_a4": "Severo",
  "patient_hip_function_q2_a5": "Extremo",
  "patient_hip_function_q3": "Sentarse",
  "patient_hip_function_q3_a1": "Ninguno",
  "patient_hip_function_q3_a2": "Leve",
  "patient_hip_function_q3_a3": "Moderado",
  "patient_hip_function_q3_a4": "Severo",
  "patient_hip_function_q3_a5": "Extremo",
  "patient_hip_function_q4": "Levantarse de estar sentado",
  "patient_hip_function_q4_a1": "Ninguno",
  "patient_hip_function_q4_a2": "Leve",
  "patient_hip_function_q4_a3": "Moderado",
  "patient_hip_function_q4_a4": "Severo",
  "patient_hip_function_q4_a5": "Extremo",
  "patient_hip_function_q5": "Levantarse y bajarse del piso/suelo",
  "patient_hip_function_q5_a1": "Ninguno",
  "patient_hip_function_q5_a2": "Leve",
  "patient_hip_function_q5_a3": "Moderado",
  "patient_hip_function_q5_a4": "Severo",
  "patient_hip_function_q5_a5": "Extremo",
  "patient_hip_function_q6": "Estar de pie",
  "patient_hip_function_q6_a1": "Ninguno",
  "patient_hip_function_q6_a2": "Leve",
  "patient_hip_function_q6_a3": "Moderado",
  "patient_hip_function_q6_a4": "Severo",
  "patient_hip_function_q6_a5": "Extremo",
  "patient_hip_function_q7": "Estar de pie durante 15 minutos",
  "patient_hip_function_q7_a1": "Ninguno",
  "patient_hip_function_q7_a2": "Leve",
  "patient_hip_function_q7_a3": "Moderado",
  "patient_hip_function_q7_a4": "Severo",
  "patient_hip_function_q7_a5": "Extremo",
  "patient_hip_function_q8": "Abrir las piernas bien separadas",
  "patient_hip_function_q8_a1": "Ninguno",
  "patient_hip_function_q8_a2": "Leve",
  "patient_hip_function_q8_a3": "Moderado",
  "patient_hip_function_q8_a4": "Severo",
  "patient_hip_function_q8_a5": "Extremo",
  "patient_hip_function_q9": "Dar zancadas al caminar",
  "patient_hip_function_q9_a1": "Ninguno",
  "patient_hip_function_q9_a2": "Leve",
  "patient_hip_function_q9_a3": "Moderado",
  "patient_hip_function_q9_a4": "Severo",
  "patient_hip_function_q9_a5": "Extremo",
  "patient_hip_function_q10": "Agacharse al piso/recoger un objeto",
  "patient_hip_function_q10_a1": "Ninguno",
  "patient_hip_function_q10_a2": "Leve",
  "patient_hip_function_q10_a3": "Moderado",
  "patient_hip_function_q10_a4": "Severo",
  "patient_hip_function_q10_a5": "Extremo",
  "patient_hip_function_q11": "Caminar sobre una superficie plana",
  "patient_hip_function_q11_a1": "Ninguno",
  "patient_hip_function_q11_a2": "Leve",
  "patient_hip_function_q11_a3": "Moderado",
  "patient_hip_function_q11_a4": "Severo",
  "patient_hip_function_q11_a5": "Extremo",
  "patient_hip_function_q12": "Caminar sobre una superficie irregular",
  "patient_hip_function_q12_a1": "Ninguno",
  "patient_hip_function_q12_a2": "Leve",
  "patient_hip_function_q12_a3": "Moderado",
  "patient_hip_function_q12_a4": "Severo",
  "patient_hip_function_q12_a5": "Extremo",
  "patient_hip_function_q13": "Caminar largas distancias",
  "patient_hip_function_q13_a1": "Ninguno",
  "patient_hip_function_q13_a2": "Leve",
  "patient_hip_function_q13_a3": "Moderado",
  "patient_hip_function_q13_a4": "Severo",
  "patient_hip_function_q13_a5": "Extremo",
  "patient_hip_function_q14": "Caminar para hacer ejercicio",
  "patient_hip_function_q14_a1": "Ninguno",
  "patient_hip_function_q14_a2": "Leve",
  "patient_hip_function_q14_a3": "Moderado",
  "patient_hip_function_q14_a4": "Severo",
  "patient_hip_function_q14_a5": "Extremo",
  "patient_hip_function_q15": "Subir colinas empinadas",
  "patient_hip_function_q15_a1": "Ninguno",
  "patient_hip_function_q15_a2": "Leve",
  "patient_hip_function_q15_a3": "Moderado",
  "patient_hip_function_q15_a4": "Severo",
  "patient_hip_function_q15_a5": "Extremo",
  "patient_hip_function_q16": "Caminar por colinas empinadas",
  "patient_hip_function_q16_a1": "Ninguno",
  "patient_hip_function_q16_a2": "Leve",
  "patient_hip_function_q16_a3": "Moderado",
  "patient_hip_function_q16_a4": "Severo",
  "patient_hip_function_q16_a5": "Extremo",
  "patient_hip_function_q17": "Primeros pasos al caminar",
  "patient_hip_function_q17_a1": "Ninguno",
  "patient_hip_function_q17_a2": "Leve",
  "patient_hip_function_q17_a3": "Moderado",
  "patient_hip_function_q17_a4": "Severo",
  "patient_hip_function_q17_a5": "Extremo",
  "patient_hip_function_q18": "Caminar por aproximadamente 10 minutos",
  "patient_hip_function_q18_a1": "Ninguno",
  "patient_hip_function_q18_a2": "Leve",
  "patient_hip_function_q18_a3": "Moderado",
  "patient_hip_function_q18_a4": "Severo",
  "patient_hip_function_q18_a5": "Extremo",
  "patient_hip_function_q19": "Caminar 15 minutos o más",
  "patient_hip_function_q19_a1": "Ninguno",
  "patient_hip_function_q19_a2": "Leve",
  "patient_hip_function_q19_a3": "Moderado",
  "patient_hip_function_q19_a4": "Severo",
  "patient_hip_function_q19_a5": "Extremo",
  "patient_hip_function_q20": "Subir y bajar bordillos",
  "patient_hip_function_q20_a1": "Ninguno",
  "patient_hip_function_q20_a2": "Leve",
  "patient_hip_function_q20_a3": "Moderado",
  "patient_hip_function_q20_a4": "Severo",
  "patient_hip_function_q20_a5": "Extremo",
  "patient_hip_function_q21": "Entrar/bajar del automóvil o del transporte público (autobús), cualquiera que sea su uso habitual",
  "patient_hip_function_q21_a1": "Ninguno",
  "patient_hip_function_q21_a2": "Leve",
  "patient_hip_function_q21_a3": "Moderado",
  "patient_hip_function_q21_a4": "Severo",
  "patient_hip_function_q21_a5": "Extremo",
  "patient_hip_function_q22": "Ir de compras",
  "patient_hip_function_q22_a1": "Ninguno",
  "patient_hip_function_q22_a2": "Leve",
  "patient_hip_function_q22_a3": "Moderado",
  "patient_hip_function_q22_a4": "Severo",
  "patient_hip_function_q22_a5": "Extremo",
  "patient_hip_function_q23": "Ponerse calcetines/medias",
  "patient_hip_function_q23_a1": "Ninguno",
  "patient_hip_function_q23_a2": "Leve",
  "patient_hip_function_q23_a3": "Moderado",
  "patient_hip_function_q23_a4": "Severo",
  "patient_hip_function_q23_a5": "Extremo",
  "patient_hip_function_q24": "Quitarse calcetines/medias",
  "patient_hip_function_q24_a1": "Ninguno",
  "patient_hip_function_q24_a2": "Leve",
  "patient_hip_function_q24_a3": "Moderado",
  "patient_hip_function_q24_a4": "Severo",
  "patient_hip_function_q24_a5": "Extremo",
  "patient_hip_function_q25": "Acostarse en la cama (darse la vuelta, mantener la posición de la cadera)",
  "patient_hip_function_q25_a1": "Ninguno",
  "patient_hip_function_q25_a2": "Leve",
  "patient_hip_function_q25_a3": "Moderado",
  "patient_hip_function_q25_a4": "Severo",
  "patient_hip_function_q25_a5": "Extremo",
  "patient_hip_function_q26": "Darse la vuelta en la cama",
  "patient_hip_function_q26_a1": "Ninguno",
  "patient_hip_function_q26_a2": "Leve",
  "patient_hip_function_q26_a3": "Moderado",
  "patient_hip_function_q26_a4": "Severo",
  "patient_hip_function_q26_a5": "Extremo",
  "patient_hip_function_q27": "Levantarse de la cama",
  "patient_hip_function_q27_a1": "Ninguno",
  "patient_hip_function_q27_a2": "Leve",
  "patient_hip_function_q27_a3": "Moderado",
  "patient_hip_function_q27_a4": "Severo",
  "patient_hip_function_q27_a5": "Extremo",
  "patient_hip_function_q28": "Entrar y salir de la bañera",
  "patient_hip_function_q28_a1": "Ninguno",
  "patient_hip_function_q28_a2": "Leve",
  "patient_hip_function_q28_a3": "Moderado",
  "patient_hip_function_q28_a4": "Severo",
  "patient_hip_function_q28_a5": "Extremo",
  "patient_hip_function_q29": "Sentarse y levantarse del inodoro",
  "patient_hip_function_q29_a1": "Ninguno",
  "patient_hip_function_q29_a2": "Leve",
  "patient_hip_function_q29_a3": "Moderado",
  "patient_hip_function_q29_a4": "Severo",
  "patient_hip_function_q29_a5": "Extremo",
  "patient_hip_function_q30": "Lavarse y secarse (por todas partes)",
  "patient_hip_function_q30_a1": "Ninguno",
  "patient_hip_function_q30_a2": "Leve",
  "patient_hip_function_q30_a3": "Moderado",
  "patient_hip_function_q30_a4": "Severo",
  "patient_hip_function_q30_a5": "Extremo",
  "patient_hip_function_q31": "Trabajo ligero a moderado (estar de pie, caminar)",
  "patient_hip_function_q31_a1": "Ninguno",
  "patient_hip_function_q31_a2": "Leve",
  "patient_hip_function_q31_a3": "Moderado",
  "patient_hip_function_q31_a4": "Severo",
  "patient_hip_function_q31_a5": "Extremo",
  "patient_hip_function_q32": "Tareas domésticas pesadas (mover cajas pesadas, fregar pisos, etc.)",
  "patient_hip_function_q32_a1": "Ninguno",
  "patient_hip_function_q32_a2": "Leve",
  "patient_hip_function_q32_a3": "Moderado",
  "patient_hip_function_q32_a4": "Severo",
  "patient_hip_function_q32_a5": "Extremo",
  "patient_hip_function_q33": "Tareas domésticas ligeras (cocinar, quitar el polvo, etc.)",
  "patient_hip_function_q33_a1": "Ninguno",
  "patient_hip_function_q33_a2": "Leve",
  "patient_hip_function_q33_a3": "Moderado",
  "patient_hip_function_q33_a4": "Severo",
  "patient_hip_function_q33_a5": "Extremo",
  "patient_hip_function_q34": "Tomar en brazos o cargar niños",
  "patient_hip_function_q34_a1": "Ninguno",
  "patient_hip_function_q34_a2": "Leve",
  "patient_hip_function_q34_a3": "Moderado",
  "patient_hip_function_q34_a4": "Severo",
  "patient_hip_function_q34_a5": "Extremo",
  "patient_hip_function_q35": "Hacer sentadillas",
  "patient_hip_function_q35_a1": "Ninguno",
  "patient_hip_function_q35_a2": "Leve",
  "patient_hip_function_q35_a3": "Moderado",
  "patient_hip_function_q35_a4": "Severo",
  "patient_hip_function_q35_a5": "Extremo",
  "patient_hip_function_q36": "Trotar para hacer ejercicio",
  "patient_hip_function_q36_a1": "Ninguno",
  "patient_hip_function_q36_a2": "Leve",
  "patient_hip_function_q36_a3": "Moderado",
  "patient_hip_function_q36_a4": "Severo",
  "patient_hip_function_q36_a5": "Extremo",
  "patient_hip_function_q37": "Correr",
  "patient_hip_function_q37_a1": "Ninguno",
  "patient_hip_function_q37_a2": "Leve",
  "patient_hip_function_q37_a3": "Moderado",
  "patient_hip_function_q37_a4": "Severo",
  "patient_hip_function_q37_a5": "Extremo",
  "patient_hip_function_q38": "Girar/pivotear sobre la pierna de apoyo",
  "patient_hip_function_q38_a1": "Ninguno",
  "patient_hip_function_q38_a2": "Leve",
  "patient_hip_function_q38_a3": "Moderado",
  "patient_hip_function_q38_a4": "Severo",
  "patient_hip_function_q38_a5": "Extremo",
  "patient_hip_function_q39": "Actividad sexual",
  "patient_hip_function_q39_a1": "Ninguno",
  "patient_hip_function_q39_a2": "Leve",
  "patient_hip_function_q39_a3": "Moderado",
  "patient_hip_function_q39_a4": "Severo",
  "patient_hip_function_q39_a5": "Extremo",
  "patient_hip_function_q39_not_relevant": "Esto no es relevante para mi",
  "patient_hip_function_q40": "Deportes de alta demanda que involucran cambios de velocidad (por ejemplo, fútbol, baloncesto, tenis, ejercicios aeróbicos)",
  "patient_hip_function_q40_a1": "Ninguno",
  "patient_hip_function_q40_a2": "Leve",
  "patient_hip_function_q40_a3": "Moderado",
  "patient_hip_function_q40_a4": "Severo",
  "patient_hip_function_q40_a5": "Extremo",
  "patient_hip_function_q41": "Deportes de baja demanda (por ejemplo, golf, bolos)",
  "patient_hip_function_q41_a1": "Ninguno",
  "patient_hip_function_q41_a2": "Leve",
  "patient_hip_function_q41_a3": "Moderado",
  "patient_hip_function_q41_a4": "Severo",
  "patient_hip_function_q41_a5": "Extremo",
  "patient_hip_function_q42": "Actividades recreativas",
  "patient_hip_function_q42_a1": "Ninguno",
  "patient_hip_function_q42_a2": "Leve",
  "patient_hip_function_q42_a3": "Moderado",
  "patient_hip_function_q42_a4": "Severo",
  "patient_hip_function_q42_a5": "Extremo",
  "patient_hip_function_q43": "¿Cómo calificaría su cadera a nivel de porcentaje en comparación con sus expectativas de un hombro 'normal', donde 0% es malo y 100% es muy bueno?",
  "patient_hip_function_q44": "Especifique el nivel más alto de actividad que puede realizar",
  "patient_hip_function_q44_q1": "Antes de la lesión de cadera / problema de cadera",
  "patient_hip_function_q44_q1_a1": "Competitivo",
  "patient_hip_function_q44_q1_a2": "Recreativo, con aptitud física",
  "patient_hip_function_q44_q1_a3": "Sin realizar deportes, solo trabajo",
  "patient_hip_function_q44_q1_a4": "Sólo actividades de la vida diaria",
  "patient_hip_function_q44_q1_a5": "Licencia por enfermedad o pensión de invalidez por problemas de salud",
  "patient_hip_function_q44_q1_a1_o1": "Deportes competitivos jugados a nivel mundial, olímpico o profesional",
  "patient_hip_function_q44_q1_a1_o2": "Deportes competitivos como atletismo, deportes de raqueta / pelota, gimnasia, remo, esquí o artes marciales realizados a nivel nacional o internacional",
  "patient_hip_function_q44_q1_a1_o3": "Deportes competitivos como deportes acuáticos, ciclismo, hockey, curling o equitación / rodeo jugado a nivel nacional o internacional",
  "patient_hip_function_q44_q1_a2_o1": "Deportes recreativos como correr, deportes de pelota / raqueta, entrenamiento con pesas, curling, remo, golf, yoga o gimnasia al menos 5 veces por semana",
  "patient_hip_function_q44_q1_a2_o2": "Deportes recreativos como natación, deportes acuáticos, esquí, hockey, patinaje, ciclismo, equitación o montañismo al menos 5 veces por semana",
  "patient_hip_function_q44_q1_a2_o3": "Deportes recreativos al menos dos veces por semana",
  "patient_hip_function_q44_q1_a2_o4": "Deportes recreativos una vez a la semana o menos",
  "patient_hip_function_q44_q1_a2_o5": "Deportes recreativos menos de una vez al mes",
  "patient_hip_function_q44_q1_a3_o1": "Trabajo pesado como la construcción",
  "patient_hip_function_q44_q1_a3_o2": "Trabajo moderadamente pesado, como conducir camiones",
  "patient_hip_function_q44_q1_a3_o3": "Trabajo ligero como la enfermería",
  "patient_hip_function_q44_q1_a3_o4": "Trabajo sedentario como el trabajo de secretariado o basado en computadora",
  "patient_hip_function_q44_q1_a4_o1": "Actividades diarias como jardinería, subir varios tramos de escaleras, llevar cargas, empujar / tirar de una carga, capacidad de correr si llega tarde",
  "patient_hip_function_q44_q1_a4_o2": "Actividades diarias como limpiar la casa, subir 1 o 2 tramos de escaleras o caminar cargando una carga ligera",
  "patient_hip_function_q44_q1_a4_o3": "Actividades diarias limitadas (por ejemplo, sin subir escaleras ni transportar cargas)",
  "patient_hip_function_q44_q2": "Estado actual.",
  "patient_hip_function_q44_q2_a1": "Competitivo",
  "patient_hip_function_q44_q2_a2": "Recreativo, con aptitud física",
  "patient_hip_function_q44_q2_a3": "Sin realizar deportes, solo trabajo",
  "patient_hip_function_q44_q2_a4": "Sólo actividades de la vida diaria",
  "patient_hip_function_q44_q2_a5": "Licencia por enfermedad o pensión de invalidez por problemas de salud",
  "patient_hip_function_q44_q2_a1_o1": "Deportes competitivos jugados a nivel mundial, olímpico o profesional",
  "patient_hip_function_q44_q2_a1_o2": "Deportes competitivos como atletismo, deportes de raqueta / pelota, gimnasia, remo, esquí o artes marciales realizados a nivel nacional o internacional",
  "patient_hip_function_q44_q2_a1_o3": "Deportes competitivos como deportes acuáticos, ciclismo, hockey, curling o equitación / rodeo jugado a nivel nacional o internacional",
  "patient_hip_function_q44_q2_a2_o1": "Deportes recreativos como correr, deportes de pelota / raqueta, entrenamiento con pesas, curling, remo, golf, yoga o gimnasia al menos 5 veces por semana",
  "patient_hip_function_q44_q2_a2_o2": "Deportes recreativos como natación, deportes acuáticos, esquí, hockey, patinaje, ciclismo, equitación o montañismo al menos 5 veces por semana",
  "patient_hip_function_q44_q2_a2_o3": "Deportes recreativos al menos dos veces por semana",
  "patient_hip_function_q44_q2_a2_o4": "Deportes recreativos una vez a la semana o menos",
  "patient_hip_function_q44_q2_a2_o5": "Deportes recreativos menos de una vez al mes",
  "patient_hip_function_q44_q2_a3_o1": "Trabajo pesado como la construcción",
  "patient_hip_function_q44_q2_a3_o2": "Trabajo moderadamente pesado, como conducir camiones",
  "patient_hip_function_q44_q2_a3_o3": "Trabajo ligero como la enfermería",
  "patient_hip_function_q44_q2_a3_o4": "Trabajo sedentario como el trabajo de secretariado o basado en computadora",
  "patient_hip_function_q44_q2_a4_o1": "Actividades diarias como jardinería, subir varios tramos de escaleras, llevar cargas, empujar / tirar de una carga, capacidad de correr si llega tarde",
  "patient_hip_function_q44_q2_a4_o2": "Actividades diarias como limpiar la casa, subir 1 o 2 tramos de escaleras o caminar cargando una carga ligera",
  "patient_hip_function_q44_q2_a4_o3": "Actividades diarias limitadas (por ejemplo, sin subir escaleras ni transportar cargas)",
  "patient_hip_quality_of_life": "Calidad de vida",
  "patient_hip_quality_of_life_instruction": "Las siguientes preguntas se refieren a inquietudes sociales, emocionales y de estilo de vida que pueda tener con respecto a su problema de cadera. Piense en cómo se ha sentido la mayor parte del tiempo en relación con su cadera.",
  "patient_hip_quality_of_life_q1": "¿Con qué frecuencia es usted consciente de su problema de cadera?",
  "patient_hip_quality_of_life_q1_a1": "Nunca",
  "patient_hip_quality_of_life_q1_a2": "Mensual",
  "patient_hip_quality_of_life_q1_a3": "Semanal",
  "patient_hip_quality_of_life_q1_a4": "Diario",
  "patient_hip_quality_of_life_q1_a5": "Constantemente",
  "patient_hip_quality_of_life_q2": "¿Ha modificado su estilo de vida para evitar actividades potencialmente dañinas para su cadera?",
  "patient_hip_quality_of_life_q2_a1": "En absoluto",
  "patient_hip_quality_of_life_q2_a2": "Levemente",
  "patient_hip_quality_of_life_q2_a3": "Moderadamente",
  "patient_hip_quality_of_life_q2_a4": "Severamente",
  "patient_hip_quality_of_life_q2_a5": "Totalmente",
  "patient_hip_quality_of_life_q3": "¿Qué tan preocupado está por su capacidad para mantener un nivel de condición física deseado?",
  "patient_hip_quality_of_life_q3_a1": "Sin preocupación en absoluto",
  "patient_hip_quality_of_life_q3_a2": "Extremadamente preocupado",
  "patient_hip_quality_of_life_q4": "¿Qué tantos problemas le provoca la falta de confianza en tu cadera?",
  "patient_hip_quality_of_life_q4_a1": "En absoluto",
  "patient_hip_quality_of_life_q4_a2": "Levemente",
  "patient_hip_quality_of_life_q4_a3": "Moderadamente",
  "patient_hip_quality_of_life_q4_a4": "Severamente",
  "patient_hip_quality_of_life_q4_a5": "Extremadamente",
  "patient_hip_quality_of_life_q5": "¿Qué tanto le distrae su problema de cadera?",
  "patient_hip_quality_of_life_q5_a1": "Sin distracciones en absoluto",
  "patient_hip_quality_of_life_q5_a2": "Distracción extrema",
  "patient_hip_quality_of_life_q6": "En general, ¿Qué tantas dificultades tiene con la cadera?",
  "patient_hip_quality_of_life_q6_a1": "Ninguna",
  "patient_hip_quality_of_life_q6_a2": "Leve",
  "patient_hip_quality_of_life_q6_a3": "Moderada",
  "patient_hip_quality_of_life_q6_a4": "Severa",
  "patient_hip_quality_of_life_q6_a5": "Extrema",
  "patient_hip_quality_of_life_q7": "¿Qué tan ansioso/deprimido estás hoy?",
  "patient_hip_quality_of_life_q7_a1": "En absoluto",
  "patient_hip_quality_of_life_q7_a2": "Levemente",
  "patient_hip_quality_of_life_q7_a3": "Moderadamente",
  "patient_hip_quality_of_life_q7_a4": "Severamente",
  "patient_hip_quality_of_life_q7_a5": "Extremadamente",
  "patient_hip_quality_of_life_q8": "¿Qué tan buena/mala es su salud hoy? (100 significa la mejor salud 0 significa la peor salud que puedas imaginar)",
  "patient_hip_quality_of_life_q8_a1": "Peor salud",
  "patient_hip_quality_of_life_q8_a2": "Mejor salud",
  "patient_hip_satisfaction": "Satisfacción",
  "patient_hip_satisfaction_instructions": "¿Qué tan satisfecho está con los resultados de su tratamiento/cirugía de cadera?",
  "patient_hip_satisfaction_instructions_1": "Muy insatisfecho",
  "patient_hip_satisfaction_instructions_2": "De alguna manera insatisfecho",
  "patient_hip_satisfaction_instructions_3": "De alguna manera satisfecho",
  "patient_hip_satisfaction_instructions_4": "Muy Satisfecho",
  "patient_hip_satisfaction_q1": "En general",
  "patient_hip_satisfaction_q1_not_applicable": "No aplicable",
  "patient_hip_satisfaction_q1_a1": "Muy insatisfecho",
  "patient_hip_satisfaction_q1_a2": "De alguna manera insatisfecho",
  "patient_hip_satisfaction_q1_a3": "De alguna manera satisfecho",
  "patient_hip_satisfaction_q1_a4": "Muy Satisfecho",
  "patient_hip_satisfaction_q2": "¿En la mejora de su dolor?",
  "patient_hip_satisfaction_q2_not_applicable": "No aplicable",
  "patient_hip_satisfaction_q2_a1": "Muy insatisfecho",
  "patient_hip_satisfaction_q2_a2": "De alguna manera insatisfecho",
  "patient_hip_satisfaction_q2_a3": "De alguna manera satisfecho",
  "patient_hip_satisfaction_q2_a4": "Muy Satisfecho",
  "patient_hip_satisfaction_q3": "¿En la mejora de su capacidad para hacer trabajos en el hogar o en el jardín?",
  "patient_hip_satisfaction_q3_not_applicable": "No aplicable",
  "patient_hip_satisfaction_q3_a1": "Muy insatisfecho",
  "patient_hip_satisfaction_q3_a2": "De alguna manera insatisfecho",
  "patient_hip_satisfaction_q3_a3": "De alguna manera satisfecho",
  "patient_hip_satisfaction_q3_a4": "Muy Satisfecho",
  "patient_hip_satisfaction_q4": "¿En la mejora de su capacidad para realizar actividades recreativas?",
  "patient_hip_satisfaction_q4_not_applicable": "No aplicable",
  "patient_hip_satisfaction_q4_a1": "Muy insatisfecho",
  "patient_hip_satisfaction_q4_a2": "De alguna manera insatisfecho",
  "patient_hip_satisfaction_q4_a3": "De alguna manera satisfecho",
  "patient_hip_satisfaction_q4_a4": "Muy Satisfecho",
  "patient_hip_complete": "Fin del Cuestionario",
  "patient_hip_complete_text1": "Gracias por tomarse el tiempo de llenar este formulario",
  "patient_hip_complete_text2": "Por favor presiona enviar para completar y ver tus resultados",
  "patient_hip_complete_text3": "Sus respuestas serán tratadas de forma confidencial y cualquier informe solo utilizará información anonimizada",
  "patient_hip_results": "Resultados",
  "patient_hip_results_text1": "A continuación, se encuentran sus puntajes de dolor y funcionamiento de la cadera, que han sido calculados a partir de su cuestionario.",
  "patient_hip_results_pain": "Dolor",
  "patient_hip_results_function": "Funcionamiento",
  "patient_hip_results_health_perception": "Percepción general de salud",
  "patient_hip_results_self_evaluation": "Puntuación de la autoevaluación de cadera",
  "patient_hip_results_text2": "Su médico estará con usted en breve.",
  "": "",
  "patient_adult_knee_pain": "Dolor",
  "patient_adult_knee_pain_q1": "¿Cuál es el nivel más alto de actividad que puede realizar sin sentir un dolor significativo en la rodilla?",
  "patient_adult_knee_pain_q1_a1": "Actividades muy extenuantes como saltar o pivotar como en el baloncesto o el fútbol",
  "patient_adult_knee_pain_q1_a2": "Actividades extenuantes como trabajo físico pesado, esquí o tenis",
  "patient_adult_knee_pain_q1_a3": "Actividades moderadas como trabajo físico moderado, correr o trotar",
  "patient_adult_knee_pain_q1_a4": "Actividades ligeras como caminar, tareas domésticas o trabajos en el jardín",
  "patient_adult_knee_pain_q1_a5": "Incapaz de realizar cualquiera de las actividades anteriores debido al dolor de rodilla",
  "patient_adult_knee_pain_q2": "¿Con qué frecuencia ha tenido dolor? ",
  "patient_adult_knee_pain_q2_a1": "Nunca",
  "patient_adult_knee_pain_q2_a2": "Mensual",
  "patient_adult_knee_pain_q2_a3": "Semanal",
  "patient_adult_knee_pain_q2_a4": "Diario",
  "patient_adult_knee_pain_q2_a5": "Constante",
  "patient_adult_knee_pain_q3": "¿Cuál de las siguientes afirmaciones describe mejor el dolor?",
  "patient_adult_knee_pain_q3_a1": "Sin dolor, rodilla normal, funciona al 100%",
  "patient_adult_knee_pain_q3_a2": "Dolor ocasional con deportes extenuantes y trabajo pesado, rodilla no del todo normal, algunas limitaciones pero menores y tolerables",
  "patient_adult_knee_pain_q3_a3": "Dolor, generalmente provocado por deportes, actividades recreativas ligeras o trabajo moderado. Ocasionalmente ocurre al caminar más de 2 km, estar de pie o trabajar ligero",
  "patient_adult_knee_pain_q3_a4": "Dolor ocasional con deportes recreativos ligeros o actividades laborales moderadas, correr o trabajar pesado, deportes extenuantes",
  "patient_adult_knee_pain_q3_a5": "El dolor es un problema importante con actividades simples como caminar menos de 2 km, aliviado por el descanso, incapaz de hacer deporte",
  "patient_adult_knee_pain_q3_a6": "Constante",
  "patient_adult_knee_pain_q4": "Si tiene dolor, ¿Qué tan grave es?",
  "patient_adult_knee_pain_q4_a1": "Ninguno",
  "patient_adult_knee_pain_q4_a2": "Muy leve",
  "patient_adult_knee_pain_q4_a3": "Leve",
  "patient_adult_knee_pain_q4_a4": "Moderado",
  "patient_adult_knee_pain_q4_a5": "Severo",
  "patient_adult_knee_pain_q5": "¿Cuánto ha interferido el dolor de la rodilla con su trabajo habitual (incluidas las tareas del hogar)?",
  "patient_adult_knee_pain_q5_a1": "En absoluto",
  "patient_adult_knee_pain_q5_a2": "Un poco",
  "patient_adult_knee_pain_q5_a3": "Moderadamente",
  "patient_adult_knee_pain_q5_a4": "Enormemente",
  "patient_adult_knee_pain_q5_a5": "Totalmente",
  "patient_adult_knee_pain_instruction": "¿Qué cantidad de DOLOR EN LA RODILLA ha experimentado durante las siguientes actividades?",
  "patient_adult_knee_pain_instruction_0": "Ninguno",
  "patient_adult_knee_pain_instruction_1": "Leve",
  "patient_adult_knee_pain_instruction_2": "Moderado",
  "patient_adult_knee_pain_instruction_3": "Severo",
  "patient_adult_knee_pain_instruction_4": "Extremo",
  "patient_adult_knee_pain_q6": "Torcer/rotar la rodilla",
  "patient_adult_knee_pain_q6_a1": "Ninguno",
  "patient_adult_knee_pain_q6_a2": "Leve",
  "patient_adult_knee_pain_q6_a3": "Moderado",
  "patient_adult_knee_pain_q6_a4": "Severo",
  "patient_adult_knee_pain_q6_a5": "Extremo",
  "patient_adult_knee_pain_q7": "Enderezar completamente la rodilla",
  "patient_adult_knee_pain_q7_a1": "Ninguno",
  "patient_adult_knee_pain_q7_a2": "Leve",
  "patient_adult_knee_pain_q7_a3": "Moderado",
  "patient_adult_knee_pain_q7_a4": "Severo",
  "patient_adult_knee_pain_q7_a5": "Extremo",
  "patient_adult_knee_pain_q8": "Doblar completamente la rodilla",
  "patient_adult_knee_pain_q8_a1": "Ninguno",
  "patient_adult_knee_pain_q8_a2": "Leve",
  "patient_adult_knee_pain_q8_a3": "Moderado",
  "patient_adult_knee_pain_q8_a4": "Severo",
  "patient_adult_knee_pain_q8_a5": "Extremo",
  "patient_adult_knee_pain_q9": "Caminar sobre una superficie plana",
  "patient_adult_knee_pain_q9_a1": "Ninguno",
  "patient_adult_knee_pain_q9_a2": "Leve",
  "patient_adult_knee_pain_q9_a3": "Moderado",
  "patient_adult_knee_pain_q9_a4": "Severo",
  "patient_adult_knee_pain_q9_a5": "Extremo",
  "patient_adult_knee_pain_q10": "¿Cuánto tiempo puede caminar antes de que el dolor en su rodilla sea grave? (con o sin bastón)",
  "patient_adult_knee_pain_q10_a1": "Más de 30 minutos",
  "patient_adult_knee_pain_q10_a2": "16-30 minutos",
  "patient_adult_knee_pain_q10_a3": "5-15 minutos",
  "patient_adult_knee_pain_q10_a4": "Solo alrededor de la casa",
  "patient_adult_knee_pain_q10_a5": "No puede caminar en absoluto / dolor intenso al caminar",
  "patient_adult_knee_pain_q11": "Subir o bajar escaleras",
  "patient_adult_knee_pain_q11_a1": "Ninguno",
  "patient_adult_knee_pain_q11_a2": "Leve",
  "patient_adult_knee_pain_q11_a3": "Moderado",
  "patient_adult_knee_pain_q11_a4": "Severo",
  "patient_adult_knee_pain_q11_a5": "Extremo",
  "patient_adult_knee_pain_q12": "Por la noche, mientras está en la cama",
  "patient_adult_knee_pain_q12_a1": "Ninguno",
  "patient_adult_knee_pain_q12_a2": "Leve",
  "patient_adult_knee_pain_q12_a3": "Moderado",
  "patient_adult_knee_pain_q12_a4": "Severo",
  "patient_adult_knee_pain_q12_a5": "Extremo",
  "patient_adult_knee_pain_q13": "¿Con qué frecuencia ha tenido problemas de dolor en su rodilla en la cama por la noche?",
  "patient_adult_knee_pain_q13_a1": "Ninguna noche",
  "patient_adult_knee_pain_q13_a2": "Sólo 1 o 2 noches",
  "patient_adult_knee_pain_q13_a3": "Algunas noches",
  "patient_adult_knee_pain_q13_a4": "La mayoría de las noches",
  "patient_adult_knee_pain_q13_a5": "Cada noche",
  "patient_adult_knee_pain_q14": "Sentado o acostado",
  "patient_adult_knee_pain_q14_a1": "Ninguno",
  "patient_adult_knee_pain_q14_a2": "Leve",
  "patient_adult_knee_pain_q14_a3": "Moderado",
  "patient_adult_knee_pain_q14_a4": "Severo",
  "patient_adult_knee_pain_q14_a5": "Extremo",
  "patient_adult_knee_pain_q15": "Estando de pie",
  "patient_adult_knee_pain_q15_a1": "Ninguno",
  "patient_adult_knee_pain_q15_a2": "Leve",
  "patient_adult_knee_pain_q15_a3": "Moderado",
  "patient_adult_knee_pain_q15_a4": "Severo",
  "patient_adult_knee_pain_q15_a5": "Extremo",
  "patient_adult_knee_pain_q16": "Levantarse de una silla",
  "patient_adult_knee_pain_q16_a1": "Ninguno",
  "patient_adult_knee_pain_q16_a2": "Leve",
  "patient_adult_knee_pain_q16_a3": "Moderado",
  "patient_adult_knee_pain_q16_a4": "Severo",
  "patient_adult_knee_pain_q16_a5": "Extremo",
  "patient_adult_knee_other_symptoms": "Otros síntomas",
  "patient_adult_knee_other_symptoms_instruction1": "Por favor elija la respuesta que mejor corresponda con sus síntomas de rodilla",
  "patient_adult_knee_other_symptoms_instruction2": "Si no está seguro de como responder una pregunta, dé la mejor respuesta que pueda",
  "patient_adult_knee_other_symptoms_instruction3": "¿Tienes alguno de los siguientes síntomas?",
  "patient_adult_knee_other_symptoms_instruction_0": "Ninguno",
  "patient_adult_knee_other_symptoms_instruction_1": "Leve",
  "patient_adult_knee_other_symptoms_instruction_2": "Moderado",
  "patient_adult_knee_other_symptoms_instruction_3": "Severo",
  "patient_adult_knee_other_symptoms_instruction_4": "Extremo",
  "patient_adult_knee_other_symptoms_q1": "Hinchazón",
  "patient_adult_knee_other_symptoms_q1_a1": "Nunca",
  "patient_adult_knee_other_symptoms_q1_a2": "Raramente",
  "patient_adult_knee_other_symptoms_q1_a3": "A veces",
  "patient_adult_knee_other_symptoms_q1_a4": "Frecuentemente",
  "patient_adult_knee_other_symptoms_q1_a5": "Siempre",
  "patient_adult_knee_other_symptoms_q2": "Chirridos, chasquido u otro tipo de ruido cuando se mueve la rodilla",
  "patient_adult_knee_other_symptoms_q2_a1": "Nunca",
  "patient_adult_knee_other_symptoms_q2_a2": "Raramente",
  "patient_adult_knee_other_symptoms_q2_a3": "A veces",
  "patient_adult_knee_other_symptoms_q2_a4": "Frecuentemente",
  "patient_adult_knee_other_symptoms_q2_a5": "Siempre",
  "patient_adult_knee_other_symptoms_q3": "Enganche o bloqueo",
  "patient_adult_knee_other_symptoms_q3_a1": "Nunca",
  "patient_adult_knee_other_symptoms_q3_a2": "Raramente",
  "patient_adult_knee_other_symptoms_q3_a3": "A veces",
  "patient_adult_knee_other_symptoms_q3_a4": "Frecuentemente",
  "patient_adult_knee_other_symptoms_q3_a5": "Siempre",
  "patient_adult_knee_other_symptoms_q4": "Cojear",
  "patient_adult_knee_other_symptoms_q4_a1": "Nunca/Raramente",
  "patient_adult_knee_other_symptoms_q4_a2": "Leve, a veces o solo en un comienzo",
  "patient_adult_knee_other_symptoms_q4_a3": "Moderado, seguido",
  "patient_adult_knee_other_symptoms_q4_a4": "La mayor parte del tiempo",
  "patient_adult_knee_other_symptoms_q4_a5": "Todo el tiempo",
  "patient_adult_knee_other_symptoms_q5": "Durante las últimas 4 semanas o desde su lesión, ¿Cuán rígida o hinchada ha estado su rodilla?",
  "patient_adult_knee_other_symptoms_q5_a1": "En absoluto",
  "patient_adult_knee_other_symptoms_q5_a2": "Levemente",
  "patient_adult_knee_other_symptoms_q5_a3": "Moderadamente",
  "patient_adult_knee_other_symptoms_q5_a4": "Muy",
  "patient_adult_knee_other_symptoms_q5_a5": "Extremadamente",
  "patient_adult_knee_other_symptoms_q6": "\n¿Cuál es el nivel más alto de actividad que puede realizar sin una INFLAMACIÓN significativa en la rodilla?",
  "patient_adult_knee_other_symptoms_q6_a1": "Actividades muy extenuantes como saltar o pivotar como en el baloncesto o el fútbol",
  "patient_adult_knee_other_symptoms_q6_a2": "Actividades extenuantes como trabajo físico pesado, esquí o tenis",
  "patient_adult_knee_other_symptoms_q6_a3": "Actividades moderadas como trabajo físico moderado, correr o trotar",
  "patient_adult_knee_other_symptoms_q6_a4": "Actividades ligeras como caminar, tareas domésticas o trabajos en el jardín",
  "patient_adult_knee_other_symptoms_q6_a5": "Incapaz de realizar cualquiera de las actividades anteriores debido a la hinchazón de la rodilla",
  "patient_adult_knee_other_symptoms_q7": "¿Qué tan severa es su RIGIDEZ articular?",
  "patient_adult_knee_other_symptoms_q7_0": "Ninguna",
  "patient_adult_knee_other_symptoms_q7_1": "Leve",
  "patient_adult_knee_other_symptoms_q7_2": "Moderarda",
  "patient_adult_knee_other_symptoms_q7_3": "Severa",
  "patient_adult_knee_other_symptoms_q7_4": "Extrema",
  "patient_adult_knee_other_symptoms_q7_q1": "Después de despertarse por primera vez en la mañana",
  "patient_adult_knee_other_symptoms_q7_q1_a1": "Ninguna",
  "patient_adult_knee_other_symptoms_q7_q1_a2": "Leve",
  "patient_adult_knee_other_symptoms_q7_q1_a3": "Moderarda",
  "patient_adult_knee_other_symptoms_q7_q1_a4": "Severa",
  "patient_adult_knee_other_symptoms_q7_q1_a5": "Extrema",
  "patient_adult_knee_other_symptoms_q7_q2": "Después de estar sentado, acostado o descansando más tarde en el día",
  "patient_adult_knee_other_symptoms_q7_q2_a1": "Ninguna",
  "patient_adult_knee_other_symptoms_q7_q2_a2": "Leve",
  "patient_adult_knee_other_symptoms_q7_q2_a3": "Moderarda",
  "patient_adult_knee_other_symptoms_q7_q2_a4": "Severa",
  "patient_adult_knee_other_symptoms_q7_q2_a5": "Extrema",
  "patient_adult_knee_other_symptoms_q8": "¿Cuál es el nivel más alto de actividad que puede realizar sin que su rodilla ceda significativamente?",
  "patient_adult_knee_other_symptoms_q8_a1": "No cede",
  "patient_adult_knee_other_symptoms_q8_a2": "Cede ocacionalmente con deportes extenuantes o trabajos pesados, capaz de participar en todos los deportes excepto de algunas limitaciones presentes. Rara vez durante el atletismo u otro esfuerzo severo",
  "patient_adult_knee_other_symptoms_q8_a3": "Con frecuencia durante el atletismo u otro esfuerzo severo. Cede ocasionalmente con deportes ligeros o trabajo moderado. Capaz de compensar pero limita las actividades vigorosas, los deportes o el trabajo pesado, incapaz girar repentinamente",
  "patient_adult_knee_other_symptoms_q8_a4": "El ceder limita los deportes o el trabajo moderado, ocurre con poca frecuencia con caminar o trabajo ligero (aprox. 3 veces al año). Ocasionalmente en las actividades diarias",
  "patient_adult_knee_other_symptoms_q8_a5": "Cede con actividades sencillas de caminar y trabajo ligero. Ocurre una vez al mes, requiere protección. A menudo en las actividades diarias",
  "patient_adult_knee_other_symptoms_q8_a6": "Problema grave con actividades simples para caminar, cede en cada paso, incapaz girar mientras camina sin ceder",
  "patient_adult_knee_other_symptoms_q9": "¿Utiliza algún soporte para caminar?",
  "patient_adult_knee_other_symptoms_q9_a1": "Ninguno",
  "patient_adult_knee_other_symptoms_q9_a2": "Bastón o muleta",
  "patient_adult_knee_other_symptoms_q9_a3": "Imposibilidad de carrear peso",
  "patient_adult_knee_function": "Funcionamiento",
  "patient_adult_knee_function_instruction": "Indique el GRADO DE DIFICULTAD que ha experimentado debido a su rodilla al realizar las siguientes actividades",
  "patient_adult_knee_function_instruction_0": "Sin dificultad",
  "patient_adult_knee_function_instruction_1": "Dificultad mínima",
  "patient_adult_knee_function_instruction_2": "Dificultad moderada",
  "patient_adult_knee_function_instruction_3": "Dificultad extrema",
  "patient_adult_knee_function_instruction_4": "Incapacidad para hacerlo",
  "patient_adult_knee_function_note": "Nota: Si hay ciertas actividades que no realizas, imagina como se sentiría tu rodilla al intentarlo.",
  "patient_adult_knee_function_q1": "Subir escaleras",
  "patient_adult_knee_function_q1_a1": "Sin dificultad",
  "patient_adult_knee_function_q1_a2": "Dificultad mínima",
  "patient_adult_knee_function_q1_a3": "Dificultad moderada",
  "patient_adult_knee_function_q1_a4": "Dificultad extrema",
  "patient_adult_knee_function_q1_a5": "Incapacidad para hacerlo",
  "patient_adult_knee_function_q2": "Bajar escaleras",
  "patient_adult_knee_function_q2_a1": "Sin dificultad",
  "patient_adult_knee_function_q2_a2": "Dificultad mínima",
  "patient_adult_knee_function_q2_a3": "Dificultad moderada",
  "patient_adult_knee_function_q2_a4": "Dificultad extrema",
  "patient_adult_knee_function_q2_a5": "Incapacidad para hacerlo",
  "patient_adult_knee_function_q3": "Arrodillarse en la parte delantera de la rodilla",
  "patient_adult_knee_function_q3_a1": "Sin dificultad",
  "patient_adult_knee_function_q3_a2": "Dificultad mínima",
  "patient_adult_knee_function_q3_a3": "Dificultad moderada",
  "patient_adult_knee_function_q3_a4": "Dificultad extrema",
  "patient_adult_knee_function_q3_a5": "Incapacidad para hacerlo",
  "patient_adult_knee_function_q4": "Extender la rodilla",
  "patient_adult_knee_function_q4_a1": "Sin dificultad",
  "patient_adult_knee_function_q4_a2": "Dificultad mínima",
  "patient_adult_knee_function_q4_a3": "Dificultad moderada",
  "patient_adult_knee_function_q4_a4": "Dificultad extrema",
  "patient_adult_knee_function_q4_a5": "Incapacidad para hacerlo",
  "patient_adult_knee_function_q5": "Doblar la rodilla",
  "patient_adult_knee_function_q5_a1": "Sin dificultad",
  "patient_adult_knee_function_q5_a2": "Dificultad mínima",
  "patient_adult_knee_function_q5_a3": "Dificultad moderada",
  "patient_adult_knee_function_q5_a4": "Dificultad extrema",
  "patient_adult_knee_function_q5_a5": "Incapacidad para hacerlo",
  "patient_adult_knee_function_q6": "Sentadillas",
  "patient_adult_knee_function_q6_a1": "Sin dificultad",
  "patient_adult_knee_function_q6_a2": "Dificultad mínima",
  "patient_adult_knee_function_q6_a3": "Dificultad moderada",
  "patient_adult_knee_function_q6_a4": "Dificultad extrema",
  "patient_adult_knee_function_q6_a5": "Incapacidad para hacerlo",
  "patient_adult_knee_function_q7": "Sentarse con la rodilla doblada",
  "patient_adult_knee_function_q7_a1": "Sin dificultad",
  "patient_adult_knee_function_q7_a2": "Dificultad mínima",
  "patient_adult_knee_function_q7_a3": "Dificultad moderada",
  "patient_adult_knee_function_q7_a4": "Dificultad extrema",
  "patient_adult_knee_function_q7_a5": "Incapacidad para hacerlo",
  "patient_adult_knee_function_q8": "Levantarse de la silla",
  "patient_adult_knee_function_q8_a1": "Sin dificultad",
  "patient_adult_knee_function_q8_a2": "Dificultad mínima",
  "patient_adult_knee_function_q8_a3": "Dificultad moderada",
  "patient_adult_knee_function_q8_a4": "Dificultad extrema",
  "patient_adult_knee_function_q8_a5": "Incapacidad para hacerlo",
  "patient_adult_knee_function_q9": "Levantarse de la cama",
  "patient_adult_knee_function_q9_a1": "Sin dificultad",
  "patient_adult_knee_function_q9_a2": "Dificultad mínima",
  "patient_adult_knee_function_q9_a3": "Dificultad moderada",
  "patient_adult_knee_function_q9_a4": "Dificultad extrema",
  "patient_adult_knee_function_q9_a5": "Incapacidad para hacerlo",
  "patient_adult_knee_function_q10": "Mantener la posición de la rodilla al girar en la cama",
  "patient_adult_knee_function_q10_a1": "Sin dificultad",
  "patient_adult_knee_function_q10_a2": "Dificultad mínima",
  "patient_adult_knee_function_q10_a3": "Dificultad moderada",
  "patient_adult_knee_function_q10_a4": "Dificultad extrema",
  "patient_adult_knee_function_q10_a5": "Incapacidad para hacerlo",
  "patient_adult_knee_function_q11": "Inclinarse para recoger un objeto del piso",
  "patient_adult_knee_function_q11_a1": "Sin dificultad",
  "patient_adult_knee_function_q11_a2": "Dificultad mínima",
  "patient_adult_knee_function_q11_a3": "Dificultad moderada",
  "patient_adult_knee_function_q11_a4": "Dificultad extrema",
  "patient_adult_knee_function_q11_a5": "Incapacidad para hacerlo",
  "patient_adult_knee_function_q12": "Ponerse calcetines/medias",
  "patient_adult_knee_function_q12_a1": "Sin dificultad",
  "patient_adult_knee_function_q12_a2": "Dificultad mínima",
  "patient_adult_knee_function_q12_a3": "Dificultad moderada",
  "patient_adult_knee_function_q12_a4": "Dificultad extrema",
  "patient_adult_knee_function_q12_a5": "Incapacidad para hacerlo",
  "patient_adult_knee_function_q13": "Quitarse calcetines/medias",
  "patient_adult_knee_function_q13_a1": "Sin dificultad",
  "patient_adult_knee_function_q13_a2": "Dificultad mínima",
  "patient_adult_knee_function_q13_a3": "Dificultad moderada",
  "patient_adult_knee_function_q13_a4": "Dificultad extrema",
  "patient_adult_knee_function_q13_a5": "Incapacidad para hacerlo",
  "patient_adult_knee_function_q14": "Estar de pie",
  "patient_adult_knee_function_q14_a1": "Sin dificultad",
  "patient_adult_knee_function_q14_a2": "Dificultad mínima",
  "patient_adult_knee_function_q14_a3": "Dificultad moderada",
  "patient_adult_knee_function_q14_a4": "Dificultad extrema",
  "patient_adult_knee_function_q14_a5": "Incapacidad para hacerlo",
  "patient_adult_knee_function_q15": "Caminar",
  "patient_adult_knee_function_q15_a1": "Sin dificultad",
  "patient_adult_knee_function_q15_a2": "Dificultad mínima",
  "patient_adult_knee_function_q15_a3": "Dificultad moderada",
  "patient_adult_knee_function_q15_a4": "Dificultad extrema",
  "patient_adult_knee_function_q15_a5": "Incapacidad para hacerlo",
  "patient_adult_knee_function_q16": "Correr en linea recta",
  "patient_adult_knee_function_q16_a1": "Sin dificultad",
  "patient_adult_knee_function_q16_a2": "Dificultad mínima",
  "patient_adult_knee_function_q16_a3": "Dificultad moderada",
  "patient_adult_knee_function_q16_a4": "Dificultad extrema",
  "patient_adult_knee_function_q16_a5": "Incapacidad para hacerlo",
  "patient_adult_knee_function_q17": "Saltar y caer sobre la pierna afectada",
  "patient_adult_knee_function_q17_a1": "Sin dificultad",
  "patient_adult_knee_function_q17_a2": "Dificultad mínima",
  "patient_adult_knee_function_q17_a3": "Dificultad moderada",
  "patient_adult_knee_function_q17_a4": "Dificultad extrema",
  "patient_adult_knee_function_q17_a5": "Incapacidad para hacerlo",
  "patient_adult_knee_function_q18": "Parar y arrancar rápidamente",
  "patient_adult_knee_function_q18_a1": "Sin dificultad",
  "patient_adult_knee_function_q18_a2": "Dificultad mínima",
  "patient_adult_knee_function_q18_a3": "Dificultad moderada",
  "patient_adult_knee_function_q18_a4": "Dificultad extrema",
  "patient_adult_knee_function_q18_a5": "Incapacidad para hacerlo",
  "patient_adult_knee_function_q19": "Girar sobre la rodilla lesionada",
  "patient_adult_knee_function_q19_a1": "Sin dificultad",
  "patient_adult_knee_function_q19_a2": "Dificultad mínima",
  "patient_adult_knee_function_q19_a3": "Dificultad moderada",
  "patient_adult_knee_function_q19_a4": "Dificultad extrema",
  "patient_adult_knee_function_q19_a5": "Incapacidad para hacerlo",
  "patient_adult_knee_function_q20": "Entrar y salir del automóvil o usar el transporte público",
  "patient_adult_knee_function_q20_a1": "Sin dificultad",
  "patient_adult_knee_function_q20_a2": "Dificultad mínima",
  "patient_adult_knee_function_q20_a3": "Dificultad moderada",
  "patient_adult_knee_function_q20_a4": "Dificultad extrema",
  "patient_adult_knee_function_q20_a5": "Incapacidad para hacerlo",
  "patient_adult_knee_function_q21": "Entrar y salir de la bañera",
  "patient_adult_knee_function_q21_a1": "Sin dificultad",
  "patient_adult_knee_function_q21_a2": "Dificultad mínima",
  "patient_adult_knee_function_q21_a3": "Dificultad moderada",
  "patient_adult_knee_function_q21_a4": "Dificultad extrema",
  "patient_adult_knee_function_q21_a5": "Incapacidad para hacerlo",
  "patient_adult_knee_function_q22": "Sentarse/levantarse del inodoro",
  "patient_adult_knee_function_q22_a1": "Sin dificultad",
  "patient_adult_knee_function_q22_a2": "Dificultad mínima",
  "patient_adult_knee_function_q22_a3": "Dificultad moderada",
  "patient_adult_knee_function_q22_a4": "Dificultad extrema",
  "patient_adult_knee_function_q22_a5": "Incapacidad para hacerlo",
  "patient_adult_knee_function_q23": "Lavarse y secarse (por todas partes)",
  "patient_adult_knee_function_q23_a1": "Sin dificultad",
  "patient_adult_knee_function_q23_a2": "Dificultad mínima",
  "patient_adult_knee_function_q23_a3": "Dificultad moderada",
  "patient_adult_knee_function_q23_a4": "Dificultad extrema",
  "patient_adult_knee_function_q23_a5": "Incapacidad para hacerlo",
  "patient_adult_knee_function_q24": "Compras para el hogar",
  "patient_adult_knee_function_q24_a1": "Sin dificultad",
  "patient_adult_knee_function_q24_a2": "Dificultad mínima",
  "patient_adult_knee_function_q24_a3": "Dificultad moderada",
  "patient_adult_knee_function_q24_a4": "Dificultad extrema",
  "patient_adult_knee_function_q24_a5": "Incapacidad para hacerlo",
  "patient_adult_knee_function_q25": "Tareas domésticas pesadas (mover cajas pesadas, fregar pisos, etc.)",
  "patient_adult_knee_function_q25_a1": "Sin dificultad",
  "patient_adult_knee_function_q25_a2": "Dificultad mínima",
  "patient_adult_knee_function_q25_a3": "Dificultad moderada",
  "patient_adult_knee_function_q25_a4": "Dificultad extrema",
  "patient_adult_knee_function_q25_a5": "Incapacidad para hacerlo",
  "patient_adult_knee_function_q26": "Tareas domésticas ligeras (cocinar, quitar el polvo, etc.)",
  "patient_adult_knee_function_q26_a1": "Sin dificultad",
  "patient_adult_knee_function_q26_a2": "Dificultad mínima",
  "patient_adult_knee_function_q26_a3": "Dificultad moderada",
  "patient_adult_knee_function_q26_a4": "Dificultad extrema",
  "patient_adult_knee_function_q26_a5": "Incapacidad para hacerlo",
  "patient_adult_knee_function_q27": "¿Cómo calificaría la funcionalidad ACTUAL de su rodilla en una escala del 0 al 10?",
  "patient_adult_knee_function_q28": "Siendo 10 normal y 0 la incapacidad para realizar cualquiera de sus actividades diarias habituales, incluyendo deportes",
  "patient_adult_knee_function_q28_a1": "Peor",
  "patient_adult_knee_function_q28_a2": "Mejor",
  "patient_adult_knee_function_q29": "¿Cuál es el nivel más alto de actividad en el que puede participar de manera regular?",
  "patient_adult_knee_function_q29_a1": "Actividades muy extenuantes como saltar o pivotar como en el baloncesto o el fútbol",
  "patient_adult_knee_function_q29_a2": "Actividades extenuantes como trabajo físico pesado, esquí o tenis",
  "patient_adult_knee_function_q29_a3": "Actividades moderadas como trabajo físico moderado, correr o trotar",
  "patient_adult_knee_function_q29_a4": "Actividades ligeras como caminar, tareas domésticas o trabajos en el jardín",
  "patient_adult_knee_function_q29_a5": "Incapaz de realizar cualquiera de las actividades mencionadas debido al dolor de rodilla",
  "patient_adult_knee_function_q30": "Especifique el nivel más alto de actividad que puede realizar",
  "patient_adult_knee_function_q30_q1": "Antes de la lesión/problema de rodilla.",
  "patient_adult_knee_function_q30_q1_a1": "Competitivo",
  "patient_adult_knee_function_q30_q1_a2": "Recreativo, con aptitud física",
  "patient_adult_knee_function_q30_q1_a3": "Sin realizar deportes, solo trabajo",
  "patient_adult_knee_function_q30_q1_a4": "Sólo actividades de la vida diaria",
  "patient_adult_knee_function_q30_q1_a5": "Licencia por enfermedad o pensión de invalidez por problemas de salud",
  "patient_adult_knee_function_q30_q1_a1_o1": "Deportes competitivos jugados a nivel mundial, olímpico o profesional",
  "patient_adult_knee_function_q30_q1_a1_o2": "Deportes competitivos como atletismo, deportes de raqueta / pelota, gimnasia, remo, esquí o artes marciales realizados a nivel nacional o internacional",
  "patient_adult_knee_function_q30_q1_a1_o3": "Deportes competitivos como deportes acuáticos, ciclismo, hockey, curling o equitación / rodeo jugado a nivel nacional o internacional",
  "patient_adult_knee_function_q30_q1_a2_o1": "Deportes recreativos como correr, deportes de pelota / raqueta, entrenamiento con pesas, curling, remo, golf, yoga o gimnasia al menos 5 veces por semana",
  "patient_adult_knee_function_q30_q1_a2_o2": "Deportes recreativos como natación, deportes acuáticos, esquí, hockey, patinaje, ciclismo, equitación o montañismo al menos 5 veces por semana",
  "patient_adult_knee_function_q30_q1_a2_o3": "Deportes recreativos al menos dos veces por semana",
  "patient_adult_knee_function_q30_q1_a2_o4": "Deportes recreativos una vez a la semana o menos",
  "patient_adult_knee_function_q30_q1_a2_o5": "Deportes recreativos menos de una vez al mes",
  "patient_adult_knee_function_q30_q1_a3_o1": "Trabajo pesado como la construcción",
  "patient_adult_knee_function_q30_q1_a3_o2": "Trabajo moderadamente pesado, como conducir camiones",
  "patient_adult_knee_function_q30_q1_a3_o3": "Trabajo ligero como la enfermería",
  "patient_adult_knee_function_q30_q1_a3_o4": "Trabajo sedentario como el trabajo de secretariado o basado en computadora",
  "patient_adult_knee_function_q30_q1_a4_o1": "Actividades diarias como jardinería, subir varios tramos de escaleras, llevar cargas, empujar / tirar de una carga, capacidad de correr si llega tarde",
  "patient_adult_knee_function_q30_q1_a4_o2": "Actividades diarias como limpiar la casa, subir 1 o 2 tramos de escaleras o caminar cargando una carga ligera",
  "patient_adult_knee_function_q30_q1_a4_o3": "Actividades diarias limitadas (por ejemplo, sin subir escaleras ni transportar cargas)",
  "patient_adult_knee_function_q30_q2": "Estado actual.",
  "patient_adult_knee_function_q30_q2_a1": "Competitivo",
  "patient_adult_knee_function_q30_q2_a2": "Recreativo, aptitud física",
  "patient_adult_knee_function_q30_q2_a3": "No hay deportes, solo trabajo",
  "patient_adult_knee_function_q30_q2_a4": "Sólo actividades de la vida diaria",
  "patient_adult_knee_function_q30_q2_a5": "Licencia por enfermedad o pensión de invalidez por problemas de salud",
  "patient_adult_knee_function_q30_q2_a1_o1": "Deportes competitivos realizados a nivel mundial u olímpico o profesionalmente",
  "patient_adult_knee_function_q30_q2_a1_o2": "Deportes competitivos como atletismo, deportes de raqueta / pelota, gimnasia, remo, esquí o artes marciales realizados a nivel nacional o internacional",
  "patient_adult_knee_function_q30_q2_a1_o3": "Deportes competitivos como deportes acuáticos, ciclismo, hockey, curling o equitación / rodeo jugado a nivel nacional o internacional",
  "patient_adult_knee_function_q30_q2_a2_o1": "Deportes recreativos como correr, deportes de pelota / raqueta, entrenamiento con pesas, curling, remo, golf, yoga o gimnasia al menos 5 veces por semana",
  "patient_adult_knee_function_q30_q2_a2_o2": "Deportes recreativos como natación, deportes acuáticos, esquí, hockey, patinaje, ciclismo, equitación o montañismo al menos 5 veces por semana",
  "patient_adult_knee_function_q30_q2_a2_o3": "Deportes recreativos al menos dos veces por semana",
  "patient_adult_knee_function_q30_q2_a2_o4": "Deportes recreativos una vez a la semana o menos",
  "patient_adult_knee_function_q30_q2_a2_o5": "Deportes recreativos menos de una vez al mes",
  "patient_adult_knee_function_q30_q2_a3_o1": "Trabajo pesado como la construcción",
  "patient_adult_knee_function_q30_q2_a3_o2": "Trabajo moderadamente pesado, como conducir camiones",
  "patient_adult_knee_function_q30_q2_a3_o3": "Trabajo ligero como la enfermería",
  "patient_adult_knee_function_q30_q2_a3_o4": "Trabajo sedentario como el trabajo de secretariado o basado en computadora",
  "patient_adult_knee_function_q30_q2_a4_o1": "Actividades diarias como jardinería, subir varios tramos de escaleras, llevar cargas, empujar / tirar de una carga, capacidad de correr si llega tarde",
  "patient_adult_knee_function_q30_q2_a4_o2": "Actividades diarias como limpiar la casa, subir 1 o 2 tramos de escaleras o caminar cargando una carga ligera",
  "patient_adult_knee_function_q30_q2_a4_o3": "Actividades diarias limitadas (por ejemplo, sin subir escaleras ni transportar cargas)",
  "patient_adult_knee_function_q31": "¿Su rótula se sale de lugar o siente que se está saliendo de lugar?",
  "patient_adult_knee_function_q31_a1": "No",
  "patient_adult_knee_function_q31_a2": "Si",
  "patient_adult_knee_patellofemoral_instability": "Inestabilidad patelofemoral",
  "patient_adult_knee_patellofemoral_instability_instruction": "Por favor elija la respuesta que mejor describa CON QUÉ FRECUENCIA SE SIENTE QUE LA RODILLA SE “SALE” DE LA ARTICULACIÓN O SE SIENTE INESTABLE cuando realiza cada una de las siguientes actividades",
  "patient_adult_knee_patellofemoral_instability_instruction_0": "Nunca",
  "patient_adult_knee_patellofemoral_instability_instruction_1": "Raramente",
  "patient_adult_knee_patellofemoral_instability_instruction_2": "A veces",
  "patient_adult_knee_patellofemoral_instability_instruction_3": "Frecuentemente",
  "patient_adult_knee_patellofemoral_instability_instruction_4": "Siempre",
  "patient_adult_knee_patellofemoral_instability_q1": "Girar/cambiar de dirección durante deportes/juegos.",
  "patient_adult_knee_patellofemoral_instability_q1_do_not_do": "No lo hago",
  "patient_adult_knee_patellofemoral_instability_q1_a1": "Nunca",
  "patient_adult_knee_patellofemoral_instability_q1_a2": "Raramente",
  "patient_adult_knee_patellofemoral_instability_q1_a3": "A veces",
  "patient_adult_knee_patellofemoral_instability_q1_a4": "Frecuentemente",
  "patient_adult_knee_patellofemoral_instability_q1_a5": "Siempre",
  "patient_adult_knee_patellofemoral_instability_q2": "Cambiar de dirección al correr",
  "patient_adult_knee_patellofemoral_instability_q2_do_not_do": "No lo hago",
  "patient_adult_knee_patellofemoral_instability_q2_a1": "Nunca",
  "patient_adult_knee_patellofemoral_instability_q2_a2": "Raramente",
  "patient_adult_knee_patellofemoral_instability_q2_a3": "A veces",
  "patient_adult_knee_patellofemoral_instability_q2_a4": "Frecuentemente",
  "patient_adult_knee_patellofemoral_instability_q2_a5": "Siempre",
  "patient_adult_knee_patellofemoral_instability_q3": "Correr en línea recta sobre superficies irregulares",
  "patient_adult_knee_patellofemoral_instability_q3_do_not_do": "No lo hago",
  "patient_adult_knee_patellofemoral_instability_q3_a1": "Nunca",
  "patient_adult_knee_patellofemoral_instability_q3_a2": "Raramente",
  "patient_adult_knee_patellofemoral_instability_q3_a3": "A veces",
  "patient_adult_knee_patellofemoral_instability_q3_a4": "Frecuentemente",
  "patient_adult_knee_patellofemoral_instability_q3_a5": "Siempre",
  "patient_adult_knee_patellofemoral_instability_q4": "Caminar sobre superficies resbaladizas, mojadas o heladas",
  "patient_adult_knee_patellofemoral_instability_q4_do_not_do": "No lo hago",
  "patient_adult_knee_patellofemoral_instability_q4_a1": "Nunca",
  "patient_adult_knee_patellofemoral_instability_q4_a2": "Raramente",
  "patient_adult_knee_patellofemoral_instability_q4_a3": "A veces",
  "patient_adult_knee_patellofemoral_instability_q4_a4": "Frecuentemente",
  "patient_adult_knee_patellofemoral_instability_q4_a5": "Siempre",
  "patient_adult_knee_patellofemoral_instability_q5": "Correr de lado",
  "patient_adult_knee_patellofemoral_instability_q5_do_not_do": "No lo hago",
  "patient_adult_knee_patellofemoral_instability_q5_a1": "Nunca",
  "patient_adult_knee_patellofemoral_instability_q5_a2": "Raramente",
  "patient_adult_knee_patellofemoral_instability_q5_a3": "A veces",
  "patient_adult_knee_patellofemoral_instability_q5_a4": "Frecuentemente",
  "patient_adult_knee_patellofemoral_instability_q5_a5": "Siempre",
  "patient_adult_knee_patellofemoral_instability_q6": "Saltos cortos",
  "patient_adult_knee_patellofemoral_instability_q6_do_not_do": "No lo hago",
  "patient_adult_knee_patellofemoral_instability_q6_a1": "Nunca",
  "patient_adult_knee_patellofemoral_instability_q6_a2": "Raramente",
  "patient_adult_knee_patellofemoral_instability_q6_a3": "A veces",
  "patient_adult_knee_patellofemoral_instability_q6_a4": "Frecuentemente",
  "patient_adult_knee_patellofemoral_instability_q6_a5": "Siempre",
  "patient_adult_knee_patellofemoral_instability_q7": "Saltar",
  "patient_adult_knee_patellofemoral_instability_q7_do_not_do": "No lo hago",
  "patient_adult_knee_patellofemoral_instability_q7_a1": "Nunca",
  "patient_adult_knee_patellofemoral_instability_q7_a2": "Raramente",
  "patient_adult_knee_patellofemoral_instability_q7_a3": "A veces",
  "patient_adult_knee_patellofemoral_instability_q7_a4": "Frecuentemente",
  "patient_adult_knee_patellofemoral_instability_q7_a5": "Siempre",
  "patient_adult_knee_patellofemoral_instability_q8": "Correr en línea recta sobre superficie PLANA",
  "patient_adult_knee_patellofemoral_instability_q8_do_not_do": "No lo hago",
  "patient_adult_knee_patellofemoral_instability_q8_a1": "Nunca",
  "patient_adult_knee_patellofemoral_instability_q8_a2": "Raramente",
  "patient_adult_knee_patellofemoral_instability_q8_a3": "A veces",
  "patient_adult_knee_patellofemoral_instability_q8_a4": "Frecuentemente",
  "patient_adult_knee_patellofemoral_instability_q8_a5": "Siempre",
  "patient_adult_knee_patellofemoral_instability_q9": "Bajar escaleras",
  "patient_adult_knee_patellofemoral_instability_q9_do_not_do": "No lo hago",
  "patient_adult_knee_patellofemoral_instability_q9_a1": "Nunca",
  "patient_adult_knee_patellofemoral_instability_q9_a2": "Raramente",
  "patient_adult_knee_patellofemoral_instability_q9_a3": "A veces",
  "patient_adult_knee_patellofemoral_instability_q9_a4": "Frecuentemente",
  "patient_adult_knee_patellofemoral_instability_q9_a5": "Siempre",
  "patient_adult_knee_patellofemoral_instability_q10": "Sentadillas",
  "patient_adult_knee_patellofemoral_instability_q10_do_not_do": "No lo hago",
  "patient_adult_knee_patellofemoral_instability_q10_a1": "Nunca",
  "patient_adult_knee_patellofemoral_instability_q10_a2": "Raramente",
  "patient_adult_knee_patellofemoral_instability_q10_a3": "A veces",
  "patient_adult_knee_patellofemoral_instability_q10_a4": "Frecuentemente",
  "patient_adult_knee_patellofemoral_instability_q10_a5": "Siempre",
  "patient_adult_knee_patellofemoral_instability_q11": "Arrodillarse",
  "patient_adult_knee_patellofemoral_instability_q11_do_not_do": "No lo hago",
  "patient_adult_knee_patellofemoral_instability_q11_a1": "Nunca",
  "patient_adult_knee_patellofemoral_instability_q11_a2": "Raramente",
  "patient_adult_knee_patellofemoral_instability_q11_a3": "A veces",
  "patient_adult_knee_patellofemoral_instability_q11_a4": "Frecuentemente",
  "patient_adult_knee_patellofemoral_instability_q11_a5": "Siempre",
  "patient_adult_knee_patellofemoral_instability_q12": "Caminar en línea recta sobre superficies irregulares",
  "patient_adult_knee_patellofemoral_instability_q12_do_not_do": "No lo hago",
  "patient_adult_knee_patellofemoral_instability_q12_a1": "Nunca",
  "patient_adult_knee_patellofemoral_instability_q12_a2": "Raramente",
  "patient_adult_knee_patellofemoral_instability_q12_a3": "A veces",
  "patient_adult_knee_patellofemoral_instability_q12_a4": "Frecuentemente",
  "patient_adult_knee_patellofemoral_instability_q12_a5": "Siempre",
  "patient_adult_knee_patellofemoral_instability_q13": "Subir escaleras",
  "patient_adult_knee_patellofemoral_instability_q13_do_not_do": "No lo hago",
  "patient_adult_knee_patellofemoral_instability_q13_a1": "Nunca",
  "patient_adult_knee_patellofemoral_instability_q13_a2": "Raramente",
  "patient_adult_knee_patellofemoral_instability_q13_a3": "A veces",
  "patient_adult_knee_patellofemoral_instability_q13_a4": "Frecuentemente",
  "patient_adult_knee_patellofemoral_instability_q13_a5": "Siempre",
  "patient_adult_knee_patellofemoral_instability_q14": "Pisar sobre un escalón alto",
  "patient_adult_knee_patellofemoral_instability_q14_do_not_do": "No lo hago",
  "patient_adult_knee_patellofemoral_instability_q14_a1": "Nunca",
  "patient_adult_knee_patellofemoral_instability_q14_a2": "Raramente",
  "patient_adult_knee_patellofemoral_instability_q14_a3": "A veces",
  "patient_adult_knee_patellofemoral_instability_q14_a4": "Frecuentemente",
  "patient_adult_knee_patellofemoral_instability_q14_a5": "Siempre",
  "patient_adult_knee_patellofemoral_instability_q15": "Cruzar las piernas al sentarse",
  "patient_adult_knee_patellofemoral_instability_q15_do_not_do": "No lo hago",
  "patient_adult_knee_patellofemoral_instability_q15_a1": "Nunca",
  "patient_adult_knee_patellofemoral_instability_q15_a2": "Raramente",
  "patient_adult_knee_patellofemoral_instability_q15_a3": "A veces",
  "patient_adult_knee_patellofemoral_instability_q15_a4": "Frecuentemente",
  "patient_adult_knee_patellofemoral_instability_q15_a5": "Siempre",
  "patient_adult_knee_patellofemoral_instability_q16": "Caminar en línea recta sobre superficie PLANA",
  "patient_adult_knee_patellofemoral_instability_q16_do_not_do": "No lo hago",
  "patient_adult_knee_patellofemoral_instability_q16_a1": "Nunca",
  "patient_adult_knee_patellofemoral_instability_q16_a2": "Raramente",
  "patient_adult_knee_patellofemoral_instability_q16_a3": "A veces",
  "patient_adult_knee_patellofemoral_instability_q16_a4": "Frecuentemente",
  "patient_adult_knee_patellofemoral_instability_q16_a5": "Siempre",
  "patient_adult_knee_patellofemoral_instability_q17": "Entrar o salir de un coche",
  "patient_adult_knee_patellofemoral_instability_q17_do_not_do": "No lo hago",
  "patient_adult_knee_patellofemoral_instability_q17_a1": "Nunca",
  "patient_adult_knee_patellofemoral_instability_q17_a2": "Raramente",
  "patient_adult_knee_patellofemoral_instability_q17_a3": "A veces",
  "patient_adult_knee_patellofemoral_instability_q17_a4": "Frecuentemente",
  "patient_adult_knee_patellofemoral_instability_q17_a5": "Siempre",
  "patient_adult_knee_patellofemoral_instability_q18": "Girar un carrito pesado en un pasillo de supermercado",
  "patient_adult_knee_patellofemoral_instability_q18_do_not_do": "No lo hago",
  "patient_adult_knee_patellofemoral_instability_q18_a1": "Nunca",
  "patient_adult_knee_patellofemoral_instability_q18_a2": "Raramente",
  "patient_adult_knee_patellofemoral_instability_q18_a3": "A veces",
  "patient_adult_knee_patellofemoral_instability_q18_a4": "Frecuentemente",
  "patient_adult_knee_patellofemoral_instability_q18_a5": "Siempre",
  "patient_adult_knee_patellofemoral_instability_q19": "Girar para mirar sobre su hombro",
  "patient_adult_knee_patellofemoral_instability_q19_do_not_do": "No lo hago",
  "patient_adult_knee_patellofemoral_instability_q19_a1": "Nunca",
  "patient_adult_knee_patellofemoral_instability_q19_a2": "Raramente",
  "patient_adult_knee_patellofemoral_instability_q19_a3": "A veces",
  "patient_adult_knee_patellofemoral_instability_q19_a4": "Frecuentemente",
  "patient_adult_knee_patellofemoral_instability_q19_a5": "Siempre",
  "patient_adult_knee_quality_of_life": "Calidad de vida",
  "patient_adult_knee_quality_of_life_instruction": "Las siguientes preguntas se refieren a inquietudes sociales, emocionales y de estilo de vida que pueda tener con respecto a su problema de rodilla. Piense en cómo se ha sentido la mayor parte del tiempo en relación con su rodilla.",
  "patient_adult_knee_quality_of_life_q1": "¿Con qué frecuencia es consciente de su problema de rodilla?",
  "patient_adult_knee_quality_of_life_q1_a1": "Nunca",
  "patient_adult_knee_quality_of_life_q1_a2": "Mensual",
  "patient_adult_knee_quality_of_life_q1_a3": "Semanal",
  "patient_adult_knee_quality_of_life_q1_a4": "Diario",
  "patient_adult_knee_quality_of_life_q1_a5": "Constantemente",
  "patient_adult_knee_quality_of_life_q2": "¿Ha modificado su estilo de vida para evitar actividades potencialmente dañinas para su rodilla?",
  "patient_adult_knee_quality_of_life_q2_a1": "En absoluto",
  "patient_adult_knee_quality_of_life_q2_a2": "Levemente",
  "patient_adult_knee_quality_of_life_q2_a3": "Moderadamente",
  "patient_adult_knee_quality_of_life_q2_a4": "Severamente",
  "patient_adult_knee_quality_of_life_q2_a5": "Totalmente",
  "patient_adult_knee_quality_of_life_q3": "¿Cuánto le preocupa la falta de confianza en su rodilla?",
  "patient_adult_knee_quality_of_life_q3_a1": "En absoluto",
  "patient_adult_knee_quality_of_life_q3_a2": "Levemente",
  "patient_adult_knee_quality_of_life_q3_a3": "Moderadamente",
  "patient_adult_knee_quality_of_life_q3_a4": "Severamente",
  "patient_adult_knee_quality_of_life_q3_a5": "Extremadamente",
  "patient_adult_knee_quality_of_life_q4": "En general, ¿Cuánta dificultad tiene con la rodilla?",
  "patient_adult_knee_quality_of_life_q4_a1": "Ninguno",
  "patient_adult_knee_quality_of_life_q4_a2": "Leve",
  "patient_adult_knee_quality_of_life_q4_a3": "Moderado",
  "patient_adult_knee_quality_of_life_q4_a4": "Severo",
  "patient_adult_knee_quality_of_life_q4_a5": "Extremo",
  "patient_adult_knee_quality_of_life_q5": "¿Qué tan ansioso/deprimido estás hoy?",
  "patient_adult_knee_quality_of_life_q5_a1": "No ansioso/deprimido",
  "patient_adult_knee_quality_of_life_q5_a2": "Levemente",
  "patient_adult_knee_quality_of_life_q5_a3": "Moderadamente",
  "patient_adult_knee_quality_of_life_q5_a4": "Severamente",
  "patient_adult_knee_quality_of_life_q5_a5": "Extremadamente",
  "patient_adult_knee_quality_of_life_q6": "¿Qué tan buena/mala es su salud hoy?",
  "patient_adult_knee_quality_of_life_q6_instruction": "100 significa la mejor salud, 0 significa la peor salud que puedas imaginar",
  "patient_adult_knee_quality_of_life_q6_instruction_a1": "Peor",
  "patient_adult_knee_quality_of_life_q6_instruction_a2": "Mejor",
  "patient_adult_knee_satisfaction": "Satisfacción",
  "patient_adult_knee_satisfaction_instructions": "¿Que tán satisfecho está con los resultados de su tratamiento/cirugía de rodilla?",
  "patient_adult_knee_satisfaction_instructions_1": "Muy insatisfecho",
  "patient_adult_knee_satisfaction_instructions_2": "De alguna manera insatisfecho",
  "patient_adult_knee_satisfaction_instructions_3": "De alguna manera satisfecho",
  "patient_adult_knee_satisfaction_instructions_4": "Muy Satisfecho",
  "patient_adult_knee_satisfaction_q1": "En general",
  "patient_adult_knee_satisfaction_q1_not_applicable": "No aplicable",
  "patient_adult_knee_satisfaction_q1_a1": "Muy insatisfecho",
  "patient_adult_knee_satisfaction_q1_a2": "De alguna manera insatisfecho",
  "patient_adult_knee_satisfaction_q1_a3": "De alguna manera satisfecho",
  "patient_adult_knee_satisfaction_q1_a4": "Muy Satisfecho",
  "patient_adult_knee_satisfaction_q2": "¿En la mejora de su dolor?",
  "patient_adult_knee_satisfaction_q2_not_applicable": "No aplicable",
  "patient_adult_knee_satisfaction_q2_a1": "Muy insatisfecho",
  "patient_adult_knee_satisfaction_q2_a2": "De alguna manera insatisfecho",
  "patient_adult_knee_satisfaction_q2_a3": "De alguna manera satisfecho",
  "patient_adult_knee_satisfaction_q2_a4": "Muy Satisfecho",
  "patient_adult_knee_satisfaction_q3": "¿En la mejora de su capacidad para hacer trabajos en el hogar o en el jardín?",
  "patient_adult_knee_satisfaction_q3_not_applicable": "No aplicable",
  "patient_adult_knee_satisfaction_q3_a1": "Muy insatisfecho",
  "patient_adult_knee_satisfaction_q3_a2": "De alguna manera insatisfecho",
  "patient_adult_knee_satisfaction_q3_a3": "De alguna manera satisfecho",
  "patient_adult_knee_satisfaction_q3_a4": "Muy Satisfecho",
  "patient_adult_knee_satisfaction_q4": "¿En la mejora de su capacidad para realizar actividades recreativas?",
  "patient_adult_knee_satisfaction_q4_not_applicable": "No aplicable",
  "patient_adult_knee_satisfaction_q4_a1": "Muy insatisfecho",
  "patient_adult_knee_satisfaction_q4_a2": "De alguna manera insatisfecho",
  "patient_adult_knee_satisfaction_q4_a3": "De alguna manera satisfecho",
  "patient_adult_knee_satisfaction_q4_a4": "Muy Satisfecho",
  "patient_adult_knee_complete": "Fin del Cuestionario",
  "patient_adult_knee_complete_text1": "Gracias por tomarse el tiempo de llenar este formulario",
  "patient_adult_knee_complete_text2": "Por favor presiona enviar para completar y ver tus resultados",
  "patient_adult_knee_complete_text3": "Sus respuestas serán tratadas de forma confidencial y cualquier informe solo utilizará información anonimizada",
  "patient_adult_knee_results": "Resultados",
  "patient_adult_knee_results_text1": "A continuación, se encuentran sus puntajes de dolor y funcionamiento de la rodilla, que han sido calculados a partir de su cuestionario.",
  "patient_adult_knee_results_pain": "Dolor",
  "patient_adult_knee_results_function": "Funcionamiento",
  "patient_adult_knee_results_health_perception": "Percepción general de salud",
  "patient_adult_knee_results_self_evaluation": "Puntuación de la autoevaluación de rodilla",
  "patient_adult_knee_results_text2": "Su médico estará con usted en breve.",
  "patient_adolescent_knee_pain": "Dolor",
  "patient_adolescent_knee_function_q23": "Ir de compras",
  "patient_adolescent_knee_function_q23_a1": "Sin dificultad",
  "patient_adolescent_knee_function_q23_a2": "Dificultad mínima",
  "patient_adolescent_knee_function_q23_a3": "Dificultad moderada",
  "patient_adolescent_knee_function_q23_a4": "Dificultad extrema",
  "patient_adolescent_knee_function_q23_a5": "Incapacidad para hacerlo",
  "patient_pedia_knee_pain": "Dolor",
  "patient_pedia_knee_pain_q1": "¿Durante las últimas 4 semanas o desde su lesión, ¿Por cuánto tiempo ha sentido dolor en la rodilla lesionada?",
  "patient_pedia_knee_pain_q1_a1": "Nunca",
  "patient_pedia_knee_pain_q1_a2": "Raramente",
  "patient_pedia_knee_pain_q1_a3": "A veces",
  "patient_pedia_knee_pain_q1_a4": "Frecuentemente",
  "patient_pedia_knee_pain_q1_a5": "Constantemente",
  "patient_pedia_knee_pain_q2": "¿Qué tan grave es el dolor de la rodilla lesionada hoy?",
  "patient_pedia_knee_pain_q2_a1": "No duele en absoluto",
  "patient_pedia_knee_pain_q2_a2": "Duele tanto que no puedo soportarlo",
  "patient_pedia_knee_pain_instructions": "¿Cuánto dolor de rodilla ha experimentado en los últimos 7 días durante las siguientes actividades?",
  "patient_pedia_knee_pain_instructions_0": "Sin dolor",
  "patient_pedia_knee_pain_instructions_1": "Un pequeño dolor",
  "patient_pedia_knee_pain_instructions_2": "Un poco de dolor",
  "patient_pedia_knee_pain_instructions_3": "Mucho dolor",
  "patient_pedia_knee_pain_instructions_4": "Dolor extremo",
  "patient_pedia_knee_pain_note": "Nota: responda las siguientes preguntas en relación a las actividades donde utiliza su rodilla.",
  "patient_pedia_knee_pain_q3": "Torcer/pivotar la rodilla al caminar/pararse/correr",
  "patient_pedia_knee_pain_q3_a1": "Sin dolor",
  "patient_pedia_knee_pain_q3_a2": "Un pequeño dolor",
  "patient_pedia_knee_pain_q3_a3": "Un poco de dolor",
  "patient_pedia_knee_pain_q3_a4": "Mucho dolor",
  "patient_pedia_knee_pain_q3_a5": "Dolor extremo",
  "patient_pedia_knee_pain_q4": "Enderezar completamente la rodilla",
  "patient_pedia_knee_pain_q4_a1": "Sin dolor",
  "patient_pedia_knee_pain_q4_a2": "Un pequeño dolor",
  "patient_pedia_knee_pain_q4_a3": "Un poco de dolor",
  "patient_pedia_knee_pain_q4_a4": "Mucho dolor",
  "patient_pedia_knee_pain_q4_a5": "Dolor extremo",
  "patient_pedia_knee_pain_q5": "Doblar completamente la rodilla",
  "patient_pedia_knee_pain_q5_a1": "Sin dolor",
  "patient_pedia_knee_pain_q5_a2": "Un pequeño dolor",
  "patient_pedia_knee_pain_q5_a3": "Un poco de dolor",
  "patient_pedia_knee_pain_q5_a4": "Mucho dolor",
  "patient_pedia_knee_pain_q5_a5": "Dolor extremo",
  "patient_pedia_knee_pain_q6": "Subir escaleras",
  "patient_pedia_knee_pain_q6_a1": "Sin dolor",
  "patient_pedia_knee_pain_q6_a2": "Un pequeño dolor",
  "patient_pedia_knee_pain_q6_a3": "Un poco de dolor",
  "patient_pedia_knee_pain_q6_a4": "Mucho dolor",
  "patient_pedia_knee_pain_q6_a5": "Dolor extremo",
  "patient_pedia_knee_pain_q7": "Bajar escaleras",
  "patient_pedia_knee_pain_q7_a1": "Sin dolor",
  "patient_pedia_knee_pain_q7_a2": "Un pequeño dolor",
  "patient_pedia_knee_pain_q7_a3": "Un poco de dolor",
  "patient_pedia_knee_pain_q7_a4": "Mucho dolor",
  "patient_pedia_knee_pain_q7_a5": "Dolor extremo",
  "patient_pedia_knee_pain_q8": "Sentarse con la rodilla doblada",
  "patient_pedia_knee_pain_q8_a1": "Sin dolor",
  "patient_pedia_knee_pain_q8_a2": "Un pequeño dolor",
  "patient_pedia_knee_pain_q8_a3": "Un poco de dolor",
  "patient_pedia_knee_pain_q8_a4": "Mucho dolor",
  "patient_pedia_knee_pain_q8_a5": "Dolor extremo",
  "patient_pedia_knee_pain_q9": "Pararse derecho sobre las dos piernas durante cualquier cantidad de tiempo",
  "patient_pedia_knee_pain_q9_a1": "Sin dolor",
  "patient_pedia_knee_pain_q9_a2": "Un pequeño dolor",
  "patient_pedia_knee_pain_q9_a3": "Un poco de dolor",
  "patient_pedia_knee_pain_q9_a4": "Mucho dolor",
  "patient_pedia_knee_pain_q9_a5": "Dolor extremo",
  "patient_pedia_knee_other_symptoms": "Otros síntomas",
  "patient_pedia_knee_other_symptoms_instruction1": "¿Con qué frecuencia ha tenido lo siguiente?",
  "patient_pedia_knee_other_symptoms_instruction1_0": "Nunca",
  "patient_pedia_knee_other_symptoms_instruction1_1": "Raramente",
  "patient_pedia_knee_other_symptoms_instruction1_2": "A veces",
  "patient_pedia_knee_other_symptoms_instruction1_3": "Frecuentemente",
  "patient_pedia_knee_other_symptoms_instruction1_4": "Siempre",
  "patient_pedia_knee_other_symptoms_q1": "Rodilla haciendo ruidos/sonidos",
  "patient_pedia_knee_other_symptoms_q1_a1": "Nunca",
  "patient_pedia_knee_other_symptoms_q1_a2": "Raramente",
  "patient_pedia_knee_other_symptoms_q1_a3": "A veces",
  "patient_pedia_knee_other_symptoms_q1_a4": "Frecuentemente",
  "patient_pedia_knee_other_symptoms_q1_a5": "Siempre",
  "patient_pedia_knee_other_symptoms_q2": "La rodilla se atasca o se traba en su lugar",
  "patient_pedia_knee_other_symptoms_q2_a1": "Nunca",
  "patient_pedia_knee_other_symptoms_q2_a2": "Raramente",
  "patient_pedia_knee_other_symptoms_q2_a3": "A veces",
  "patient_pedia_knee_other_symptoms_q2_a4": "Frecuentemente",
  "patient_pedia_knee_other_symptoms_q2_a5": "Siempre",
  "patient_pedia_knee_other_symptoms_q3": "Sentir que la rodilla se está atascando (atrapando) pero aún podría moverla",
  "patient_pedia_knee_other_symptoms_q3_a1": "Nunca",
  "patient_pedia_knee_other_symptoms_q3_a2": "Raramente",
  "patient_pedia_knee_other_symptoms_q3_a3": "A veces",
  "patient_pedia_knee_other_symptoms_q3_a4": "Frecuentemente",
  "patient_pedia_knee_other_symptoms_q3_a5": "Siempre",
  "patient_pedia_knee_other_symptoms_q4": "Rodilla hinchada o inflamada",
  "patient_pedia_knee_other_symptoms_q4_a1": "Nunca",
  "patient_pedia_knee_other_symptoms_q4_a2": "Raramente",
  "patient_pedia_knee_other_symptoms_q4_a3": "A veces",
  "patient_pedia_knee_other_symptoms_q4_a4": "Frecuentemente",
  "patient_pedia_knee_other_symptoms_q4_a5": "Siempre",
  "patient_pedia_knee_other_symptoms_q5": "¿Qué tan HINCHADA (O INFLAMADA) estaba su rodilla?",
  "patient_pedia_knee_other_symptoms_q5_a1": "Para nada hinchada",
  "patient_pedia_knee_other_symptoms_q5_a2": "Un poco hinchada",
  "patient_pedia_knee_other_symptoms_q5_a3": "Algo hinchada",
  "patient_pedia_knee_other_symptoms_q5_a4": "Muy hinchada",
  "patient_pedia_knee_other_symptoms_q5_a5": "Extremadamente hinchada",
  "patient_pedia_knee_other_symptoms_instruction2": "Si te pidieran que hicieras las siguientes actividades, ¿Qué es lo máximo que podrías hacer HOY?",
  "patient_pedia_knee_other_symptoms_instruction2_0": "Actividades muy dificiles como saltar o girar rápido para cambiar de dirección como en el baloncesto o el fútbol.",
  "patient_pedia_knee_other_symptoms_instruction2_1": "Actividades dificiles como levantar objetos pesados, esquiar o jugar al tenis",
  "patient_pedia_knee_other_symptoms_instruction2_2": "Actividades algo dificiles como caminar rápido o trotar",
  "patient_pedia_knee_other_symptoms_instruction2_3": "Actividades ligeras como caminar a una velocidad normal",
  "patient_pedia_knee_other_symptoms_instruction2_4": "No puedo hacer ninguna de las actividades mencionadas anteriormente.",
  "patient_pedia_knee_other_symptoms_q6": "Sin que te duela mucho la rodilla",
  "patient_pedia_knee_other_symptoms_q6_a1": "Actividades muy difíciles",
  "patient_pedia_knee_other_symptoms_q6_a2": "Actividades difíciles",
  "patient_pedia_knee_other_symptoms_q6_a3": "Actividades un poco difíciles",
  "patient_pedia_knee_other_symptoms_q6_a4": "Actividades ligeras",
  "patient_pedia_knee_other_symptoms_q6_a5": "No puedo hacer nada de lo anterior",
  "patient_pedia_knee_other_symptoms_q7": "Sin hinchar (o inflamar) la rodilla",
  "patient_pedia_knee_other_symptoms_q7_a1": "Actividades muy difíciles",
  "patient_pedia_knee_other_symptoms_q7_a2": "Actividades difíciles",
  "patient_pedia_knee_other_symptoms_q7_a3": "Actividades un poco difíciles",
  "patient_pedia_knee_other_symptoms_q7_a4": "Actividades ligeras",
  "patient_pedia_knee_other_symptoms_q7_a5": "No puedo hacer nada de lo anterior",
  "patient_pedia_knee_other_symptoms_q8": "Sin sentir que tu rodilla NO PUEDE SOSTENERTE",
  "patient_pedia_knee_other_symptoms_q8_a1": "Actividades muy difíciles",
  "patient_pedia_knee_other_symptoms_q8_a2": "Actividades difíciles",
  "patient_pedia_knee_other_symptoms_q8_a3": "Actividades un poco difíciles",
  "patient_pedia_knee_other_symptoms_q8_a5": "No puedo hacer nada de lo anterior",
  "patient_pedia_knee_other_symptoms_q9": "La mayor parte del tiempo",
  "patient_pedia_knee_other_symptoms_q9_a1": "Actividades muy difíciles",
  "patient_pedia_knee_other_symptoms_q9_a2": "Actividades difíciles",
  "patient_pedia_knee_other_symptoms_q9_a3": "Actividades un poco difíciles",
  "patient_pedia_knee_other_symptoms_q9_a4": "Actividades ligeras",
  "patient_pedia_knee_other_symptoms_q9_a5": "No puedo hacer nada de lo anterior",
  "patient_pedia_knee_function": "Funcionamiento",
  "patient_pedia_knee_function_instruction": "¿Cuánta dificultad ha tenido para hacer lo siguiente debido a su rodilla?",
  "patient_pedia_knee_function_instruction_0": "Sin dificultad",
  "patient_pedia_knee_function_instruction_1": "Un poco",
  "patient_pedia_knee_function_instruction_2": "Algo",
  "patient_pedia_knee_function_instruction_3": "Mucha",
  "patient_pedia_knee_function_instruction_4": "Dificultad extrema/incapaz de realizar",
  "patient_pedia_knee_function_note": "Nota: Si hay ciertas actividades que no realizas, imagina como se sentiría tu rodilla al intentarlo.",
  "patient_pedia_knee_function_q1": "Subir escaleras",
  "patient_pedia_knee_function_q1_a1": "Sin dificultad",
  "patient_pedia_knee_function_q1_a2": "Un poco",
  "patient_pedia_knee_function_q1_a3": "Algo",
  "patient_pedia_knee_function_q1_a4": "Mucha",
  "patient_pedia_knee_function_q1_a5": "Dificultad extrema/incapaz de realizar",
  "patient_pedia_knee_function_q2": "Bajar escaleras",
  "patient_pedia_knee_function_q2_a1": "Sin dificultad",
  "patient_pedia_knee_function_q2_a2": "Un poco",
  "patient_pedia_knee_function_q2_a3": "Algo",
  "patient_pedia_knee_function_q2_a4": "Mucha",
  "patient_pedia_knee_function_q2_a5": "Dificultad extrema/incapaz de realizar",
  "patient_pedia_knee_function_q3": "Arrodillarse en la parte frontal de la rodilla",
  "patient_pedia_knee_function_q3_a1": "Sin dificultad",
  "patient_pedia_knee_function_q3_a2": "Un poco",
  "patient_pedia_knee_function_q3_a3": "Algo",
  "patient_pedia_knee_function_q3_a4": "Mucha",
  "patient_pedia_knee_function_q3_a5": "Dificultad extrema/incapaz de realizar",
  "patient_pedia_knee_function_q4": "Estirar completamente la rodilla",
  "patient_pedia_knee_function_q4_a1": "Sin dificultad",
  "patient_pedia_knee_function_q4_a2": "Un poco",
  "patient_pedia_knee_function_q4_a3": "Algo",
  "patient_pedia_knee_function_q4_a4": "Mucha",
  "patient_pedia_knee_function_q4_a5": "Dificultad extrema/incapaz de realizar",
  "patient_pedia_knee_function_q5": "Doblar completamente la rodilla",
  "patient_pedia_knee_function_q5_a1": "Sin dificultad",
  "patient_pedia_knee_function_q5_a2": "Un poco",
  "patient_pedia_knee_function_q5_a3": "Algo",
  "patient_pedia_knee_function_q5_a4": "Mucha",
  "patient_pedia_knee_function_q5_a5": "Dificultad extrema/incapaz de realizar",
  "patient_pedia_knee_function_q6": "Mover la rodilla justo después de despertarse por la mañana",
  "patient_pedia_knee_function_q6_a1": "Sin dificultad",
  "patient_pedia_knee_function_q6_a2": "Un poco",
  "patient_pedia_knee_function_q6_a3": "Algo",
  "patient_pedia_knee_function_q6_a4": "Mucha",
  "patient_pedia_knee_function_q6_a5": "Dificultad extrema/incapaz de realizar",
  "patient_pedia_knee_function_q7": "Mover la rodilla más tarde en el día después de estar sedentario por un tiempo",
  "patient_pedia_knee_function_q7_a1": "Sin dificultad",
  "patient_pedia_knee_function_q7_a2": "Un poco",
  "patient_pedia_knee_function_q7_a3": "Algo",
  "patient_pedia_knee_function_q7_a4": "Mucha",
  "patient_pedia_knee_function_q7_a5": "Dificultad extrema/incapaz de realizar",
  "patient_pedia_knee_function_q8": "Agacharse como un catcher de béisbol ",
  "patient_pedia_knee_function_q8_a1": "Sin dificultad",
  "patient_pedia_knee_function_q8_a2": "Un poco",
  "patient_pedia_knee_function_q8_a3": "Algo",
  "patient_pedia_knee_function_q8_a4": "Mucha",
  "patient_pedia_knee_function_q8_a5": "Dificultad extrema/incapaz de realizar",
  "patient_pedia_knee_function_q9": "Sentarse en una silla con la rodilla doblada y los pies apoyados en el suelo",
  "patient_pedia_knee_function_q9_a1": "Sin dificultad",
  "patient_pedia_knee_function_q9_a2": "Un poco",
  "patient_pedia_knee_function_q9_a3": "Algo",
  "patient_pedia_knee_function_q9_a4": "Mucha",
  "patient_pedia_knee_function_q9_a5": "Dificultad extrema/incapaz de realizar",
  "patient_pedia_knee_function_q10": "Levantarse de una silla",
  "patient_pedia_knee_function_q10_a1": "Sin dificultad",
  "patient_pedia_knee_function_q10_a2": "Un poco",
  "patient_pedia_knee_function_q10_a3": "Algo",
  "patient_pedia_knee_function_q10_a4": "Mucha",
  "patient_pedia_knee_function_q10_a5": "Dificultad extrema/incapaz de realizar",
  "patient_pedia_knee_function_q11": "Inclinarse para recoger un objeto del piso",
  "patient_pedia_knee_function_q11_a1": "Sin dificultad",
  "patient_pedia_knee_function_q11_a2": "Un poco",
  "patient_pedia_knee_function_q11_a3": "Algo",
  "patient_pedia_knee_function_q11_a4": "Mucha",
  "patient_pedia_knee_function_q11_a5": "Dificultad extrema/incapaz de realizar",
  "patient_pedia_knee_function_q12": "Correr durante el juego o actividades deportivas",
  "patient_pedia_knee_function_q12_a1": "Sin dificultad",
  "patient_pedia_knee_function_q12_a2": "Un poco",
  "patient_pedia_knee_function_q12_a3": "Algo",
  "patient_pedia_knee_function_q12_a4": "Mucha",
  "patient_pedia_knee_function_q12_a5": "Dificultad extrema/incapaz de realizar",
  "patient_pedia_knee_function_q13": "Saltar durante el juego o actividades deportivas",
  "patient_pedia_knee_function_q13_a1": "Sin dificultad",
  "patient_pedia_knee_function_q13_a2": "Un poco",
  "patient_pedia_knee_function_q13_a3": "Algo",
  "patient_pedia_knee_function_q13_a4": "Mucha",
  "patient_pedia_knee_function_q13_a5": "Dificultad extrema/incapaz de realizar",
  "patient_pedia_knee_function_q14": "Comenzar y dejar de moverse rápidamente",
  "patient_pedia_knee_function_q14_a1": "Sin dificultad",
  "patient_pedia_knee_function_q14_a2": "Un poco",
  "patient_pedia_knee_function_q14_a3": "Algo",
  "patient_pedia_knee_function_q14_a4": "Mucha",
  "patient_pedia_knee_function_q14_a5": "Dificultad extrema/incapaz de realizar",
  "patient_pedia_knee_function_q15": "Giro/pivote durante el juego o actividades deportivas",
  "patient_pedia_knee_function_q15_a1": "Sin dificultad",
  "patient_pedia_knee_function_q15_a2": "Un poco",
  "patient_pedia_knee_function_q15_a3": "Algo",
  "patient_pedia_knee_function_q15_a4": "Mucha",
  "patient_pedia_knee_function_q15_a5": "Dificultad extrema/incapaz de realizar",
  "patient_pedia_knee_function_q16": "Mantener el equilibrio al caminar o correr en terreno irregular",
  "patient_pedia_knee_function_q16_a1": "Sin dificultad",
  "patient_pedia_knee_function_q16_a2": "Un poco",
  "patient_pedia_knee_function_q16_a3": "Algo",
  "patient_pedia_knee_function_q16_a4": "Mucha",
  "patient_pedia_knee_function_q16_a5": "Dificultad extrema/incapaz de realizar",
  "patient_pedia_knee_function_q17": "Hacer deporte",
  "patient_pedia_knee_function_q17_a1": "Sin dificultad",
  "patient_pedia_knee_function_q17_a2": "Un poco",
  "patient_pedia_knee_function_q17_a3": "Algo",
  "patient_pedia_knee_function_q17_a4": "Mucha",
  "patient_pedia_knee_function_q17_a5": "Dificultad extrema/incapaz de realizar",
  "patient_pedia_knee_function_q18": "Entrar/salir de un automóvil",
  "patient_pedia_knee_function_q18_a1": "Sin dificultad",
  "patient_pedia_knee_function_q18_a2": "Un poco",
  "patient_pedia_knee_function_q18_a3": "Algo",
  "patient_pedia_knee_function_q18_a4": "Mucha",
  "patient_pedia_knee_function_q18_a5": "Dificultad extrema/incapaz de realizar",
  "patient_pedia_knee_function_q19": "Levantarse de la cama",
  "patient_pedia_knee_function_q19_a1": "Sin dificultad",
  "patient_pedia_knee_function_q19_a2": "Un poco",
  "patient_pedia_knee_function_q19_a3": "Algo",
  "patient_pedia_knee_function_q19_a4": "Mucha",
  "patient_pedia_knee_function_q19_a5": "Dificultad extrema/incapaz de realizar",
  "patient_pedia_knee_function_q20": "Cambiar la posición de las rodillas al acostarse en la cama",
  "patient_pedia_knee_function_q20_a1": "Sin dificultad",
  "patient_pedia_knee_function_q20_a2": "Un poco",
  "patient_pedia_knee_function_q20_a3": "Algo",
  "patient_pedia_knee_function_q20_a4": "Mucha",
  "patient_pedia_knee_function_q20_a5": "Dificultad extrema/incapaz de realizar",
  "patient_pedia_knee_function_q21": "Entrar/salir de la bañera/ducha",
  "patient_pedia_knee_function_q21_a1": "Sin dificultad",
  "patient_pedia_knee_function_q21_a2": "Un poco",
  "patient_pedia_knee_function_q21_a3": "Algo",
  "patient_pedia_knee_function_q21_a4": "Mucha",
  "patient_pedia_knee_function_q21_a5": "Dificultad extrema/incapaz de realizar",
  "patient_pedia_knee_function_q22": "Llevar bolsas/mochilas pesadas, etc.",
  "patient_pedia_knee_function_q22_a1": "Sin dificultad",
  "patient_pedia_knee_function_q22_a2": "Un poco",
  "patient_pedia_knee_function_q22_a3": "Algo",
  "patient_pedia_knee_function_q22_a4": "Mucha",
  "patient_pedia_knee_function_q22_a5": "Dificultad extrema/incapaz de realizar",
  "patient_pedia_knee_function_q23": "Hacer tareas livianas como limpiar tu habitación, llenar/vaciar el lavavajillas, hacer tu cama, etc.",
  "patient_pedia_knee_function_q23_a1": "Sin dificultad",
  "patient_pedia_knee_function_q23_a2": "Un poco",
  "patient_pedia_knee_function_q23_a3": "Algo",
  "patient_pedia_knee_function_q23_a4": "Mucha",
  "patient_pedia_knee_function_q23_a5": "Dificultad extrema/incapaz de realizar",
  "patient_pedia_knee_function_q24": "Llegar a la escuela o caminar en la escuela (subir escaleras, abrir puertas, llevar libros, participar durante el recreo)",
  "patient_pedia_knee_function_q24_a1": "Sin dificultad",
  "patient_pedia_knee_function_q24_a2": "Un poco",
  "patient_pedia_knee_function_q24_a3": "Algo",
  "patient_pedia_knee_function_q24_a4": "Mucha",
  "patient_pedia_knee_function_q24_a5": "Dificultad extrema/incapaz de realizar",
  "patient_pedia_knee_function_q25": "Hacer cosas con amigos",
  "patient_pedia_knee_function_q25_a1": "Sin dificultad",
  "patient_pedia_knee_function_q25_a2": "Un poco",
  "patient_pedia_knee_function_q25_a3": "Algo",
  "patient_pedia_knee_function_q25_a4": "Mucha",
  "patient_pedia_knee_function_q25_a5": "Dificultad extrema/incapaz de realizar",
  "patient_pedia_knee_function_q26": "¿Qué tan bien funcionaba su rodilla ANTES DE LA LESIÓN?",
  "patient_pedia_knee_function_q26_instruction": "siendo 10 normal y 0 la incapacidad para realizar cualquiera de sus actividades diarias habituales, incluyendo deportes",
  "patient_pedia_knee_function_q26_instruction_a1": "No podía hacer nada en absoluto",
  "patient_pedia_knee_function_q26_instruction_a2": "Podía hacer lo que quisiera",
  "patient_pedia_knee_function_q27": "¿Qué tan bien funciona su rodilla EN ESTOS MOMENTOS?",
  "patient_pedia_knee_function_q27_instruction": "Siendo 10 normal y 0 la incapacidad para realizar cualquiera de sus actividades diarias habituales, incluyendo deportes",
  "patient_pedia_knee_function_q27_instruction_a1": "No soy capaz de realizar ninguna actividad",
  "patient_pedia_knee_function_q27_instruction_a2": "Soy capaz de hacer cualquier cosa que quiera hacer",
  "patient_pedia_knee_function_q28": "¿Su rótula se sale de lugar o siente que se está saliendo de lugar?",
  "patient_pedia_knee_function_q28_a1": "No",
  "patient_pedia_knee_function_q28_a2": "Si",
  "patient_pedia_knee_instability": "Inestabilidad patelofemoral",
  "patient_pedia_knee_instability_instruction": "Que es lo que mejor describe CON QUÉ FRECUENCIA SE SIENTE QUE LA RODILLA SE “SALE” DE LA ARTICULACIÓN O SE SIENTE INESTABLE cuando realiza cada una de las siguientes actividades",
  "patient_pedia_knee_instability_instruction_0": "Nunca",
  "patient_pedia_knee_instability_instruction_1": "Raramente",
  "patient_pedia_knee_instability_instruction_2": "A veces",
  "patient_pedia_knee_instability_instruction_3": "Frecuentemente",
  "patient_pedia_knee_instability_instruction_4": "Siempre",
  "patient_pedia_knee_instability_note": "Nota: Por favor responda las siguientes preguntas basadas en sus actividades relacionadas a la rodilla",
  "patient_pedia_knee_instability_q1": "Girar/cambiar de dirección durante deportes/juegos.",
  "patient_pedia_knee_instability_q1_do_not_do": "No lo hago",
  "patient_pedia_knee_instability_q1_a1": "Nunca",
  "patient_pedia_knee_instability_q1_a2": "Raramente",
  "patient_pedia_knee_instability_q1_a3": "A veces",
  "patient_pedia_knee_instability_q1_a4": "Frecuentemente",
  "patient_pedia_knee_instability_q1_a5": "Siempre",
  "patient_pedia_knee_instability_q2": "Cambiar de dirección al correr",
  "patient_pedia_knee_instability_q2_do_not_do": "No lo hago",
  "patient_pedia_knee_instability_q2_a1": "Nunca",
  "patient_pedia_knee_instability_q2_a2": "Raramente",
  "patient_pedia_knee_instability_q2_a3": "A veces",
  "patient_pedia_knee_instability_q2_a4": "Frecuentemente",
  "patient_pedia_knee_instability_q2_a5": "Siempre",
  "patient_pedia_knee_instability_q3": "Correr en línea recta sobre superficies irregulares",
  "patient_pedia_knee_instability_q3_do_not_do": "No lo hago",
  "patient_pedia_knee_instability_q3_a1": "Nunca",
  "patient_pedia_knee_instability_q3_a2": "Raramente",
  "patient_pedia_knee_instability_q3_a3": "A veces",
  "patient_pedia_knee_instability_q3_a4": "Frecuentemente",
  "patient_pedia_knee_instability_q3_a5": "Siempre",
  "patient_pedia_knee_instability_q4": "Caminar sobre superficies resbaladizas, mojadas o heladas",
  "patient_pedia_knee_instability_q4_do_not_do": "No lo hago",
  "patient_pedia_knee_instability_q4_a1": "Nunca",
  "patient_pedia_knee_instability_q4_a2": "Raramente",
  "patient_pedia_knee_instability_q4_a3": "A veces",
  "patient_pedia_knee_instability_q4_a4": "Frecuentemente",
  "patient_pedia_knee_instability_q4_a5": "Siempre",
  "patient_pedia_knee_instability_q5": "Correr de lado",
  "patient_pedia_knee_instability_q5_do_not_do": "No lo hago",
  "patient_pedia_knee_instability_q5_a1": "Nunca",
  "patient_pedia_knee_instability_q5_a2": "Raramente",
  "patient_pedia_knee_instability_q5_a3": "A veces",
  "patient_pedia_knee_instability_q5_a4": "Frecuentemente",
  "patient_pedia_knee_instability_q5_a5": "Siempre",
  "patient_pedia_knee_instability_q6": "Saltos cortos",
  "patient_pedia_knee_instability_q6_do_not_do": "No lo hago",
  "patient_pedia_knee_instability_q6_a1": "Nunca",
  "patient_pedia_knee_instability_q6_a2": "Raramente",
  "patient_pedia_knee_instability_q6_a3": "A veces",
  "patient_pedia_knee_instability_q6_a4": "Frecuentemente",
  "patient_pedia_knee_instability_q6_a5": "Siempre",
  "patient_pedia_knee_instability_q7": "Saltar",
  "patient_pedia_knee_instability_q7_do_not_do": "No lo hago",
  "patient_pedia_knee_instability_q7_a1": "Nunca",
  "patient_pedia_knee_instability_q7_a2": "Raramente",
  "patient_pedia_knee_instability_q7_a3": "A veces",
  "patient_pedia_knee_instability_q7_a4": "Frecuentemente",
  "patient_pedia_knee_instability_q7_a5": "Siempre",
  "patient_pedia_knee_instability_q8": "Correr en línea recta sobre superficie plana",
  "patient_pedia_knee_instability_q8_do_not_do": "No lo hago",
  "patient_pedia_knee_instability_q8_a1": "Nunca",
  "patient_pedia_knee_instability_q8_a2": "Raramente",
  "patient_pedia_knee_instability_q8_a3": "A veces",
  "patient_pedia_knee_instability_q8_a4": "Frecuentemente",
  "patient_pedia_knee_instability_q8_a5": "Siempre",
  "patient_pedia_knee_instability_q9": "Bajar escaleras",
  "patient_pedia_knee_instability_q9_do_not_do": "No lo hago",
  "patient_pedia_knee_instability_q9_a1": "Nunca",
  "patient_pedia_knee_instability_q9_a2": "Raramente",
  "patient_pedia_knee_instability_q9_a3": "A veces",
  "patient_pedia_knee_instability_q9_a4": "Frecuentemente",
  "patient_pedia_knee_instability_q9_a5": "Siempre",
  "patient_pedia_knee_instability_q10": "Sentadillas",
  "patient_pedia_knee_instability_q10_do_not_do": "No lo hago",
  "patient_pedia_knee_instability_q10_a1": "Nunca",
  "patient_pedia_knee_instability_q10_a2": "Raramente",
  "patient_pedia_knee_instability_q10_a3": "A veces",
  "patient_pedia_knee_instability_q10_a4": "Frecuentemente",
  "patient_pedia_knee_instability_q10_a5": "Siempre",
  "patient_pedia_knee_instability_q11": "Arrodillarse",
  "patient_pedia_knee_instability_q11_do_not_do": "No lo hago",
  "patient_pedia_knee_instability_q11_a1": "Nunca",
  "patient_pedia_knee_instability_q11_a2": "Raramente",
  "patient_pedia_knee_instability_q11_a3": "A veces",
  "patient_pedia_knee_instability_q11_a4": "Frecuentemente",
  "patient_pedia_knee_instability_q11_a5": "Siempre",
  "patient_pedia_knee_instability_q12": "Caminar en línea recta sobre superficies irregulares",
  "patient_pedia_knee_instability_q12_do_not_do": "No lo hago",
  "patient_pedia_knee_instability_q12_a1": "Nunca",
  "patient_pedia_knee_instability_q12_a2": "Raramente",
  "patient_pedia_knee_instability_q12_a3": "A veces",
  "patient_pedia_knee_instability_q12_a4": "Frecuentemente",
  "patient_pedia_knee_instability_q12_a5": "Siempre",
  "patient_pedia_knee_instability_q13": "Subir escaleras",
  "patient_pedia_knee_instability_q13_do_not_do": "No lo hago",
  "patient_pedia_knee_instability_q13_a1": "Nunca",
  "patient_pedia_knee_instability_q13_a2": "Raramente",
  "patient_pedia_knee_instability_q13_a3": "A veces",
  "patient_pedia_knee_instability_q13_a4": "Frecuentemente",
  "patient_pedia_knee_instability_q13_a5": "Siempre",
  "patient_pedia_knee_instability_q14": "Pisar sobre un escalón alto",
  "patient_pedia_knee_instability_q14_do_not_do": "No lo hago",
  "patient_pedia_knee_instability_q14_a1": "Nunca",
  "patient_pedia_knee_instability_q14_a2": "Raramente",
  "patient_pedia_knee_instability_q14_a3": "A veces",
  "patient_pedia_knee_instability_q14_a4": "Frecuentemente",
  "patient_pedia_knee_instability_q14_a5": "Siempre",
  "patient_pedia_knee_instability_q15": "Cruzar las piernas al sentarse",
  "patient_pedia_knee_instability_q15_do_not_do": "No lo hago",
  "patient_pedia_knee_instability_q15_a1": "Nunca",
  "patient_pedia_knee_instability_q15_a2": "Raramente",
  "patient_pedia_knee_instability_q15_a3": "A veces",
  "patient_pedia_knee_instability_q15_a4": "Frecuentemente",
  "patient_pedia_knee_instability_q15_a5": "Siempre",
  "patient_pedia_knee_instability_q16": "Caminar en línea recta sobre superficie PLANA",
  "patient_pedia_knee_instability_q16_do_not_do": "No lo hago",
  "patient_pedia_knee_instability_q16_a1": "Nunca",
  "patient_pedia_knee_instability_q16_a2": "Raramente",
  "patient_pedia_knee_instability_q16_a3": "A veces",
  "patient_pedia_knee_instability_q16_a4": "Frecuentemente",
  "patient_pedia_knee_instability_q16_a5": "Siempre",
  "patient_pedia_knee_instability_q17": "Entrar o salir de un coche",
  "patient_pedia_knee_instability_q17_do_not_do": "No lo hago",
  "patient_pedia_knee_instability_q17_a1": "Nunca",
  "patient_pedia_knee_instability_q17_a2": "Raramente",
  "patient_pedia_knee_instability_q17_a3": "A veces",
  "patient_pedia_knee_instability_q17_a4": "Frecuentemente",
  "patient_pedia_knee_instability_q17_a5": "Siempre",
  "patient_pedia_knee_instability_q18": "Girar un carrito pesado en un pasillo de supermercado",
  "patient_pedia_knee_instability_q18_do_not_do": "No lo hago",
  "patient_pedia_knee_instability_q18_a1": "Nunca",
  "patient_pedia_knee_instability_q18_a2": "Raramente",
  "patient_pedia_knee_instability_q18_a3": "A veces",
  "patient_pedia_knee_instability_q18_a4": "Frecuentemente",
  "patient_pedia_knee_instability_q18_a5": "Siempre",
  "patient_pedia_knee_instability_q19": "Girar para mirar sobre su hombro",
  "patient_pedia_knee_instability_q19_do_not_do": "No lo hago",
  "patient_pedia_knee_instability_q19_a1": "Nunca",
  "patient_pedia_knee_instability_q19_a2": "Raramente",
  "patient_pedia_knee_instability_q19_a3": "A veces",
  "patient_pedia_knee_instability_q19_a4": "Frecuentemente",
  "patient_pedia_knee_instability_q19_a5": "Siempre",
  "patient_pedia_knee_quality_of_life": "Calidad de vida",
  "patient_pedia_knee_quality_of_life_instruction": "¿Cómo ha afectado tu lesión a tu vida diaria?",
  "patient_pedia_knee_quality_of_life_q1": "¿Con qué frecuencia piensas en tu problema de rodilla?",
  "patient_pedia_knee_quality_of_life_q1_a1": "Nunca",
  "patient_pedia_knee_quality_of_life_q1_a2": "Raramente",
  "patient_pedia_knee_quality_of_life_q1_a3": "A veces",
  "patient_pedia_knee_quality_of_life_q1_a4": "Frecuentemente",
  "patient_pedia_knee_quality_of_life_q1_a5": "Todo el tiempo",
  "patient_pedia_knee_problem_q2": "¿Cuánto ha cambiado su estilo de vida debido a su rodilla lesionada?",
  "patient_pedia_knee_problem_q2_a1": "En absoluto",
  "patient_pedia_knee_problem_q2_a2": "Un poco",
  "patient_pedia_knee_problem_q2_a3": "Algo",
  "patient_pedia_knee_problem_q2_a4": "Mucho",
  "patient_pedia_knee_problem_q2_a5": "Demasiado",
  "patient_pedia_knee_problem_q3": "¿Cuánto confía en su rodilla lesionada?",
  "patient_pedia_knee_problem_q3_a1": "Completamente",
  "patient_pedia_knee_problem_q3_a2": "Mucho",
  "patient_pedia_knee_problem_q3_a3": "Algo",
  "patient_pedia_knee_problem_q3_a4": "Un poco",
  "patient_pedia_knee_problem_q3_a5": "En absoluto",
  "patient_pedia_knee_problem_q4": "En general, ¿Cuánta dificultad tiene con su rodilla lesionada?",
  "patient_pedia_knee_problem_q4_a1": "Sin dificultad",
  "patient_pedia_knee_problem_q4_a2": "Un poco",
  "patient_pedia_knee_problem_q4_a3": "Algo",
  "patient_pedia_knee_problem_q4_a4": "Mucha",
  "patient_pedia_knee_problem_q4_a5": "Dificultad extrema",
  "patient_pedia_knee_satisfaction": "Satisfacción",
  "patient_pedia_knee_satisfaction_instruction": "¿Qué tan satisfecho está con los resultados de su tratamiento/cirugía de rodilla?",
  "patient_pedia_knee_satisfaction_instruction_a1": "Muy insatisfecho",
  "patient_pedia_knee_satisfaction_instruction_a2": "De alguna manera insatisfecho",
  "patient_pedia_knee_satisfaction_instruction_a3": "De alguna manera satisfecho",
  "patient_pedia_knee_satisfaction_instruction_a4": "Muy Satisfecho",
  "patient_pedia_knee_satisfaction_q1": "En general",
  "patient_pedia_knee_satisfaction_q1_NA": "No aplicable",
  "patient_pedia_knee_satisfaction_q1_a1": "Muy insatisfecho",
  "patient_pedia_knee_satisfaction_q1_a2": "De alguna manera insatisfecho",
  "patient_pedia_knee_satisfaction_q1_a3": "De alguna manera satisfecho",
  "patient_pedia_knee_satisfaction_q1_a4": "Muy Satisfecho",
  "patient_pedia_knee_satisfaction_q2": "¿En la mejora de su dolor?",
  "patient_pedia_knee_satisfaction_q2_NA": "No aplicable",
  "patient_pedia_knee_satisfaction_q2_a1": "Muy insatisfecho",
  "patient_pedia_knee_satisfaction_q2_a2": "De alguna manera insatisfecho",
  "patient_pedia_knee_satisfaction_q2_a3": "De alguna manera satisfecho",
  "patient_pedia_knee_satisfaction_q2_a4": "Muy Satisfecho",
  "patient_pedia_knee_satisfaction_q3": "¿En la mejora de su capacidad para hacer trabajos en el hogar o en el jardín?",
  "patient_pedia_knee_satisfaction_q3_NA": "No aplicable",
  "patient_pedia_knee_satisfaction_q3_a1": "Muy insatisfecho",
  "patient_pedia_knee_satisfaction_q3_a2": "De alguna manera insatisfecho",
  "patient_pedia_knee_satisfaction_q3_a3": "De alguna manera satisfecho",
  "patient_pedia_knee_satisfaction_q3_a4": "Muy Satisfecho",
  "patient_pedia_knee_satisfaction_q4": "¿En la mejora de su capacidad para realizar actividades recreativas?",
  "patient_pedia_knee_satisfaction_q4_NA": "No aplicable",
  "patient_pedia_knee_satisfaction_q4_a1": "Muy insatisfecho",
  "patient_pedia_knee_satisfaction_q4_a2": "De alguna manera insatisfecho",
  "patient_pedia_knee_satisfaction_q4_a3": "De alguna manera satisfecho",
  "patient_pedia_knee_satisfaction_q4_a4": "Muy Satisfecho",
  "patient_pedia_knee_complete": "Fin del Cuestionario",
  "patient_pedia_knee_complete_text1": "Gracias por tomarse el tiempo de llenar este formulario",
  "patient_pedia_knee_complete_text2": "Por favor presiona enviar para completar y ver tus resultados",
  "patient_pedia_knee_complete_text3": "Sus respuestas serán tratadas de forma confidencial y cualquier informe solo utilizará información anonimizada",
  "patient_pedia_knee_results": "Resultados",
  "patient_pedia_knee_results_text1": "A continuación, se encuentran sus puntajes de dolor y funcionamiento de la rodilla, que han sido calculados a partir de su cuestionario.",
  "patient_pedia_knee_results_pain": "Dolor",
  "patient_pedia_knee_results_function": "Funcionamiento",
  "patient_pedia_knee_results_self_evaluation": "Puntuación de la autoevaluación de rodilla",
  "patient_pedia_knee_results_text2": "Su médico estará con usted en breve.",
  "patient_foot_ankle_pain": "Dolor",
  "patient_foot_ankle_pain_q1": "¿Cuál es el NIVEL DE DOLOR ACTUAL de su pie/tobillo?",
  "patient_foot_ankle_pain_q1_0": "Ninguno",
  "patient_foot_ankle_pain_q1_1": "Peor dolor imaginable",
  "patient_foot_ankle_pain_q2": "¿Cuál es la mejor descripción del DOLOR que siente en su pie/tobillo?",
  "patient_foot_ankle_pain_q2_a1": "Ninguno",
  "patient_foot_ankle_pain_q2_a2": "Leve, ocacional",
  "patient_foot_ankle_pain_q2_a3": "Moderado, diario",
  "patient_foot_ankle_pain_q2_a4": "Severo, presente casi siempre",
  "patient_foot_ankle_pain_q3": "¿Con qué FRECUENCIA experimenta el dolor de pie/tobillo?",
  "patient_foot_ankle_pain_q3_a1": "Nunca",
  "patient_foot_ankle_pain_q3_a2": "Mensual",
  "patient_foot_ankle_pain_q3_a3": "Semanal",
  "patient_foot_ankle_pain_q3_a4": "Diario",
  "patient_foot_ankle_pain_q3_a5": "Siempre",
  "patient_foot_ankle_pain_instruction": "¿Qué cantidad de DOLOR DE PIE/TOBILLO ha experimentado durante las siguientes actividades?",
  "patient_foot_ankle_pain_instruction_0": "Ninguno",
  "patient_foot_ankle_pain_instruction_1": "Leve",
  "patient_foot_ankle_pain_instruction_2": "Moderado",
  "patient_foot_ankle_pain_instruction_3": "Severo",
  "patient_foot_ankle_pain_instruction_4": "Extremo",
  "patient_foot_ankle_pain_q4": "Nivel general de dolor",
  "patient_foot_ankle_pain_q4_a1": "Ninguno",
  "patient_foot_ankle_pain_q4_a2": "Leve",
  "patient_foot_ankle_pain_q4_a3": "Moderado",
  "patient_foot_ankle_pain_q4_a4": "Severo",
  "patient_foot_ankle_pain_q4_a5": "Extremo",
  "patient_foot_ankle_pain_q5": "En reposo",
  "patient_foot_ankle_pain_q5_a1": "Ninguno",
  "patient_foot_ankle_pain_q5_a2": "Leve",
  "patient_foot_ankle_pain_q5_a3": "Moderado",
  "patient_foot_ankle_pain_q5_a4": "Severo",
  "patient_foot_ankle_pain_q5_a5": "Extremo",
  "patient_foot_ankle_pain_q6": "Durante actividades normales",
  "patient_foot_ankle_pain_q6_a1": "Ninguno",
  "patient_foot_ankle_pain_q6_a2": "Leve",
  "patient_foot_ankle_pain_q6_a3": "Moderado",
  "patient_foot_ankle_pain_q6_a4": "Severo",
  "patient_foot_ankle_pain_q6_a5": "Extremo",
  "patient_foot_ankle_pain_q7": "Primera cosa en la mañana",
  "patient_foot_ankle_pain_q7_a1": "Ninguno",
  "patient_foot_ankle_pain_q7_a2": "Leve",
  "patient_foot_ankle_pain_q7_a3": "Moderado",
  "patient_foot_ankle_pain_q7_a4": "Severo",
  "patient_foot_ankle_pain_q7_a5": "Extremo",
  "patient_foot_ankle_pain_q8": "Girar/pivotear sobre su pie/tobillo",
  "patient_foot_ankle_pain_q8_a1": "Ninguno",
  "patient_foot_ankle_pain_q8_a2": "Leve",
  "patient_foot_ankle_pain_q8_a3": "Moderado",
  "patient_foot_ankle_pain_q8_a4": "Severo",
  "patient_foot_ankle_pain_q8_a5": "Extremo",
  "patient_foot_ankle_pain_q9": "Enderezar completamente el pie/tobillo",
  "patient_foot_ankle_pain_q9_a1": "Ninguno",
  "patient_foot_ankle_pain_q9_a2": "Leve",
  "patient_foot_ankle_pain_q9_a3": "Moderado",
  "patient_foot_ankle_pain_q9_a4": "Severo",
  "patient_foot_ankle_pain_q9_a5": "Extremo",
  "patient_foot_ankle_pain_q10": "Doblar completamente el pie/tobillo",
  "patient_foot_ankle_pain_q10_a1": "Ninguno",
  "patient_foot_ankle_pain_q10_a2": "Leve",
  "patient_foot_ankle_pain_q10_a3": "Moderado",
  "patient_foot_ankle_pain_q10_a4": "Severo",
  "patient_foot_ankle_pain_q10_a5": "Extremo",
  "patient_foot_ankle_pain_q11": "Caminar sobre una superficie plana",
  "patient_foot_ankle_pain_q11_a1": "Ninguno",
  "patient_foot_ankle_pain_q11_a2": "Leve",
  "patient_foot_ankle_pain_q11_a3": "Moderado",
  "patient_foot_ankle_pain_q11_a4": "Severo",
  "patient_foot_ankle_pain_q11_a5": "Extremo",
  "patient_foot_ankle_pain_q12": "Subir o bajar escaleras",
  "patient_foot_ankle_pain_q12_a1": "Ninguno",
  "patient_foot_ankle_pain_q12_a2": "Leve",
  "patient_foot_ankle_pain_q12_a3": "Moderado",
  "patient_foot_ankle_pain_q12_a4": "Severo",
  "patient_foot_ankle_pain_q12_a5": "Extremo",
  "patient_foot_ankle_pain_q13": "Por la noche, mientras está en la cama",
  "patient_foot_ankle_pain_q13_a1": "Ninguno",
  "patient_foot_ankle_pain_q13_a2": "Leve",
  "patient_foot_ankle_pain_q13_a3": "Moderado",
  "patient_foot_ankle_pain_q13_a4": "Severo",
  "patient_foot_ankle_pain_q13_a5": "Extremo",
  "patient_foot_ankle_pain_q14": "Sentado o acostado",
  "patient_foot_ankle_pain_q14_a1": "Ninguno",
  "patient_foot_ankle_pain_q14_a2": "Leve",
  "patient_foot_ankle_pain_q14_a3": "Moderado",
  "patient_foot_ankle_pain_q14_a4": "Severo",
  "patient_foot_ankle_pain_q14_a5": "Extremo",
  "patient_foot_ankle_pain_q15": "Estando de pie",
  "patient_foot_ankle_pain_q15_a1": "Ninguno",
  "patient_foot_ankle_pain_q15_a2": "Leve",
  "patient_foot_ankle_pain_q15_a3": "Moderado",
  "patient_foot_ankle_pain_q15_a4": "Severo",
  "patient_foot_ankle_pain_q15_a5": "Extremo",
  "patient_foot_ankle_instability": "Inestabilidad",
  "patient_foot_ankle_instability_q1": "\n¿Experimenta esguinces repetidos, cedimiento de su tobillo, o siente que va a ceder?",
  "patient_foot_ankle_instability_q1_a1": "No",
  "patient_foot_ankle_instability_q1_a2": "Si",
  "patient_foot_ankle_instability_q2": "¿Alguna vez ha sido diagnosticado/tratado por inestabilidad de tobillo/esguinces recurrentes?",
  "patient_foot_ankle_instability_q2_a1": "No",
  "patient_foot_ankle_instability_q2_a2": "Si",
  "patient_foot_ankle_instability_q3": "¿Su tobillo no afectado se siente completamente estable?",
  "patient_foot_ankle_instability_q3_a1": "No",
  "patient_foot_ankle_instability_q3_a2": "Si",
  "patient_foot_ankle_cumberland": "HERRAMIENTA DE INESTABILIDAD DEL TOBILLO CUMBERLAND",
  "patient_foot_ankle_cumberland_instruction": "Elija una declaración en cada pregunta que mejor describa su tobillo.",
  "patient_foot_ankle_cumberland_q1": "Tengo dolor en mi tobillo",
  "patient_foot_ankle_cumberland_q1_a1": "Nunca",
  "patient_foot_ankle_cumberland_q1_a2": "Durante el deporte",
  "patient_foot_ankle_cumberland_q1_a3": "Correr sobre superficies irregulares",
  "patient_foot_ankle_cumberland_q1_a4": "Correr en superficies niveladas",
  "patient_foot_ankle_cumberland_q1_a5": "Caminar sobre superficies irregulares",
  "patient_foot_ankle_cumberland_q1_a6": "Caminar sobre superficies niveladas",
  "patient_foot_ankle_cumberland_q2": "Siento mi tobillo INESTABLE",
  "patient_foot_ankle_cumberland_q2_a1": "Nunca",
  "patient_foot_ankle_cumberland_q2_a2": "A veces durante el deporte (no siempre)",
  "patient_foot_ankle_cumberland_q2_a3": "Con frecuencia durante el deporte (siempre)",
  "patient_foot_ankle_cumberland_q2_a4": "A veces durante la actividad diaria",
  "patient_foot_ankle_cumberland_q2_a5": "Con frecuencia durante actividades diaria",
  "patient_foot_ankle_cumberland_q3": "Cuando hago giros AGUDOS mi tobillo se siente INESTABLE",
  "patient_foot_ankle_cumberland_q3_a1": "Nunca",
  "patient_foot_ankle_cumberland_q3_a2": "A veces cuando estoy corriendo",
  "patient_foot_ankle_cumberland_q3_a3": "A menudo cuando estoy corriendo",
  "patient_foot_ankle_cumberland_q3_a4": "Al caminar",
  "patient_foot_ankle_cumberland_q4": "Al bajar las escaleras mi tobillo se siente INESTABLE",
  "patient_foot_ankle_cumberland_q4_a1": "Nunca",
  "patient_foot_ankle_cumberland_q4_a2": "Si voy rápido",
  "patient_foot_ankle_cumberland_q4_a3": "Ocasionalmente",
  "patient_foot_ankle_cumberland_q4_a4": "Siempre",
  "patient_foot_ankle_cumberland_q5": "Mi tobillo se siente INESTABLE cuando estoy parado en UNA pierna",
  "patient_foot_ankle_cumberland_q5_a1": "Nunca",
  "patient_foot_ankle_cumberland_q5_a2": "En la bola del pie",
  "patient_foot_ankle_cumberland_q5_a3": "Con el pie plano",
  "patient_foot_ankle_cumberland_q6": "Mi tobillo se siente inestable cuando",
  "patient_foot_ankle_cumberland_q6_a1": "Nunca",
  "patient_foot_ankle_cumberland_q6_a2": "Salto de un lado a otro",
  "patient_foot_ankle_cumberland_q6_a3": "Salto en el mismo lugar",
  "patient_foot_ankle_cumberland_q6_a4": "Salto",
  "patient_foot_ankle_cumberland_q7": "Mi tobillo se siente inestable cuando",
  "patient_foot_ankle_cumberland_q7_a1": "Nunca",
  "patient_foot_ankle_cumberland_q7_a2": "Corro sobre superficies irregulares",
  "patient_foot_ankle_cumberland_q7_a3": "Troto en superficies irregulares",
  "patient_foot_ankle_cumberland_q7_a4": "Camino sobre superficies irregulares",
  "patient_foot_ankle_cumberland_q7_a5": "Camino sobre superficies planas",
  "patient_foot_ankle_cumberland_q8": "POR LO GENERAL, cuando empiezo a torcer mi tobillo, puedo detenerlo",
  "patient_foot_ankle_cumberland_q8_a1": "Inmediatamente",
  "patient_foot_ankle_cumberland_q8_a2": "Frecuentemente",
  "patient_foot_ankle_cumberland_q8_a3": "A veces",
  "patient_foot_ankle_cumberland_q8_a4": "Nunca",
  "patient_foot_ankle_cumberland_q8_a5": "Nunca me he torcido el tobillo",
  "patient_foot_ankle_cumberland_q9": "Después de un incidente TÍPICO de torcer mi tobillo, este vuelve a la \"normalidad\"",
  "patient_foot_ankle_cumberland_q9_a1": "Casi inmediatamente",
  "patient_foot_ankle_cumberland_q9_a2": "Menos de un día",
  "patient_foot_ankle_cumberland_q9_a3": "1-2 días",
  "patient_foot_ankle_cumberland_q9_a4": "Más de 2 días",
  "patient_foot_ankle_cumberland_q9_a5": "Nunca me he torcido el tobillo",
  "patient_foot_ankle_other_symptoms": "Otros síntomas",
  "patient_foot_ankle_other_symptoms_instruction1": "Por favor elija la respuesta que mejor corresponda con sus síntomas de pie/tobillo",
  "patient_foot_ankle_other_symptoms_instruction2": "Si no está seguro de como responder una pregunta, dé la mejor respuesta que pueda",
  "patient_foot_ankle_other_symptoms_note": "Nota: Estas preguntas deben responderse considerando los síntomas de su pie/tobillo durante la última semana.",
  "patient_foot_ankle_other_symptoms_instruction3": "¿Tienes alguno de los siguientes síntomas?",
  "patient_foot_ankle_other_symptoms_instruction3_0": "Nunca",
  "patient_foot_ankle_other_symptoms_instruction3_1": "Raramente",
  "patient_foot_ankle_other_symptoms_instruction3_2": "A veces",
  "patient_foot_ankle_other_symptoms_instruction3_3": "Frecuentemente",
  "patient_foot_ankle_other_symptoms_instruction3_4": "Siempre",
  "patient_foot_ankle_other_symptoms_q1": "Hinchazón en el pie/tobillo",
  "patient_foot_ankle_other_symptoms_q1_a1": "Nunca",
  "patient_foot_ankle_other_symptoms_q1_a2": "Raramente",
  "patient_foot_ankle_other_symptoms_q1_a3": "A veces",
  "patient_foot_ankle_other_symptoms_q1_a4": "Frecuentemente",
  "patient_foot_ankle_other_symptoms_q1_a5": "Siempre",
  "patient_foot_ankle_other_symptoms_q2": "Escuchar un clic o algún tipo de sonido similar al mover el pie/tobillo",
  "patient_foot_ankle_other_symptoms_q2_a1": "Nunca",
  "patient_foot_ankle_other_symptoms_q2_a2": "Raramente",
  "patient_foot_ankle_other_symptoms_q2_a3": "A veces",
  "patient_foot_ankle_other_symptoms_q2_a4": "Frecuentemente",
  "patient_foot_ankle_other_symptoms_q2_a5": "Siempre",
  "patient_foot_ankle_other_symptoms_q3": "Sensación enganche o bloqueo cuando se realiza algún movimiento",
  "patient_foot_ankle_other_symptoms_q3_a1": "Nunca",
  "patient_foot_ankle_other_symptoms_q3_a2": "Raramente",
  "patient_foot_ankle_other_symptoms_q3_a3": "A veces",
  "patient_foot_ankle_other_symptoms_q3_a4": "Frecuentemente",
  "patient_foot_ankle_other_symptoms_q3_a5": "Siempre",
  "patient_foot_ankle_other_symptoms_instruction4": "Que tan severa es la RIGIDEZ de su pie/tobillo:",
  "patient_foot_ankle_other_symptoms_instruction4_0": "Ninguno",
  "patient_foot_ankle_other_symptoms_instruction4_1": "Leve",
  "patient_foot_ankle_other_symptoms_instruction4_2": "Moderado",
  "patient_foot_ankle_other_symptoms_instruction4_3": "Severo",
  "patient_foot_ankle_other_symptoms_instruction4_4": "Extremo",
  "patient_foot_ankle_other_symptoms_q4": "Al despertar en la mañana",
  "patient_foot_ankle_other_symptoms_q4_a1": "Ninguno",
  "patient_foot_ankle_other_symptoms_q4_a2": "Leve",
  "patient_foot_ankle_other_symptoms_q4_a3": "Moderado",
  "patient_foot_ankle_other_symptoms_q4_a4": "Severo",
  "patient_foot_ankle_other_symptoms_q4_a5": "Extremo",
  "patient_foot_ankle_other_symptoms_q5": "Después de estar sentado, acostado o descansando más tarde en el día",
  "patient_foot_ankle_other_symptoms_q5_a1": "Ninguno",
  "patient_foot_ankle_other_symptoms_q5_a2": "Leve",
  "patient_foot_ankle_other_symptoms_q5_a3": "Moderado",
  "patient_foot_ankle_other_symptoms_q5_a4": "Severo",
  "patient_foot_ankle_other_symptoms_q5_a5": "Extremo",
  "patient_foot_ankle_function": "Funcionamiento",
  "patient_foot_ankle_function_q1": "¿Qué limitaciones en las actividades diarias ha tenido debido a su pie/tobillo?",
  "patient_foot_ankle_function_q1_a1": "Sin limitaciones, sin soporte",
  "patient_foot_ankle_function_q1_a2": "Sin limitación de las actividades diarias, limitación de las actividades recreativas, sin apoyo",
  "patient_foot_ankle_function_q1_a3": "Actividades diarias y recreativas limitadas, Bastón ",
  "patient_foot_ankle_function_q1_a4": "Limitación severa de las actividades diarias y recreativas, andador, muletas, silla de ruedas, aparato ortopédico",
  "patient_foot_ankle_function_q2": "¿Qué tipo de calzado utilizas usualmente?",
  "patient_foot_ankle_function_q2_a1": "Zapatos convencionales y de moda, no requiere inserto",
  "patient_foot_ankle_function_q2_a2": "Calzado de confort, plantilla para zapatos",
  "patient_foot_ankle_function_q2_a3": "Zapatos o aparatos ortopédicos modificados",
  "patient_foot_ankle_function_q3": "¿Qué tipo de superficie te da dificultades al caminar?",
  "patient_foot_ankle_function_q3_a1": "Sin dificultad en ninguna superficie",
  "patient_foot_ankle_function_q3_a2": "Alguna dificultad en terrenos irregulares, escaleras, pendientes, escaleras",
  "patient_foot_ankle_function_q3_a3": "Dificultad severa en terrenos irregulares, escaleras, pendientes, escaleras",
  "patient_foot_ankle_function_instruction": "Indique el GRADO DE DIFICULTAD que ha experimentado DEBIDO A SU PIE/TOBILLO",
  "patient_foot_ankle_function_instruction_0": "Sin dificultad",
  "patient_foot_ankle_function_instruction_1": "Dificultad leve",
  "patient_foot_ankle_function_instruction_2": "Dificultad moderada",
  "patient_foot_ankle_function_instruction_3": "Dificultad extrema",
  "patient_foot_ankle_function_instruction_4": "Incapacidad para hacerlo",
  "patient_foot_ankle_function_note": "\nNota: si la actividad en cuestión está limitada por algo que no sea su pie o tobillo, marque 'No aplicable' en los elementos donde esta sea una opción. De lo contrario, responda cada pregunta con una respuesta que describa mejor su condición durante la última semana.\n",
  "patient_foot_ankle_function_q4": "Enderezar completamente el pie/tobillo",
  "patient_foot_ankle_function_q4_a1": "Sin dificultad",
  "patient_foot_ankle_function_q4_a2": "Dificultad leve",
  "patient_foot_ankle_function_q4_a3": "Dificultad moderada",
  "patient_foot_ankle_function_q4_a4": "Dificultad extrema",
  "patient_foot_ankle_function_q4_a5": "Incapacidad para hacerlo",
  "patient_foot_ankle_function_q5": "Doblar completamente el pie/tobillo",
  "patient_foot_ankle_function_q5_a1": "Sin dificultad",
  "patient_foot_ankle_function_q5_a2": "Dificultad leve",
  "patient_foot_ankle_function_q5_a3": "Dificultad moderada",
  "patient_foot_ankle_function_q5_a4": "Dificultad extrema",
  "patient_foot_ankle_function_q5_a5": "Incapacidad para hacerlo",
  "patient_foot_ankle_function_q6": "Bajar las escaleras",
  "patient_foot_ankle_function_q6_a1": "Sin dificultad",
  "patient_foot_ankle_function_q6_a2": "Dificultad leve",
  "patient_foot_ankle_function_q6_a3": "Dificultad moderada",
  "patient_foot_ankle_function_q6_a4": "Dificultad extrema",
  "patient_foot_ankle_function_q6_a5": "Incapacidad para hacerlo",
  "patient_foot_ankle_function_q7": "Subir las escaleras",
  "patient_foot_ankle_function_q7_a1": "Sin dificultad",
  "patient_foot_ankle_function_q7_a2": "Dificultad leve",
  "patient_foot_ankle_function_q7_a3": "Dificultad moderada",
  "patient_foot_ankle_function_q7_a4": "Dificultad extrema",
  "patient_foot_ankle_function_q7_a5": "Incapacidad para hacerlo",
  "patient_foot_ankle_function_q8": "Levantarse de estar sentado",
  "patient_foot_ankle_function_q8_a1": "Sin dificultad",
  "patient_foot_ankle_function_q8_a2": "Dificultad leve",
  "patient_foot_ankle_function_q8_a3": "Dificultad moderada",
  "patient_foot_ankle_function_q8_a4": "Dificultad extrema",
  "patient_foot_ankle_function_q8_a5": "Incapacidad para hacerlo",
  "patient_foot_ankle_function_q9": "Estar de pie",
  "patient_foot_ankle_function_q9_a1": "Sin dificultad",
  "patient_foot_ankle_function_q9_a2": "Dificultad leve",
  "patient_foot_ankle_function_q9_a3": "Dificultad moderada",
  "patient_foot_ankle_function_q9_a4": "Dificultad extrema",
  "patient_foot_ankle_function_q9_a5": "Incapacidad para hacerlo",
  "patient_foot_ankle_function_q10": "Inclinarse para recoger un objeto del piso",
  "patient_foot_ankle_function_q10_a1": "Sin dificultad",
  "patient_foot_ankle_function_q10_a2": "Dificultad leve",
  "patient_foot_ankle_function_q10_a3": "Dificultad moderada",
  "patient_foot_ankle_function_q10_a4": "Dificultad extrema",
  "patient_foot_ankle_function_q10_a5": "Incapacidad para hacerlo",
  "patient_foot_ankle_function_q11": "Caminar sobre una superficie plana",
  "patient_foot_ankle_function_q11_a1": "Sin dificultad",
  "patient_foot_ankle_function_q11_a2": "Dificultad leve",
  "patient_foot_ankle_function_q11_a3": "Dificultad moderada",
  "patient_foot_ankle_function_q11_a4": "Dificultad extrema",
  "patient_foot_ankle_function_q11_a5": "Incapacidad para hacerlo",
  "patient_foot_ankle_function_q12": "¿Cuánto es lo máximo que puedes caminar?",
  "patient_foot_ankle_function_q12_a1": "Más de 6 cuadras",
  "patient_foot_ankle_function_q12_a2": "4-6 cuadras",
  "patient_foot_ankle_function_q12_a3": "1-3 cuadras",
  "patient_foot_ankle_function_q12_a4": "Menos de 1 cuadra",
  "patient_foot_ankle_function_q13": "Caminar en terreno llano sin zapatos",
  "patient_foot_ankle_function_q13_a1": "Sin dificultad",
  "patient_foot_ankle_function_q13_a2": "Dificultad leve",
  "patient_foot_ankle_function_q13_a3": "Dificultad moderada",
  "patient_foot_ankle_function_q13_a4": "Dificultad extrema",
  "patient_foot_ankle_function_q13_a5": "Incapacidad para hacerlo",
  "patient_foot_ankle_function_q14": "Subir colinas",
  "patient_foot_ankle_function_q14_a1": "Sin dificultad",
  "patient_foot_ankle_function_q14_a2": "Dificultad leve",
  "patient_foot_ankle_function_q14_a3": "Dificultad moderada",
  "patient_foot_ankle_function_q14_a4": "Dificultad extrema",
  "patient_foot_ankle_function_q14_a5": "Incapacidad para hacerlo",
  "patient_foot_ankle_function_q15": "Caminar por las colinas",
  "patient_foot_ankle_function_q15_a1": "Sin dificultad",
  "patient_foot_ankle_function_q15_a2": "Dificultad leve",
  "patient_foot_ankle_function_q15_a3": "Dificultad moderada",
  "patient_foot_ankle_function_q15_a4": "Dificultad extrema",
  "patient_foot_ankle_function_q15_a5": "Incapacidad para hacerlo",
  "patient_foot_ankle_function_q16": "Caminar sobre terreno irregular",
  "patient_foot_ankle_function_q16_a1": "Sin dificultad",
  "patient_foot_ankle_function_q16_a2": "Dificultad leve",
  "patient_foot_ankle_function_q16_a3": "Dificultad moderada",
  "patient_foot_ankle_function_q16_a4": "Dificultad extrema",
  "patient_foot_ankle_function_q16_a5": "Incapacidad para hacerlo",
  "patient_foot_ankle_function_q17": "Subir y bajar bordillos",
  "patient_foot_ankle_function_q17_a1": "Sin dificultad",
  "patient_foot_ankle_function_q17_a2": "Dificultad leve",
  "patient_foot_ankle_function_q17_a3": "Dificultad moderada",
  "patient_foot_ankle_function_q17_a4": "Dificultad extrema",
  "patient_foot_ankle_function_q17_a5": "Incapacidad para hacerlo",
  "patient_foot_ankle_function_q18": "Pararse de puntillas",
  "patient_foot_ankle_function_q18_a1": "Sin dificultad",
  "patient_foot_ankle_function_q18_a2": "Dificultad leve",
  "patient_foot_ankle_function_q18_a3": "Dificultad moderada",
  "patient_foot_ankle_function_q18_a4": "Dificultad extrema",
  "patient_foot_ankle_function_q18_a5": "Incapacidad para hacerlo",
  "patient_foot_ankle_function_q19": "Primeros pasos al caminar",
  "patient_foot_ankle_function_q19_a1": "Sin dificultad",
  "patient_foot_ankle_function_q19_a2": "Dificultad leve",
  "patient_foot_ankle_function_q19_a3": "Dificultad moderada",
  "patient_foot_ankle_function_q19_a4": "Dificultad extrema",
  "patient_foot_ankle_function_q19_a5": "Incapacidad para hacerlo",
  "patient_foot_ankle_function_q20": "Caminar 5 minutos o menos",
  "patient_foot_ankle_function_q20_a1": "Sin dificultad",
  "patient_foot_ankle_function_q20_a2": "Dificultad leve",
  "patient_foot_ankle_function_q20_a3": "Dificultad moderada",
  "patient_foot_ankle_function_q20_a4": "Dificultad extrema",
  "patient_foot_ankle_function_q20_a5": "Incapacidad para hacerlo",
  "patient_foot_ankle_function_q21": "Caminar por aproximadamente 10 minutos",
  "patient_foot_ankle_function_q21_a1": "Sin dificultad",
  "patient_foot_ankle_function_q21_a2": "Dificultad leve",
  "patient_foot_ankle_function_q21_a3": "Dificultad moderada",
  "patient_foot_ankle_function_q21_a4": "Dificultad extrema",
  "patient_foot_ankle_function_q21_a5": "Incapacidad para hacerlo",
  "patient_foot_ankle_function_q22": "Caminar 15 minutos o más",
  "patient_foot_ankle_function_q22_a1": "Sin dificultad",
  "patient_foot_ankle_function_q22_a2": "Dificultad leve",
  "patient_foot_ankle_function_q22_a3": "Dificultad moderada",
  "patient_foot_ankle_function_q22_a4": "Dificultad extrema",
  "patient_foot_ankle_function_q22_a5": "Incapacidad para hacerlo",
  "patient_foot_ankle_function_q23": "Entrar/salir del coche",
  "patient_foot_ankle_function_q23_a1": "Sin dificultad",
  "patient_foot_ankle_function_q23_a2": "Dificultad leve",
  "patient_foot_ankle_function_q23_a3": "Dificultad moderada",
  "patient_foot_ankle_function_q23_a4": "Dificultad extrema",
  "patient_foot_ankle_function_q23_a5": "Incapacidad para hacerlo",
  "patient_foot_ankle_function_q24": "Ir de compras",
  "patient_foot_ankle_function_q24_a1": "Sin dificultad",
  "patient_foot_ankle_function_q24_a2": "Dificultad leve",
  "patient_foot_ankle_function_q24_a3": "Dificultad moderada",
  "patient_foot_ankle_function_q24_a4": "Dificultad extrema",
  "patient_foot_ankle_function_q24_a5": "Incapacidad para hacerlo",
  "patient_foot_ankle_function_q25": "Ponerse calcetines/medias",
  "patient_foot_ankle_function_q25_a1": "Sin dificultad",
  "patient_foot_ankle_function_q25_a2": "Dificultad leve",
  "patient_foot_ankle_function_q25_a3": "Dificultad moderada",
  "patient_foot_ankle_function_q25_a4": "Dificultad extrema",
  "patient_foot_ankle_function_q25_a5": "Incapacidad para hacerlo",
  "patient_foot_ankle_function_q26": "Quitarse calcetines/medias",
  "patient_foot_ankle_function_q26_a1": "Sin dificultad",
  "patient_foot_ankle_function_q26_a2": "Dificultad leve",
  "patient_foot_ankle_function_q26_a3": "Dificultad moderada",
  "patient_foot_ankle_function_q26_a4": "Dificultad extrema",
  "patient_foot_ankle_function_q26_a5": "Incapacidad para hacerlo",
  "patient_foot_ankle_function_q27": "Levantarse de la cama",
  "patient_foot_ankle_function_q27_a1": "Sin dificultad",
  "patient_foot_ankle_function_q27_a2": "Dificultad leve",
  "patient_foot_ankle_function_q27_a3": "Dificultad moderada",
  "patient_foot_ankle_function_q27_a4": "Dificultad extrema",
  "patient_foot_ankle_function_q27_a5": "Incapacidad para hacerlo",
  "patient_foot_ankle_function_q28": "Acostarse en la cama (darse la vuelta manteniendo la posición del pie/tobillo)",
  "patient_foot_ankle_function_q28_a1": "Sin dificultad",
  "patient_foot_ankle_function_q28_a2": "Dificultad leve",
  "patient_foot_ankle_function_q28_a3": "Dificultad moderada",
  "patient_foot_ankle_function_q28_a4": "Dificultad extrema",
  "patient_foot_ankle_function_q28_a5": "Incapacidad para hacerlo",
  "patient_foot_ankle_function_q29": "Dormir",
  "patient_foot_ankle_function_q29_a1": "Sin dificultad",
  "patient_foot_ankle_function_q29_a2": "Dificultad leve",
  "patient_foot_ankle_function_q29_a3": "Dificultad moderada",
  "patient_foot_ankle_function_q29_a4": "Dificultad extrema",
  "patient_foot_ankle_function_q29_a5": "Incapacidad para hacerlo",
  "patient_foot_ankle_function_q30": "Entrar y salir de la bañera",
  "patient_foot_ankle_function_q30_a1": "Sin dificultad",
  "patient_foot_ankle_function_q30_a2": "Dificultad leve",
  "patient_foot_ankle_function_q30_a3": "Dificultad moderada",
  "patient_foot_ankle_function_q30_a4": "Dificultad extrema",
  "patient_foot_ankle_function_q30_a5": "Incapacidad para hacerlo",
  "patient_foot_ankle_function_q31": "Sentarse",
  "patient_foot_ankle_function_q31_a1": "Sin dificultad",
  "patient_foot_ankle_function_q31_a2": "Dificultad leve",
  "patient_foot_ankle_function_q31_a3": "Dificultad moderada",
  "patient_foot_ankle_function_q31_a4": "Dificultad extrema",
  "patient_foot_ankle_function_q31_a5": "Incapacidad para hacerlo",
  "patient_foot_ankle_function_q32": "Sentarse y levantarse del inodoro",
  "patient_foot_ankle_function_q32_a1": "Sin dificultad",
  "patient_foot_ankle_function_q32_a2": "Dificultad leve",
  "patient_foot_ankle_function_q32_a3": "Dificultad moderada",
  "patient_foot_ankle_function_q32_a4": "Dificultad extrema",
  "patient_foot_ankle_function_q32_a5": "Incapacidad para hacerlo",
  "patient_foot_ankle_function_q33": "Responsabilidades del hogar",
  "patient_foot_ankle_function_q33_a1": "Sin dificultad",
  "patient_foot_ankle_function_q33_a2": "Dificultad leve",
  "patient_foot_ankle_function_q33_a3": "Dificultad moderada",
  "patient_foot_ankle_function_q33_a4": "Dificultad extrema",
  "patient_foot_ankle_function_q33_a5": "Incapacidad para hacerlo",
  "patient_foot_ankle_function_q34": "Actividades de la vida diaria",
  "patient_foot_ankle_function_q34_a1": "Sin dificultad",
  "patient_foot_ankle_function_q34_a2": "Dificultad leve",
  "patient_foot_ankle_function_q34_a3": "Dificultad moderada",
  "patient_foot_ankle_function_q34_a4": "Dificultad extrema",
  "patient_foot_ankle_function_q34_a5": "Incapacidad para hacerlo",
  "patient_foot_ankle_function_q35": "Cuidado personal (incluido lavarse y vestirse solo)",
  "patient_foot_ankle_function_q35_a1": "Sin dificultad",
  "patient_foot_ankle_function_q35_a2": "Dificultad leve",
  "patient_foot_ankle_function_q35_a3": "Dificultad moderada",
  "patient_foot_ankle_function_q35_a4": "Dificultad extrema",
  "patient_foot_ankle_function_q35_a5": "Incapacidad para hacerlo",
  "patient_foot_ankle_function_q36": "Trabajo pesado (empujar/tirar, escalar, transportar, mover cajas pesadas, fregar pisos, etc.)",
  "patient_foot_ankle_function_q36_a1": "Sin dificultad",
  "patient_foot_ankle_function_q36_a2": "Dificultad leve",
  "patient_foot_ankle_function_q36_a3": "Dificultad moderada",
  "patient_foot_ankle_function_q36_a4": "Dificultad extrema",
  "patient_foot_ankle_function_q36_a5": "Incapacidad para hacerlo",
  "patient_foot_ankle_function_q37": "Tareas domésticas ligeras (cocinar, quitar el polvo, etc.)",
  "patient_foot_ankle_function_q37_a1": "Sin dificultad",
  "patient_foot_ankle_function_q37_a2": "Dificultad leve",
  "patient_foot_ankle_function_q37_a3": "Dificultad moderada",
  "patient_foot_ankle_function_q37_a4": "Dificultad extrema",
  "patient_foot_ankle_function_q37_a5": "Incapacidad para hacerlo",
  "patient_foot_ankle_function_q38": "Trabajo ligero a moderado (estar de pie, caminar)",
  "patient_foot_ankle_function_q38_a1": "Sin dificultad",
  "patient_foot_ankle_function_q38_a2": "Dificultad leve",
  "patient_foot_ankle_function_q38_a3": "Dificultad moderada",
  "patient_foot_ankle_function_q38_a4": "Dificultad extrema",
  "patient_foot_ankle_function_q38_a5": "Incapacidad para hacerlo",
  "patient_foot_ankle_function_q39": "Actividades recreativas",
  "patient_foot_ankle_function_q39_a1": "Sin dificultad",
  "patient_foot_ankle_function_q39_a2": "Dificultad leve",
  "patient_foot_ankle_function_q39_a3": "Dificultad moderada",
  "patient_foot_ankle_function_q39_a4": "Dificultad extrema",
  "patient_foot_ankle_function_q39_a5": "Incapacidad para hacerlo",
  "patient_foot_ankle_function_q40": "Sentadillas",
  "patient_foot_ankle_function_q40_a1": "Sin dificultad",
  "patient_foot_ankle_function_q40_a2": "Dificultad leve",
  "patient_foot_ankle_function_q40_a3": "Dificultad moderada",
  "patient_foot_ankle_function_q40_a4": "Dificultad extrema",
  "patient_foot_ankle_function_q40_a5": "Incapacidad para hacerlo",
  "patient_foot_ankle_function_q41": "Girar/pivotear sobre su pie/tobillo",
  "patient_foot_ankle_function_q41_a1": "Sin dificultad",
  "patient_foot_ankle_function_q41_a2": "Dificultad leve",
  "patient_foot_ankle_function_q41_a3": "Dificultad moderada",
  "patient_foot_ankle_function_q41_a4": "Dificultad extrema",
  "patient_foot_ankle_function_q41_a5": "Incapacidad para hacerlo",
  "patient_foot_ankle_function_q42": "Arrodillarse",
  "patient_foot_ankle_function_q42_a1": "Sin dificultad",
  "patient_foot_ankle_function_q42_a2": "Dificultad leve",
  "patient_foot_ankle_function_q42_a3": "Dificultad moderada",
  "patient_foot_ankle_function_q42_a4": "Dificultad extrema",
  "patient_foot_ankle_function_q42_a5": "Incapacidad para hacerlo",
  "patient_foot_ankle_function_q43": "Correr",
  "patient_foot_ankle_function_q43_not_applicable": "No aplicable",
  "patient_foot_ankle_function_q43_a1": "Sin dificultad",
  "patient_foot_ankle_function_q43_a2": "Dificultad leve",
  "patient_foot_ankle_function_q43_a3": "Dificultad moderada",
  "patient_foot_ankle_function_q43_a4": "Dificultad extrema",
  "patient_foot_ankle_function_q43_a5": "Incapacidad para hacerlo",
  "patient_foot_ankle_function_q44": "Saltar",
  "patient_foot_ankle_function_q44_not_applicable": "No aplicable",
  "patient_foot_ankle_function_q44_a1": "Sin dificultad",
  "patient_foot_ankle_function_q44_a2": "Dificultad leve",
  "patient_foot_ankle_function_q44_a3": "Dificultad moderada",
  "patient_foot_ankle_function_q44_a4": "Dificultad extrema",
  "patient_foot_ankle_function_q44_a5": "Incapacidad para hacerlo",
  "patient_foot_ankle_function_q45": "Caer de pie después de un salto",
  "patient_foot_ankle_function_q45_not_applicable": "No aplicable",
  "patient_foot_ankle_function_q45_a1": "Sin dificultad",
  "patient_foot_ankle_function_q45_a2": "Dificultad leve",
  "patient_foot_ankle_function_q45_a3": "Dificultad moderada",
  "patient_foot_ankle_function_q45_a4": "Dificultad extrema",
  "patient_foot_ankle_function_q45_a5": "Incapacidad para hacerlo",
  "patient_foot_ankle_function_q46": "Arrancar y parar rápidamente",
  "patient_foot_ankle_function_q46_not_applicable": "No aplicable",
  "patient_foot_ankle_function_q46_a1": "Sin dificultad",
  "patient_foot_ankle_function_q46_a2": "Dificultad leve",
  "patient_foot_ankle_function_q46_a3": "Dificultad moderada",
  "patient_foot_ankle_function_q46_a4": "Dificultad extrema",
  "patient_foot_ankle_function_q46_a5": "Incapacidad para hacerlo",
  "patient_foot_ankle_function_q47": "Arrancar y parar rápidamente",
  "patient_foot_ankle_function_q47_not_applicable": "No aplicable",
  "patient_foot_ankle_function_q47_a1": "Sin dificultad",
  "patient_foot_ankle_function_q47_a2": "Dificultad leve",
  "patient_foot_ankle_function_q47_a3": "Dificultad moderada",
  "patient_foot_ankle_function_q47_a4": "Dificultad extrema",
  "patient_foot_ankle_function_q47_a5": "Incapacidad para hacerlo",
  "patient_foot_ankle_function_q48": "Actividades de bajo impacto",
  "patient_foot_ankle_function_q48_not_applicable": "No aplicable",
  "patient_foot_ankle_function_q48_a1": "Sin dificultad",
  "patient_foot_ankle_function_q48_a2": "Dificultad leve",
  "patient_foot_ankle_function_q48_a3": "Dificultad moderada",
  "patient_foot_ankle_function_q48_a4": "Dificultad extrema",
  "patient_foot_ankle_function_q48_a5": "Incapacidad para hacerlo",
  "patient_foot_ankle_function_q49": "Capacidad para realizar actividades con su técnica normal",
  "patient_foot_ankle_function_q49_not_applicable": "No aplicable",
  "patient_foot_ankle_function_q49_a1": "Sin dificultad",
  "patient_foot_ankle_function_q49_a2": "Dificultad leve",
  "patient_foot_ankle_function_q49_a3": "Dificultad moderada",
  "patient_foot_ankle_function_q49_a4": "Dificultad extrema",
  "patient_foot_ankle_function_q49_a5": "Incapacidad para hacerlo",
  "patient_foot_ankle_function_q50": "Posibilidad de participar en el deporte que desee durante el tiempo que desee.",
  "patient_foot_ankle_function_q50_not_applicable": "No aplicable",
  "patient_foot_ankle_function_q50_a1": "Sin dificultad",
  "patient_foot_ankle_function_q50_a2": "Dificultad leve",
  "patient_foot_ankle_function_q50_a3": "Dificultad moderada",
  "patient_foot_ankle_function_q50_a4": "Dificultad extrema",
  "patient_foot_ankle_function_q50_a5": "Incapacidad para hacerlo",
  "patient_foot_ankle_function_q51": "¿Cómo calificaría su tobillo/pie hoy en porcentajes de normalidad? (0-100% siendo 100% normal)",
  "patient_foot_ankle_function_q51_a1": "Normal",
  "patient_foot_ankle_function_q52": "¿Cuál es  el nivel más alto de actividad que puede realizar?",
  "patient_foot_ankle_function_q52_q1": "Antes de la lesión/problema del pie o tobillo",
  "patient_foot_ankle_function_q52_q1_a1": "Nivel superior",
  "patient_foot_ankle_function_q52_q1_a2": "Niveles competitivos más bajos",
  "patient_foot_ankle_function_q52_q1_a3": "Recreativo",
  "patient_foot_ankle_function_q52_q1_a4": "No practíco deporte pero trabajo",
  "patient_foot_ankle_function_q52_q1_a5": "Sin deportes, sin trabajo",
  "patient_foot_ankle_function_q52_q1_a4_o7": "Incapaz de caminar, discapacitado debido a problemas en el tobillo",
  "patient_foot_ankle_function_q52_q1_a1_title": "Nivel superior (élite internacional, profesional, selección nacional o primera división)",
  "patient_foot_ankle_function_q52_q1_a2_title": "Niveles competitivos más bajos",
  "patient_foot_ankle_function_q52_q1_a3_title": "Recreativo",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o1": "Aeróbico, fitness",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o2": "Esquí alpino y snowboard",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o3": "Fútbol americano",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o4": "Bádminton",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o5": "Béisbol",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o6": "Baloncesto",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o7": "Bolos/curling",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_08": "Boxeo",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o9": "Carrera a campo traviesa",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o10": "Ciclismo",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o11": "Baile",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o12": "Buceo",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o13": "Ecuestre",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o14": "Esgrima",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o15": "Floorball",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o16": "Snowboard estilo libre",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o17": "Golf",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o18": "Gimnasia",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o19": "Balonmano",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o20": "Hockey",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o21": "Hockey sobre hielo",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o22": "Korfball",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o23": "Artes marciales",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o24": "Pentatlón moderno",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o25": "Automovilismo, deportes técnicos",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o26": "Montañismo y escalada",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o27": "Ciclismo de montaña/bmx",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o28": "Esquí nórdico",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o29": "Orientación",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o30": "Paracaidismo",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o31": "Levantamientos olímpicos de potencia",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o32": "Gimnasia rítmica",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o33": "Remo, kayak",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o34": "Rugby",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o35": "Navegación",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o36": "Submarinismo",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o37": "Tiro, tiro con arco",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o38": "Patinaje, patinaje en línea",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o39": "Fútbol",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o40": "Softbol",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o41": "Squash",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o42": "Surf, windsurf",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o43": "Tenis de mesa",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o44": "Tenis",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o45": "Atletismo: eventos de campo",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o46": "Atletismo: eventos de pista",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o47": "Triatlón",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o48": "Vóleibol",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o49": "Waterpolo y natación",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o50": "Esquí acuático",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o51": "Levantamiento de pesas",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o52": "Lucha",
  "patient_foot_ankle_function_q52_q1_a4_o1": "Trabajo físico pesado",
  "patient_foot_ankle_function_q52_q1_a4_o2": "Trabajo físico",
  "patient_foot_ankle_function_q52_q1_a4_o3": "Profesiones especiales: bailarín de ballet, soldado profesional, trabajador de rescate especial, doble",
  "patient_foot_ankle_function_q52_q1_a4_o4": "Capaz de caminar sobre cualquier terreno irregular",
  "patient_foot_ankle_function_q52_q1_a4_o5": "Actividades cotidianas no limitadas",
  "patient_foot_ankle_function_q52_q1_a4_o6": "Capaz de caminar sobre terreno uniforme, pero con actividades diarias limitadas",
  "patient_foot_ankle_function_q52_q2": "Estado actual.",
  "patient_foot_ankle_function_q52_q2_a1": "Nivel superior",
  "patient_foot_ankle_function_q52_q2_a2": "Niveles competitivos más bajos",
  "patient_foot_ankle_function_q52_q2_a3": "Recreativo",
  "patient_foot_ankle_function_q52_q2_a4": "Ninguna actividad deportiva",
  "patient_foot_ankle_function_q52_q2_a5": "Sin deportes, sin trabajo",
  "patient_foot_ankle_function_q52_q2_a4_o7": "Incapaz de caminar, discapacitado debido a problemas en el tobillo",
  "patient_foot_ankle_function_q52_q2_a1_title": "Nivel superior (élite internacional, profesional, selección nacional o primera división)",
  "patient_foot_ankle_function_q52_q2_a2_title": "Niveles competitivos más bajos",
  "patient_foot_ankle_function_q52_q2_a3_title": "Recreativo",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o1": "Aeróbico, fitness",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o2": "Esquí alpino y snowboard",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o3": "Fútbol americano",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o4": "Bádminton",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o5": "Béisbol",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o6": "Baloncesto",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o7": "Bolos/curling",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o8": "Boxeo",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o9": "Carrera a campo traviesa",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o10": "Ciclismo",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o11": "Baile",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o12": "Buceo",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o13": "Ecuestre",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o14": "Esgrima",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o15": "Floorball",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o16": "Snowboard estilo libre",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o17": "Golf",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o18": "Gimnasia",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o19": "Balonmano",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o20": "Hockey",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o21": "Hockey sobre hielo",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o22": "Korfball",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o23": "Artes marciales",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o24": "Pentatlón moderno",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o25": "Automovilismo, deportes técnicos",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o26": "Montañismo y escalada",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o27": "Ciclismo de montaña/bmx",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o28": "Esquí nórdico",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o29": "Orientación",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o30": "Paracaidismo",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o31": "Levantamientos olímpicos de potencia",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o32": "Gimnasia rítmica",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o33": "Remo, kayak",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o34": "Rugby",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o35": "Navegación",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o36": "Submarinismo",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o37": "Tiro, tiro con arco",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o38": "Patinaje, patinaje en línea",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o39": "Fútbol",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o40": "Softbol",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o41": "Squash",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o42": "Surf, windsurf",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o43": "Tenis de mesa",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o44": "Tenis",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o45": "Atletismo: eventos de campo",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o46": "Atletismo: eventos de pista",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o47": "Triatlón",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o48": "Vóleibol",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o49": "Waterpolo y natación",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o50": "Esquí acuático",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o51": "Levantamiento de pesas",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o52": "Lucha",
  "patient_foot_ankle_function_q52_q2_a4_o1": "Trabajo físico pesado",
  "patient_foot_ankle_function_q52_q2_a4_o2": "Trabajo físico",
  "patient_foot_ankle_function_q52_q2_a4_o3": "Profesiones especiales: bailarín de ballet, soldado profesional, trabajador de rescate especial, doble",
  "patient_foot_ankle_function_q52_q2_a4_o4": "Capaz de caminar sobre cualquier terreno irregular",
  "patient_foot_ankle_function_q52_q2_a4_o5": "Actividades cotidianas no limitadas",
  "patient_foot_ankle_function_q52_q2_a4_o6": "Capaz de caminar sobre terreno uniforme, todas las actividades limitadas",
  "patient_foot_ankle_quality_of_life": "Calidad de vida",
  "patient_foot_ankle_quality_of_life_instructions": "Las siguientes preguntas se refieren a inquietudes sociales, emocionales y de estilo de vida que pueda tener con respecto a su problema de pie/tobillo. Piense en cómo se ha sentido la mayor parte del tiempo en relación con su pie/tobillo.",
  "patient_foot_ankle_quality_of_life_q1": "¿Con qué frecuencia está usted consciente de su problema de pie/tobillo?",
  "patient_foot_ankle_quality_of_life_q1_a1": "Nunca",
  "patient_foot_ankle_quality_of_life_q1_a2": "Mensual",
  "patient_foot_ankle_quality_of_life_q1_a3": "Semanal",
  "patient_foot_ankle_quality_of_life_q1_a4": "Diario",
  "patient_foot_ankle_quality_of_life_q1_a5": "Constantemente",
  "patient_foot_ankle_quality_of_life_q2": "¿Ha modificado su estilo de vida para evitar actividades potencialmente dañinas para su pie/tobillo?",
  "patient_foot_ankle_quality_of_life_q2_a1": "En absoluto",
  "patient_foot_ankle_quality_of_life_q2_a2": "Levemente",
  "patient_foot_ankle_quality_of_life_q2_a3": "Moderadamente",
  "patient_foot_ankle_quality_of_life_q2_a4": "Severamente",
  "patient_foot_ankle_quality_of_life_q2_a5": "Totalmente",
  "patient_foot_ankle_quality_of_life_q3": "¿Cuánto le preocupa la falta de confianza en su pie/tobillo?",
  "patient_foot_ankle_quality_of_life_q3_a1": "En absoluto",
  "patient_foot_ankle_quality_of_life_q3_a2": "Levemente",
  "patient_foot_ankle_quality_of_life_q3_a3": "Moderadamente",
  "patient_foot_ankle_quality_of_life_q3_a4": "Severamente",
  "patient_foot_ankle_quality_of_life_q3_a5": "Extremadamente",
  "patient_foot_ankle_quality_of_life_q4": "En general, ¿Cuánta dificultad tiene con su pie/tobillo?",
  "patient_foot_ankle_quality_of_life_q4_a1": "Ninguno",
  "patient_foot_ankle_quality_of_life_q4_a2": "Leve",
  "patient_foot_ankle_quality_of_life_q4_a3": "Moderado",
  "patient_foot_ankle_quality_of_life_q4_a4": "Severo",
  "patient_foot_ankle_quality_of_life_q4_a5": "Extremo",
  "patient_foot_ankle_quality_of_life_q5": "¿Qué tan ansioso/deprimido estás hoy?",
  "patient_foot_ankle_quality_of_life_q5_a1": "No ansioso/deprimido",
  "patient_foot_ankle_quality_of_life_q5_a2": "Levemente",
  "patient_foot_ankle_quality_of_life_q5_a3": "Moderadamente",
  "patient_foot_ankle_quality_of_life_q5_a4": "Severamente",
  "patient_foot_ankle_quality_of_life_q5_a5": "Extremadamente",
  "patient_foot_ankle_quality_of_life_q6": "¿Qué tan buena/mala es su salud hoy? (100 significa la mejor salud 0 significa la peor salud que pueda imaginar)",
  "patient_foot_ankle_quality_of_life_q6_a1": "Peor salud",
  "patient_foot_ankle_quality_of_life_q6_a2": "Mejor salud",
  "patient_foot_ankle_satisfaction": "Satisfacción",
  "patient_foot_ankle_satisfaction_instruction": "Que tan satisfecho está con los resultados de su tratamiento/cirugía de pie/tobillo?",
  "patient_foot_ankle_satisfaction_instruction_a1": "Muy insatisfecho",
  "patient_foot_ankle_satisfaction_instruction_a2": "De alguna manera insatisfecho",
  "patient_foot_ankle_satisfaction_instruction_a3": "De alguna manera satisfecho",
  "patient_foot_ankle_satisfaction_instruction_a4": "Muy Satisfecho",
  "patient_foot_ankle_satisfaction_q1": "¿En proporcionar alivio de los síntomas?",
  "patient_foot_ankle_satisfaction_q1_not_applicable": "No aplicable",
  "patient_foot_ankle_satisfaction_q1_a1": "Muy insatisfecho",
  "patient_foot_ankle_satisfaction_q1_a2": "De alguna manera insatisfecho",
  "patient_foot_ankle_satisfaction_q1_a3": "De alguna manera satisfecho",
  "patient_foot_ankle_satisfaction_q1_a4": "Muy Satisfecho",
  "patient_foot_ankle_satisfaction_q2": "¿En mejorar su capacidad para realizar las actividades diarias?",
  "patient_foot_ankle_satisfaction_q2_not_applicable": "No aplicable",
  "patient_foot_ankle_satisfaction_q2_a1": "Muy insatisfecho",
  "patient_foot_ankle_satisfaction_q2_a2": "De alguna manera insatisfecho",
  "patient_foot_ankle_satisfaction_q2_a3": "De alguna manera satisfecho",
  "patient_foot_ankle_satisfaction_q2_a4": "Muy Satisfecho",
  "patient_foot_ankle_satisfaction_q3": "¿En olvidar el problema de su pie/tobillo? (conciencia del problema del pie/tobillo)",
  "patient_foot_ankle_satisfaction_q3_not_applicable": "No aplicable",
  "patient_foot_ankle_satisfaction_q3_a1": "Muy insatisfecho",
  "patient_foot_ankle_satisfaction_q3_a2": "De alguna manera insatisfecho",
  "patient_foot_ankle_satisfaction_q3_a3": "De alguna manera satisfecho",
  "patient_foot_ankle_satisfaction_q3_a4": "Muy Satisfecho",
  "patient_foot_ankle_complete": "Fin del Cuestionario",
  "patient_foot_ankle_complete_text1": "Gracias por tomarse el tiempo de llenar este formulario",
  "patient_foot_ankle_complete_text2": "Por favor presiona enviar para completar y ver tus resultados",
  "patient_foot_ankle_complete_text3": "Sus respuestas serán tratadas de forma confidencial y cualquier informe solo utilizará información anonimizada",
  "patient_foot_ankle_results": "Resultados",
  "patient_foot_ankle_results_text1": "A continuación, se encuentran sus puntajes de dolor y funcionamiento del pie/tobillo, que han sido calculados a partir de su cuestionario.",
  "patient_foot_ankle_results_pain": "Dolor",
  "patient_foot_ankle_results_function": "Función",
  "patient_foot_ankle_results_overall_health_perception": "Percepción general de salud",
  "patient_foot_ankle_results_feeling_of_instability": "Sensación de inestabilidad",
  "patient_foot_ankle_results_self_evaluation": "Puntuación de la autoevaluación de pie/tobillo",
  "patient_foot_ankle_results_text2": "Su médico estará con usted en breve.",
  "patient_elbow_pain": "Dolor",
  "patient_elbow_pain_q1": "¿Experimenta dolor en el codo?",
  "patient_elbow_pain_q1_a1": "No",
  "patient_elbow_pain_q1_a2": "Si",
  "patient_elbow_pain_q2": "¿Cómo describirías mejor el dolor en tu codo?",
  "patient_elbow_pain_q2_a1": "Ninguno o ignorado",
  "patient_elbow_pain_q2_a2": "Leve al realizar actividades, sin uso o uso ocacional medicamentos/analgésicos",
  "patient_elbow_pain_q2_a3": "Moderado con o sin actividad, uso diario de analgésicos",
  "patient_elbow_pain_q2_a4": "Moderado en reposo o por la noche",
  "patient_elbow_pain_q2_a5": "Severo, en reposo, uso constante de medicación, dolor  incapacitante",
  "patient_elbow_pain_instruction": "Califica tu DOLOR en el codo",
  "patient_elbow_pain_q3": "En este momento",
  "patient_elbow_pain_q3_a1": "Sin dolor",
  "patient_elbow_pain_q3_a2": "El peor dolor, insoportable",
  "patient_elbow_pain_q4": "Dolor habitual durante las últimas 4 semanas",
  "patient_elbow_pain_q4_a1": "Sin dolor",
  "patient_elbow_pain_q4_a2": "El peor dolor, insoportable",
  "patient_elbow_pain_q5": "Cuando está en su peor momento durante las últimas 4 semanas",
  "patient_elbow_pain_q5_a1": "Sin dolor",
  "patient_elbow_pain_q5_a2": "El peor dolor, insoportable",
  "patient_elbow_pain_q6": "En reposo",
  "patient_elbow_pain_q6_a1": "Sin dolor",
  "patient_elbow_pain_q6_a2": "El peor dolor, insoportable",
  "patient_elbow_pain_q7": "Al levantar objetos pesados",
  "patient_elbow_pain_q7_a1": "Sin dolor",
  "patient_elbow_pain_q7_a2": "El peor dolor, insoportable",
  "patient_elbow_pain_q8": "Al realizar una tarea con movimientos de codo repetitivos",
  "patient_elbow_pain_q8_a1": "Sin dolor",
  "patient_elbow_pain_q8_a2": "El peor dolor, insoportable",
  "patient_elbow_pain_q9": "Por la noche",
  "patient_elbow_pain_q9_a1": "Sin dolor",
  "patient_elbow_pain_q9_a2": "El peor dolor, insoportable",
  "patient_elbow_pain_q10": "¿Con qué frecuencia su dolor de codo ha sido un problema por las noches?",
  "patient_elbow_pain_q10_a1": "En absoluto",
  "patient_elbow_pain_q10_a2": "1-2 noches",
  "patient_elbow_pain_q10_a3": "Algunas noches",
  "patient_elbow_pain_q10_a4": "La mayoría de las noches",
  "patient_elbow_pain_q10_a5": "Cada noche",
  "patient_elbow_pain_q11": "¿Con qué frecuencia el dolor de codo ha interferido con su sueño?",
  "patient_elbow_pain_q11_a1": "En absoluto",
  "patient_elbow_pain_q11_a2": "Ocasionalmente",
  "patient_elbow_pain_q11_a3": "Algunas veces",
  "patient_elbow_pain_q11_a4": "La mayor parte del tiempo",
  "patient_elbow_pain_q11_a5": "Todo el tiempo",
  "patient_elbow_function": "Funcionamiento",
  "patient_elbow_function_q1": "¿Cuál es la mejor descripción del FUNCIONAMIENTO de su codo?",
  "patient_elbow_function_q1_a1": "Sin limitaciones",
  "patient_elbow_function_q1_a2": "Ligeras limitaciones Sin restricciones en las actividades de la vida diaria",
  "patient_elbow_function_q1_a3": "Incapaz de levantar objetos mayores a 4,5 kg",
  "patient_elbow_function_q1_a4": "Restricciones moderadas en las actividades de la vida diaria",
  "patient_elbow_function_q1_a5": "Incapaz de peinarse o tocarse la cabeza",
  "patient_elbow_function_q1_a6": "Incapaz de alimentarse a sí mismo",
  "patient_elbow_function_q2": "¿Cuál es la mejor descripción de la ACTIVIDAD que puedes hacer?",
  "patient_elbow_function_q2_a1": "Uso ilimitado del codo.",
  "patient_elbow_function_q2_a2": "Uso limitado para actividades recreativas.",
  "patient_elbow_function_q2_a3": "Limitado a actividades domésticas y laborales.",
  "patient_elbow_function_q2_a4": "Capaz de cuidar de sí mismo",
  "patient_elbow_function_q2_a5": "Inválido",
  "patient_elbow_function_q3": "¿Cuánto tiempo puedes usar tu codo?",
  "patient_elbow_function_q3_a1": "Puede realizar actividades durante 30 minutos.",
  "patient_elbow_function_q3_a2": "Puede realizar actividades durante 15 minutos.",
  "patient_elbow_function_q3_a3": "Puede realizar actividades durante 5 minutos.",
  "patient_elbow_function_q3_a4": "No puede usar el codo",
  "patient_elbow_function_instruction": "Idique el NIVEL DE DIFICULTAD que ha tenido para realizar las siguientes actividades en la última semana con relación a su codo",
  "patient_elbow_function_instruction_0": "Sin dificultad",
  "patient_elbow_function_instruction_1": "Leve",
  "patient_elbow_function_instruction_2": "Moderada",
  "patient_elbow_function_instruction_3": "Severa",
  "patient_elbow_function_instruction_4": "Incapaz",
  "patient_elbow_function_q4": "Abrir un frasco apretado o nuevo",
  "patient_elbow_function_q4_a1": "Sin dificultad",
  "patient_elbow_function_q4_a2": "Leve",
  "patient_elbow_function_q4_a3": "Moderada",
  "patient_elbow_function_q4_a4": "Severa",
  "patient_elbow_function_q4_a5": "Incapaz",
  "patient_elbow_function_q5": "Llevar una bolsa de compras o maletín",
  "patient_elbow_function_q5_a1": "Sin dificultad",
  "patient_elbow_function_q5_a2": "Leve",
  "patient_elbow_function_q5_a3": "Moderada",
  "patient_elbow_function_q5_a4": "Severa",
  "patient_elbow_function_q5_a5": "Incapaz",
  "patient_elbow_function_q6": "Levantar cosas en su casa, como sacar la basura",
  "patient_elbow_function_q6_a1": "Sin dificultad",
  "patient_elbow_function_q6_a2": "Leve",
  "patient_elbow_function_q6_a3": "Moderada",
  "patient_elbow_function_q6_a4": "Severa",
  "patient_elbow_function_q6_a5": "Incapaz",
  "patient_elbow_function_q7": "Lavarte la espalda",
  "patient_elbow_function_q7_a1": "Sin dificultad",
  "patient_elbow_function_q7_a2": "Leve",
  "patient_elbow_function_q7_a3": "Moderada",
  "patient_elbow_function_q7_a4": "Severa",
  "patient_elbow_function_q7_a5": "Incapaz",
  "patient_elbow_function_q8": "Manejar o gestionar el uso del baño",
  "patient_elbow_function_q8_a1": "Sin dificultad",
  "patient_elbow_function_q8_a2": "Leve",
  "patient_elbow_function_q8_a3": "Moderada",
  "patient_elbow_function_q8_a4": "Severa",
  "patient_elbow_function_q8_a5": "Incapaz",
  "patient_elbow_function_q9": "Lavarse por todas partes",
  "patient_elbow_function_q9_a1": "Sin dificultad",
  "patient_elbow_function_q9_a2": "Leve",
  "patient_elbow_function_q9_a3": "Moderada",
  "patient_elbow_function_q9_a4": "Severa",
  "patient_elbow_function_q9_a5": "Incapaz",
  "patient_elbow_function_q10": "Realizar higiene personal",
  "patient_elbow_function_q10_a1": "Sin dificultad",
  "patient_elbow_function_q10_a2": "Leve",
  "patient_elbow_function_q10_a3": "Moderada",
  "patient_elbow_function_q10_a4": "Severa",
  "patient_elbow_function_q10_a5": "Incapaz",
  "patient_elbow_function_q11": "Usar un cuchillo para cortar la comida",
  "patient_elbow_function_q11_a1": "Sin dificultad",
  "patient_elbow_function_q11_a2": "Leve",
  "patient_elbow_function_q11_a3": "Moderada",
  "patient_elbow_function_q11_a4": "Severa",
  "patient_elbow_function_q11_a5": "Incapaz",
  "patient_elbow_function_q12": "Comer con utensilio",
  "patient_elbow_function_q12_a1": "Sin dificultad",
  "patient_elbow_function_q12_a2": "Leve",
  "patient_elbow_function_q12_a3": "Moderada",
  "patient_elbow_function_q12_a4": "Severa",
  "patient_elbow_function_q12_a5": "Incapaz",
  "patient_elbow_function_q13": "Actividades recreativas en las que recibe alguna fuerza o impacto a través de su brazo, hombro o mano (p. ej., golf, martillazos, tenis, etc.)",
  "patient_elbow_function_q13_a1": "Sin dificultad",
  "patient_elbow_function_q13_a2": "Leve",
  "patient_elbow_function_q13_a3": "Moderada",
  "patient_elbow_function_q13_a4": "Severa",
  "patient_elbow_function_q13_a5": "Incapaz",
  "patient_elbow_function_q14": "Dormir debido al dolor en el brazo, el hombro o la mano.",
  "patient_elbow_function_q14_a1": "Sin dificultad",
  "patient_elbow_function_q14_a2": "Leve",
  "patient_elbow_function_q14_a3": "Moderada",
  "patient_elbow_function_q14_a4": "Severa",
  "patient_elbow_function_q14_a5": "Incapaz",
  "patient_elbow_function_q15": "Ponerse la camisa",
  "patient_elbow_function_q15_a1": "Sin dificultad",
  "patient_elbow_function_q15_a2": "Leve",
  "patient_elbow_function_q15_a3": "Moderada",
  "patient_elbow_function_q15_a4": "Severa",
  "patient_elbow_function_q15_a5": "Incapaz",
  "patient_elbow_function_q16": "Abotonar el botón superior de la camisa",
  "patient_elbow_function_q16_a1": "Sin dificultad",
  "patient_elbow_function_q16_a2": "Leve",
  "patient_elbow_function_q16_a3": "Moderada",
  "patient_elbow_function_q16_a4": "Severa",
  "patient_elbow_function_q16_a5": "Incapaz",
  "patient_elbow_function_q17": "Vestirse solo",
  "patient_elbow_function_q17_a1": "Sin dificultad",
  "patient_elbow_function_q17_a2": "Leve",
  "patient_elbow_function_q17_a3": "Moderada",
  "patient_elbow_function_q17_a4": "Severa",
  "patient_elbow_function_q17_a5": "Incapaz",
  "patient_elbow_function_q18": "Peinar el cabello",
  "patient_elbow_function_q18_a1": "Sin dificultad",
  "patient_elbow_function_q18_a2": "Leve",
  "patient_elbow_function_q18_a3": "Moderada",
  "patient_elbow_function_q18_a4": "Severa",
  "patient_elbow_function_q18_a5": "Incapaz",
  "patient_elbow_function_q19": "Ponerse zapatos",
  "patient_elbow_function_q19_a1": "Sin dificultad",
  "patient_elbow_function_q19_a2": "Leve",
  "patient_elbow_function_q19_a3": "Moderada",
  "patient_elbow_function_q19_a4": "Severa",
  "patient_elbow_function_q19_a5": "Incapaz",
  "patient_elbow_function_q20": "Atar zapatos",
  "patient_elbow_function_q20_a1": "Sin dificultad",
  "patient_elbow_function_q20_a2": "Leve",
  "patient_elbow_function_q20_a3": "Moderada",
  "patient_elbow_function_q20_a4": "Severa",
  "patient_elbow_function_q20_a5": "Incapaz",
  "patient_elbow_function_q21": "Llevar un objeto pesado",
  "patient_elbow_function_q21_a1": "Sin dificultad",
  "patient_elbow_function_q21_a2": "Leve",
  "patient_elbow_function_q21_a3": "Moderada",
  "patient_elbow_function_q21_a4": "Severa",
  "patient_elbow_function_q21_a5": "Incapaz",
  "patient_elbow_function_q22": "Levantarse de una silla empujando con el brazo",
  "patient_elbow_function_q22_a1": "Sin dificultad",
  "patient_elbow_function_q22_a2": "Leve",
  "patient_elbow_function_q22_a3": "Moderada",
  "patient_elbow_function_q22_a4": "Severa",
  "patient_elbow_function_q22_a5": "Incapaz",
  "patient_elbow_function_q23": "Hacer tareas domésticas pesadas (p. ej., lavar paredes, pisos)",
  "patient_elbow_function_q23_a1": "Sin dificultad",
  "patient_elbow_function_q23_a2": "Leve",
  "patient_elbow_function_q23_a3": "Moderada",
  "patient_elbow_function_q23_a4": "Severa",
  "patient_elbow_function_q23_a5": "Incapaz",
  "patient_elbow_function_q24": "Girar una llave",
  "patient_elbow_function_q24_a1": "Sin dificultad",
  "patient_elbow_function_q24_a2": "Leve",
  "patient_elbow_function_q24_a3": "Moderada",
  "patient_elbow_function_q24_a4": "Severa",
  "patient_elbow_function_q24_a5": "Incapaz",
  "patient_elbow_function_q25": "Lanzar una pelota",
  "patient_elbow_function_q25_a1": "Sin dificultad",
  "patient_elbow_function_q25_a2": "Leve",
  "patient_elbow_function_q25_a3": "Moderada",
  "patient_elbow_function_q25_a4": "Severa",
  "patient_elbow_function_q25_a5": "Incapaz",
  "patient_elbow_function_q26": "Hacer su trabajo habitual debido a dolor en el brazo, el hombro o la mano (el trabajo incluye las tareas domésticas si esa es su función principal)",
  "patient_elbow_function_q26_a1": "Sin dificultad",
  "patient_elbow_function_q26_a2": "Leve",
  "patient_elbow_function_q26_a3": "Moderada",
  "patient_elbow_function_q26_a4": "Severa",
  "patient_elbow_function_q26_a5": "Incapaz",
  "patient_elbow_function_q27": "Especificar trabajo habitual",
  "patient_elbow_function_q27_placeholder": "Especificar trabajo",
  "patient_elbow_function_i_donot_work": "No trabajo",
  "patient_elbow_function_q28": "Usar tu técnica habitual de trabajo.",
  "patient_elbow_function_q28_a1": "Sin dificultad",
  "patient_elbow_function_q28_a2": "Leve",
  "patient_elbow_function_q28_a3": "Moderada",
  "patient_elbow_function_q28_a4": "Severa",
  "patient_elbow_function_q28_a5": "Incapaz",
  "patient_elbow_function_q29": "Hacer tu trabajo tan bien como te gustaría",
  "patient_elbow_function_q29_a1": "Sin dificultad",
  "patient_elbow_function_q29_a2": "Leve",
  "patient_elbow_function_q29_a3": "Moderada",
  "patient_elbow_function_q29_a4": "Severa",
  "patient_elbow_function_q29_a5": "Incapaz",
  "patient_elbow_function_q30": "Pasar la cantidad habitual de tiempo haciendo su trabajo",
  "patient_elbow_function_q30_a1": "Sin dificultad",
  "patient_elbow_function_q30_a2": "Leve",
  "patient_elbow_function_q30_a3": "Moderada",
  "patient_elbow_function_q30_a4": "Severa",
  "patient_elbow_function_q30_a5": "Incapaz",
  "patient_elbow_function_q31": "Tocar instrumentos musicales o practicar deporte debido a dolor en el brazo, el hombro o la mano",
  "patient_elbow_function_q31_a1": "Sin dificultad",
  "patient_elbow_function_q31_a2": "Leve",
  "patient_elbow_function_q31_a3": "Moderada",
  "patient_elbow_function_q31_a4": "Severa",
  "patient_elbow_function_q31_a5": "Incapaz",
  "patient_elbow_function_q32": "Especifique el deporte o el instrumento musical que toca",
  "patient_elbow_function_q32_placeholder": "Especifique el deporte/instrumento musical",
  "patient_elbow_function_i_donot_play": "No hago deporte o toco algún instrumento",
  "patient_elbow_function_q33": "Usar tu técnica habitual para tocar tu instrumento o practicar tu deporte",
  "patient_elbow_function_q33_a1": "Sin dificultad",
  "patient_elbow_function_q33_a2": "Leve",
  "patient_elbow_function_q33_a3": "Moderada",
  "patient_elbow_function_q33_a4": "Severa",
  "patient_elbow_function_q33_a5": "Incapaz",
  "patient_elbow_function_q34": "Tocar tu instrumento musical o practicar tu deporte tan bien como te gustaría",
  "patient_elbow_function_q34_a1": "Sin dificultad",
  "patient_elbow_function_q34_a2": "Leve",
  "patient_elbow_function_q34_a3": "Moderada",
  "patient_elbow_function_q34_a4": "Severa",
  "patient_elbow_function_q34_a5": "Incapaz",
  "patient_elbow_function_q35": "Pasar la misma cantidad de tiempo habitual practicando o tocando su deporte o instrumento",
  "patient_elbow_function_q35_a1": "Sin dificultad",
  "patient_elbow_function_q35_a2": "Leve",
  "patient_elbow_function_q35_a3": "Moderada",
  "patient_elbow_function_q35_a4": "Severa",
  "patient_elbow_function_q35_a5": "Incapaz",
  "patient_elbow_function_q36": "Durante la última semana, ¿En qué medida su problema en el brazo, el hombro o la mano ha interferido en sus ACTIVIDADES SOCIALES/DE OCIO NORMALES con familiares, amigos, vecinos o grupos?",
  "patient_elbow_function_q36_a1": "En absoluto",
  "patient_elbow_function_q36_a2": "Levemente",
  "patient_elbow_function_q36_a3": "Moderadamente",
  "patient_elbow_function_q36_a4": "Bastante",
  "patient_elbow_function_q36_a5": "Extremadamente",
  "patient_elbow_function_q37": "Durante la ultima semana, ¿Se vió limitado al realizar su TRABAJO O ACTIVIDADES DIARIAS como resultado de su problema en el brazo, hombro, o mano?",
  "patient_elbow_function_q37_a1": "En absoluto",
  "patient_elbow_function_q37_a2": "Levemente",
  "patient_elbow_function_q37_a3": "Moderadamente",
  "patient_elbow_function_q37_a4": "Bastante",
  "patient_elbow_function_q37_a5": "Extremadamente",
  "patient_elbow_symptoms": "Otros síntomas",
  "patient_elbow_symptoms_instruction": "Califique la gravedad de los siguientes síntomas en la última semana",
  "patient_elbow_symptoms_instruction_0": "Ninguno",
  "patient_elbow_symptoms_instruction_1": "Leve",
  "patient_elbow_symptoms_instruction_2": "Moderado",
  "patient_elbow_symptoms_instruction_3": "Severo",
  "patient_elbow_symptoms_instruction_4": "Extremo",
  "patient_elbow_symptoms_q1": "Dolor en el brazo, hombro o mano",
  "patient_elbow_symptoms_q1_a1": "Ninguno",
  "patient_elbow_symptoms_q1_a2": "Leve",
  "patient_elbow_symptoms_q1_a3": "Moderado",
  "patient_elbow_symptoms_q1_a4": "Severo",
  "patient_elbow_symptoms_q1_a5": "Extremo",
  "patient_elbow_symptoms_q2": "Hormigueo (agujas y/o alfileres) en tu brazo, hombro o mano",
  "patient_elbow_symptoms_q2_a1": "Ninguno",
  "patient_elbow_symptoms_q2_a2": "Leve",
  "patient_elbow_symptoms_q2_a3": "Moderado",
  "patient_elbow_symptoms_q2_a4": "Severo",
  "patient_elbow_symptoms_q2_a5": "Extremo",
  "patient_elbow_symptoms_q3": "¿Cómo calificaría su codo hoy en porcentajes de normalidad? (0-100% siendo 100% normal)",
  "patient_elbow_quality_of_life": "Calidad de vida",
  "patient_elbow_quality_of_life_instruction": "Las siguientes preguntas se refieren a inquietudes sociales, emocionales y de estilo de vida que pueda tener con respecto a su problema en el codo. Piense en cómo se ha sentido la mayor parte del tiempo en relación con su codo.",
  "patient_elbow_quality_of_life_q1": "Durante las últimas 4 semanas, ¿Ha sentido que su problema en el codo “controla su vida”?",
  "patient_elbow_quality_of_life_q1_a1": "En absoluto",
  "patient_elbow_quality_of_life_q1_a2": "Ocasionalmente",
  "patient_elbow_quality_of_life_q1_a3": "Algunos días",
  "patient_elbow_quality_of_life_q1_a4": "La mayoría de los días",
  "patient_elbow_quality_of_life_q1_a5": "Cada día",
  "patient_elbow_quality_of_life_q2": "Durante las últimas 4 semanas, ¿Cuánto ha estado “en su mente” su codo?",
  "patient_elbow_quality_of_life_q2_a1": "En absoluto",
  "patient_elbow_quality_of_life_q2_a2": "Un poco de tiempo",
  "patient_elbow_quality_of_life_q2_a3": "Algunas veces",
  "patient_elbow_quality_of_life_q2_a4": "La mayor parte del tiempo",
  "patient_elbow_quality_of_life_q2_a5": "Todo el tiempo",
  "patient_elbow_quality_of_life_instruction1": "Califique los siguientes puntos para describir mejor su SALUD HOY",
  "patient_elbow_quality_of_life_instruction1_0": "Ningún problema",
  "patient_elbow_quality_of_life_instruction1_1": "Leve",
  "patient_elbow_quality_of_life_instruction1_2": "Moderado",
  "patient_elbow_quality_of_life_instruction1_3": "Muy fuerte",
  "patient_elbow_quality_of_life_instruction1_4": "Extremo/incapaz",
  "patient_elbow_quality_of_life_q3": "Problemas al caminar",
  "patient_elbow_quality_of_life_q3_a1": "Ningún problema",
  "patient_elbow_quality_of_life_q3_a2": "Leve",
  "patient_elbow_quality_of_life_q3_a3": "Moderado",
  "patient_elbow_quality_of_life_q3_a4": "Severo",
  "patient_elbow_quality_of_life_q3_a5": "Incapaz",
  "patient_elbow_quality_of_life_q4": "Problemas con el lavado o secado propio",
  "patient_elbow_quality_of_life_q4_a1": "Ningún problema",
  "patient_elbow_quality_of_life_q4_a2": "Leve",
  "patient_elbow_quality_of_life_q4_a3": "Moderado",
  "patient_elbow_quality_of_life_q4_a4": "Severo",
  "patient_elbow_quality_of_life_q4_a5": "Incapaz",
  "patient_elbow_quality_of_life_q5": "Problemas realizando actividades habituales (p. ej., trabajo, estudio, tareas domésticas, familia o actividades de ocio)",
  "patient_elbow_quality_of_life_q5_a1": "Ningún problema",
  "patient_elbow_quality_of_life_q5_a2": "Leve",
  "patient_elbow_quality_of_life_q5_a3": "Moderado",
  "patient_elbow_quality_of_life_q5_a4": "Severo",
  "patient_elbow_quality_of_life_q5_a5": "Incapaz",
  "patient_elbow_quality_of_life_q6": "Dolor o malestar",
  "patient_elbow_quality_of_life_q6_a1": "Ningún problema",
  "patient_elbow_quality_of_life_q6_a2": "Leve",
  "patient_elbow_quality_of_life_q6_a3": "Moderado",
  "patient_elbow_quality_of_life_q6_a4": "Severo",
  "patient_elbow_quality_of_life_q6_a5": "Incapaz",
  "patient_elbow_quality_of_life_q7": "Ansiedad/Depresión",
  "patient_elbow_quality_of_life_q7_a1": "No ansioso/deprimido",
  "patient_elbow_quality_of_life_q7_a2": "Ligeramente ansioso/deprimido",
  "patient_elbow_quality_of_life_q7_a3": "Moderadamente ansioso/deprimido",
  "patient_elbow_quality_of_life_q7_a4": "Severamente ansioso/deprimido",
  "patient_elbow_quality_of_life_q7_a5": "Extremadamente ansioso/deprimido",
  "patient_elbow_quality_of_life_q8": "¿Qué tan buena o mala es tu salud HOY?",
  "patient_elbow_quality_of_life_q8_instruction": "100 significa la mejor salud, 0 significa la peor salud que puedas imaginar",
  "patient_elbow_quality_of_life_q8_a1": "Peor",
  "patient_elbow_quality_of_life_q8_a2": "Mejor",
  "patient_elbow_satisfaction": "Satisfacción",
  "patient_elbow_satisfaction_q1": "¿Estás satisfecho con el tratamiento brindado para tu codo?",
  "patient_elbow_satisfaction_na": "No aplicable",
  "patient_elbow_satisfaction_q1_a1": "No del todo satisfecho",
  "patient_elbow_satisfaction_q1_a2": "Muy Satisfecho",
  "patient_elbow_complete": "Fin del Cuestionario",
  "patient_elbow_complete_text1": "Gracias por tomarse el tiempo de llenar este formulario",
  "patient_elbow_complete_text2": "Por favor presiona enviar para completar y ver tus resultados",
  "patient_elbow_complete_text3": "Sus respuestas serán tratadas de forma confidencial y cualquier informe solo utilizará información anonimizada",
  "patient_elbow_results": "Resultados",
  "patient_elbow_results_text1": "A continuación, se encuentran sus puntajes de dolor y funcionamiento del codo, que han sido calculados a partir de su cuestionario.",
  "patient_elbow_results_function": "Funcionamiento",
  "patient_elbow_results_pain": "Dolor",
  "patient_elbow_results_health_perception": "Percepción general de salud",
  "patient_elbow_results_self_evaluation": "Puntuación de la autoevaluación de codo",
  "patient_elbow_results_text4": "Su médico estará con usted en breve.",
  "patient_hand_pain": "Dolor",
  "patient_hand_pain_q1": "Describa el dolor en su mano/muñeca en la ÚLTIMA SEMANA.",
  "patient_hand_pain_q1_a1": "Ninguno o muy suave",
  "patient_hand_pain_q1_a2": "Leve",
  "patient_hand_pain_q1_a3": "Moderado",
  "patient_hand_pain_q1_a4": "Severo",
  "patient_hand_pain_q1_a5": "Muy grave/extremo",
  "patient_hand_pain_q2": "¿Qué tan fuerte es el dolor en su mano/muñeca EN ESTE MOMENTO?",
  "patient_hand_pain_q2_a1": "Sin dolor",
  "patient_hand_pain_q2_a2": "Peor dolor",
  "patient_hand_pain_q3": "¿Con qué frecuencia el dolor en su mano/muñeca ha interferido con sus actividades diarias (como comer o bañarse) en la última semana?",
  "patient_hand_pain_q3_a1": "Nunca",
  "patient_hand_pain_q3_a2": "Raramente",
  "patient_hand_pain_q3_a3": "A veces",
  "patient_hand_pain_q3_a4": "Frecuentemente",
  "patient_hand_pain_q3_a5": "Siempre",
  "patient_hand_symptoms": "Otros síntomas",
  "patient_hand_symptoms_q1": "¿Qué tan severo ha sido el hormigueo (alfileres y agujas) en su brazo, hombro o mano en la última semana?",
  "patient_hand_symptoms_q1_a1": "Ninguno",
  "patient_hand_symptoms_q1_a2": "Leve",
  "patient_hand_symptoms_q1_a3": "Moderado",
  "patient_hand_symptoms_q1_a4": "Severo",
  "patient_hand_symptoms_q1_a5": "Extremo",
  "patient_hand_symptoms_q2": "¿Cómo ha sido la sensación (tacto) en su mano durante la semana pasada?",
  "patient_hand_symptoms_q2_a1": "Muy buena",
  "patient_hand_symptoms_q2_a2": "Buena",
  "patient_hand_symptoms_q2_a3": "Normal",
  "patient_hand_symptoms_q2_a4": "Pobre",
  "patient_hand_symptoms_q2_a5": "Muy pobre",
  "patient_hand_symptoms_q3": "¿La apariencia de mi mano interfiere con mis actividades diarias normales?",
  "patient_hand_symptoms_q3_a1": "Totalmente en desacuerdo",
  "patient_hand_symptoms_q3_a2": "En desacuerdo",
  "patient_hand_symptoms_q3_a3": "Ni de acuerdo ni en desacuerdo",
  "patient_hand_symptoms_q3_a4": "De acuerdo",
  "patient_hand_symptoms_q3_a5": "Totalmente de acuerdo",
  "patient_hand_symptoms_q3_a6": "¿Cómo calificaría su mano/muñeca hoy en porcentajes de normalidad? (0-100% siendo 100% normal)",
  "patient_hand_symptoms_q3_a6_normal": "Normal",
  "patient_hand_function": "Funcionamiento",
  "patient_hand_function_instruction1": "Si no realiza ciertas actividades, por favor estimar la dificultad que le tomaría realizarla.",
  "patient_hand_function_instruction2": "Si no se encuentra seguro de como responder a ciertas preguntas, por favor responda como usted considere mejor.",
  "patient_hand_function_q1": "En general, ¿Cómo ha funcionado su mano en la ultima semana?",
  "patient_hand_function_q1_a1": "Muy bien",
  "patient_hand_function_q1_a2": "Bien",
  "patient_hand_function_q1_a3": "Normal",
  "patient_hand_function_q1_a4": "Pobre",
  "patient_hand_function_q1_a5": "Muy pobre",
  "patient_hand_function_q2": "¿Con qué frecuencia NO PUDO HACER SU TRABAJO debido a problemas con su mano/muñeca?",
  "patient_hand_function_q2_a1": "Nunca",
  "patient_hand_function_q2_a2": "Raramente",
  "patient_hand_function_q2_a3": "A veces",
  "patient_hand_function_q2_a4": "Frecuentemente",
  "patient_hand_function_q2_a5": "Siempre",
  "patient_hand_function_q3": "¿Con qué frecuencia TARDÓ MÁS TIEMPO EN HACER LAS TAREAS en su trabajo debido a problemas con su mano/muñeca?",
  "patient_hand_function_q3_a1": "Nunca",
  "patient_hand_function_q3_a2": "Raramente",
  "patient_hand_function_q3_a3": "A veces",
  "patient_hand_function_q3_a4": "Frecuentemente",
  "patient_hand_function_q3_a5": "Siempre",
  "patient_hand_function_instruction": "Indique el NIVEL DE DIFICULTAD para realizar las siguientes actividades durante la ultima semana en relacion a su mano/muñeca",
  "patient_hand_function_instruction_0": "Sin dificultad",
  "patient_hand_function_instruction_1": "Dificultad leve",
  "patient_hand_function_instruction_2": "Dificultad moderada",
  "patient_hand_function_instruction_3": "Dificultad severa",
  "patient_hand_function_instruction_4": "Dificultad extrema/incapaz",
  "patient_hand_function_q4": "Abrir un frasco apretado o nuevo",
  "patient_hand_function_q4_a1": "Sin dificultad",
  "patient_hand_function_q4_a2": "Dificultad leve",
  "patient_hand_function_q4_a3": "Dificultad moderada",
  "patient_hand_function_q4_a4": "Dificultad severa",
  "patient_hand_function_q4_a5": "Dificultad extrema/incapaz",
  "patient_hand_function_q5": "Sostener un sartén",
  "patient_hand_function_q5_a1": "Sin dificultad",
  "patient_hand_function_q5_a2": "Dificultad leve",
  "patient_hand_function_q5_a3": "Dificultad moderada",
  "patient_hand_function_q5_a4": "Dificultad severa",
  "patient_hand_function_q5_a5": "Dificultad extrema/incapaz",
  "patient_hand_function_q6": "Abotonarse una camisa o blusa",
  "patient_hand_function_q6_a1": "Sin dificultad",
  "patient_hand_function_q6_a2": "Dificultad leve",
  "patient_hand_function_q6_a3": "Dificultad moderada",
  "patient_hand_function_q6_a4": "Dificultad severa",
  "patient_hand_function_q6_a5": "Dificultad extrema/incapaz",
  "patient_hand_function_q7": "Hacer tareas domésticas pesadas (p. ej., lavar paredes, pisos)",
  "patient_hand_function_q7_a1": "Sin dificultad",
  "patient_hand_function_q7_a2": "Dificultad leve",
  "patient_hand_function_q7_a3": "Dificultad moderada",
  "patient_hand_function_q7_a4": "Dificultad severa",
  "patient_hand_function_q7_a5": "Dificultad extrema/incapaz",
  "patient_hand_function_q8": "Llevar una bolsa de compras o maletín",
  "patient_hand_function_q8_a1": "Sin dificultad",
  "patient_hand_function_q8_a2": "Dificultad leve",
  "patient_hand_function_q8_a3": "Dificultad moderada",
  "patient_hand_function_q8_a4": "Dificultad severa",
  "patient_hand_function_q8_a5": "Dificultad extrema/incapaz",
  "patient_hand_function_q9": "Lavarte la espalda",
  "patient_hand_function_q9_a1": "Sin dificultad",
  "patient_hand_function_q9_a2": "Dificultad leve",
  "patient_hand_function_q9_a3": "Dificultad moderada",
  "patient_hand_function_q9_a4": "Dificultad severa",
  "patient_hand_function_q9_a5": "Dificultad extrema/incapaz",
  "patient_hand_function_q10": "Usar un cuchillo para cortar la comida",
  "patient_hand_function_q10_a1": "Sin dificultad",
  "patient_hand_function_q10_a2": "Dificultad leve",
  "patient_hand_function_q10_a3": "Dificultad moderada",
  "patient_hand_function_q10_a4": "Dificultad severa",
  "patient_hand_function_q10_a5": "Dificultad extrema/incapaz",
  "patient_hand_function_q11": "Actividades recreativas en las que recibe alguna fuerza o impacto a través de su brazo, hombro o mano (p. ej., golf, martillazos, tenis, etc.)",
  "patient_hand_function_q11_a1": "Sin dificultad",
  "patient_hand_function_q11_a2": "Dificultad leve",
  "patient_hand_function_q11_a3": "Dificultad moderada",
  "patient_hand_function_q11_a4": "Dificultad severa",
  "patient_hand_function_q11_a5": "Dificultad extrema/incapaz",
  "patient_hand_function_q12": "Dormir debido al dolor en el brazo, el hombro o la mano.",
  "patient_hand_function_q12_a1": "Sin dificultad",
  "patient_hand_function_q12_a2": "Dificultad leve",
  "patient_hand_function_q12_a3": "Dificultad moderada",
  "patient_hand_function_q12_a4": "Dificultad severa",
  "patient_hand_function_q12_a5": "Dificultad extrema/incapaz",
  "patient_hand_function_q13": "Realizar su trabajo habitual debido al dolor de su mano, hombro o mano (trabajo incluye labores del hogar si ese es su rolo principal)",
  "patient_hand_function_q13_a1": "Sin dificultad",
  "patient_hand_function_q13_a2": "Dificultad leve",
  "patient_hand_function_q13_a3": "Dificultad moderada",
  "patient_hand_function_q13_a4": "Dificultad severa",
  "patient_hand_function_q13_a5": "Dificultad extrema/incapaz",
  "patient_hand_function_q14": "Especificar trabajo habitual",
  "patient_hand_function_q14_placeholder": "Especificar el trabajo",
  "patient_hand_function_do_dot_work": "No trabajo",
  "patient_hand_function_q15": "Usar tu técnica habitual de trabajo.",
  "patient_hand_function_q15_a1": "Sin dificultad",
  "patient_hand_function_q15_a2": "Dificultad leve",
  "patient_hand_function_q15_a3": "Dificultad moderada",
  "patient_hand_function_q15_a4": "Dificultad severa",
  "patient_hand_function_q15_a5": "Dificultad extrema/incapaz",
  "patient_hand_function_q16": "Hacer tu trabajo tan bien como te gustaría",
  "patient_hand_function_q16_a1": "Sin dificultad",
  "patient_hand_function_q16_a2": "Dificultad leve",
  "patient_hand_function_q16_a3": "Dificultad moderada",
  "patient_hand_function_q16_a4": "Dificultad severa",
  "patient_hand_function_q16_a5": "Dificultad extrema/incapaz",
  "patient_hand_function_q17": "Pasar la cantidad habitual de tiempo haciendo su trabajo",
  "patient_hand_function_q17_a1": "Sin dificultad",
  "patient_hand_function_q17_a2": "Dificultad leve",
  "patient_hand_function_q17_a3": "Dificultad moderada",
  "patient_hand_function_q17_a4": "Dificultad severa",
  "patient_hand_function_q17_a5": "Dificultad extrema/incapaz",
  "patient_hand_function_q18": "Tocar instrumentos musicales o practicar deporte debido a dolor en el brazo, el hombro o la mano",
  "patient_hand_function_q18_a1": "Sin dificultad",
  "patient_hand_function_q18_a2": "Dificultad leve",
  "patient_hand_function_q18_a3": "Dificultad moderada",
  "patient_hand_function_q18_a4": "Dificultad severa",
  "patient_hand_function_q18_a5": "Dificultad extrema/incapaz",
  "patient_hand_function_q19": "Especifique el deporte que practica o el instrumento musical que toca",
  "patient_hand_function_q19_placeholder": "Especifique deporte/instrumento musical",
  "patient_hand_function_donot_play": "No practico deporte o toco algún instrumento",
  "patient_hand_function_20": "Usar tu técnica habitual para tocar instrumento o hacer deporte",
  "patient_hand_function_q20_a1": "Sin dificultad",
  "patient_hand_function_q20_a2": "Dificultad leve",
  "patient_hand_function_q20_a3": "Dificultad moderada",
  "patient_hand_function_q20_a4": "Dificultad severa",
  "patient_hand_function_q20_a5": "Dificultad extrema/incapaz",
  "patient_hand_function_q21": "Tocar tu instrumento musical o hacer deporte tan bien como te gustaría",
  "patient_hand_function_q21_a1": "Sin dificultad",
  "patient_hand_function_q21_a2": "Dificultad leve",
  "patient_hand_function_q21_a3": "Dificultad moderada",
  "patient_hand_function_q21_a4": "Dificultad severa",
  "patient_hand_function_q21_a5": "Dificultad extrema/incapaz",
  "patient_hand_function_q22": "Pasar la cantidad de tiempo habitual practicando o tocando su deporte o instrumento",
  "patient_hand_function_q22_a1": "Sin dificultad",
  "patient_hand_function_q22_a2": "Dificultad leve",
  "patient_hand_function_q22_a3": "Dificultad moderada",
  "patient_hand_function_q22_a4": "Dificultad severa",
  "patient_hand_function_q22_a5": "Dificultad extrema/incapaz",
  "patient_hand_function_q23": "Durante la última semana, ¿En qué medida su problema en el brazo, el hombro o la mano ha interferido en sus ACTIVIDADES SOCIALES/DE OCIO TÍPICAS con familiares, amigos, vecinos o grupos?",
  "patient_hand_function_q23_a1": "En absoluto",
  "patient_hand_function_q23_a2": "Levemente",
  "patient_hand_function_q23_a3": "Moderadamente",
  "patient_hand_function_q23_a4": "Bastante",
  "patient_hand_function_q23_a5": "Extremadamente",
  "patient_hand_function_q24": "Durante la semana pasada, ¿Su TRABAJO U OTRAS ACTIVIDADES DIARIAS REGULARES se vieron limitadas como resultado de su problema en el brazo, el hombro o la mano?",
  "patient_hand_function_q24_a1": "En absoluto",
  "patient_hand_function_q24_a2": "Levemente",
  "patient_hand_function_q24_a3": "Moderadamente",
  "patient_hand_function_q24_a4": "Bastante",
  "patient_hand_function_q24_a5": "Extremadamente",
  "patient_hand_questionnaire": "Cuestionario sobre el síndrome del túnel carpiano de Boston",
  "patient_hand_questionnaire_symptom": "Síntomas",
  "patient_hand_questionnaire_symptom_instruction": "Por favor elija la respuesta que mejor corresponda con sus síntomas.",
  "patient_hand_questionnaire_symptom_instruction_0": "Normal",
  "patient_hand_questionnaire_symptom_instruction_1": "Leve",
  "patient_hand_questionnaire_symptom_instruction_2": "Medio",
  "patient_hand_questionnaire_symptom_instruction_3": "Severo",
  "patient_hand_questionnaire_symptom_instruction_4": "Muy serio",
  "patient_hand_questionnaire_symptom_q1": "¿Qué tan severo es el dolor de mano o muñeca que tiene por la noche?",
  "patient_hand_questionnaire_symptom_q1_a1": "Normal",
  "patient_hand_questionnaire_symptom_q1_a2": "Leve",
  "patient_hand_questionnaire_symptom_q1_a3": "Medio",
  "patient_hand_questionnaire_symptom_q1_a4": "Severo",
  "patient_hand_questionnaire_symptom_q1_a5": "Muy serio",
  "patient_hand_questionnaire_symptom_q2": "¿Con qué frecuencia el dolor en la mano o muñeca lo ha despertado en una noche típica dentro de las últimas 2 semanas?",
  "patient_hand_questionnaire_symptom_q2_a1": "Normal",
  "patient_hand_questionnaire_symptom_q2_a2": "Una vez",
  "patient_hand_questionnaire_symptom_q2_a3": "2-3 veces",
  "patient_hand_questionnaire_symptom_q2_a4": "4-5 veces",
  "patient_hand_questionnaire_symptom_q2_a5": "Más de 5 veces",
  "patient_hand_questionnaire_symptom_q3": "¿Suele tener dolor en la mano o muñeca durante el día?",
  "patient_hand_questionnaire_symptom_q3_a1": "Sin dolor",
  "patient_hand_questionnaire_symptom_q3_a2": "Leve",
  "patient_hand_questionnaire_symptom_q3_a3": "Medio",
  "patient_hand_questionnaire_symptom_q3_a4": "Severo",
  "patient_hand_questionnaire_symptom_q3_a5": "Muy serio",
  "patient_hand_questionnaire_symptom_q4": "¿Con qué frecuencia tiene dolor en la mano o muñeca durante el día?",
  "patient_hand_questionnaire_symptom_q4_a1": "Normal",
  "patient_hand_questionnaire_symptom_q4_a2": "1-2 veces/día",
  "patient_hand_questionnaire_symptom_q4_a3": "3-5 veces/día",
  "patient_hand_questionnaire_symptom_q4_a4": "Más de 5 veces/día",
  "patient_hand_questionnaire_symptom_q4_a5": "Continuo",
  "patient_hand_questionnaire_symptom_q5": "¿Cuánto dura en promedio un episodio de dolor durante el día?",
  "patient_hand_questionnaire_symptom_q5_a1": "Normal",
  "patient_hand_questionnaire_symptom_q5_a2": "<10 minutos",
  "patient_hand_questionnaire_symptom_q5_a3": "10-60 minutos continuaron",
  "patient_hand_questionnaire_symptom_q5_a4": ">60 minutos",
  "patient_hand_questionnaire_symptom_q5_a5": "Continuado",
  "patient_hand_questionnaire_symptom_q6": "¿Tiene entumecimiento (pérdida de sensibilidad) en la mano?",
  "patient_hand_questionnaire_symptom_q6_a1": "Normal",
  "patient_hand_questionnaire_symptom_q6_a2": "Leve",
  "patient_hand_questionnaire_symptom_q6_a3": "Medio",
  "patient_hand_questionnaire_symptom_q6_a4": "Severo",
  "patient_hand_questionnaire_symptom_q6_a5": "Muy serio",
  "patient_hand_questionnaire_symptom_q7": "¿Tiene debilidad en la mano o la muñeca?",
  "patient_hand_questionnaire_symptom_q7_a1": "Normal",
  "patient_hand_questionnaire_symptom_q7_a2": "Leve",
  "patient_hand_questionnaire_symptom_q7_a3": "Medio",
  "patient_hand_questionnaire_symptom_q7_a4": "Severo",
  "patient_hand_questionnaire_symptom_q7_a5": "Muy serio",
  "patient_hand_questionnaire_symptom_q8": "¿Tiene sensación de hormigueo en la mano?",
  "patient_hand_questionnaire_symptom_q8_a1": "Normal",
  "patient_hand_questionnaire_symptom_q8_a2": "Leve",
  "patient_hand_questionnaire_symptom_q8_a3": "Medio",
  "patient_hand_questionnaire_symptom_q8_a4": "Severo",
  "patient_hand_questionnaire_symptom_q8_a5": "Muy serio",
  "patient_hand_questionnaire_symptom_q9": "¿Qué tan grave es el entumecimiento (pérdida de la sensación) u hormigueo por la noche?",
  "patient_hand_questionnaire_symptom_q9_a1": "Normal",
  "patient_hand_questionnaire_symptom_q9_a2": "Leve",
  "patient_hand_questionnaire_symptom_q9_a3": "Medio",
  "patient_hand_questionnaire_symptom_q9_a4": "Severo",
  "patient_hand_questionnaire_symptom_q9_a5": "Muy serio",
  "patient_hand_questionnaire_symptom_q10": "¿Qué tan seguido el entumecimiento o hormigueo de su mano lo ha despertado durante una noche típica en las ultimas 2 semanas?",
  "patient_hand_questionnaire_symptom_q10_a1": "Normal",
  "patient_hand_questionnaire_symptom_q10_a2": "Una vez",
  "patient_hand_questionnaire_symptom_q10_a3": "2-3 veces",
  "patient_hand_questionnaire_symptom_q10_a4": "4-5 veces",
  "patient_hand_questionnaire_symptom_q10_a5": "Más de 5 veces",
  "patient_hand_questionnaire_function": "Funcionamiento",
  "patient_hand_questionnaire_function_instructions": "Califique el grado de dificultad que tiene al realizar las siguientes actividades en relación a sus síntomas:",
  "patient_hand_questionnaire_function_instructions_0": "Sin dificultad",
  "patient_hand_questionnaire_function_instructions_1": "Poca dificultad",
  "patient_hand_questionnaire_function_instructions_2": "Dificultad moderada",
  "patient_hand_questionnaire_function_instructions_3": "Dificultad intensa",
  "patient_hand_questionnaire_function_instructions_4": "No se puede realizar en absoluto",
  "patient_hand_questionnaire_function_q1": "Dificultad para agarrar y usar objetos pequeños como llaves o bolígrafos",
  "patient_hand_questionnaire_function_q1_a1": "Sin dificultad",
  "patient_hand_questionnaire_function_q1_a2": "Poca dificultad",
  "patient_hand_questionnaire_function_q1_a3": "Dificultad moderada",
  "patient_hand_questionnaire_function_q1_a4": "Dificultad intensa",
  "patient_hand_questionnaire_function_q1_a5": "No se puede realizar en absoluto",
  "patient_hand_questionnaire_function_q2": "Escribir ",
  "patient_hand_questionnaire_function_q2_a1": "Sin dificultad",
  "patient_hand_questionnaire_function_q2_a2": "Poca dificultad",
  "patient_hand_questionnaire_function_q2_a3": "Dificultad moderada",
  "patient_hand_questionnaire_function_q2_a4": "Dificultad intensa",
  "patient_hand_questionnaire_function_q2_a5": "No se puede realizar en absoluto",
  "patient_hand_questionnaire_function_q3": "Abotonar la ropa",
  "patient_hand_questionnaire_function_q3_a1": "Sin dificultad",
  "patient_hand_questionnaire_function_q3_a2": "Poca dificultad",
  "patient_hand_questionnaire_function_q3_a3": "Dificultad moderada",
  "patient_hand_questionnaire_function_q3_a4": "Dificultad intensa",
  "patient_hand_questionnaire_function_q3_a5": "No se puede realizar en absoluto",
  "patient_hand_questionnaire_function_q4": "Sostener un libro mientras lee",
  "patient_hand_questionnaire_function_q4_a1": "Sin dificultad",
  "patient_hand_questionnaire_function_q4_a2": "Poca dificultad",
  "patient_hand_questionnaire_function_q4_a3": "Dificultad moderada",
  "patient_hand_questionnaire_function_q4_a4": "Dificultad intensa",
  "patient_hand_questionnaire_function_q4_a5": "No se puede realizar en absoluto",
  "patient_hand_questionnaire_function_q5": "Agarrar de un asa de teléfono",
  "patient_hand_questionnaire_function_q5_a1": "Sin dificultad",
  "patient_hand_questionnaire_function_q5_a2": "Poca dificultad",
  "patient_hand_questionnaire_function_q5_a3": "Dificultad moderada",
  "patient_hand_questionnaire_function_q5_a4": "Dificultad intensa",
  "patient_hand_questionnaire_function_q5_a5": "No se puede realizar en absoluto",
  "patient_hand_questionnaire_function_q6": "Abrir un frasco",
  "patient_hand_questionnaire_function_q6_a1": "Sin dificultad",
  "patient_hand_questionnaire_function_q6_a2": "Poca dificultad",
  "patient_hand_questionnaire_function_q6_a3": "Dificultad moderada",
  "patient_hand_questionnaire_function_q6_a4": "Dificultad intensa",
  "patient_hand_questionnaire_function_q6_a5": "No se puede realizar en absoluto",
  "patient_hand_questionnaire_function_q7": "Tareas del hogar",
  "patient_hand_questionnaire_function_q7_a1": "Sin dificultad",
  "patient_hand_questionnaire_function_q7_a2": "Poca dificultad",
  "patient_hand_questionnaire_function_q7_a3": "Dificultad moderada",
  "patient_hand_questionnaire_function_q7_a4": "Dificultad intensa",
  "patient_hand_questionnaire_function_q7_a5": "No se puede realizar en absoluto",
  "patient_hand_questionnaire_function_q8": "Llevar una canasta de compras",
  "patient_hand_questionnaire_function_q8_a1": "Sin dificultad",
  "patient_hand_questionnaire_function_q8_a2": "Poca dificultad",
  "patient_hand_questionnaire_function_q8_a3": "Dificultad moderada",
  "patient_hand_questionnaire_function_q8_a4": "Dificultad intensa",
  "patient_hand_questionnaire_function_q8_a5": "No se puede realizar en absoluto",
  "patient_hand_questionnaire_function_q9": "Bañarse y vestirse",
  "patient_hand_questionnaire_function_q9_a1": "Sin dificultad",
  "patient_hand_questionnaire_function_q9_a2": "Poca dificultad",
  "patient_hand_questionnaire_function_q9_a3": "Dificultad moderada",
  "patient_hand_questionnaire_function_q9_a4": "Dificultad intensa",
  "patient_hand_questionnaire_function_q9_a5": "No se puede realizar en absoluto",
  "patient_hand_uram": "Escala URAM de la mano y muñeca",
  "patient_hand_uram_canyou": "Es usted capaz de...",
  "patient_hand_uram_q1": "Lavarse con una franela manteniendo la mano plana",
  "patient_hand_uram_q1_a1": "Sin dificultad",
  "patient_hand_uram_q1_a2": "Con muy poca dificultad",
  "patient_hand_uram_q1_a3": "Con cierta dificultad",
  "patient_hand_uram_q1_a4": "Con mucha dificultad",
  "patient_hand_uram_q1_a5": "Casi imposible",
  "patient_hand_uram_q1_a6": "Imposible",
  "patient_hand_uram_q2": "Lavarse la cara",
  "patient_hand_uram_q2_a1": "Sin dificultad",
  "patient_hand_uram_q2_a2": "Con muy poca dificultad",
  "patient_hand_uram_q2_a3": "Con cierta dificultad",
  "patient_hand_uram_q2_a4": "Con mucha dificultad",
  "patient_hand_uram_q2_a5": "Casi imposible",
  "patient_hand_uram_q2_a6": "Imposible",
  "patient_hand_uram_q3": "Sostener una botella en una mano",
  "patient_hand_uram_q3_a1": "Sin dificultad",
  "patient_hand_uram_q3_a2": "Con muy poca dificultad",
  "patient_hand_uram_q3_a3": "Con cierta dificultad",
  "patient_hand_uram_q3_a4": "Con mucha dificultad",
  "patient_hand_uram_q3_a5": "Casi imposible",
  "patient_hand_uram_q3_a6": "Imposible",
  "patient_hand_uram_q4": "Estrechar la mano de alguien",
  "patient_hand_uram_q4_a1": "Sin dificultad",
  "patient_hand_uram_q4_a2": "Con muy poca dificultad",
  "patient_hand_uram_q4_a3": "Con cierta dificultad",
  "patient_hand_uram_q4_a4": "Con mucha dificultad",
  "patient_hand_uram_q4_a5": "Casi imposible",
  "patient_hand_uram_q4_a6": "Imposible",
  "patient_hand_uram_q5": "Acariciar algo o a alguien",
  "patient_hand_uram_q5_a1": "Sin dificultad",
  "patient_hand_uram_q5_a2": "Con muy poca dificultad",
  "patient_hand_uram_q5_a3": "Con cierta dificultad",
  "patient_hand_uram_q5_a4": "Con mucha dificultad",
  "patient_hand_uram_q5_a5": "Casi imposible",
  "patient_hand_uram_q5_a6": "Imposible",
  "patient_hand_uram_q6": "Aplaudir",
  "patient_hand_uram_q6_a1": "Sin dificultad",
  "patient_hand_uram_q6_a2": "Con muy poca dificultad",
  "patient_hand_uram_q6_a3": "Con cierta dificultad",
  "patient_hand_uram_q6_a4": "Con mucha dificultad",
  "patient_hand_uram_q6_a5": "Casi imposible",
  "patient_hand_uram_q6_a6": "Imposible",
  "patient_hand_uram_q7": "Extender los dedos",
  "patient_hand_uram_q7_a1": "Sin dificultad",
  "patient_hand_uram_q7_a2": "Con muy poca dificultad",
  "patient_hand_uram_q7_a3": "Con cierta dificultad",
  "patient_hand_uram_q7_a4": "Con mucha dificultad",
  "patient_hand_uram_q7_a5": "Casi imposible",
  "patient_hand_uram_q7_a6": "Imposible",
  "patient_hand_uram_q8": "Apoyarse sobre la mano",
  "patient_hand_uram_q8_a1": "Sin dificultad",
  "patient_hand_uram_q8_a2": "Con muy poca dificultad",
  "patient_hand_uram_q8_a3": "Con cierta dificultad",
  "patient_hand_uram_q8_a4": "Con mucha dificultad",
  "patient_hand_uram_q8_a5": "Casi imposible",
  "patient_hand_uram_q8_a6": "Imposible",
  "patient_hand_uram_q9": "Recoger objetos pequeños con el pulgar y el índice",
  "patient_hand_uram_q9_a1": "Sin dificultad",
  "patient_hand_uram_q9_a2": "Con muy poca dificultad",
  "patient_hand_uram_q9_a3": "Con cierta dificultad",
  "patient_hand_uram_q9_a4": "Con mucha dificultad",
  "patient_hand_uram_q9_a5": "Casi imposible",
  "patient_hand_uram_q9_a6": "Imposible",
  "patient_hand_quality_of_life": "Calidad de vida",
  "patient_hand_quality_of_life_instruction": "Califique los siguientes puntos para describir mejor su SALUD HOY",
  "patient_hand_quality_of_life_instruction_0": "Ningún problema",
  "patient_hand_quality_of_life_instruction_1": "Leve",
  "patient_hand_quality_of_life_instruction_2": "Moderado",
  "patient_hand_quality_of_life_instruction_3": "Severo",
  "patient_hand_quality_of_life_instruction_4": "Extremo/incapaz",
  "patient_hand_quality_of_life_q1": "Problemas al caminar",
  "patient_hand_quality_of_life_q1_a1": "Ningún problema",
  "patient_hand_quality_of_life_q1_a2": "Leve",
  "patient_hand_quality_of_life_q1_a3": "Moderado",
  "patient_hand_quality_of_life_q1_a4": "Severo",
  "patient_hand_quality_of_life_q1_a5": "Incapaz",
  "patient_hand_quality_of_life_q2": "Problemas con el lavado o secado propio",
  "patient_hand_quality_of_life_q2_a1": "Ningún problema",
  "patient_hand_quality_of_life_q2_a2": "Leve",
  "patient_hand_quality_of_life_q2_a3": "Moderado",
  "patient_hand_quality_of_life_q2_a4": "Severo",
  "patient_hand_quality_of_life_q2_a5": "Incapaz",
  "patient_hand_quality_of_life_q3": "Problemas con las actividades habituales (p. ej., trabajo, estudio, tareas domésticas, familia o actividades de ocio)",
  "patient_hand_quality_of_life_q3_a1": "Ningún problema",
  "patient_hand_quality_of_life_q3_a2": "Leve",
  "patient_hand_quality_of_life_q3_a3": "Moderado",
  "patient_hand_quality_of_life_q3_a4": "Severo",
  "patient_hand_quality_of_life_q3_a5": "Incapaz",
  "patient_hand_quality_of_life_q4": "Dolor o malestar",
  "patient_hand_quality_of_life_q4_a1": "Ninguno",
  "patient_hand_quality_of_life_q4_a2": "Leve",
  "patient_hand_quality_of_life_q4_a3": "Moderado",
  "patient_hand_quality_of_life_q4_a4": "Severo",
  "patient_hand_quality_of_life_q4_a5": "Extremo",
  "patient_hand_quality_of_life_q5": "Ansiedad/Depresión",
  "patient_hand_quality_of_life_q5_a1": "No ansioso/deprimido",
  "patient_hand_quality_of_life_q5_a2": "Levemente ansioso/deprimido",
  "patient_hand_quality_of_life_q5_a3": "Moderadamente ansioso/deprimido",
  "patient_hand_quality_of_life_q5_a4": "Severamente ansioso/deprimido",
  "patient_hand_quality_of_life_q5_a5": "Extremadamente ansioso/deprimido",
  "patient_hand_quality_of_life_q6": "¿Qué tan buena o mala es su salud HOY? (100 significa la mejor salud 0 significa la peor salud que puedas imaginar)",
  "patient_hand_quality_of_life_q6_a1": "Peor",
  "patient_hand_quality_of_life_q6_a2": "Mejor",
  "patient_hand_satisfaction": "Satisfacción",
  "patient_hand_satisfaction_instrucion": "Indique qué tan satisfecho está con lo siguiente",
  "patient_hand_satisfaction_instrucion_0": "Muy insatisfecho",
  "patient_hand_satisfaction_instrucion_1": "Insatisfecho",
  "patient_hand_satisfaction_instrucion_2": "Ni satisfecho ni insatisfecho",
  "patient_hand_satisfaction_instrucion_3": "De alguna manera satisfecho",
  "patient_hand_satisfaction_instrucion_4": "Muy Satisfecho",
  "patient_hand_satisfaction_q1": "¿Movimiento de sus DEDOS?",
  "patient_hand_satisfaction_q1_na": "No aplicable",
  "patient_hand_satisfaction_q1_a1": "Muy insatisfecho",
  "patient_hand_satisfaction_q1_a2": "Insatisfecho",
  "patient_hand_satisfaction_q1_a3": "Ni satisfecho ni insatisfecho",
  "patient_hand_satisfaction_q1_a4": "De alguna manera satisfecho",
  "patient_hand_satisfaction_q1_a5": "Muy Satisfecho",
  "patient_hand_satisfaction_q2": "Movimiento de su MUÑECA",
  "patient_hand_satisfaction_q2_na": "No aplicable",
  "patient_hand_satisfaction_q2_a1": "Muy insatisfecho",
  "patient_hand_satisfaction_q2_a2": "Insatisfecho",
  "patient_hand_satisfaction_q2_a3": "Ni satisfecho ni insatisfecho",
  "patient_hand_satisfaction_q2_a4": "De alguna manera satisfecho",
  "patient_hand_satisfaction_q2_a5": "Muy Satisfecho",
  "patient_hand_satisfaction_q3": "Cuidado de su MANO",
  "patient_hand_satisfaction_q3_na": "No aplicable",
  "patient_hand_satisfaction_q3_a1": "Muy insatisfecho",
  "patient_hand_satisfaction_q3_a2": "Insatisfecho",
  "patient_hand_satisfaction_q3_a3": "Ni satisfecho ni insatisfecho",
  "patient_hand_satisfaction_q3_a4": "De alguna manera satisfecho",
  "patient_hand_satisfaction_q3_a5": "Muy Satisfecho",
  "patient_hand_satisfaction_q4": "Tratamiento de su mano/muñeca",
  "patient_hand_satisfaction_q4_na": "No aplicable",
  "patient_hand_satisfaction_q4_a1": "Muy insatisfecho",
  "patient_hand_satisfaction_q4_a2": "Insatisfecho",
  "patient_hand_satisfaction_q4_a3": "Ni satisfecho ni insatisfecho",
  "patient_hand_satisfaction_q4_a4": "De alguna manera satisfecho",
  "patient_hand_satisfaction_q4_a5": "Muy Satisfecho",
  "patient_hand_complete": "Fin del Cuestionario",
  "patient_hand_complete_text1": "Gracias por tomarse el tiempo de llenar este formulario",
  "patient_hand_complete_text2": "Por favor presiona enviar para completar y ver tus resultados",
  "patient_hand_complete_text3": "Sus respuestas serán tratadas de forma confidencial y cualquier informe solo utilizará información anonimizada",
  "patient_hand_result": "Resultados",
  "patient_hand_result_text1": "A continuación, se encuentran sus puntajes de dolor y funcionamiento de la mano y muñeca, que han sido calculados a partir de su cuestionario.",
  "patient_hand_result_function": "Funcionamiento",
  "patient_hand_result_pain": "Dolor",
  "patient_hand_result_health_perception": "Percepción general de salud",
  "patient_hand_result_self_evaluation": "Puntuación de la autoevaluación de mano y muñeca",
  "patient_hand_result_text2": "Su médico estará con usted en breve.",
  "patient_general_history": "Registros de la molestia/lesión/enfermedad",
  "patient_general_history_q1": "Molestia principal (describa la molestia con sus propias palabras)",
  "patient_general_history_q2": "Fecha en que el problema empezó",
  "patient_general_history_q3": "Se le ha realizado alguna captura de imagenes para diagnosticar la molestia actual?",
  "patient_general_history_q3_a1": "Ninguna",
  "patient_general_history_q3_a2": "Rayos-X",
  "patient_general_history_q3_a3": "Ultrasonido",
  "patient_general_history_q3_a4": "Resonancia magnética (RM)",
  "patient_general_history_q3_a5": "Artrografía por resonancia magnética (RM con tinte en la articulación)",
  "patient_general_history_q3_a6": "Tomografía (TC)",
  "patient_general_history_q3_a7": "Artrografía por TC (TC con tinte en la articulación)",
  "patient_general_history_q4": "¿Se ha realizado alguna investigación posterior, en relación a lo ya registrado, con respecto a su condicion actual?",
  "patient_general_history_q4_placeholder": "Otras investigaciones",
  "patient_general_past_history": "HISTORIAL MÉDICO PASADO / PROBLEMAS MÉDICOS CONTINUOS",
  "patient_general_past_history_q1": "Tienes conocimiento de posibles alergias? (medicamento o comida)",
  "patient_general_past_history_q1_a1": "No",
  "patient_general_past_history_q1_a2": "Si",
  "patient_general_past_history_placeholder": "Especifique el tipo de alergia y reacción a esta",
  "patient_general_past_history_q2": "¿Eres alérgico al látex?",
  "patient_general_past_history_q2_a1": "No",
  "patient_general_past_history_q2_a2": "Si",
  "patient_general_past_history_q3": "¿Tiene o está recibiendo tratamiento para la presión arterial alta?",
  "patient_general_past_history_q3_a1": "No",
  "patient_general_past_history_q3_a2": "Si",
  "patient_general_past_history_q3_a2_placeholder": "Especifique los medicamentos que consume para la presión alta",
  "patient_general_past_history_q4": "¿Tiene o ha tenido alguna afección/enfermedad cardíaca?",
  "patient_general_past_history_q4_a1": "No",
  "patient_general_past_history_q4_a2": "Si",
  "patient_general_past_history_q4_a2_o1": "Indique la condición",
  "patient_general_past_history_q4_a2_o2": "Angina de pecho (dolor de pecho)",
  "patient_general_past_history_q4_a2_o3": "Infarto",
  "patient_general_past_history_q4_a2_o4": "Insuficiencia cardíaca (líquido en los pulmones)",
  "patient_general_past_history_q4_a2_o5": "Soplo o problema de las válvulas cardíacas",
  "patient_general_past_history_q4_a2_o6": "Palpitaciones/latidos cardíacos irregulares",
  "patient_general_past_history_q4_a2_o7": "Dispositivo implantado (DCI, marcapasos)",
  "patient_general_past_history_q4_a2_o8": "Cirugía cardíaca",
  "patient_general_past_history_q4_a2_o9": "Cirugía de la arteria carótida",
  "patient_general_past_history_q4_a2_o10": "Otra condición cardiaca",
  "patient_general_past_history_q4_a2_o8_q1_placeholder": "Especificar sirugía cardiaca",
  "patient_general_past_history_q4_a2_o10_q1_placeholder": "Especificar otras condiciones cardiacas",
  "patient_general_past_history_q5": "Tiene un cardiólogo?",
  "patient_general_past_history_q5_a1": "No",
  "patient_general_past_history_q5_a2": "Si",
  "patient_general_past_history_q5_a2_placeholder": "Nombre y práctica",
  "patient_general_past_history_q6": "¿Tiene problemas con las circulaciones en las piernas (enfermedad vascular periférica)?",
  "patient_general_past_history_q6_a1": "No",
  "patient_general_past_history_q6_a2": "Si",
  "patient_general_past_history_q6_a2_placeholder": "Describir",
  "patient_general_past_history_q7": "¿Has tomado un anticoagulante en el último mes?",
  "patient_general_past_history_q7_a1": "No",
  "patient_general_past_history_q7_a2": "Si",
  "patient_general_past_history_q7_a2_q1": "¿Cuál?",
  "patient_general_past_history_q7_a2_q1_a1": "Aspirina",
  "patient_general_past_history_q7_a2_q1_a2": "Clopidogrel/Plavix",
  "patient_general_past_history_q7_a2_q1_a3": "Rivaroxabán",
  "patient_general_past_history_q7_a2_q1_a4": "Eliquis",
  "patient_general_past_history_q7_a2_q1_a5": "Otro",
  "patient_general_past_history_q7_a2_q1_a5_placeholder": "Especificar anticoagulante",
  "patient_general_past_history_q8": "¿Ha tenido algun problema con los pulmones o el tórax?",
  "patient_general_past_history_q8_a1": "No",
  "patient_general_past_history_q8_a2": "Si",
  "patient_general_past_history_q8_a2_o1": "¿Tos con esputo?",
  "patient_general_past_history_q8_a2_o2": "¿Algún problema con su respiración?",
  "patient_general_past_history_q8_a2_o3": "Otros",
  "patient_general_past_history_q8_a2_o2_o1": "Asma",
  "patient_general_past_history_q8_a2_o2_o2": "Presión arterial alta en los pulmones (hipertensión pulmonar)",
  "patient_general_past_history_q8_a2_o2_o3": "Fibrosis pulmonar",
  "patient_general_past_history_q8_a2_o2_o4": "Sarcoidosis",
  "patient_general_past_history_q8_a2_o2_o5": "EPOC o enfisema",
  "patient_general_past_history_q8_a2_o2_o6": "Fibrosis quística",
  "patient_general_past_history_q8_a2_o2_o7": "TB (tuberculosis)",
  "patient_general_past_history_q8_a2_o2_o8": "Otros",
  "patient_general_past_history_q8_a2_o3_placehold": "Especificar",
  "patient_general_past_history_q8_a2_o2_o8_placeholder": "Especificar",
  "patient_general_past_history_q9": "¿Ha tomado esteroides (prednisona o cortisona) en el último año?",
  "patient_general_past_history_q9_a1": "No",
  "patient_general_past_history_q9_a2": "Si",
  "patient_general_past_history_q10": "¿Usa oxígeno en el hogar?",
  "patient_general_past_history_q10_a1": "No",
  "patient_general_past_history_q10_a2": "Si",
  "patient_general_past_history_q11": "¿Tiene un especialista respiratorio?",
  "patient_general_past_history_q11_a1": "No",
  "patient_general_past_history_q11_a2": "Si",
  "patient_general_past_history_q11_a2_placeholder": "Nombre y práctica",
  "patient_general_past_history_q12": "¿Tiene ronquidos excesivos o apnea del sueño?",
  "patient_general_past_history_q12_a1": "No",
  "patient_general_past_history_q12_a2": "Si",
  "patient_general_past_history_q12_a2_q1": "¿Su apnea del sueño se trata con CPAP o BiPAP?",
  "patient_general_past_history_q12_a2_q1_a1": "No",
  "patient_general_past_history_q12_a2_q1_a2": "Si",
  "patient_general_past_history_q13": "¿Alguna vez has tenido problemas hepáticos?",
  "patient_general_past_history_q13_a1": "No",
  "patient_general_past_history_q13_a2": "Si",
  "patient_general_past_history_q13_a2_o1": "Seleccionado",
  "patient_general_past_history_q13_a2_o2": "Cirrosis",
  "patient_general_past_history_q13_a2_o3": "Hepatitis A, B o C",
  "patient_general_past_history_q13_a2_o4": "Ictericia",
  "patient_general_past_history_q13_a2_o5": "Otros",
  "patient_general_past_history_q13_a2_o5_placeholder": "Especificar",
  "patient_general_past_history_q14": "¿Tiene acidez estomacal frecuente, úlceras o hernia de hiato?",
  "patient_general_past_history_q14_a1": "No",
  "patient_general_past_history_q14_a2": "Si",
  "patient_general_past_history_q15": "¿Alguna vez has tenido enfermedad renal?",
  "patient_general_past_history_q15_a1": "No",
  "patient_general_past_history_q15_a2": "Si",
  "patient_general_past_history_q15_a2_q1": "¿Es usted un paciente de diálisis? ¿Peritoneal? Hemodiálisis",
  "patient_general_past_history_q15_a2_q1_a1": "No",
  "patient_general_past_history_q15_a2_q1_a2": "Si",
  "patient_general_past_history_q15_a2_placeholder": "Especificar enfermedad renal",
  "patient_general_past_history_q15_a2_q1_a2_placeholder": "Especificar tipo y plan de diálisis",
  "patient_general_past_history_q16": "¿Tiene usted diabetes?",
  "patient_general_past_history_q16_a1": "No",
  "patient_general_past_history_q16_a2": "Si",
  "patient_general_past_history_q16_a2_q1": "¿Cómo maneja su diabetes? Dieta, medicamentos, insulina?",
  "patient_general_past_history_q16_a2_q1_placeholder": "Especificar",
  "patient_general_past_history_q17": "¿Tiene problemas de tiroides?",
  "patient_general_past_history_q17_a1": "No",
  "patient_general_past_history_q17_a2": "Si",
  "patient_general_past_history_q18": "¿Alguna vez ha tenido epilepsia o convulsiones?",
  "patient_general_past_history_q18_a1": "No",
  "patient_general_past_history_q18_a2": "Si",
  "patient_general_past_history_q18_a2_q1": "¿Cuándo fue su última convulsión?",
  "patient_general_past_history_q18_a2_q1_placeholder": "Especificar",
  "patient_general_past_history_q19": "¿Ha tenido problemas con sus nervios o músculos?",
  "patient_general_past_history_q19_a1": "No",
  "patient_general_past_history_q19_a2": "Si",
  "patient_general_past_history_q19_a2_o1": "Convulsiones",
  "patient_general_past_history_q19_a2_o1_placeholder": "Cuándo?",
  "patient_general_past_history_q19_a2_o2": "AIT (ataque isquémico transitorio) o accidente cerebrovascular",
  "patient_general_past_history_q19_a2_o2_placeholder": "Cuándo?",
  "patient_general_past_history_q19_a2_o3": "Debilidad facial, de piernas o brazos",
  "patient_general_past_history_q19_a2_o4": "\nTrastorno neurológico (ejemplos: esclerosis múltiple, ELA, Alzheimer)",
  "patient_general_past_history_q19_a2_o4_placeholder": "Especificar",
  "patient_general_past_history_q19_a2_o5": "Trastorno muscular (ejemplos: miastenia grave, distrofia muscular)",
  "patient_general_past_history_q19_a2_o5_placeholder": "Especificar",
  "patient_general_past_history_q19_a2_o6": "Problemas con la audición, la visión o la memoria",
  "patient_general_past_history_q19_a2_o7": "Dolor crónico",
  "patient_general_past_history_q19_a2_o8": "Otros",
  "patient_general_past_history_q19_a2_o8_placeholder": "Especificar",
  "patient_general_past_history_q20": "¿Tiene Artritis?",
  "patient_general_past_history_q20_a1": "No",
  "patient_general_past_history_q20_a2": "Si",
  "patient_general_past_history_q20_a2_o1": "Osteoartritis",
  "patient_general_past_history_q20_a2_o2": "Artritis reumatoide",
  "patient_general_past_history_q20_a2_o3": "Otros",
  "patient_general_past_history_q20_a2_o3_placeholder": "Especificar",
  "patient_general_past_history_q21": "¿Alguna vez ha recibido tratamiento para una enfermedad psiquiátrica?",
  "patient_general_past_history_q21_a1": "No",
  "patient_general_past_history_q21_a2": "Si",
  "patient_general_past_history_q21_a2_o1": "\nAnsiedad severa",
  "patient_general_past_history_q21_a2_o2": "Depresión",
  "patient_general_past_history_q21_a2_o3": "Otros",
  "patient_general_past_history_q21_a2_o3_placeholder": "Especificar",
  "patient_general_past_history_q22": "¿Alguna vez le han diagnosticado un trastorno hemorrágico?",
  "patient_general_past_history_q22_a1": "No",
  "patient_general_past_history_q22_a2": "Si",
  "patient_general_past_history_q22_a2_o1": "Leucemia o linfoma",
  "patient_general_past_history_q22_a2_o2": "Hemofilia",
  "patient_general_past_history_q22_a2_o3": "Coágulos de sangre",
  "patient_general_past_history_q22_a2_o4": "Anemia",
  "patient_general_past_history_q22_a2_o5": "Enfermedad de células falciformes",
  "patient_general_past_history_q22_a2_o6": "Otros",
  "patient_general_past_history_q22_a2_o6_placeholder": "Especificar",
  "patient_general_past_history_q23": "¿Estás anémico?",
  "patient_general_past_history_q23_a1": "No",
  "patient_general_past_history_q23_a2": "Si",
  "patient_general_past_history_q24": "¿Alguna vez has tenido un coágulo de sangre en las piernas o los pulmones?",
  "patient_general_past_history_q24_a1": "No",
  "patient_general_past_history_q24_a2": "Si",
  "patient_general_past_history_q25": "¿Usted o alguien de su familia ha tenido problemas graves con:",
  "patient_general_past_history_q25_a1": "Sangrado nasal",
  "patient_general_past_history_q25_a1_o1": "No",
  "patient_general_past_history_q25_a1_o2": "Si",
  "patient_general_past_history_q25_a2": "Sangrado con extracciones dentales",
  "patient_general_past_history_q25_a2_o1": "No",
  "patient_general_past_history_q25_a2_o2": "Si",
  "patient_general_past_history_q25_a3": "Sangrado después de la cirugía",
  "patient_general_past_history_q25_a3_o1": "No",
  "patient_general_past_history_q25_a3_o2": "Si",
  "patient_general_past_history_q26": "¿Alguna vez ha sido diagnosticado con cáncer?",
  "patient_general_past_history_q26_a1": "No",
  "patient_general_past_history_q26_a2": "Si",
  "patient_general_past_history_q26_a2_placeholder1": "Especificar el tipo de cáncer",
  "patient_general_past_history_q26_a2_placeholder2": "¿Cuándo?",
  "patient_general_past_history_q27": "¿Alguna vez te han tratado con quimioterapia o radioterapia?",
  "patient_general_past_history_q27_a1": "No",
  "patient_general_past_history_q27_a2": "Si",
  "patient_general_past_history_q27_a2_placeholder1": "Nombre del tratamiento",
  "patient_general_past_history_q27_a2_placeholder2": "Fecha del último tratamiento",
  "patient_general_past_history_q28": "¿Usted o su familiar han tenido algún problema con la anestesia?",
  "patient_general_past_history_q28_a1": "No",
  "patient_general_past_history_q28_a2": "Si",
  "patient_general_past_history_q28_a2_q1": "¿Qué problemas?",
  "patient_general_past_history_q28_a2_q1_placeholder": "Elegir",
  "patient_general_past_history_q28_a2_q1_o1": "Náuseas o vómitos intensos después de la cirugía",
  "patient_general_past_history_q28_a2_q1_o2": "Hipertermia maligna (en familiares que son parientes consanguíneos o en usted mismo)",
  "patient_general_past_history_q28_a2_q1_o3": "¿Problema respiratorio o dificultad con la inserción del tubo respiratorio de anestesia?",
  "patient_general_past_history_q28_a2_q1_o4": "Problemas con la colocación de un tubo de respiración",
  "patient_general_past_history_q28_a2_q1_o5": "Otros",
  "patient_general_past_history_q28_a2_q1_o5_placeholder": "Especificar",
  "patient_general_past_history_q29": "¿Tiene alguno de los siguientes?",
  "patient_general_past_history_q29_q1": "Dientes astillados o flojos, dentaduras postizas, parciales",
  "patient_general_past_history_q29_q1_a1": "No",
  "patient_general_past_history_q29_q1_a2": "Si",
  "patient_general_past_history_q29_q2": "Problemas para mover el cuello",
  "patient_general_past_history_q29_q2_a1": "No",
  "patient_general_past_history_q29_q2_a2": "Si",
  "patient_general_past_history_q29_q3": "Problemas para abrir la boca",
  "patient_general_past_history_q29_q3_a1": "No",
  "patient_general_past_history_q29_q3_a2": "Si",
  "patient_general_past_history_q30": "¿Podría estar embarazada?",
  "patient_general_past_history_q30_a1": "No",
  "patient_general_past_history_q30_a2": "Si",
  "patient_general_past_history_q30_not_applicable": "No aplicable",
  "patient_general_past_history_q30_a2_q1": "Último periodo menstrual",
  "patient_general_past_history_q30_a2_q1_placeholder": "Último periodo menstrual",
  "patient_general_past_history_q31": "Historial médico: Enumere cualquier enfermedad médica adicional que tenga que no se haya mencionado anteriormente",
  "patient_general_past_history_q31_q1": "Enumere todos los medicamentos (incluya medicamentos de venta libre, inhaladores, parches, gotas, vitaminas, minerales, suplementos, hierbas)",
  "patient_general_past_history_q31_q2": "Indique el nombre del medicamento, la dosis, la frecuencia y el motivo por el que lo toma",
  "patient_general_past_history_q31_q2_placeholder": "Nombre del medicamento, dosis, frecuencia, razón para tomar",
  "patient_general_past_history_q31_q3": "Dosis",
  "patient_general_past_history_q31_q4": "Frecuentemente",
  "patient_general_past_history_q31_q5": "Razón para tomarlo",
  "patient_general_past_history_q31_q6": "Historial Quirúrgico",
  "patient_general_past_history_q31_q6_placeholder": "Especificar el tipo y fecha del procedimiento",
  "patient_general_social_history": "HISTORIAL SOCIAL",
  "patient_general_social_history_q1": "¿Vives solo?",
  "patient_general_social_history_q1_a1": "No",
  "patient_general_social_history_q1_a2": "Si",
  "patient_general_social_history_q1_a1_q1": "¿Quién vive contigo?",
  "patient_general_social_history_q1_a1_q1_placeholder": "¿Quién vive contigo?",
  "patient_general_social_history_q2": "¿Usted fuma?",
  "patient_general_social_history_q2_a1": "No",
  "patient_general_social_history_q2_a2": "Si",
  "patient_general_social_history_q2_a2_q1": "¿Cuántos cigarrillos por día?",
  "patient_general_social_history_q2_a2_q1_placeholder": "Número de cigarrillos",
  "patient_general_social_history_q2_a2_q2": "¿Número de años?",
  "patient_general_social_history_q2_a2_q2_placeholder": "Años",
  "patient_general_social_history_q3": "¿Has fumado en el pasado?",
  "patient_general_social_history_q3_a1": "No",
  "patient_general_social_history_q3_a2": "Si",
  "patient_general_social_history_q3_a2_q1": "¿Cuándo dejaste de fumar?",
  "patient_general_social_history_q3_a2_q1_placeholder": "¿Cuándo dejaste de fumar?",
  "patient_general_social_history_q3_a2_q2_placeholder": "Años",
  "patient_general_social_history_q4": "¿Usted bebe alcohol?",
  "patient_general_social_history_q4_a1": "No",
  "patient_general_social_history_q4_a2": "Si",
  "patient_general_social_history_q4_a2_q1_placeholder": "Número de bebidas estándar a la semana",
  "patient_general_social_history_q5": "¿Usa o alguna vez ha usado drogas recreativas o ilícitas, marihuana u opioides no recetados para usted?",
  "patient_general_social_history_q5_a1": "No",
  "patient_general_social_history_q5_a2": "Si",
  "patient_general_social_history_q5_a2_placeholder": "Especificar drogas",
  "patient_general_complete": "Fin del Cuestionario",
  "patient_general_complete_text1": "Gracias por tomarse el tiempo de llenar este formulario",
  "patient_general_complete_text2": "Por favor presiona enviar para completar y ver tus resultados",
  "patient_general_complete_text3": "Sus respuestas serán tratadas de forma confidencial y cualquier informe solo utilizará información anonimizada",
  "AppVersion": "Versión",
  "Complete_personal_info": "Información personal completa",
  "To_complete_the_sign_up": "Para completar el registro, por favor complete con su información personal",
  "Select_Existing_Profile": "Seleccionar perfil existente",
  "Get_In": "Ingresar",
  "Create_new_patient": "Crear nuevo paciente",
  "myProfile": "Mi perfil",
  "First_Name": "Nombre*",
  "Last_Name": "Apellido*",
  "DOB": "Fecha de nacimiento*",
  "Sex": "Género*",
  "Address": "Dirección*",
  "Postcode": "Código postal*",
  "Suburb": "Ciudad*",
  "Country_Code": "Código de país",
  "Mobile_Phone": "Teléfono móvil",
  "Home_Phone": "Teléfono fijo",
  "Email": "Correo electrónico*",
  "Emergency_Contact": "Contacto de emergencia*",
  "Phone": "Teléfono*",
  "Relationship": "Relación*",
  "Regular_GP_Name": "Nombre del médico de cabecera*",
  "Practice_Name": "Practica del médico de cabecera*",
  "Medicare_Card_Number": "Número de seguro de salud",
  "Ref": "Referencia",
  "Message": "Mensaje",
  "Expiry": "Vencimiento",
  "Private_Health_Fund": "Seguro de salud privado",
  "Membership_Number": "Número de membresía",
  "Claim_Type": "Tipo de reclamo",
  "Work_Cover": "Cobertura de empleo",
  "Employer": "Empleador",
  "Third_Party_Claim_Number": "Número de reclamo externo",
  "Third_Party_Name": "Nombre de parte externa",
  "DVA_Concession_Card": " ",
  "Number": "Número",
  "Defence_Personnel": "Personal de defensa",
  "I_agree": "Acepto el procesamiento de mis datos personales y he leído la ",
  "Privacy_Policy": "Política de privacidad.",
  "visitClinic": "Agendar cita",
  "selectdocOrg": "Seleccionar médico y organización*",
  "doctorOrganization": "Organización del médico*",
  "Select_Treatment_Category": "Seleccionar categoría de tratamiento*",
  "Do_you_want_to_submit_patient_form_or_not": "¿Desea registrar un formulario de paciente?",
  "areyousurewanttocontinueyourform": "Su formulario anterior está pendiente. ¿Desea continuar?",
  "myVisit": "Visitas anteriores",
  "View_score": "Ver puntuación",
  "upcoming_visits": "Próximas visitas",
  "No_data_found": "No se encontraron datos",
  "Doctor_Name": "Nombre del médico",
  "Appointment_Date_Time": "Fecha y hora de la cita",
  "Clinic_Name": "Nombre de la clínica",
  "Clinic_Address": "Dirección de la clínica",
  "akmed": "Akunah Med",
  "Type": "Tipo",
  "Date_Time": "Fecha y hora",
  "Action": "Acción",
  "View": "Ver",
  "No_Messages": "No hay mensajes",
  "settings": "Ajustes",
  "Language": "Idioma",
  "language": "Idioma y Región",
  "timezone": "Zona horaria",
  "Timeformat": "Formato de hora",
  "List_of_my_profile": "Mis perfiles",
  "next_button": "Siguiente",
  "back_button": "Atrás",
  "Continue": "Continuar",
  "Close": "Cerrar",
  "submit": "Enviar",
  "Sign_out": "Volver al perfil",
  "Results": "Resultados",
  "update": "Formulario actualizado correctamente",
  "Personal_Data": "Datos personales",
  "Date_of_Birth": "Fecha de nacimiento",
  "Hip_arthritis": "¿Artritis de cadera?*",
  "Area_of_foot_ankle": "¿Área del pie/tobillo afectada?*",
  "Diagnosis": "¿Diagnóstico?*",
  "Evaluated_Shoulder": "Hombro evaluado",
  "Dominant_Hand": "Mano dominante*",
  "Age": "Edad",
  "No": "No",
  "Yes": "Si",
  "visitNo": "visitaNo",
  "visitYes": "visitaSi",
  "shoulderPainR": "dolor de hombro D",
  "Is_it_initial_visit": "Es la primera visita al",
  "for_the_problem": "relacionada a este problema?*",
  "Diagnosis_Procedure": "Diagnóstico / Procedimiento",
  "Procedure": "Procedimiento",
  "Evaluated_sdfs": "Lado evaluado*",
  "Please_Select_previous_visits": "Por favor, seleccione visitas anteriores*",
  "Follow_Up": "Seguimiento",
  "Assessment_Date": "Fecha de evaluación",
  "Do_you_have_Dupuytren_disease": "¿Tiene enfermedad de Dupuytren?",
  "Do_you_have_carpal_tunnel_syndrome": "¿Tiene síndrome del túnel carpiano?",
  "Is_your_unaffected_shoulder_pain": "¿Su hombro no afectado está libre de dolor y permite un normal funcionamiento?",
  "Is_your_unaffected_hip_pain": "¿Su lado no afectado de la cadera está libre de dolor y permite un normal funcionamiento?",
  "Is_your_unaffected_knee_pain": "¿Su rodilla no afectada está libre de dolor y permite un normal funcionamiento?",
  "Is_your_unaffected_elbow_pain": "¿Su codo no afectado está libre de dolor y permite un normal funcionamiento?",
  "Is_your_unaffected_foot_and_ankle_pain": "¿Su pie y tobillo no afectado está libre de dolor y permite un normal funcionamiento?",
  "Is_your_unaffected_hand_pain": "¿Su mano y muñeca no afectada está libre de dolor y permite un normal funcionamiento?",
  "Patient_Consent": "Consentimiento del paciente",
  "Patient_Last_Name": "Apellido del paciente",
  "Patient_First_Name": "Nombre del paciente",
  "Patient_Date_of_Birth": "Fecha de nacimiento del paciente",
  "information": "Información",
  "I_am_informedDDD": "Con el propósito de evaluar, planificar y analizar los resultados de su tratamiento ortopédico, su doctor,",
  "Clinician": "(de ahora en adelante el “Clínico”) utilizará la evaluación, la recopilación y el análisis de datos, y de resultados y/o los servicios de planificación prequirúrgica y software proporcionados por Akunah Medical Technology Pty Ltd y sus subsidiarias (“Akunah”).",
  "For_that": "Para ese propósito, el Clínico transferirá los datos personales de usted a Akunah, incluidas las tomografías computarizadas (TC) de su cuerpo y otros datos médicos.",
  "Akunah_softawre": "El software y los servicios de Akunah pueden implicar la evaluación de imágenes, la evaluación de datos médicos, la creación de modelos 3D personalizados de su anatomía junto con medidas anatómicas clave (especificas), planificación quirúrgica y el apoyo para la cirugía, las cuales se compartirán con el Clínico.",
  "the_clinic": "El Clinico utilizará la evaluación y la planificación prequirúrgica para su tratamiento ortopédico.",
  "oportunnity": "Usted también tiene la oportunidad de proporcionar datos personales a través del portal de pacientes de Akunah y acceder a los modelos y planes de Akunah.",
  "akunah_approch": "Enfoque de Akunah sobre la privacidad y la seguridad",
  "akunah_approch1": "La privacidad y la seguridad de los datos que Akunah recopilará de usted para ayudar a su Clínico es lo más importante para nosotros.",
  "akunah_approch2": "Akunah cumple voluntariamente con las Leyes Europeas de Protección de Datos Aplicables, que incluyen el Reglamento General de Protección de Datos de la EU/2016/679, ya que esas leyes son las leyes de privacidad más estrictas promulgadas a nivel mundial. Estas exceden las leyes de privacidad promulgadas por Australia, los Estados Unidos y otros países.",
  "akunah_approch3": "El \"Código de privacidad para el procesamiento de información personal de clientes individuales\" de Akunah describe cómo Akunah cumple con las Leyes europeas de protección de datos aplicables (el ",
  "akunah_approch4": "\"Código de privacidad\"",
  "akunah_approch5": "El \"Acuerdo de procesamiento de datos\" de Akunah, describe los arreglos que tiene con sus Clínicos para proteger y asegurar tu información personal (el ",
  "akunah_approch6": "\"Acuerdo de procesamiento de datos\").",
  "akunah_approch7": "Esas políticas están disponibles en el sitio web de Akunah ",
  "terms_": "https://akunah.com/termsofservices.",
  "akunah_approch8": "En la medida en que exista alguna inconsistencia entre el Código de privacidad, el Acuerdo de procesamiento de datos y este documento, se aplicarán los términos del primer documento mencionado en la medida de cualquier inconsistencia con este documento.",
  "Data_Collection_and_Processing": "Colección y Procesamiento de Datos",
  "I_am_informed": "Akunah puede recopilar los datos de usted, incluyendo su información de contacto, autenticación, identificación, ocupación y datos demográficos. Akunah también puede recopilar datos médicos relacionados con su salud, como: ",
  "Assessment_and_evaluation": "•    Valoración y evaluación de datos, características físicas y otra información relevante para ser usados en los tratamientos y resultados propuestos;",
  "Previous_surgery": "• Cirugía previa, diagnóstico, escaneos radiológicos, modelos 3D, anatomía, medidas anatómicas; y",
  "Health_and_medical_history": "• Historial médico y de salud, incluyendo sus síntomas, medicamentos y cualquier diagnóstico y/o tratamiento previo que haya recibido. ",
  "I_have_been_assured": "Akunah siempre recopila la información de manera legal y justa, ya sea directamente de un paciente, compartida por un Clínico, o a través de los productos de Akunah. Akunah tratará estos datos en cumplimiento de su consentimiento expresado a continuación, para permitir la prestación del servicio de evaluación y planificación prequirúrgica en caso de ser necesario.",
  "My_data": "Además, Akunah puede usar los datos personales de usted para cumplir con todas las leyes y regulaciones aplicables.",
  "your_data": "Sus datos también podrán ser comunicados a terceros, si así lo exige la prestación de los servicios propuestos o la ley.",
  "Data_Access_and_Storage": "Acceso, Almacenamiento y Seguridad de Datos",
  "Personal_data_provided": "Los datos proporcionados por usted y su Clínico solo serán accesibles para el personal de Akunah y terceros que contribuyan a su tratamiento o recuperación (por ejemplo, anestesista, fisioterapeuta) y necesiten tener acceso a sus datos.",
  "data1": "Los datos ingresados a través del software de Akunah están alojados de forma segura en el Mancomunidad de Australia por un proveedor de alojamiento aprobado. Esto garantiza un nivel adecuado de confidencialidad y seguridad que refleja la naturaleza de sus datos procesados a través del software Akunah.",
  "data2": "Si se encuentra fuera de la Mancomunidad de Australia y/o las subsidiarias de Akunah ubicadas fuera de la Mancomunidad de Australia, incluso en la Unión Europea o los Estados Unidos, pueden acceder a los datos ingresados ​​a través del software de Akunah, es posible que se apliquen diferentes estándares de privacidad sobre cómo se usan y protegen sus datos en esos países. En este caso, sus datos personales serán almacenados en ese país.",
  "data3": "Akunah cuenta con salvaguardas apropiadas de acuerdo con todos los requisitos legales aplicables para garantizar que los datos se mantengan confidenciales y protegidos con un nivel adecuado de protección de datos con todos los terceros. Requerimos que todos aquellos con acceso a los datos adopten estándares que aseguren un nivel de protección de datos equivalente al adoptado por Akunah.",
  "data4": "Su Clínico comparte la responsabilidad de recopilar, acceder, analizar y compartir los datos de usted de acuerdo con los requisitos legales.",
  "p_data1": "Los datos personales de usted serán conservados por Akunah durante un período de tiempo que variará. El período de retención estará determinado por los siguientes criterios: (i) el propósito para el cual Akunah o el Clínico están utilizando tus datos personales (deberán conservar los datos solo durante el tiempo que sea necesario para ese fin); y (ii) leyes o regulaciones aplicables que pueden establecer un período mínimo durante el cual Akunah debe conservar tus datos personales.",
  "Deidentified_Data": "Datos anonimizados",
  "d_txt1": "Akunah podría anonimizar los datos de usted y luego usarlosde manera anónima, generalmente junto a datos anonimizados de otros pacientes, para fines de registro, investigación y desarrollo, análisis de datos, desarrollo de software/dispositivos y mejora de los productos y servicios de Akunah. Esto permite a Akunah mejorar continuamente sus productos y servicios en beneficio de pacientes como usted.",
  "Patient_Rights": "Sus Derechos",
  "I_understand_that": "Usted tiene la libertad de rechazardar el consentimiento de proporcionar sus datos personales a Akunah. En este caso, usted debe de informar al Clínico, ya que es posible que el no pueda brindar los servicios o tratamientos médicos sin usar el software y los servicios de Akunah.",
  "I_understand_that_1txt": "Usted tiene el derecho a retirar su consentimiento en cualquier momento, por cualquier medio y, donde aplique, tiene derecho a: (i) obtener información sobre el tratamiento de sus datos personales y acceder a dichos datos; (ii) solicitar la rectificación de los datos cuando estos sean inexactos; (iii) solicitar la restricción del procesamiento de sus datos personales, lo que incluye oponerse a cierto procesamiento de dichos datos y solicitar el retiro del consentimiento que proporcionó; (iv) solicitar la supresión de sus datos personales; (v) definir pautas relacionadas con el procesamiento de sus datos después de su muerte; y (vi) obtener una copia de sus datos personales tratados en las condiciones anteriores en un formato estructurado, de uso común y lectura mecánica. Puedes ejercer estos derechos solicitandolo directamente con el Clínico. ",
  "I_understand_that_2txt": "Sus derechos están sujetos a las excepciones y restricciones aplicables en la ley.",
  "I_understand_that_3txt": "Después del retiro, es posible que su Clínico no pueda brindarle servicios o tratamientos médicos, si ejerce esos derechos antes del procedimiento propuesto.",
  "I_understand_that_4txt": "También usted es libre de presentar una queja sobre el procesamiento de sus datos personales ante la autoridad supervisora de su país de residencia.",
  "I_understand_that_5txt": "Con respecto a los datos anonimizados, estos no están sujetos a las mismas restricciones legales que los datos personales. Tenga en cuenta que no se puede retirar el consentimiento para el acceso y el uso de sus datos no anonimizados ni solicitar el borrado de estos datos, porque Akunah no podrá identificarlos. Después de la anonimización, Akunah no puede rastrear los datos hasta usted.",
  "Contact": "Consentimiento",
  "I_am_able_to_contact": "He leído y entendido la Información presentada en la sección anterior, o alguien me la ha leído en un idioma que entiendo.",
  "or_by_post": "He tenido la oportunidad de hacer preguntas y estoy satisfecho con las respuestas que he recibido. Entiendo que mi información permanecerá confidencial.",
  "or_by_post2": "Le doy permiso a mi doctor",
  "or_by_post2_1": "(el “Clinico”) para compartir mis datos personales con Akunah.",
  "or_by_post3": "Doy permiso para que Akunah recopile, almacene, use y comparta mis datos personales y anónimos como se describe en la sección de información anterior.",
  "or_by_post4": "Comprendo que soy libre de retirar mi consentimiento en cualquier momento.",
  "or_by_post5": "Entiendo que se me dará una copia firmada de este documento para que lo conserve.",
  "or_by_post6": "Puedo ponerme en contacto con Akunah con respecto al procesamiento de mis datos por correo electrónico a",
  "info_akunah": "info@akunah.com",
  "or_by_post7": "o por correo a, Suite 307 Nicholson Street Specialist Centre, Level 9, 121 Newdegate Street, Greenslopes, QLD, 4120, Australia.",
  "Clear": "Borrar",
  "Date": "Fecha",
  "Signed_by_Mouse": "Firmar con Mouse*",
  "Name_if_consent_on_behalf_of_patient": "Nombre (si el consentimiento es firmado en nombre del paciente)",
  "Relationship_to_patient_if_applicable": "Relación con el paciente si corresponde",
  "Choose_Sign_Type": "Elija el tipo de firma*",
  "Draw": "Dibujar",
  "Signed_by_Keyboard": "Firmado por teclado *",
  "Agreement": "Acuerdo",
  "agree_that_I_have_read": "Acepto que he leído y entendido este formulario de consentimiento de paciente y doy mi consentimiento explícito para el procesamiento de mis datos personales para los fines descritos anteriormente.",
  "NOTE": "NOTA",
  "If_the_patient": "Si el paciente es menor de 18 años, este debe ser firmado por su padre o tutor legal.",
  "If_the_patient_is_deceased": "Si el paciente ha fallecido, este debe ser firmado por sus familiares más cercanos.",
  "patient_signature": "Firma del paciente",
  "profile_updated": "Perfil actualizado",
  "profile_not_updated": "Perfil no actualizado",
  "video": "Video",
  "radiology_form": "Formulario de radiología",
  "doc_document": "Documento",
  "pathology_form": "Formulario de patología",
  "text": "Texto",
  "Document_Name": "Nombre del documento",
  "email_sent_successfully": "Email enviado exitosamente",
  "messages": "Mensajes",
  "adult_knee": "Rodilla de adulto",
  "paediatric_knee": "Rodilla pediátrica",
  "document": "Mis documentos",
  "Check_in": "Registrar",
  "info_icon": "Completar Cuestionario de paciente",
  "reminder": "Notas",
  "Search_with_minimum_three_characters": "Buscar con un mínimo de tres carácteres",
  "Select": "Elegir",
  "delete_account": "Eliminar cuenta",
  "DD_MM_YYYY": "DD / MM / AAAA",
  "Female": "Femenino",
  "Male": "Masculino",
  "want_to_disclose": "No quiero revelar",
  "enter_address": "Ingresar dirección",
  "logout": "Salir",
  "full_name": "Nombre completo",
  "mmyy": "MM / AA",
  "health_found": "Fondo de salud",
  "Not_Applicable": "No aplica",
  "not_available": "No disponible",
  "available": "Disponible",
  "shared": "Compartido",
  "private": "Privado",
  "add_document": "Agregar documento",
  "drap_and_drop": "Arrastrar archivos aquí",
  "or_browser_files": "o buscar archivos en la computadora",
  "browse_files": "Buscar archivos",
  "download": "Descargar",
  "Oops_error": "Oops! Los datos solicitados no fueron encontrados",
  "go_to_home": "Volver al inicio",
  "This_field_is_required": "Este campo es obligatorio",
  "PendingForm": "Formulario pendiente",
  "NoFollowUpRecords": "Sin registros de seguimiento",
  "Right": "Derecha",
  "Both": "Ambos",
  "Left": "Izquierda",
  "shoulder": "Hombro",
  "hip_form": "Cadera",
  "Foot_And_Ankle": "Pie y tobillo",
  "Hand_And_Wrist": "Mano y muñeca",
  "General": "General",
  "adolescent_knee": "Rodilla adolescente",
  "Elbow": "Codo",
  "title": "Título",
  "description": "Descripción",
  "enter_your_description_here": "Ingrese su Descripción aquí",
  "enter_your_title_here": "Ingrese su título aquí",
  "progress": "Pendiente",
  "done": "Completado",
  "reset": "Restablecer",
  "send": "Enviar",
  "Edit": "Editar",
  "delete": "Borrar",
  "Are_you_sure_want_to_done_the_remainder": "¿Está seguro de completar esta nota?",
  "Confirm": "Confirmar",
  "Update": "Actualizar",
  "success": "Éxito",
  "error": "Error",
  "Are_you_sure_you_want_to_delete": "¿Está seguro de que desea eliminar",
  "cancel": "Cancelar",
  "Hour_Time": "Sistema horario de 24 horas",
  "Knee": "Rodilla",
  "no": "No ",
  "yes": "Si",
  "Year": "0 años, 0 meses, 0 días",
  "Bilateral": "Ambidiestro",
  "Left_Arm": "Hombro izquierdo",
  "Right_Arm": "Hombro derecho",
  "out_of": "de",
  "Right_Elbow": "Codo derecho",
  "Left_Elbow": "Codo izquierdo",
  "right_foot_ankle": "Pie/Tobillo derecho",
  "left_foot_ankle": "Pie/Tobillo derecho",
  "Right_hand_and_wrist": "Mano y muñeca derecha",
  "Left_hand_and_wrist": "Mano y muñeca derecha",
  "Hip_Function_R": "Cadera izquierda",
  "Hip_Function_L": "Cadera izquierda",
  "Right_Knee": "Rodilla derecha",
  "Left_Knee": "Rodilla izquierda",
  "Right_Pedi_Knee": "Rodilla derecha",
  "Left_Pedi_Knee": "Rodilla izquierda",
  "Scoring_Self_Evaluation": "Fecha de cita",
  "presciption_form": "Prescripción",
  "insurance_form": "Seguro",
  "letter_form": "Carta",
  "refferal_form": "Remisión",
  "medical_form": "Médica",
  "are_you_sure_delete_account": "¿Está seguro que desea eliminar su cuenta?",
  "Adult_Knee_Pain": "Dolor de rodilla adulta",
  "Knee_pain_13_18_year": "Dolor de rodilla",
  "day_pdata": "Dia",
  "days_pdata": "Días",
  "month_pdata": "Mes",
  "months_pdata": "Meses",
  "year_pdata": "Año",
  "years_pdata": "Años",
  "Name": "Nombre",
  "akunah_id": "Akunah ID",
  "mobile": "Teléfono",
  "email": "Correo electrónico",
  "upload_a_profile": "Añadir imagen de perfil",
  "No_special_characters_are_allowed": "No usar caracteres especiales",
  "numbers_are_not_allowed": "No está permitido usar números",
  "maximum_50_character_are_allowed": "Máximo 50 caracteres permitidos",
  "invalid_character": "Caracteres no válidos",
  "fields_only_contain_10_character": "El campo contiene solo 10 caracteres",
  "maximum_10_Numbers_are_allowed": "Máximo 10 números permitidos",
  "Only_I": "Solo está permitido usar números",
  "invalid_home_phone_number": "Número telefónico no válido",
  "special_characters_are_not_allowed": "Caracteres especiales no están permitidos",
  "maximum_digit_are_allowed": "Máximo 1 dígito permitido",
  "invalid_date": "Fecha no válida",
  "maximum_10_digits_are_allowed": "Máximos 10 dígitos permitidos",
  "switch_profile": "Cambiar perfil",
  "loading": "Cargando",
  "error_max_profile_limit": "Has alcanzado el maximo de cuentas disponibles para este email",
  "error_upcoming_appointments": "\nNo se encontraron citas próximas",
  "error_past_visits": "No se encontraron citas anteriores",
  "error_documents": "Documentos no encontrados",
  "country_hawaii": "(UTC-10:00) Hawaii Time",
  "country_tahiti": "(UTC-10:00) Tahiti",
  "country_pitcairn": "(UTC-08:00) Pitcairn",
  "country_niue": "(UTC-11:00) Niue",
  "country_mountain": "(UTC-07:00) Mountain Time",
  "country_arizona": "(UTC-07:00) Mountain Time - Arizona",
  "country_whitehorse": "(UTC-08:00) Pacific Time - Whitehorse",
  "country_chihuahua": "(UTC-07:00) Mountain Time - Chihuahua, Mazatlan",
  "country_edmonton": "(UTC-07:00) Mountain Time - Edmonton",
  "country_gambier": "(UTC-09:00) Gambier",
  "country_yellowknife": "(UTC-07:00) Mountain Time - Yellowknife",
  "country_dawson": "(UTC-07:00) Mountain Time - Dawson Creek",
  "country_belize": "(UTC-06:00) Belize",
  "country_hermosillo": "(UTC-07:00) Mountain Time - Hermosillo",
  "country_mexico": "(UTC-06:00) Central Time - Mexico City",
  "country_regina": "(UTC-06:00) Central Time - Regina",
  "country_tegucigalpa": "(UTC-06:00) Central Time - Tegucigalpa",
  "country_centraltime": "(UTC-06:00) Central Time",
  "country_rarotonga": "(UTC-10:00) Rarotonga",
  "country_pago": "(UTC-11:00) Pago Pago",
  "country_pacific": "(UTC-08:00) Pacific Time",
  "country_alaska": "(UTC-09:00) Alaska Time",
  "country_vancouver": "(UTC-08:00) Pacific Time - Vancouver",
  "country_tijuana": "(UTC-08:00) Pacific Time - Tijuana",
  "country_salvador": "(UTC-06:00) El Salvador",
  "country_costa": "(UTC-06:00) Costa Rica",
  "country_winnipeg": "(UTC-06:00) Central Time - Winnipeg",
  "country_galapagos": "(UTC-06:00) Galapagos",
  "country_managua": "(UTC-06:00) Managua",
  "country_america_cancun": "(UTC-05:00) America Cancun",
  "country_guatemala": "(UTC-06:00) Guatemala",
  "country_bogota": "(UTC-05:00) Bogota",
  "country_easter_island": "(UTC-05:00) Easter Island",
  "country_iqaluit": "(UTC-05:00) Eastern Time - Iqaluit",
  "country_toronto": "(UTC-05:00) Eastern Time - Toronto",
  "country_eastern_time": "(UTC-05:00) Eastern Time",
  "country_havana": "(UTC-05:00) Havana",
  "country_lima": "(UTC-05:00) Lima",
  "country_jamaica": "(UTC-05:00) Jamaica",
  "country_panama": "(UTC-05:00) Panama",
  "country_port_au_prince": "(UTC-05:00) Port-au-Prince",
  "country_nassau": "(UTC-05:00) Nassau",
  "country_rio_branco": "(UTC-05:00) Rio Branco",
  "country_barbados": "(UTC-04:00) Barbados",
  "country_halifax": "(UTC-04:00) Atlantic Time - Halifax",
  "country_bermuda": "(UTC-04:00) Bermuda",
  "country_boa_vista": "(UTC-04:00) Boa Vista",
  "country_curacao": "(UTC-04:00) Curacao",
  "country_marquesas": "(UTC-09:30) Marquesas",
  "country_caracas": "(UTC-04:00) Caracas",
  "country_grand_turk": "(UTC-04:00) Grand Turk",
  "country_la_paz": "(UTC-04:00) La Paz",
  "timezone_guyana": "(UTC-04:00) Guyana",
  "timezone_port_of_spain": "(UTC-04:00) Port of Spain",
  "timezone_manaus": "(UTC-04:00) Manaus",
  "timezone_martinique": "(UTC-04:00) Martinique",
  "timezone_porto_velho": "(UTC-04:00) Porto Velho",
  "timezone_santo_domingo": "(UTC-04:00) Santo Domingo",
  "timezone_puerto_rico": "(UTC-04:00) Puerto Rico",
  "timezone_araguaina": "(UTC-03:00) Araguaina",
  "timezone_belem": "(UTC-03:00) Belem",
  "timezone_buenos_aires": "(UTC-03:00) Buenos Aires",
  "timezone_newfound_time": "(UTC-03:30) Newfoundland Time - St. Johns",
  "timezone_asuncion": "(UTC-03:00) Asuncion",
  "timezone_campo": "(UTC-03:00) Campo Grande",
  "timezone_cayenne": "(UTC-03:00) Cayenne",
  "timezone_cuiaba": "(UTC-03:00) Cuiaba",
  "timezone_maceio": "(UTC-03:00) Maceio",
  "timezone_godthab": "(UTC-03:00) Godthab",
  "timezone_fortaleza": "(UTC-03:00) Fortaleza",
  "timezone_miquelon": "(UTC-03:00) Miquelon",
  "timezone_palmer": "(UTC-03:00) Palmer",
  "timezone_montevideo": "(UTC-03:00) Montevideo",
  "timezone_recife": "(UTC-03:00) Recife",
  "timezone_rothera": "(UTC-03:00) Rothera",
  "timezone_paramaribo": "(UTC-03:00) Paramaribo",
  "timezone_punta_arenas": "(UTC-03:00) Punta Arenas",
  "timezone_santig": "(UTC-03:00) Santiago",
  "timezone_salvador": "(UTC-03:00) Salvador",
  "timezone_stanley": "(UTC-03:00) Stanley",
  "timezone_south_georgia": "(UTC-02:00) South Georgia",
  "timezone_noronha": "(UTC-02:00) Noronha",
  "timezone_sao_pulo": "(UTC-02:00) Sao Paulo",
  "timezone_azores": "(UTC-01:00) Azores",
  "timezone_abidjan": "(UTC+00:00) Abidjan",
  "timezone_cape_verde": "(UTC-01:00) Cape Verde",
  "timezone_scoresbysund": "(UTC-01:00) Scoresbysund",
  "timezone_bissau": "(UTC+00:00) Bissau",
  "timezone_accra": "(UTC+00:00) Accra",
  "timezone_danmarkshavn": "(UTC+00:00) Danmarkshavn",
  "timezone_casablanca": "(UTC+00:00) Casablanca",
  "timezone_dublin": "(UTC+00:00) Dublin",
  "timezone_canary_islands": "(UTC+00:00) Canary Islands",
  "timezone_el_aaiun": "(UTC+00:00) El Aaiun",
  "timezone_thule": "(UTC-04:00) Thule",
  "timezone_gmt": "(UTC+00:00) GMT (no daylight saving)",
  "timezone_lisbon": "(UTC+00:00) Lisbon",
  "timezone_faeroe": "(UTC+00:00) Faeroe",
  "timezone_reykjavik": "(UTC+00:00) Reykjavik",
  "timezone_monrovia": "(UTC+00:00) Monrovia",
  "timezone_algiers": "(UTC+01:00) Algiers",
  "timezone_london": "(UTC+00:00) London",
  "timezone_amsterdam": "(UTC+01:00) Amsterdam",
  "timezone_andorra": "(UTC+01:00) Andorra",
  "timezone_berlin": "(UTC+01:00) Berlin",
  "timezone_budapest": "(UTC+01:00) Budapest",
  "timezone_ceuta": "(UTC+01:00) Ceuta",
  "timezone_brussels": "(UTC+01:00) Brussels",
  "timezone_copenhagen": "(UTC+01:00) Copenhagen",
  "timezone_gibrltar": "(UTC+01:00) Gibraltar",
  "timezone_prague": "(UTC+01:00) Central European Time - Prague",
  "timezone_lagos": "(UTC+01:00) Lagos",
  "timezone_luxembourg": "(UTC+01:00) Luxembourg",
  "timezone_madrid": "(UTC+01:00) Madrid",
  "timezone_malta": "(UTC+01:00) Malta",
  "timezone_ndjamena": "(UTC+01:00) Ndjamena",
  "timezone_paris": "(UTC+01:00) Paris",
  "timezone_oslo": "(UTC+01:00) Oslo",
  "timezone_monaco": "(UTC+01:00) Monaco",
  "timezone_tirane": "(UTC+01:00) Tirane",
  "timezone_rome": "(UTC+01:00) Rome",
  "timezone_vienna": "(UTC+01:00) Vienna",
  "timezone_tunis": "(UTC+01:00) Tunis",
  "timezone_warsaw": "(UTC+01:00) Warsaw",
  "timezone_amman": "(UTC+02:00) Amman",
  "timezone_zurich": "(UTC+01:00) Zurich",
  "timezone_athens": "(UTC+02:00) Athens",
  "timezone_burcharest": "(UTC+02:00) Bucharest",
  "timezone_chisinau": "(UTC+02:00) Chisinau",
  "timezone_stockholm": "(UTC+01:00) Stockholm",
  "timezone_cairo": "(UTC+02:00) Cairo",
  "timezone_gaza": "(UTC+02:00) Gaza",
  "timezone_jerusalem": "(UTC+02:00) Jerusalem",
  "timezone_johannesburg": "(UTC+02:00) Johannesburg",
  "timezone_helsinki": "(UTC+02:00) Helsinki",
  "timezone_khartoum": "(UTC+02:00) Khartoum",
  "timezone_belgrade": "(UTC+01:00) Central European Time - Belgrade",
  "timezone_damascus": "(UTC+02:00) Damascus",
  "timezone_maputo": "(UTC+02:00) Maputo",
  "timezone_kaliningrad": "(UTC+02:00) Moscow-01 - Kaliningrad",
  "timezone_riga": "(UTC+02:00) Riga",
  "timezone_kiev": "(UTC+02:00) Kiev",
  "timezone_nicosia": "(UTC+02:00) Nicosia",
  "timezone_tallinn": "(UTC+02:00) Tallinn",
  "timezone_tripoli": "(UTC+02:00) Tripoli",
  "timezone_sofia": "(UTC+02:00) Sofia",
  "timezone_vilnius": "(UTC+02:00) Vilnius",
  "timezone_istanbul": "(UTC+03:00) Istanbul",
  "timezone_baghdad": "(UTC+03:00) Baghdad",
  "timezone_minsk": "(UTC+03:00) Minsk",
  "timezone_windhoek": "(UTC+02:00) Windhoek",
  "timezone_nairobi": "(UTC+03:00) Nairobi",
  "timezone_moscow": "(UTC+03:00) Moscow+00 - Moscow",
  "timezone_qatar": "(UTC+03:00) Qatar",
  "timezone_beirut": "(UTC+02:00) Beirut",
  "timezone_riyadh": "(UTC+03:00) Riyadh",
  "timezone_syowa": "(UTC+03:00) Syowa",
  "timezone_tehran": "(UTC+03:30) Tehran",
  "timezone_baku": "(UTC+04:00) Baku",
  "timezone_dubai": "(UTC+04:00) Dubai",
  "timezone_mahe": "(UTC+04:00) Mahe",
  "timezone_mauritius": "(UTC+04:00) Mauritius",
  "timezone_tbilisi": "(UTC+04:00) Tbilisi",
  "timezone_samara": "(UTC+04:00) Moscow+01 - Samara",
  "timezone_reunion": "(UTC+04:00) Reunion",
  "timezone_yerevan": "(UTC+04:00) Yerevan",
  "timezone_kabul": "(UTC+04:30) Kabul",
  "timezone_aqtau": "(UTC+05:00) Aqtau",
  "timezone_aqtobe": "(UTC+05:00) Aqtobe",
  "timezone_ashgabat": "(UTC+05:00) Ashgabat",
  "timezone_dushanbe": "(UTC+05:00) Dushanbe",
  "timezone_maldives": "(UTC+05:00) Maldives",
  "timezone_yekaterinburg": "(UTC+05:00) Moscow+02 - Yekaterinburg",
  "timezone_kerguelen": "(UTC+05:00) Kerguelen",
  "timezone_mawson": "(UTC+05:00) Mawson",
  "timezone_tashkent": "(UTC+05:00) Tashkent",
  "timezone_colombo": "(UTC+05:30) Colombo",
  "timezone_kathmandu": "(UTC+05:45) Kathmandu",
  "timezone_omsk": "(UTC+06:00) Moscow+03 - Omsk",
  "timezone_india_standard_time": "(UTC+05:30) India Standard Time",
  "timezone_chagos": "(UTC+06:00) Chagos",
  "timezone_dhaka": "(UTC+06:00) Dhaka",
  "timezone_bishkek": "(UTC+06:00) Bishkek",
  "timezone_cocos": "(UTC+06:30) Cocos",
  "timezone_thimphu": "(UTC+06:00) Thimphu",
  "timezone_almaty": "(UTC+06:00) Almaty",
  "timezone_vostok": "(UTC+06:00) Vostok",
  "timezone_rangoon": "(UTC+06:30) Rangoon",
  "timezone_christmas": "(UTC+07:00) Christmas",
  "timezone_davis": "(UTC+07:00) Davis",
  "timezone_karachi": "(UTC+05:00) Karachi",
  "timezone_hanoi": "(UTC+07:00) Hanoi",
  "timezone_beijing": "(UTC+08:00) China Time - Beijing",
  "timezone_hongkong": "(UTC+08:00) Hong Kong",
  "timezone_hovd": "(UTC+07:00) Hovd",
  "timezone_jakarta": "(UTC+07:00) Jakarta",
  "timezone_kuala_lumpur": "(UTC+08:00) Kuala Lumpur",
  "timezone_bangkok": "(UTC+07:00) Bangkok",
  "timezone_krasnoyarsk": "(UTC+07:00) Moscow+04 - Krasnoyarsk",
  "timezone_brunei": "(UTC+08:00) Brunei",
  "timezone_macau": "(UTC+08:00) Macau",
  "timezone_makassar": "(UTC+08:00) Makassar",
  "timezone_choibalsan": "(UTC+08:00) Choibalsan",
  "timezone_ulaanbaatar": "(UTC+08:00) Ulaanbaatar",
  "timezone_manila": "(UTC+08:00) Manila",
  "timezone_pyongyang": "(UTC+08:30) Pyongyang",
  "timezone_singapore": "(UTC+08:00) Singapore",
  "timezone_irkutsk": "(UTC+08:00) Moscow+05 - Irkutsk",
  "timezone_taipei": "(UTC+08:00) Taipei",
  "timezone_jayapura": "(UTC+09:00) Jayapura",
  "timezone_dili": "(UTC+09:00) Dili",
  "timezone_perth": "(UTC+08:00) Western Time - Perth",
  "timezone_yakutsk": "(UTC+09:00) Moscow+06 - Yakutsk",
  "timezone_palau": "(UTC+09:00) Palau",
  "timezone_seoul": "(UTC+09:00) Seoul",
  "timezone_tokyo": "(UTC+09:00) Tokyo",
  "timezone_darwin": "(UTC+09:30) Central Time - Darwin",
  "timezone_dumont": "(UTC+10:00) Dumont D'Urville",
  "timezone_brisbane": "(UTC+10:00) Eastern Time - Brisbane",
  "timezone_guam": "(UTC+10:00) Guam",
  "timezone_vladivostok": "(UTC+10:00) Moscow+07 - Vladivostok",
  "timezone_port_moresby": "(UTC+10:00) Port Moresby",
  "timezone_truk": "(UTC+10:00) Truk",
  "timezone_adelaide": "(UTC+10:30) Central Time - Adelaide",
  "timezone_casey": "(UTC+11:00) Eastern Time - Hobart",
  "timezone_melbourne_sydney": "(UTC+11:00) Eastern Time - Melbourne, Sydney",
  "timezone_kosrae": "(UTC+11:00) Kosrae",
  "timezone_efate": "(UTC+11:00) Efate",
  "timezone_guadalcanal": "(UTC+11:00) Guadalcanal",
  "timezone_ponape": "(UTC+11:00) Ponape",
  "timezone_norfolk": "(UTC+11:00) Norfolk",
  "timezone_magadan": "(UTC+11:00) Moscow+08 - Magadan",
  "timezone_noumea": "(UTC+11:00) Noumea",
  "timezone_majuro": "(UTC+12:00) Majuro",
  "timezone_funafuti": "(UTC+12:00) Funafuti",
  "timezone_nauru": "(UTC+12:00) Nauru",
  "timezone_tarawa": "(UTC+12:00) Tarawa",
  "timezone_kwajalein": "(UTC+12:00) Kwajalein",
  "timezone_enderbury": "(UTC+13:00) Enderbury",
  "timezone_wake": "(UTC+12:00) Wake",
  "timezone_fakaofo": "(UTC+13:00) Fakaofo",
  "timezone_wallis": "(UTC+12:00) Wallis",
  "timezone_fiji": "(UTC+13:00) Fiji",
  "timezone_apia": "(UTC+14:00) Apia",
  "timezone_kiritimati": "(UTC+14:00) Kiritimati",
  "timezone_tongatapu": "(UTC+13:00) Tongatapu",
  "timezone_auckland": "(UTC+13:00) Auckland",
  "dateFormet": "Formato de fecha",
  "Org_success": "La solicitud de organización ha sido enviada",
  "Org_err": "Ya te has unido a la organización",
  "Request_Organization": "Solicitar organización",
  "organization_requested": "Organización solicitada",
  "join": "Unirse",
  "your_email": "email@address.com",
  "medical_summary": "No has completado el resumen médico",
  "medical_summary_msg": "Mi resumen médico",
  "medical_success": "Resumen médico enviado exitosamente",
  "patient_form_popup": "Desea continuar con el cuestionario que estaba completando anteriormente?",
  "Please_confirm_that_your_details_are_correct": "Por favor confirmar que los datos son correctos",
  "Welcome_to_Akunah_your_Health_Passport": "Bienvenido a Akunah, tu pasaporte sanitario.",
  "Your_helth_is_in_your_hands": "Tu salud está en tus manos",
  "Lets_begin_your_journey": "Comenzemos con tu viaje",
  "Please_complete_your_profile": "Por favor completar tu perfil",
  "Profile_complete": "Perfil completo!",
  "Would_you_like_to_add_another_profile_for_a_family_member": "Deseas añadir otro perfil para un miembro de la familia?",
  "Alert_detected": "Alerta: Multiples ingresos detectados",
  "To_safeguard_your_data": "Para proteger la privacidad y seguridad de tus datos, , la página web ha expirado",
  "Kindly_close_all_other_Akunah": "Por favor cerrar todas las pestañas de Akunah y volver al inicio para poder continuar",
  "Akunah_Team_is_dedicated": "El equipo Akunah está dedicado a proteger tus datos",
  "Last_Created": "Ultimo creado",
  "First_Created": "Primero creado",
  "Alphabetically": "Alfabético (A-Z)",
  "Alphabetically1": "Alfabético (Z-A)",
  "Your_form_submission_was_successful_Clinician": "Su formulario ha sido enviado de manera exitosa. Estamos redireccionando automaticamente a la app clínica",
  "Your_form_submission_was_successful_Patient": "Su formulario ha sido enviado de manera exitosa. Estamos redireccionando automaticamente a la app del paciente",
  "If_this_doesnt_happen_within": "Si la pagina no es actualizada en 5 segundos, por favor",
  "click_here": "Click acá",
  "Save": "Guardar",
  "Proceed_to_my_dashboard": "Ir a mi tablero",
  "myJourney": "Mi viaje",
  "Home": "Inicio",
  "No_journey": "Viajes no encontrados",
  "Share_results_with_your_doctor": "Compartir resultados con tu doctor",
  "If_you_would_like_to_share_your_results_with": "Si desea compartir sus resultados con su doctor, elegir compartir AHORA abajo.",
  "Link_PROMs": "Link PROMs",
  "Welcome_to_akunah": "Bienvenido a Akunah",
  "Share_to_your_Doctor_using_one_of_the_3_options_below": "Compartir con su doctor usando una de las siguientes opciones",
  "Doctor_Code": "Código del doctor",
  "Enter_the_code_that_has_been_provided_to_you_by_your": "Ingresar el código que se le ha entregado por su doctor por email en la clinica. Si el código no esta disponible, por favor revisar sus visitas recientes o buscar a su doctor",
  "Recent_Visits": "Visitas recientes",
  "Select_your_recent_doctor_or": "Seleccionar a su doctor reciente u",
  "organisation_here": "organización aquí",
  "Search": "Buscar",
  "Search_for_your_doctor_or": "Buscar a su doctor o",
  "Please_confirm_that_the_following_details": "Por favor confirmar que los siguientes detalles son correctos",
  "Organisation": "Organización",
  "PROMs_Specialty_Date": "PROMs Especialidad y Fecha",
  "Get_started_by_completing_your": "Iniciar completando su questionario de PROMs",
  "and_exploring_your_progress": "y explorando su progreso",
  "Fill_up_your_PROMs_questionnaire": "Completar su cuestionario the PROMs",
  "Hip": "Cadera",
  "My_Progress": "Mi progreso",
  "Completed": "Completado",
  "Expired": "Expirado",
  "Incomplete": "Incompleto",
  "Specialty": "Especialidad",
  "Doctor": "Doctor",
  "Status": "Estatus",
  "View_form": "Ver formulario",
  "Share_to_another_doctor": "Compartir con otro doctor",
  "Edit_form": "Editar formulario",
  "Answer_the_form": "Responder formulario",
  "Complete_the_form": "Completar formulario",
  "Delete_the_form": "Borrar formulario",
  "Share_to_doctor": "Compartir con doctor",
  "Your_account_has_been_created": "Tu cuenta ha sido creada",
  "Please_click_below_to_proceed_to_your_dashboard": "Por favor haz click debajo para ir al tablero ",
  "If_you_would_like_to_add_another_profile_for_a_family": "Si le desea añadir otro perfil para un miembro de su familia por favor",
  "Share": "compartir",
  "Hip_Scoring_Self_Evaluation_Questionnaire": "Puntuación del cuestionario de autoevaluación de cadera",
  "Hand_and_Wrist_Scoring_Self_Evaluation_Questionnaire": "Puntuación del cuestionario de autoevaluación de mano y muñeca",
  "Right_Hand_nd_Wrist": "Mano y muñeca derecha",
  "Left_Hand_nd_Wrist": "Mano y muñeca izquierda",
  "DOB_1": "Fecha de nacimiento",
  "Sex_1": "Sexo",
  "Address_1": "Dirección",
  "Please_Choose_PROMs_to_link": "Por favor elegir PROMs para enlazar",
  "Please_select_atleast_one_form_or_close": "Por favor elegir al menos un formulario o cerrar",
  "cant_find_appropriate_PROMs_to_link": "No encuentras el PROMs apropiado para enlazar?",
  "Request_New_PROMs": "Solicitar nuevo PROMs",
  "Warning_This_patient_has_an_incomplete_patient_PROMs_from_a_recent_visit": "Alerta: Tienes cuestionarios incompletos de una visita reciente",
  "If_you_select_No_a_new_patient_PROMs_will_be_created_and_the_incomplete_PROMs_will_be_deleted": "Si eliges \"No\", el cuestionario incompleto será eliminado y un nuevo cuestionario será creado.",
  "Would_you_like_to_continue_this_form": "Deseas continuar?",
  "Note": "Nota:",
  "Expiring_in": "Expira:",
  "Date_Created": "Fecha de creación",
  "Days": "Días",
  "Shared": "Compartido",
  "Pending": "Pendiente",
  "Initial": "Inicial",
  "Recent": "Reciente",
  "Pain": "Dolor",
  "Function": "Funcionamiento",
  "Share_Now": "Compartir ahora",
  "Share_Later": "Compartir más tarde",
  "Updated": "Actualizado",
  "Display_More": "Mostrar más",
  "Address_Now": "Responder ahora",
  "Address_Later": "Responder más tarde",
  "You_have_pending_questionnaires": "Tienes cuestionarios pendientes",
  "Notifications": "Notificaciones",
  "Form_shared_successfully": "Formulario compartido exitosamente",
  "You_have_already_shared_with_same_doctor_and_organization": "Ya has compartido con el mismo doctor y organización",
  "No_recent_notifications": "No hay notificaciones recientes",
  "Have_a_good_day": "Buen día",
  "Patient_scores": "Puntuación de pacientes",
  "Display less": "Mostrar menos",
  "myJourney_Shoulder": "Hombro",
  "myJourney_Shoulder_txt": "Escoge el hombro para visualizar el trayecto de tu hombro",
  "myJourney_Elbow": "Codo",
  "myJourney_Elbow_txt": "Escoge el codo para visualizar el trayecto de tu codo",
  "myJourney_Hand_Wrist": "Mano y muñeca",
  "myJourney_Hand_Wrist_txt": "Escoge la mano y muñeca para visualizar el trayecto de tu mano y muñeca",
  "myJourney_Hip": "Cadera",
  "myJourney_Hip_txt": "Escoge la cadera para visualizar el trayecto de tu cadera",
  "myJourney_knee": "Rodilla",
  "myJourney_knee_txt": "Escoge la rodilla para visualizar el trayecto de tu rodilla",
  "myJourney_Foot_Ankle": "Pie y tobillo",
  "myJourney_Foot_Ankle_txt": "Escoge el pie para visualizar el trayecto de tu pie",
  "data_sort": "Ordenar",
  "Visit_Clinic": "Visita Clinica",
  "My_Visits": "Mis visitas",
  "Patient_Scoring_Forms": "Formularios de puntaje de pacientes",
  "Other_findings": "Otros ",
  "Organization": "Organización",
  "more": "Más",
  "mediacal_summary": "Resumen médico",
  "my_consent": "Mis consentimientos",
  "Are_you_sure_you_want_to_reject": "Estás seguro/a de que quieres bloquear esta referencia?",
  "reject": "Rechazar",
  "i_accept_consent": "Doy mi consentimiento para esto",
  "grant": "Conceder",
  "no_conscent_found": "Consentimiento no encontrado",
  "rejected": "Rechazado",
  "No_Time_Lines": "Sin cronogramas",
  "Hide_Score": "Ocultar puntuación",
  "Show_Score": "Mostrar puntuación",
  "patient_sex": "Sexo",
  "Dominant_hand_1": "Mano dominante",
  "patient_Initial_Assessment": "Evaluación inicial",
  "patient_Treatment_Category": "Categoria del tratamiento",
  "View_all": "Ver todo",
  "Display_Less": "Mostrar menos",
  "patient_general_past_Medical_history": "Historial médico",
  "patient_general_past_Medication_name": "Lista de medicamentos",
  "Evaluated_Knee": "Rodilla evaluada",
  "Is_your_unaffected_Hand_Wrist_pain_free_and_allows_normal_function": "Su mano y muñeca no afectadas están libres de dolor y permiten un funcionamiento normal?",
  "Evaluated_Hip": "Cadera evaluada",
  "Evaluated_Foot_Ankle": "Pie y tobillo evaluados",
  "patient_Evaluated_Elbow": "Codo evaluado",
  "Is_your_unaffected_Foot_Ankle_pain_free_and_allows_normal_function": "Su pie y tobillo no afectados están libres de dolor y permiten un funcionamiento normal?",
  "Evaluated_Hand_Wrist": "Mano y muñeca evaluadas",
  "patient_shoulder_feeling_instability_q2": "Indique qué tan inestable es su hombro",
  "patient_shoulder_feeling_instability_q3": "Indique qué tan inestable es su hombro",
  "patient_Hand_BCTQ": "BCTQ de mano y muñeca",
  "Paediatric_Knee_Satisfaction": "Satisfacción de rodilla pediatrica",
  "patient_foot_ankle_symptoms": "Otros sintomas de pie y tobillo",
  "Right_Foot_and_Ankle": "Pie y tobillo derecho",
  "Left_Foot_and_Ankle": "Pie y tobillo izquierdo",
  "summary": "Resumen",
  "Shoulder_Treatment_Form": "Formulario de tratamiento de hombro",
  "Adolescent_knee_Treatment_Form": "Formulario the tratamiento de rodilla de adolescente",
  "Adult_Knee_Treatment_Form": "Formulario the tratamiento de rodilla de adulto",
  "Paediatric_Knee_Treatment_Form": "Formulario the tratamiento de rodilla pediatrica",
  "Hip_Treatment_Form": "Formulario de tratamiento de cadera",
  "Foot_Ankle_Treatment_Form": "Formulario de tratamiento de pie y tobillo",
  "Elbow_Treatment_Form": "Formulario de tratamiento de codo",
  "Hand_Wrist_Treatment_Form": "Formulario de tratamiento de mano y muñeca",
  "Upload_complete": "Carga completada",
  "Select_Date_Format": "Seleccionar formato de fecha",
  "timezone_casey1": "(UTC+11:00) Casey",
  "timezone_Kamchatskiy": "(UTC+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy",
  "timezone_Guayaquil": "(UTC-05:00) Guayaquil",
  "requested": "solicitó un",
  "Display_name": "Mostrar nombre",
  "Are_you_sure_you_want_to_delete_Consent": "Estas seguro(a) de rechazar este consentimiento?",
  "form": "formulario",
  "Requested_by": "Solicitado por",
  "requested_hip_form": "solicitó un formulario de cadera",
  "requested_shoulder_form": "solicitó un formulario de hombro",
  "requested_knee_form": "solicitó un formulario de rodilla",
  "requested_foot_form": "solicitó un formulario de pie y tobillo",
  "requested_hand_form": "solicitó un formulario de mano y muñeca",
  "requested_elbow_form": "solicitó un formulario de codo",
  "requested_General_form": "solicitó un formulario general",
  "requested_Paediatric_form": "solicitó un formulario de rodilla pediatrica",
  "requested_Adolescent_form": "solicitó un formulario de rodilla adolescente",
  "Expiring_in_Tomorrow": "Expira mañana",
  "PROMs_questionnaire": "El Dr. [nombre completo del doctor que lo solicitó] ha solicitado completar el cuestionario de PROMs de [especialidad]",
  "new_message": "Tienes un nuevo mensaje de [nombre completo del remitente del mensaje]",
  "new_appointment": "Tienes una nueva cita con el Dr. [nombre completo del doctor]",
  "upcoming_appointment": "Recordatorio: próxima cita con el Dr. [nombre completo] el [fecha]",
  "pending_message": "Recordatorio: tienes pendiente el cuestionario de PROMs de [especialidad]",
  "document_message": "El Dr. [nombre completo de quien compartió] compartió un documento contigo",
  "requested_message": "El Dr. [nombre completo de quien lo solicitó] ha solicitado tu consentimiento",
  "patient_added_message": "Has sido añadido como paciente del Dr. [nombre completo] en [organización]",
  "cancelled_appointment_message": "Tu cita con el Dr. [nombre completo] el [fecha de la cita] ha sido cancelada.",
  "Mark_all_read": "Marcar todo como leído",
  "Delete_All": "Eliminar todo",
  "No_Notification": "No se encontraron notificaciones",
  "You_have_not": "Aún no has comenzado tu viaje. Por favor, completa un cuestionario PROMs para comenzar tu viaje.",
  "pass_verification1": "La contraseña debe tener al menos 8 caracteres *",
  "pass_verification2": "La contraseña debe contener al menos 1 letra mayúscula *",
  "pass_verification3": "La contraseña debe contener al menos 1 letra minúscula *",
  "pass_verification4": "La contraseña debe contener al menos 1 dígito *",
  "loginTitle": "Akunah: Iniciar sesión ::",
  "Create_patient_mobile": "Create new profile",
  "Select_Profile_mobile": "Select your profile",
  "Profile_Created_mobile": "Profile Created",
  "Your_account_has_been_created_mobile": "Your account has been created.",
  "Please_tap_below_to_sign_in_to_your_workspace_mobile": "Please tap below to sign in to your workspace.",
  "Start_Journey_mobile": "Start Journey",
  "If_add_another_profile_mobile": "If you would like to add another profile for a family member, please",
  "Create_Profile_mobile": "Create Profile",
  "change_Profile_Pic_mobile": "Change profile photo",
  "MobileImp_mobile": "Mobile*",
  "Gender_mobile": "Gender",
  "Country_1_mobile": "Country",
  "Med_1_mobile": "Med",
  "Appoinments_mobile": "Appointments",
  "View_Profile_mobile": "View Profile",
  "Sign_out_mobile": "Sign out",
  "My_Appointments_mobile": "My Appointments",
  "Appoinments_Past_mobile": "Past",
  "No_appointments_mobile": "No appointments",
  "When_appointment_available_mobile": "When your next appointment is available, it will be displayed here",
  "Available_soon_mobile": "Available soon!",
  "please_akunah_desktop_mobile": "To access it now, please login tome.akunah.comon your desktop.",
  "All_Notes_mobile": "All Notes",
  "AddButton_mobile": "AddButton",
  "Start_your_note_mobile": "Start your note...",
  "Done_mobile": "Done",
  "Note_Title_mobile": "Note Title",
  "New_Consents_mobile": "New Consents",
  "Signed_Consents_mobile": "Signed Consents",
  "Consent_mobile": "Consent",
  "Signed_Type_mobile": "Signed by Type",
  "Submit_Consent_mobile": "Submit Consent",
  "Reject_Consent_mobile": "Reject Consent",
  "Type_name_mobile": "Type your name",
  "Signature_mobile": "Signature",
  "No_consents_mobile": "No consents",
  "Any_display_ready_mobile": "Any new or signed consents will display here when ready",
  "Clinician_Documents_mobile": "Clinician Documents",
  "Uploaded_Documents_mobile": "Uploaded Documents",
  "Upload_Document_mobile": "Upload Document",
  "Upload_file_mobile": "Upload file",
  "Upload_photo_mobile": "Upload photo",
  "Take_photo_mobile": "Take photo",
  "Uploading_1_mobile": "Uploading",
  "New_1_mobile": "New",
  "Share_document_mobile": "Share this document",
  "Enter_email_mobile": "Enter email",
  "Add_message_like_mobile": "Add a message, if you’d like.",
  "Are_delete_account_mobile": "Are you sure you want to delete your account?",
  "No_display_mobile": "No documents to display",
  "All_clinician_documents_mobile": "All clinician documents will display here",
  "New_Messages_mobile": "New Messages",
  "Read_Messages_mobile": "Read Messages",
  "Any_new_unread_messages_ready_mobile": "Any new or unread messages will be displayed here when ready",
  "Shoulder_Journey_mobile": "Shoulder Journey",
  "Hip_Journey_mobile": "Hip Journey",
  "Foot_Ankle_Journey_mobile": "Foot and Ankle Journey",
  "Elbow_Journey_mobile": "Elbow Journey",
  "Knee_Journey_mobile": "Knee Journey",
  "Hand_Wrists_Journey_mobile": "Hand and Wrist Journey",
  "New_Form_mobile": "New Form",
  "Start_new_form_mobile": "Start new form",
  "Requested_Forms_mobile": "Requested Forms",
  "Incomplete_Forms_mobile": "Incomplete Forms",
  "Completed_Forms_mobile": "Completed Forms",
  "No_Available_mobile": "No Forms Available",
  "Start_new_journey_mobile": "Start a new form to begin your journey.",
  "View_Results_mobile": "View Results",
  "Complete_Form_mobile": "Complete Form",
  "Delete_Form_mobile": "Delete Form",
  "Edit_Details_mobile": "Edit Details",
  "Personal_Details_mobile": "Personal Details",
  "DOB_1_mobile": "DOB",
  "Unknown_Gender_mobile": "Unknown Gender",
  "Mobile_mobile": "Mobile",
  "Next_kin’s_name_mobile": "Next of kin’s name",
  "Next_kin’s_relationship_mobile": "Next of kin’s relationship",
  "GP_Details_mobile": "GP Details",
  "GP_Name_mobile": "GP Name",
  "Practice_mobile": "Practice",
  "Edit_Profile_mobile": "Edit Profile",
  "Full_Name_mobile": "Full Name",
  "Country_Code_mobile": "Country Code",
  "GP_Full_Name_mobile": "GP Full Name",
  "Doctor’s_full_name_mobile": "Doctor’s full name",
  "All_mobile": "All",
  "No_notifications_mobile": "No recent notifications",
  "Any_new_available_mobile": "Any new or unread notifications will be displayed here when available",
  "Account_mobile": "Account",
  "Manage_My_Account_mobile": "Manage My Account",
  "Change_Password_mobile": "Change Password",
  "Privacy_mobile": "Privacy",
  "Time_mobile": "Time",
  "Date_Format_mobile": "Date & Format",
  "Support_mobile": "Support",
  "All_Notifications_mobile": "All Notifications",
  "Receive_notifications_mobile": "Receive all notifications",
  "Journey_Updates_mobile": "Journey Updates",
  "Receive_appointment_notifications_mobile": "Receive all appointment notifications",
  "Start_Form_mobile": "Start Form",
  "Your_journey_starts_here_mobile": "Your journey starts here",
  "To_recovery_questions_mobile": "To get started with your journey to recovery, we just ask you to please best answer the following questions.",
  "Please_estimate_questions_mobile": "Please estimate 12-15 minutes to answer these questions.",
  "Shoulder_Form_mobile": "Shoulder Form",
  "Save_and_Continue_mobile": "Save & Continue",
  "How_years_smoke_mobile": "How many years did you smoke?",
  "patient_shoulder_pain_inner_value_mobile": "(0 means no pain, 15 means intolerable pain)",
  "Scoring_Key_mobile": "Scoring Key",
  "patient_shoulder_wosi_1_mobile": "Western Ontario Shoulder Instability Index (WOSI)",
  "patient_shoulder_wosi_sectionA_phy_mobile": "PHYSICAL SYMPTOMS",
  "patient_shoulder_wosi_sectionB_sp_mobile": "SPORTS/RECREATION/WORK",
  "patient_shoulder_wosi_sectionC_life_mobile": "LIFESTYLE",
  "patient_shoulder_wosi_sectionD_emo_mobile": "EMOTIONS",
  "Specific_AC_Score_mobile": "Specific AC Score (SACS)",
  "patient_shoulder_sacs_sectionA_pain_mobile": "Pain",
  "patient_shoulder_sacs_sectionB_function_mobile": "FUNCTION",
  "patient_shoulder_sacs_sectionC_Qua_mobile": "Quality of life and satisfaction",
  "Finish_mobile": "Finish",
  "Share_Results_mobile": "Share Results",
  "Share_Doctor_mobile": "Share to your Doctor",
  "Choose_share_results_doctor_mobile": "Choose one of the 3 options below to share your results with your doctor",
  "Share_your_Clinician_mobile": "Share with your Clinician",
  "Select_recent_doctor_organisation_mobile": "Select your recent doctor or organisation here",
  "Clinician_Search_mobile": "Clinician Search",
  "Search_doctor_organisation_mobile": "Search for your doctor or organisation here",
  "Clinician_Code_mobile": "Clinician Code",
  "Enter_doctor_code_search_mobile": "Enter the code that has been provided to you by your doctor in your email or in the clinic. If the code is not available, please check you recent visits or use the search.",
  "Skip_sharing_now_mobile": "Skip sharing for now",
  "Code_please_try_again_mobile": "Code incorrect, please try again.",
  "Confirm_details_mobile": "Confirm details",
  "PROMs_Speciality_Date_mobile": "PROMs Speciality & Date",
  "Results_Shared_mobile": "Results Shared!",
  "Your_successfully_doctor_mobile": "Your results has been successfully shared with your doctor.",
  "Please_results_mobile": "Please tap below to view your results.",
  "If_another_doctor_mobile": "If you would like to share with another doctor",
  "please_mobile": "please",
  "click_here_mobile": "click here",
  "Pain_Score_mobile": "Pain Score",
  "Recent_Scores_mobile": "Recent Scores",
  "Right_Arm_mobile": "Right Arm",
  "SACS_Score_mobile": "SACS",
  "complete_mobile": "Complete",
  "Add_Note_mobile": "Add Note",
  "Pending_Forms_mobile": "Pending Forms",
  "Your_pending__will_display_mobile": "Your pending forms will be displayed here",
  "Your_Incomplete_display_mobile": "Your Incomplete Forms will be displayed here",
  "Your_complete_display_mobile": "Your complete forms will be displayed here",
  "PROMs_Shoulder_request_mobile": "PROMs Shoulder request",
  "New_message_mobile": "New message",
  "New_appointment_mobile": "New appointment",
  "New_Consent_request_mobile": "New Consent request",
  "New_shared_document_mobile": "New shared document",
  "New_Organisation_Clinician_mobile": "New Organisation/Clinician",
  "This_field_cannot_empty_mobile": "This field cannot be empty.",
  "List_work_activities_mobile": "List work activities ...",
  "List_hobby_activities_mobile": "List hobby and sport activities ...",
  "patient_shoulder_wosi_sectionA_mobile": "SECTION A:",
  "patient_shoulder_wosi_sectionB_mobile": "SECTION B:",
  "patient_shoulder_wosi_sectionC_mobile": "Section C:",
  "patient_shoulder_wosi_sectionD_mobile": "Section D:",
  "File_size_should_than_10MB_mobile": "File size should not more than 10MB",
  "Share_this_document_mobile": "Share this document",
  "sac_explaination": "Explanation",
  "patient_shoulder_sacs_explanation_mobile": "For each question, you will be asked to rate either your level of pain or difficulty performing different tasks. The rating scale is designed so that 0 reflects no pain or difficulty while 10 reflects the most severe amount of pain or difficulty.",
  "patient_shoulder_sacs_a3_note_mobile": "(e.g. Laptop or hand bag strap).",
  "patient_shoulder_sacs_b2_note_mobile": "(e.g. Resting your arm in a pocket or using a sling).",
  "patient_shoulder_sacs_b3_note_mobile": "(e.g. Push up, cycling, yoga).",
  "patient_shoulder_sacs_b4_note_mobile": "(e.g. Bag of shopping).",
  "patient_shoulder_sacs_b5_note_mobile": "(e.g. To reach across and touch the unaffected shoulder).",
  "patient_shoulder_sacs_b6_note_mobile": "(e.g. Greater than five kilograms).",
  "patient_shoulder_sacs_b7_note_mobile": "(e.g. Holding the steering wheel whilst driving).",
  "patient_shoulder_sacs_c1_note_mobile": "(Including the presence of a lump).",
  "Function_Score_mobile": "Function Score",
  "Instability_Score_mobile": "Instability Score",
  "patient_shoulder_pain_q2_mobile": "On a scale of 0-15, what is the highest level of pain that you have experienced in your shoulder during ordinary activities within the last 24 hours?",
  "patient_shoulder_pain_q6_mobile": "Do you take pain medication",
  "patient_shoulder_pain_q6_mobile_sub": "(eg paracetamol etc)?",
  "patient_shoulder_pain_q6_a2_q1_mobile": "Do you take strong pain medication",
  "patient_shoulder_pain_q6_a2_q1_mobile_sub": "(eg codeine, tramadol, morphine etc)?",
  "patient_shoulder_feeling_instability_q1_b1_mobile_sub": "(as if it is going to dislocate)?",
  "patient_shoulder_feeling_instability_q1_mobile": "Does your shoulder feel unstable",
  "patient_shoulder_wosi_instruction_mobile": "INSTRUCTIONS",
  "Next_of_kins_number_mobile": "Next of kin’s number",
  "Next_of_Kin_mobile": "Next of Kin",
  "Doctor_Name_mobile": "Doctor's name",
  "Doctors_practice_mobile": "Doctor’s practice",
  "Doctors_practice_address_mobile": "Doctor’s practice address",
  "Language_mobile": "Languages",
  "No_Internet_Connection_mobile": "No Internet Connection",
  "Please_type_your_name_mobile": "Please type your name",
  "Choose_Sign_Type_mobile": "Choose Sign Type",
  "Signed_by_Draw_mobile": "Signed by Draw",
  "select_image_mobile": "select image",
  "Consent_rejected_mobile": "Consent rejected",
  "Consent_submitted_mobile": "Consent submitted",
  "Today_mobile": "Today",
  "New_mobile": "New",
  "No_recent_scores_display_mobile": "No recent scores to display",
  "All_recent_scores_display_here_mobile": "All recent scores will display here",
  "Shared_Documents_mobile": "Shared Documents"
}