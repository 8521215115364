import React, { useState, useEffect } from 'react'
import { Modal } from "react-bootstrap";
import Checkbox from "../../custom/checkbox/Checkbox";
import useAxios from "../../../axiosinstance"
import AddModelTableSchedular from './AddModelTableSchedular';
import useTranslation from "../../customHooks/translations";
import Button from '../../custom/button/Button';
import Iicon from './Iicon.png'
import SubmitModel from "../SubmissionModel/SubmitModel"
import { useParams } from "react-router-dom";
import Message from "../../custom/toaster/Message"
import "./Promss.css"
import { useSelector, useDispatch } from 'react-redux';

export default function Promsschudle({ openModal, setDisplayConfirmationModal, type, patientuserID }) {

  const translation = useTranslation();
  const { rId } = useParams();
  let name = sessionStorage.getItem("name")
  let patient_akid = sessionStorage.getItem("patient_akid")
  const [patientID, setpatientID] = useState('');
  let userage = sessionStorage.getItem("age")
  let org_id = sessionStorage.getItem("orgid")
  const queryParams = new URLSearchParams(window.location.search);
  const dId = queryParams.get("doctor");
  const axiosInstance = useAxios();
  const akId = queryParams.get("akpt");
  const [showModal, setshowModal] = useState(false);
  const [assignErr, setassignErr] = useState(false)
  const [checkboxValues, setCheckboxValues] = useState("");
  const [schedulerForm2, setschedulerForm2] = useState([]);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState("");
  const [statusda, setStatusda] = useState(false)
  const [showModalImp, setShowModalImp] = useState(false)
  const [displayConfirmationModal2, setDisplayConfirmationModal2] =
    useState(false);
  const [err, setErr] = useState(false)
const [activeData,setActiveData]=useState([])
const [orgId,setOrgId]=useState("")
const [docValue,setDocValue]=useState("")
const [docErr,setDocErr]=useState("")
  useEffect(() => {
    setshowModal(openModal)
  }, [openModal])

  const handleClose = () => {
    setshowModal(false);
    setDisplayConfirmationModal(false)
    setCheckboxValues("")
    setDisplayConfirmationModal2(true)
  };
  let jsonHip = useSelector((state) => state.hipScoreReducer);
  let adultKneeScore = useSelector((state) => state.adultKneeScore);
  let footAnkle = useSelector((state) => state.footAnkle);
  let pediaKnee = useSelector((state) => state.pediaKnee);
  let elbowScore = useSelector((state) => state.elbowScore);
  let handWrist = useSelector((state) => state.handWrist);
  let shoulderJson = useSelector((state) => state.shoulderScore);
  const genjson = useSelector((state) => state.GeneralFormReduces);
  const link_forms = async () => {
    try {
      const response = await axiosInstance.current.post(
        `extapp/doctors/link_forms?ak_dc=${dId}&ak_pt=${patient_akid}&pat_form_id=${checkboxValues}&doc_form_id=${rId}&ak_ou=${org_id?org_id:docValue}`
      );
      if (response) {
        localStorage.setItem("formlink", "true")
        setMessage("PROMs and Clinical Exam successfully linked");
        setMessageType("success");
        setTimeout(() => {
          setMessage(false);
        }, 2000);
        setshowModal(false);
        setShowModalImp(false)
        setDisplayConfirmationModal2(true)
      }
    } catch (error) {
    } finally {
    }
  }


  const updateCheckboxValues = (newValue) => {
   if (newValue.checked) {
      setCheckboxValues(newValue.resource_id);
      setOrgId(newValue.org_Id)
      
    }
   
  };

  console.log(Number(userage), "userage");
  let kneejson = Number(userage) >= 15 ? adultKneeScore : pediaKnee
  //  let kneetype=(type==="20"||type==="5")===Number(userage)>=15?"5":"20"
  let kneetype = ((type === "20" || type === "5") && Number(userage) >= 15) ? "5" : "20";

  let alltype = (type === "20" || type === "5") ? kneetype : type

  const handleOpenModal = async () => {
    try {
      let res = await axiosInstance.current.post(
        `/extapp/forms/doc_update_form?resource_id=${rId}&ak_pt=${patient_akid}&ak_dc=${dId}&ak_ou=${org_id}&type=${alltype}`,
        alltype === "4" ? shoulderJson : alltype === "6" ? jsonHip : alltype === "21" ? footAnkle : alltype === "22" ? elbowScore : alltype === "23" ? handWrist : alltype === "33" ? genjson : kneejson,
        // `extapp/forms/create_all_types_of_form?ak_id=${dId}&ak_ou=${org_id}&ak_pt=${patient_akid}&type=${alltype}&resource_id=&otherData=&doctor=true&isDoctor=1&action=1`,alltype==="4" ?shoulderJson:alltype==="6"?jsonHip:alltype==="21"?footAnkle:alltype==="22"?elbowScore:alltype==="23"?handWrist:alltype==="33"?genjson:kneejson,
        {
          headers: {
            "Content-Type": "application/json",
          },
        })
      if (res) {
        setStatusda(true)
        setshowModal(false);
        setDisplayConfirmationModal2(true)
      }
    } catch (err) {
      if (err.response.data.status === false) {
        setMessageType("error");
        setMessage("PROMs already requested !!!!");
        setTimeout(() => {
          setMessage(false);
        }, 6000);
      }
    }
  }

  useEffect(() => {
    if (statusda) {
      setMessage("New PROMs request sent successfully");
      setMessageType("success");
      // dispatch(noteRefresh(!dataRefe));
      setTimeout(() => {
        setMessage(false);
      }, 4000);
    }
  }, [statusda])
  const getData = async () => {
    try {
      const response = await axiosInstance.current.get(
        `extapp/doctors/viewDetailsScoringSection/${dId}/${patient_akid}?org_id=&type_id=${alltype}&status=pending&doctor=1&userid=${dId}`
      );
      console.log(response.data.data, "response.data.data");
      setschedulerForm2(response.data.data);
    } catch (error) {
      if (error) {
        setschedulerForm2([])
      }
    } finally {
    }
  };

  useEffect(() => {
    if (patient_akid) {
      getData();
    }
  }, [patient_akid])
  const [status, setStatus] = useState("")

  useEffect(() => {
    if ((status === "1" || status === "3") && openModal) {
      setshowModal(false)
      setDisplayConfirmationModal2(true)
    }
  }, [openModal, status])
  const getall_formsData = async () => {
    try {
      const response = await axiosInstance.current.get(
        `/extapp/forms/all_forms?resource_id=${rId}&type=${alltype}`
      );
      setStatus(response.data.data2[0].status);
    } catch (error) {
      if (error) {
        setschedulerForm2([])
      }
    } finally {
    }
  };

  useEffect(() => {
    if (rId) {
      getall_formsData();
    }
  }, [rId])

  const handleCloseImp=()=>{
    setShowModalImp(false)
    setDocValue("")
    setDocErr("")
  }

  const activeDoctor= async ()=>{
    try{
      const response = await axiosInstance.current.get(
        `extapp/doctors/active_practitioner_org_lists?ak_dc=${dId}&akou=${orgId}`
      );
      setActiveData(response.data.doctor_names);
    }catch(err){
  
    }
  }

  useEffect(()=>{
    if(orgId){
  
      activeDoctor()
    }
  },[orgId])

  const handleCheckboxValue =(checkValue)=>{
    setDocValue(checkValue)
    }



  const handleCreate = () => {
    if (checkboxValues === "") {
      setErr(true)
      setShowModalImp(false)
    }else{
      if(activeData.length > 1){
        setShowModalImp(true)
      }else{
        link_forms()
      }
    }
  

  }
  
 const handleConfirm=()=>{
  if(docValue === ""){
    setDocErr(true)
    
  }else{
    link_forms()
  }
}

  return (

    <div>
      {message && <Message message={message} type={messageType} />}
      <Modal
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-xs patient_notes_popup modal-dialog-centered modal-content1"
      >
        <Modal.Header className="close-buttonM" closeButton>
          <Modal.Title >
            {/* {translation.Link_PROMs}<img src={Iicon} alt="i" style={{ width: "25px", margin: "4px", borderRadius: "8px",cursor:"pointer" }} data-bs-toggle="tooltip" data-bs-placement="top" title="Linking a clinical exam with PROMs is permanent and cannot be undone. You may reach out to helpdesk@akunah.com for any concerns."/> */}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                {translation.Link_PROMs}<img src={Iicon} alt="i" style={{ width: "25px", margin: "4px", borderRadius: "8px", cursor: "pointer" }} data-bs-toggle="tooltip" data-bs-placement="top" title="Linking a clinical exam with PROMs is permanent and cannot be undone. You may reach out to helpdesk@akunah.com for any concerns." />
              </div>
              <span
                className="fst-italic fw-light "
                style={{
                  color: "#3F2783",
                  fontSize: "11px",
                }}
              >
                {"Linking a clinical exam with PROMs is permanent and cannot be undone. You may reach out to helpdesk@akunah.com for any concerns."}
              </span>
            </div>
          </Modal.Title>
          {/* <button className="close-button" onClick={handleClose}> */}
          {/* &times;
          </button> */}
        </Modal.Header>
        <Modal.Body className="">
          <div className="whitebox" style={{ backgroundColor: "rgb(209 203 230 / 45%)", border: "rgb(209 203 230 / 45%)" }}>
            <div className='d-flex justify-content-between mb-2'>
              <h6 className="text-capitalize" style={{ color: "#3f2783" }}>
                <strong>{name}</strong>
              </h6>

              <div className="float-end ps-5" style={{ width: '80px' }}>
                <Checkbox
                  selected={"CarpalDiagnosis1"}
                  onChange={"handleCarpalDiagnosis1"}
                  styleClass="custom_check_box tick f_400 large"
                  id="CarpalDiagnosis1"
                />
              </div>
            </div>
            <div className='d-flex justify-content-between'>
              <span className="" style={{ color: "#3f2783" }}>
                <strong>{userage} {userage <= 1 ? translation.year_pdata : translation.years_pdata}</strong></span>
              <span className="float-end ps-5 text-capitalize" style={{ color: "#3f2783" }}><strong>{patient_akid} </strong>&nbsp;</span>
            </div>
          </div>
          {schedulerForm2?.length !== 0 && (
            <>
              <div className="whitebox"
                style={{
                  border: "1px solid #3f2783",
                  // height: "200px",
                  // width: "100%",
                }}>
                <label>{translation.Please_Choose_PROMs_to_link}</label>
                <div className="scrollbar_vert " style={{ height: "140px" }}>
                  <AddModelTableSchedular data={schedulerForm2} updateCheckboxValues={updateCheckboxValues} />
                </div>
              </div>
              {err && (
                <>
                  {checkboxValues === "" && (
                    <span className='text-danger text-center'>Select a PROMS to link with - if unable simply exit by pressing x</span>
                  )}
                </>
              )}
              <div className="text-center">
                <br />
                <Button
                  onClick={handleCreate}
                  value="Submit"
                  buttonStyle="btn_outline ms-2 mb-3 "
                  width="400px"
                  height={'50px'}
                />
                <br />
                {assignErr && (
                  <>
                    {checkboxValues.length === 0 && (
                      <span className="text-danger">{translation.Please_select_atleast_one_form_or_close}</span>
                    )}
                  </>
                )}
              </div>
            </>
          )}
          <div className="text-center">
            <p>{translation.cant_find_appropriate_PROMs_to_link}</p>
            <label onClick={handleOpenModal} className="c_pointer">
              <u><b>{translation.Request_New_PROMs}</b></u>
            </label>
          </div>
        </Modal.Body>
      </Modal>
{Array.isArray(activeData) && activeData.length > 1 && (
<>
<Modal show={showModalImp}
        onHide={handleCloseImp}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-xs patient_notes_popup modal-dialog-centered modal-content1">
<Modal.Header className="close-buttonM">
<Modal.Title >
                  <div className="text-center"
                  style={{fontWeight: 700,
                    fontSize:"larger"}}
                    >
                    Important
                  </div>
               
                <div
        style={{
          textAlign: "center",
          marginTop: "10px",
        }}
      >
        <span
          style={{
            fontSize: "20px",
            fontWeight: 500,
          }}
        >
          Please select to which clinician this linked
        </span>
        <div
          style={{
            marginTop: "2px",
            fontSize: "20px",
            fontWeight: 500,
            marginBottom: "22px"
          }}
        >
          form will be assigned
        </div>
      </div>
      {activeData.map((item, index) => {
        const cleanedLabel= item.label.replace(/\s*\(.*?\)/g, "");
        return (
  <div
    key={item.value} 
    className="form-group custom_check_box custom_radio_box large text-black"
    style={{
      display: "flex",
      alignItems: "center",
      gap: "8px",
      marginLeft: "80px",
      // marginTop: "3px",
    }}
  >
       {docValue==item.value?
    <input
      type="radio"
      id={`radioOption-${item.value}`}
      name="radio"
      defaultChecked={true}
      checked={true}
    
    />
   :
   <input
   type="radio"
   id={`radioOption-${item.value}`}
   name="radio"
   onClick={()=>{
     handleCheckboxValue(item.value)
   }}
 />
   
   } 
    <label
      htmlFor={`radioOption-${item.value}`}
      style={{
        fontSize: "16px",
        // color: "black", 
      }}
      onClick={()=>{
        handleCheckboxValue(item.value)
      }}
    >
      {cleanedLabel} 
    </label>
  </div>
)})}
{docErr && (
                      <>
                        {docValue === "" && (
                          <span className='text-danger text-center' style={{fontSize: "small",
                            marginLeft: "83px"}}>This is field is required</span>
                        )}
                      </>
                    )}


                </Modal.Title>
  </Modal.Header>
  <Modal.Footer className="text-center" style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
        <Button  value="Cancel"
                            buttonStyle="btn_outline"
                            width="154px"
                            // marginRight="20px"
                            height={'50px'}
                            onClick={handleCloseImp}>
         
        </Button>
       
        <Button  value="Confirm"
                            buttonStyle="btn_green"
                            width="154px"
                            //  marginRight="25px"
                            height={'50px'}
                            onClick={handleConfirm}
                            >
         
        </Button>
      </Modal.Footer>
       
</Modal>
</>
)}


      <SubmitModel
        showModal={displayConfirmationModal2}
        patientID={patientuserID ? patientuserID : akId}
        checkboxValues={checkboxValues}
      />
    </div>
  )
}
