import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import backIcon from "../../../../images/back.png";
import nextWhiteIcon from "../../../../images/next-white.png";
import nextIcon from "../../../../images/next.png";
import LoadingSpinner from "../../../custom/loader/LoadingSpinner";
import InputField from "../../../custom/inputfield/InputField";
import Dropdown from "../../../custom/dropdown/Dropdown";
import DropdownOption from "../../../custom/dropdown/DropdownOption";
import { Validators } from "../../../../utilities/Validator";
import RadioSwitch from "../../../custom/radio-switch/RadioSwitch";
//  import { DateFormate, UserDateFormat } from "../../utilities/DateFormate";
import {
  updateHipScores,
  updateHipScoresDominant,
} from "../../../../redux/actions/hipAction";
import { updateElbowScores } from "../../../../redux/actions/elbowAction";
import { updateFootAnkle } from "../../../../redux/actions/footAnkle";
import { updateHandWristScores } from "../../../../redux/actions/handWrist";
import { updateAdultKneeScores } from "../../../../redux/actions/index";
import { updatePediaScores } from "../../../../redux/actions/pediaKnee";
import { updateShoulder } from "../../../../redux/actions/shoulderAction";
import useAxios from "../../../../axiosinstance";
import { useDispatch, useSelector } from "react-redux";
import useTransition from "../../../customHooks/translations";
import { useSearchParams } from "react-router-dom";
import { UserDateFormat, UserDateFormat2 , DateFormatWithTimeZone, ConsentFormDateFormat} from "../../../../utilities/DateFormate";
import NameCustom from "../../../custom/name/NameCustom";
export default function ClinPersonalData({Datapass}) {
  const translation = useTransition();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("doctor");
  let isdoctorForm = searchParams.get("is_doctor_form");
  let isdoctorvalue = searchParams.get("doctorvalue");
  let status = searchParams.get("status");
  let selectedDoc=searchParams.get("selected_id")
  const { akPt, akDc, akOu, formId } = useParams();
  // console.log(doctorEnable, typeof doctorEnable);
  useEffect(()=>{
    localStorage.setItem("isdoctorForm",isdoctorForm)
  },[isdoctorForm])
  useEffect(()=>{
    sessionStorage.setItem("doctor_id",window.atob(akDc))
  },[akDc])
  const dispatch = useDispatch();
  const axiosinstance = useAxios();
  const formIds = window.atob(formId);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  useEffect(()=>{
    Datapass(window.atob(akDc))
  },[akDc])


  // const date11 = new Date().toLocaleDateString();

  // const date11 = new Date().toLocaleDateString('en-GB', {
  //   day: 'numeric',
  //   month: 'long',
  //   year: 'numeric'
  // });

  const today = new Date();
const day1 = String(today.getDate()).padStart(2, '0');
const month1 = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so we add 1
const year1 = today.getFullYear();

const formattedDate1 = `${day1}/${month1}/${year1}`;

  // const [startDate, setStartDate] = useState(new Date());
  const [startDate, setStartDate] = useState("");
  const [sex, setSex] = useState("");
  const [dob, setDob] = useState("");
  const [dominant, setDominant] = useState("");
  const [visit, setVisit] = useState("1");
  const [evaluated, setEvaluated] = useState("");
  const [previous, setPrevious] = useState("");
  const [follow, setFollow] = useState("");
  const [procedure, setProcedure] = useState(1);
  const [init, setInitial] = useState("1");
  const [allready, setAllready] = useState("");
  const [unaffected_shoulder, setUnaffected_shoulder] = useState("1");
  const [previousVisits, setPreviousVisits] = useState([]);
  const [disableInitialVisit, setdisableInitialVisit] = useState(false);
  const [isSyndrome, setSyndrome] = useState("0");
  const [isDisease, setDisease] = useState("0");
  const [newData, setNewData] = useState(false);
  // console.log(previousVisits.length);
  // console.log(allready);
  const [age, setAge] = useState(1);
  const [profileData, setProfileData] = useState([]);
  const [date,setDate]= useState("")
  const previousVisitsRef = useRef();
  const dominantRef = useRef();
  const evaluatedRef = useRef();
  useEffect(() => {
    const checkAlready = async () => {
      const response = await axiosinstance.current.get(
        // `extapp/forms/get_complete_forms?ak_pt=${akptId}&ak_dc=${doctor}&ak_ou=${org}&type=${scoringForm}`
        `extapp/forms/get_complete_forms?ak_pt=${window.atob(
          akPt
        )}&ak_dc=${window.atob(akDc)}&ak_ou=${window.atob(
          akOu
        )}&type=${formIds}`
      );
      // console.log(response);
      setAllready(response.data);
    };
    checkAlready();
  }, [akOu, akOu, akPt, formIds]);


  // useEffect(() =>{
  //   save()
  // })

  console.log(akOu,akPt,formIds,"testting");
  const save = () => {
    axiosinstance.current
      .get(
        `extapp/forms/all_forms?resource_id=${allready.data
        }&type=${formIds}&use_for_doctor=${doctorEnable === "true" ? 1 : ""}`
      )
      .then((res) => {
        // setDominant(res.data.data2[0].dominant);
        // if (formIds === "4") {
          console.log(res.data.data2[0].doctorDate,"vvvvvvvvvvvv")
          setDate(res.data.data2[0].doctorDate)
        if (res.data.data2[0].initial_assessment.toString() === "0") {

          setNewData(true);
        }
        setevalShow(res.data.data2[0].evaluted_value);
        // }
        if (formIds === "5" || formIds === "20") {
          setDominant("1");
        } else if (formIds === "4") {
          setDominant(res.data.data2[0].dominant);
        } else {
          setDominant(res.data.data2[0].evaluted_value);
        }
        if (formIds === "21") {
          setDominant("1");
        }
        if (formIds === "6") {
          setDominant("1");
        }
        if (formIds === "22") {
          setDominant(res.data.data2[0].dominant);
        }
        if (formIds === "23") {
          setDominant(res.data.data2[0].dominant);
          setSyndrome(res.data.data2[0].isSyndrome === "2" ? "1" : "0");
          setDisease(res.data.data2[0].isDisease === "2" ? "1" : "0");
        }
        if (res.data.data2[0].parent_resource_form_id === null) {
          setEvaluated(res.data.data2[0].evaluted_value);
          // setdisableInitialVisit(true);
          setInitial(res.data.data2[0].initial_assessment.toString());
          setVisit(res.data.data2[0].initial_assessment.toString());
        } else {
          setEvaluated(res.data.data2[0].evaluted_value);
          setInitial(res.data.data2[0].initial_assessment.toString());
          setPrevious(res.data.data2[0].parent_resource_form_id);
          setVisit(res.data.data2[0].initial_assessment.toString());
          // setdisableInitialVisit(false);
        }
      });

    // setChk(true);
  };
  useEffect(() => {
    const getData = async () => {
      try {
        const result = await axiosinstance.current.get(
          `extapp/forms/personal_data?org_id=${window.atob(
            akOu
          )}&ak_id=${window.atob(akDc)}&akpt_id=${window.atob(
            akPt
          )}&formType=${formIds}&resource_id=${allready.data}&is_patient=${isdoctorForm === "true" ? 1 : 0}`
        );
        // console.log(result);
        if (result.status === 200) {
          setProfileData(result.data.profile[0]);
          setFirstName(result.data.profile[0].firstname);
          setLastName(result.data.profile[0].lastname);
          setEmail(result.data.profile[0].email);
          setDob(result.data.profile[0].dob);
          setSex(result.data.profile[0].gender);
          setPreviousVisits(result.data.previousVisits);
          // console.log(result.data.previousVisits[0].value , "hello")
          setPrevious(result.data.previousVisits.length !==0 ? result.data.previousVisits[0].value : "");
          if (newData) {

            setVisit(result.data.profile[0].initial);
            setInitial(result.data.profile[0].initial);
          }
          if (!allready.status) {
            setVisit(result.data.profile[0].initial);
            setInitial(result.data.profile[0].initial);
          }

          setDominant(result.data.profile[0].dominant);
        }
      } catch (error) {

        if (error.response.status === 401) {
          navigate("/un-authorized-access");
        }
   
      } finally {
  
        
      }
    };
    getData();
  }, [allready]);

  const getData1 = async () => {
    try {
      const result = await axiosinstance.current.get(
        `extapp/forms/getPersonal_data?resource_id=${previous}`
      );
      if (result.status == "200") {
        // setEvaluated(result.data.data.evaluted_value);
        dispatch(updateHipScores(result.data.data.evaluted_value));
        dispatch(updateElbowScores(result.data.data.evaluted_value));
        dispatch(updateFootAnkle(result.data.data.evaluted_value));
        dispatch(updateHandWristScores(result.data.data.evaluted_value));
        dispatch(updateAdultKneeScores(result.data.data.evaluted_value));
        dispatch(updatePediaScores(result.data.data.evaluted_value));
        dispatch(updateShoulder(result.data.data.evaluted_value));
        setFollow(`${result.data.data.year} ${result.data.data.year_text === "Year" ? translation.year_pdata : translation.years_pdata}, ${result.data.data.month} ${result.data.data.month_text === "Month" ? translation.month_pdata : translation.months_pdata}, ${result.data.data.day} ${result.data.data.day_text === "Day" ? translation.day_pdata : translation.days_pdata}`)

      }
    } catch (err) {
      // console.log(err.Messages);
    } finally {
      console.log("api response finally");
    }
  };
  useEffect(() => {
    if(previous){

      getData1();
    }
  }, [previous]);
  let redirect= localStorage.getItem("isdoctorForm")
  const reDir = (r_id) => {
    if (formIds === "4") {
      navigate("/pain/" + r_id+`?is_doctor_form=${redirect}`);
    }
    if (formIds === "5") {
      navigate("/adult-knee-pain/" + r_id+`?is_doctor_form=${redirect}`);
    }
    if (formIds === "20") {
      navigate("/pedia-knee-pain/" + r_id+`?is_doctor_form=${redirect}`);
    }
    if (formIds === "6") {
      navigate("/hip-pain/" + r_id+`?is_doctor_form=${redirect}`);
    }
    if (formIds === "21") {
      navigate("/foot-ankle-pain/" + r_id+`?is_doctor_form=${redirect}`);
    }
    if (formIds === "22") {
      navigate("/elbow-pain/" + r_id+`?is_doctor_form=${redirect}`);
    }
    if (formIds === "23") {
      navigate("/hand-and-wrist-pain/" + r_id+`?is_doctor_form=${redirect}`);
    }
    if (formIds === "33") {
      navigate("/general-history/" + r_id+`?is_doctor_form=${isdoctorForm}&status=${status}`);
    }
  };

 
  const handleFeedBack = (rowId) => {
    if (formIds === "4") {
      navigate(
        "/shoulder-treatment-form/step-1" +
        `/${rowId}?doctor=${window.atob(akDc)}&patient=${akPt}&is_doctor_form=${redirect}`
      );
    }
    if (formIds === "6") {
      navigate(
        "/hip-feedback" +
        `/${rowId}?doctor=${window.atob(akDc)}&patient=${akPt}&is_doctor_form=${redirect}`
      );
    }
    if (formIds === "21") {
      navigate(
        "/foot-doctor-feedback" +
        `/${rowId}?doctor=${window.atob(akDc)}&patient=${akPt}&is_doctor_form=${redirect}`
      );
    }
    if (formIds === "22") {
      navigate(
        "/elbow-feedback" +
        `/${rowId}?doctor=${window.atob(akDc)}&patient=${akPt}&is_doctor_form=${redirect}`
      );
    }
    if (formIds === "23") {
      navigate(
        "/hand-wrist-feedback" +
        `/${rowId}?doctor=${window.atob(akDc)}&patient=${akPt}&is_doctor_form=${redirect}`
      );
    }
    if (formIds === "20") {
      navigate(
        "/knee-feedback" +
        `/${rowId}?doctor=${window.atob(akDc)}&patient=${akPt}&is_doctor_form=${redirect}`
      );
    }
    if (formIds === "5") {
      navigate(
        "/knee-feedback" +
        `/${rowId}?doctor=${window.atob(akDc)}&patient=${akPt}&is_doctor_form=${redirect}`
      );
    }
    if (formIds === "33") { 
      navigate(
        "/general-feedback" +
        `/${rowId}?doctor=${window.atob(akDc)}&patient=${akPt}&is_doctor_form=${isdoctorForm}`
      );
    }
  };

  useEffect(() => {
    // if (formIds === "22") {
    //   // console.log(dominant, "dominant22");
    //   setDominant("1");
    // }
    if (formIds === "5") {
      // console.log(dominant, "dominant5");
      const [day, month, year] = dob.split("/");
      const dateObject = new Date(`${year}-${month}-${day}`);
      const yearValue = dateObject.getFullYear(); // 2003
      let res = new Date().getFullYear() - yearValue;

      if (res > 18) {
        setAge("2");
      } else {
        setAge("1");
      }
      setDominant("1");
    }
    if (formIds === "20") {
      // console.log(dominant, "dominant20");
      setDominant("1");
    }
    if (formIds === "23") {
      // console.log(dominant, "dominant20");
      // setDominant("1");
    }
    if (formIds === "6") {
      // console.log(dominant, "dominant20");
      setDominant("2");
    }
    if (formIds === "21") {
      // Pahle isko dominant nhi milta tha ab by default 1 bhej diye
      setDominant("1");
    }
  }, [profileData]);

  // console.log(typeof(unaffected_shoulder), "unaffected_shoulder");

  // console.log(evaluated, dominant, "dominant");

  const continueRedirect = (e) => {
    // if (!previousVisits) {
    //   previousVisitsRef.current.errUpdate();
    // }
    if (!dominant) {
      dominantRef.current.errUpdate();
    }
    if (!evaluated) {
      evaluatedRef.current.errUpdate();
    }
    if (visit === "1") {
      if (!evaluated) {
        evaluatedRef.current.errUpdate();
      }
    } else {
      if (previousVisits.length !== 0) {
        if (!previous) {
          previousVisitsRef.current.errUpdate();
        }
      } else {
        if (!evaluated) {
          evaluatedRef.current.errUpdate();
        }
      }
    }
    // if (unaffected_shoulder === "0") {
    //   setEvaluated("3");
    //   dispatch(updateShoulder("3"));
    //   dispatch(updateHipScores("3"));
    //   dispatch(updateElbowScores("3"));
    //   dispatch(updateFootAnkle("3"));
    //   dispatch(updateHandWristScores("3"));
    //   dispatch(updateAdultKneeScores("3"));
    //   dispatch(updatePediaScores("3"));

    // }
    if (!evaluated || !dominant) {
      console.log(evaluated,"something went wrong", dominant);
    } else {
      if (allready.status) {
        updateDate();
      } else {
        createResID();
      }
      // createResID();
    }
  };
  const continueRedirect2 = (e) => {
    if (allready.status) {
      updateDate();
    } else {
      createResID();
    }
  };

  useEffect(() => {
    if (formIds === "33") {
        setIsLoading(true)
      continueRedirect2()
    }
  }, [formIds])

  let jsonHip = useSelector((state) => state.hipScoreReducer);
  let adultKneeScore = useSelector((state) => state.adultKneeScore);
  let footAnkle = useSelector((state) => state.footAnkle);
  let pediaKnee = useSelector((state) => state.pediaKnee);
  let elbowScore = useSelector((state) => state.elbowScore);
  let handWrist = useSelector((state) => state.handWrist);
  let shoulderJson = useSelector((state) => state.shoulderScore);
  let generalJson = useSelector((state) => state.GeneralFormReduces);

  let hipOtherData = [
    {
      dominant: dominant,
      evaluted_value: unaffected_shoulder === "0" ? "3" : evaluated,
      procedure: procedure,
      evlHipPain: unaffected_shoulder === "0" ? "3" : evaluated,
      followups: follow,
      unaffected_hip: unaffected_shoulder,
      is_doctor_form: isdoctorForm === "true" ? 1 : 0,
    },
  ];
  let adultOtherData = [
    {
      dominant: dominant,
      evaluted_value: unaffected_shoulder === "0" ? "3" : evaluated,
      age: age,
      evlAdultKnee: unaffected_shoulder === "0" ? "3" : evaluated,
      unaffected_adult: unaffected_shoulder,
      followups: follow,
      is_doctor_form: isdoctorForm === "true" ? 1 : 0,
    },
  ];
  let footAnkleOtherData = [
    {
      dominant: dominant === "" ? 1 : dominant,
      evaluted_value: unaffected_shoulder === "0" ? "3" : evaluated,
      unaffected_footAnkle: unaffected_shoulder,
      anklePain: unaffected_shoulder === "0" ? "3" : evaluated,
      followups: follow,
      is_doctor_form: isdoctorForm === "true" ? 1 : 0,
    },
  ];
  let pediaKneeOtherData = [
    {
      dominant: dominant,
      evaluted_value: unaffected_shoulder === "0" ? "3" : evaluated,
      unaffected_pedia: unaffected_shoulder,
      evlPediaPain: unaffected_shoulder === "0" ? "3" : evaluated,
      followups: follow,
      is_doctor_form: isdoctorForm === "true" ? 1 : 0,
    },
  ];
  let elbowOtherData = [
    {
      dominant: dominant,
      evaluted_value: unaffected_shoulder === "0" ? "3" : evaluated,
      unaffected_elbow: unaffected_shoulder,
      elbowPain: unaffected_shoulder === "0" ? "3" : evaluated,
      followups: follow,
      is_doctor_form: isdoctorForm === "true" ? 1 : 0,
    },
  ];
  let handWristOtherData = [
    {
      dominant: dominant,
      evaluted_value: unaffected_shoulder === "0" ? "3" : evaluated,
      unaffected_hand: unaffected_shoulder,
      evaluated: unaffected_shoulder === "0" ? "3" : evaluated,
      followups: follow,
      isDisease: isDisease === "0" ? "1" : "2",
      isSyndrome: isSyndrome === "0" ? "1" : "2",
      is_doctor_form: isdoctorForm === "true" ? 1 : 0,
    },
  ];
  let ShoulderData = [
    {
      dominant: dominant,
      evaluted_value: unaffected_shoulder === "0" ? "3" : evaluated,
      unaffected_shoulder: unaffected_shoulder,
      shoulserScore: unaffected_shoulder === "0" ? "3" : evaluated,
      followups: follow,
      is_doctor_form: isdoctorForm === "true" ? 1 : 0,
    },
  ];
  let GeneralData = [
    {
      dominant: "0",
      evaluted_value: "0",
      unaffected_shoulder: "0",
      shoulserScore: "0",
      followups: follow,
      is_doctor_form: isdoctorForm === "true" ? 1 : 0,
    },
  ];
  const [isApiPending, setIsApiPending] = useState(false);
  const updateDate = () => {
    // let json;
    // if (unaffected_shoulder === "0") {
    //   setEvaluated("3");
    //   dispatch(updateShoulder("3"));
    //   dispatch(updateHipScores("3"));
    //   dispatch(updateElbowScores("3"));
    //   dispatch(updateFootAnkle("3"));
    //   dispatch(updateHandWristScores("3"));
    //   dispatch(updateAdultKneeScores("3"));
    //   dispatch(updatePediaScores("3"));
    //   dispatch(updateShoulder("3"));

    // }
    let otherData;

    if (formIds === "6") {
      // // json = jsonHip;
      otherData = hipOtherData[0];
      // if (visit === "0") {
      //   otherData.parent_resource_form_id = previous;
      // }
    }
    if (formIds === "5") {
      // json = adultKneeScore;
      otherData = adultOtherData[0];
      // if (visit === "0") {
      //   otherData.parent_resource_form_id = previous;
      // }
    }
    if (formIds === "20") {
      // json = pediaKnee;
      otherData = pediaKneeOtherData[0];
      // if (visit === "0") {
      //   otherData.parent_resource_form_id = Number(previous);
      // }
    }
    if (formIds === "21") {
      // json = footAnkle;
      otherData = footAnkleOtherData[0];
      // if (visit === "0") {
      //   otherData.parent_resource_form_id = Number(previous);
      // }
    }
    if (formIds === "22") {
      // json = elbowScore;
      otherData = elbowOtherData[0];
      // if (visit === "0") {
      //   otherData.parent_resource_form_id = Number(previous);
      // }
    }
    if (formIds === "23") {
      // json = handWrist;
      otherData = handWristOtherData[0];
      // if (visit === "0") {
      //   otherData.parent_resource_form_id = Number(previous);
      // }
    }

    if (formIds === "33") {
      // console.log(json)
      otherData = GeneralData[0];
      // if (visit === "0") {
      //   otherData.parent_resource_form_id = Number(previous);
      // }
    }

    if (formIds === "4") {
      // json = shoulderJson;
      otherData = ShoulderData[0];
      // if (visit === "0") {
      //   otherData.parent_resource_form_id = Number(previous);
      // }
    }
    setIsApiPending(true)
    axiosinstance.current
      .patch(
        `extapp/forms/update_all_form_type?resource_id=${allready.data}&action=0`,
        otherData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {

        
        if (isdoctorvalue === "1") {
          setIsApiPending(false)
          handleFeedBack(allready.data);
        } else {
          reDir(allready.data);
        }
      }).finally(() => {
        setIsApiPending(false)
      });
  };

  const createResID = () => {
    // if (unaffected_shoulder === "0") {
    //   setEvaluated("3");
    //   dispatch(updateShoulder("3"));
    //   dispatch(updateHipScores("3"));
    //   dispatch(updateElbowScores("3"));
    //   dispatch(updateFootAnkle("3"));
    //   dispatch(updateHandWristScores("3"));
    //   dispatch(updateAdultKneeScores("3"));
    //   dispatch(updatePediaScores("3"));
    //   dispatch(updateShoulder("3"));

    // }
    let json;
    let otherData;

    if (formIds === "6") {
      json = jsonHip;
      otherData = JSON.stringify(hipOtherData);
    }
    if (formIds === "5") {
      json = adultKneeScore;
      otherData = JSON.stringify(adultOtherData);
    }
    if (formIds === "20") {
      json = pediaKnee;
      otherData = JSON.stringify(pediaKneeOtherData);
    }
    if (formIds === "21") {
      json = footAnkle;
      otherData = JSON.stringify(footAnkleOtherData);
    }
    if (formIds === "22") {
      json = elbowScore;
      otherData = JSON.stringify(elbowOtherData);
    }
    if (formIds === "23") {
      json = handWrist;
      otherData = JSON.stringify(handWristOtherData);
    }
    if (formIds === "4") {
      json = shoulderJson;
      // console.log(json)
      otherData = JSON.stringify(ShoulderData);
    }
    if (formIds === "33") {
      json = generalJson;
      // console.log(json)
      otherData = JSON.stringify(GeneralData);
    }
    setIsApiPending(true)
    axiosinstance.current
      .post(
        `extapp/forms/create_medical_form?ak_pt=${window.atob(
          akPt
        )}&ak_dc=${window.atob(akDc)}&ak_ou=${window.atob(
          akOu
        )}&type=${formIds}&resource_id=${previous}&otherData=${otherData}&doctor=${isdoctorvalue ? isdoctorvalue : ""
        }&isDoctor=${isdoctorvalue === 'null'  ? "0"  : isdoctorvalue  ? 1 : ""}&selected_id=${window.atob(selectedDoc)}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (isdoctorvalue === "1") {
          handleFeedBack(res.data.resource_id);
        } else {
          reDir(res.data.resource_id);
        }
      }).catch ((error)=>{
        if (error.response.status === 401) {
          navigate("/un-authorized-access");
        }
      }) .finally(() => {
        setIsApiPending(false)
      });
  };

  const handleFirstName = (e) => {
    setFirstName(e);
  };
  const handleLastName = (e) => {
    setLastName(e);
  };
  const handleSex = (e) => {
    setSex(e);
  };
  const handleEmail = (e) => {
    setEmail(e);
  };
  const handleDominant = (e) => {
    setDominant(e);
    dispatch(updateHipScoresDominant(e));
  };
  const handleVisit = (e) => {
    setVisit(e);
    if (e === "0") {
      setPrevious("");
    } else {
      setPrevious("");
    }
  };
  // const handleAge = (e) => {
  //   setAge(e);
  // };
  //   const handleEvaluated = (e) => {
  //     setEvaluated(e);
  //   };
  const handlePrevious = (e) => {
    console.log(e , "e hello")
    setPrevious(e);
  };
  const handleFollow = (e) => {
    setFollow(e);
  };
  const handle_unaffected_shoulder = (e) => {
    setUnaffected_shoulder(e);
  };

  const handlerSyndrome = (e) => {
    setSyndrome(e);
  };
  const handlerDisease = (e) => {
    setDisease(e);
  };

  useEffect(() => {
    if(formIds !== "33"){
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }
  }, []);

  const [evalShow, setevalShow] = useState("3");
  const handleEvaluated = (e) => {
    setevalShow(e);
    setEvaluated(e);
    setUnaffected_shoulder("1");
    dispatch(updateHipScores(e));
    dispatch(updateElbowScores(e));
    dispatch(updateFootAnkle(e));
    dispatch(updateHandWristScores(e));
    dispatch(updateAdultKneeScores(e));
    dispatch(updatePediaScores(e));
    dispatch(updateShoulder(e));
  };
  // const [show, setshow] = useState(false);
  // useEffect(() => {
  //   setInitial(init);
  //   setshow(true);
  // }, [init]);
  const Back = () => {
    if(isdoctorForm==="true"){
      window.open(process.env.REACT_APP_DOCTOR_URL);
    }else{
      window.open(process.env.REACT_APP_PATIENT_URL); 
    }
   
  };

  useEffect(() => {
    if (allready.status) {
      save();
    }
  }, [allready]);

  

  var dateObj = new Date();

  var year = dateObj.getUTCFullYear();
  var month = (dateObj.getUTCMonth() + 1).toString().padStart(2, '0');
  var day = dateObj.getUTCDate().toString().padStart(2, '0');

  var formattedDate = day + "/" + month + '/' + year;

  useEffect(() => {
    if (formattedDate) {
      setStartDate(formattedDate)
    }
  }, [formattedDate])



  let previousVisitsdata = previousVisits.map((item) => {
    return {
      label: `${item.date} - ${item.side === "Both" ? translation.Both : item.side === "Right" ? translation.Right : item.side === "Left" ? translation.Left : ""} ${item.name !== "General" ? "- " : ""}${item.name === "Shoulder" ? translation.shoulder : item.name === "Adult Knee" ? translation.adult_knee : item.name === "Paediatric Knee" ? translation.paediatric_knee : item.name === "Adolescent Knee" ? translation.adolescent_knee : item.name === "Hip" ? translation.hip_form : item.name === "Foot And Ankle" ? translation.Foot_And_Ankle : item.name === "Elbow" ? translation.Elbow : item.name === "Hand And Wrist" ? translation.Hand_And_Wrist : item.name === "General" ? translation.General : ""}`, value: item.value
    }
  })
  let dateformat = sessionStorage.getItem("dateformat")
  let timeZone = sessionStorage.getItem("timeZone")


  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="pain_screen mb_100">
            <div className="panel">
              <div className="panel-body p_25">
                <NameCustom firstName={firstName + " "+ lastName} />
            
                <h4 className="ph_title f_40">{translation.Personal_Data}</h4>
                <div className="">
                  <div className="panel">
                    <form>
                      <div className="panel-body p_25">
                        <div className="row">
                          <div className="col-sm-5 col-md-5 mb_20">
                            <InputField
                              label={translation.First_Name}
                              value={firstName}
                              name="firstName"
                              type="text"
                              placeholder={translation.First_Name}
                              onChange={handleFirstName}
                              disabled
                              validators={[
                                {
                                  check: Validators.required,
                                  message: translation.This_field_is_required,
                                },
                              ]}
                              styleClass="br_10 mxw_100"
                            />
                          </div>
                          <div className="col-sm-5 col-md-5 mb_20">
                            <InputField
                              label={translation.Last_Name}
                              value={lastName}
                              type="text"
                              placeholder={translation.Last_Name}
                              onChange={handleLastName}
                              disabled
                              styleClass="br_10 mxw_100"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-5 col-md-5 mb_20">
                            {/* <Form.Group className="mb-3" controlId="fromDate">
                              <Form.Label>Date of Birth*</Form.Label>
                              <DatePicker
                                className="form-control mb_0 br_10"
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                             readOnly={true}
                                validators={[
                                  {
                                    check: Validators.required,
                                    message: "This field is required",
                                  },
                                ]}
                                styleClass="br_10 mxw_100"
                              />
                            </Form.Group> */}
                            <InputField
                              label={translation.Date_of_Birth}
                              value={UserDateFormat2(dob, dateformat)}
                              type="text"
                              placeholder={translation.Last_Name}
                              onChange={handleLastName}
                              disabled
                              styleClass="br_10 mxw_100"
                            />
                          </div>
                          <div className="col-sm-5 col-md-5 mb_20">
                            <Dropdown
                              data={[
                                { value: "", label: translation.Select },
                                { value: "1", label: translation.Male },
                                { value: "2", label: translation.Female },
                                {
                                  value: "3",
                                  label: translation.want_to_disclose,
                                },
                              ]}
                              label={translation.Sex}
                              value={sex}
                              onChange={handleSex}
                              disabled
                              validators={[
                                {
                                  check: Validators.required,
                                  message: translation.This_field_is_required,
                                },
                              ]}
                              styleClass="br_10 mxw_100"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-10 col-md-10 mb_20">
                            <InputField
                              label={translation.Email}
                              value={email}
                              disabled
                              type="text"
                              placeholder={translation.your_email}
                              onChange={handleEmail}
                              styleClass="br_10 mxw_100"
                              validators={[
                                {
                                  check: Validators.required,
                                  message: translation.This_field_is_required,
                                },
                              ]}
                            />
                          </div>
                        </div>
                        <div className="row">
                          {formIds === "4" && (
                            <div className="col-sm-5 col-md-5 col-12 mb_20">
                              {/* (disableInitialVisit ? (
                                  <Dropdown
                                    data={[
                                      { value: "", label: {translation.Select} },
                                      { value: "1", label: "Right" },
                                      { value: "2", label: "Left" },
                                      { value: "3", label: "Bilateral" },
                                    ]}
                                    label={translation.Dominant_Hand}
                                    value={dominant}
                                    onChange={handleDominant}
                                    placeholder={translation.Select}
                                    
                                    ref={dominantRef}
                                    validators={[
                                      {
                                        check: Validators.required,
                                        message: translation.This_field_is_required,
                                      },
                                    ]}
                                    styleClass="br_10 mxw_100"
                                  />
                                ) : (
                                  <Dropdown
                                    data={[
                                      { value: "", label: {translation.Select} },
                                      { value: "1", label: "Right" },
                                      { value: "2", label: "Left" },
                                      { value: "3", label: "Bilateral" },
                                    ]}
                                    label={translation.Dominant_Hand}
                                    value={dominant}
                                    onChange={handleDominant}
                                    placeholder={translation.Select}
                                    ref={dominantRef}
                                    validators={[
                                      {
                                        check: Validators.required,
                                        message: translation.This_field_is_required,
                                      },
                                    ]}
                                    styleClass="br_10 mxw_100"
                                  />
                                )) */}
                              {profileData.dominant === null && (
                                <Dropdown
                                  data={[
                                    { value: "", label: translation.Select },
                                    { value: "1", label: translation.Right },
                                    { value: "2", label: translation.Left },
                                    { value: "3", label: translation.Bilateral },
                                  ]}
                                  label={translation.Dominant_Hand}
                                  value={dominant}
                                  onChange={handleDominant}
                                  placeholder={translation.Select}
                                  ref={dominantRef}
                                  validators={[
                                    {
                                      check: Validators.required,
                                      message: translation.This_field_is_required,
                                    },
                                  ]}
                                  styleClass="br_10 mxw_100"
                                />
                              )}
                              {profileData.dominant !== null && (
                                <Dropdown
                                  data={[
                                    { value: "", label: translation.Select },
                                    { value: "1", label: translation.Right },
                                    { value: "2", label: translation.Left },
                                    { value: "3", label: translation.Bilateral },
                                  ]}
                                  label={translation.Dominant_Hand}
                                  value={profileData.dominant}
                                  onChange={handleDominant}
                                  placeholder={translation.Select}
                                  ref={dominantRef}
                                  disabled
                                  validators={[
                                    {
                                      check: Validators.required,
                                      message: translation.This_field_is_required,
                                    },
                                  ]}
                                  styleClass="br_10 mxw_100"
                                />
                              )}
                            </div>
                          )}

                          {formIds === "6" && (
                            <div className="col-sm-5 col-md-5 col-12 mb_20 d-none ">
                              <Dropdown
                                data={[
                                  { value: "", label: translation.Select },
                                  { value: "2", label: translation.Dont_Know },
                                  { value: "0", label: translation.No },
                                  { value: "1", label: translation.Yes },
                                ]}
                                label={translation.Hip_arthritis}
                                value={dominant}
                                onChange={handleDominant}
                                placeholder={translation.Select}
                                ref={dominantRef}
                                validators={[
                                  {
                                    check: Validators.required,
                                    message: translation.This_field_is_required,
                                  },
                                ]}
                                styleClass="br_10 mxw_100"
                              />
                            </div>
                          )}

                          {/* {formIds === "21" && (
                            <div className="col-sm-5 col-md-5 mb_20">
                              <Dropdown
                                data={[
                                  { value: "", label: "select" },
                                  {
                                    value: "1",
                                    label: "Big toe or lesser toes",
                                  },
                                  { value: "2", label: "Midfoot" },
                                  { value: "3", label: "Hindfoot/ankle" },
                                ]}
                                label={translation.Area_footankle_affected}
                                value={dominant}
                                onChange={handleDominant}
                                placeholder={translation.Select}
                                ref={dominantRef}
                                validators={[
                                  {
                                    check: Validators.required,
                                    message: translation.This_field_is_required,
                                  },
                                ]}
                                styleClass="br_10 mxw_100"
                              />
                            </div>
                          )} */}

                          {formIds === "23" && (
                            <div className="col-sm-5 col-md-5 col-12 mb_20">
                              <div className="col-md-6 col-6 col-12">
                                <Dropdown
                                  data={[
                                    { value: "", label: translation.Select },
                                    { value: "1", label: translation.Right },
                                    { value: "2", label: translation.Left },
                                    { value: "3", label: translation.Bilateral },
                                  ]}
                                  label={translation.Dominant_Hand}
                                  value={dominant}
                                  onChange={handleDominant}
                                  placeholder={translation.Select}
                                  ref={dominantRef}
                                  validators={[
                                    {
                                      check: Validators.required,
                                      message: translation.This_field_is_required,
                                    },
                                  ]}
                                  styleClass="br_10 mxw_100"
                                />
                              </div>

                              <div className="row mt-3">
                                <div className="col-md-6 col-sm-12 col-12">
                                  <label className="mt-4">
                                    {" "}
                                    {
                                      translation.Do_you_have_carpal_tunnel_syndrome
                                    }
                                  </label>
                                </div>

                                <div className="col-md-6 col-sm-12 col-12">
                                  <RadioSwitch
                                    labelLeft={translation.No}
                                    labelRight={translation.Yes}
                                    idLeft={`syndrome_no`}
                                    idRight={`syndrome_yes`}
                                    name={`isSyndrome`}
                                    // title="Do you have carpal tunnel syndrome?"
                                    styleClass=""
                                    value={isSyndrome}
                                    onChange={handlerSyndrome}
                                    placeholder={translation.Select}
                                    validators={[
                                      {
                                        check: Validators.required,
                                        message: translation.This_field_is_required,
                                      },
                                    ]}
                                  />
                                </div>
                              </div>
                              <div className="row mt-3">
                                <div className="col-md-6 col-sm-12 col-12">
                                  <label className="mt-4">
                                    {" "}
                                    {translation.Do_you_have_Dupuytren_disease}
                                  </label>
                                </div>

                                <div className="col-md-6 col-sm-12 col-12">
                                  <RadioSwitch
                                    labelLeft={translation.No}
                                    labelRight={translation.Yes}
                                    idLeft={`disease_no`}
                                    idRight={`disease_yes`}
                                    name={`isDisease`}
                                    // title="Do you have Dupuytren's disease?"
                                    styleClass=""
                                    value={isDisease}
                                    onChange={handlerDisease}
                                    placeholder={translation.Select}
                                    validators={[
                                      {
                                        check: Validators.required,
                                        message: translation.This_field_is_required,
                                      },
                                    ]}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                          {formIds === "22" && (
                            <div className="col-sm-5 col-md-5 mb_20">
                              {/* (disableInitialVisit ? (
                                  <Dropdown
                                    data={[
                                      { value: "", label: {translation.Select} },
                                      { value: "1", label: "Right" },
                                      { value: "2", label: "Left" },
                                      { value: "3", label: "Bilateral" },
                                    ]}
                                    label={translation.Dominant_Hand}
                                    value={dominant}
                                    onChange={handleDominant}
                                    placeholder={translation.Select}
                                    
                                    ref={dominantRef}
                                    validators={[
                                      {
                                        check: Validators.required,
                                        message: translation.This_field_is_required,
                                      },
                                    ]}
                                    styleClass="br_10 mxw_100"
                                  />
                                ) : (
                                  <Dropdown
                                    data={[
                                      { value: "", label: {translation.Select} },
                                      { value: "1", label: "Right" },
                                      { value: "2", label: "Left" },
                                      { value: "3", label: "Bilateral" },
                                    ]}
                                    label={translation.Dominant_Hand}
                                    value={dominant}
                                    onChange={handleDominant}
                                    placeholder={translation.Select}
                                    ref={dominantRef}
                                    validators={[
                                      {
                                        check: Validators.required,
                                        message: translation.This_field_is_required,
                                      },
                                    ]}
                                    styleClass="br_10 mxw_100"
                                  />
                                )) */}
                              {profileData.dominant === null && (
                                <Dropdown
                                  data={[
                                    { value: "", label: translation.Select },
                                    { value: "1", label: translation.Right },
                                    { value: "2", label: translation.Left },
                                    { value: "3", label: translation.Bilateral },
                                  ]}
                                  label={translation.Dominant_Hand}
                                  value={dominant}
                                  onChange={handleDominant}
                                  placeholder={translation.Select}
                                  ref={dominantRef}
                                  validators={[
                                    {
                                      check: Validators.required,
                                      message: translation.This_field_is_required,
                                    },
                                  ]}
                                  styleClass="br_10 mxw_100"
                                />
                              )}
                              {profileData.dominant !== null && (
                                <Dropdown
                                  data={[
                                    { value: "", label: translation.Select },
                                    { value: "1", label: translation.Right },
                                    { value: "2", label: translation.Left },
                                    { value: "3", label: translation.Bilateral },
                                  ]}
                                  label={translation.Dominant_Hand}
                                  value={profileData.dominant}
                                  onChange={handleDominant}
                                  placeholder={translation.Select}
                                  ref={dominantRef}
                                  disabled
                                  validators={[
                                    {
                                      check: Validators.required,
                                      message: translation.This_field_is_required,
                                    },
                                  ]}
                                  styleClass="br_10 mxw_100"
                                />
                              )}
                            </div>
                          )}
                          {/* {formIds === "5" && (
                            <div className="col-sm-5 col-md-5 mb_20">
                              <Dropdown
                                data={[
                                  { value: 1, label: "13-18 years old" },
                                  { value: 2, label: "Above 18 years old" },
                                ]}
                                label={translation.Age}
                                value={age}
                                onChange={handleAge}
                                validators={[
                                  {
                                    check: Validators.required,
                                    message: translation.This_field_is_required,
                                  },
                                ]}
                                styleClass="br_10 mxw_100"
                              />
                            </div>
                          )} */}
                        </div>
                        <div className="row d-none">
                          <div className="col-sm-6 col-md-6 mt-3">
                            <label
                              className=""
                              style={{ color: "#eb7c3c" }}
                            >{`${translation.Is_it_initial_visit} ${profileData.doctor}, ${profileData.org_name} ${translation.for_the_problem}`}</label>
                          </div>
                          <div className="col-sm-6 col-md-6 mb_20 ">
                            <div className="mb_10">
                              {/* {init === "1" && disableInitialVisit && (
                                <RadioSwitch
                                  labelLeft={translation.No}
                                  labelRight={translation.Yes}
                                  idLeft={translation.visitNo}
                                  idRight={translation.visitYes}
                                  name={translation.shoulderPainR}
                                  title={`${translation.Is_it_initial_visit} ${profileData.doctor} ,${profileData.org_name} ${translation.for_the_problem}`}
                                  styleClass=""
                                  value={visit}
                                  disabled
                                  onChange={handleVisit}
                                  placeholder="select"
                                />
                              )} */}
                              {init === "1" && !disableInitialVisit && (
                                <RadioSwitch
                                  labelLeft={translation.No}
                                  labelRight={translation.Yes}
                                  idLeft={translation.visitNo}
                                  idRight={translation.visitYes}
                                  name={translation.shoulderPainR}
                                  title=""
                                  styleClass=""
                                  value={visit}
                                  disabled
                                  onChange={handleVisit}
                                  placeholder={translation.Select}
                                />
                              )}

                              {init === "0" && !disableInitialVisit && (
                                <RadioSwitch
                                  labelLeft={translation.No}
                                  labelRight={translation.Yes}
                                  idLeft={translation.visitNo}
                                  idRight={translation.visitYes}
                                  name={translation.shoulderPainR}
                                  title=""
                                  styleClass=""
                                  value={visit}
                                  onChange={handleVisit}
                                  placeholder={translation.Select}
                                />
                              )}

                              {init === "0" && disableInitialVisit && (
                                <>
                                  <RadioSwitch
                                    labelLeft={translation.No}
                                    labelRight={translation.Yes}
                                    idLeft={translation.visitNo}
                                    idRight={translation.visitYes}
                                    name={translation.shoulderPainR}
                                    title=""
                                    styleClass=""
                                    value={visit}
                                    disabled
                                    onChange={handleVisit}
                                    placeholder={translation.Select}
                                  />
                                </>
                              )}
                              {disableInitialVisit && init === "1" && (
                                <RadioSwitch
                                  labelLeft={translation.No}
                                  labelRight={translation.Yes}
                                  idLeft={translation.visitNo}
                                  idRight={translation.visitYes}
                                  name={translation.shoulderPainR}
                                  title=""
                                  styleClass=""
                                  value={visit}
                                  disabled
                                  onChange={handleVisit}
                                  placeholder={translation.Select}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          {age === "2" && (
                            <div className="col-sm-5 col-md-5 mb_20 d-none">
                              <Dropdown
                                data={[
                                  // { value: "", label: "select" },
                                  { value: 1, label: translation.Yes },
                                  { value: 0, label: translation.No },
                                ]}
                                label={translation.Diagnosis_Procedure}
                                value={dominant}
                                onChange={handleDominant}
                                placeholder={translation.Select}
                                ref={dominantRef}
                                validators={[
                                  {
                                    check: Validators.required,
                                    message: translation.This_field_is_required,
                                  },
                                ]}
                                styleClass="br_10 mxw_100"
                              />
                            </div>
                          )}
                        </div>

                        {formIds === "6" && (
                          <div className="row">
                            {dominant === "1" && (
                              <div className="col-sm-5 col-md-5 mb_20 d-none">
                                <Dropdown
                                  data={[
                                    { value: 0, label: translation.Hip_arthroscopy },
                                    {
                                      value: 1,
                                      label:
                                        translation.Hip_replacement,
                                    },
                                  ]}
                                  label={translation.Procedure}
                                  value={procedure}
                                  onChange={setProcedure}
                                  validators={[
                                    {
                                      check: Validators.required,
                                      message: translation.This_field_is_required,
                                    },
                                  ]}
                                  styleClass="br_10 mxw_100"
                                />
                              </div>
                            )}
                            {/* <div className="col-sm-5 col-md-5 mb_20">
                            <Dropdown
                              data={[
                                { value: 2, label: "Don't Know" },
                                { value: 0, label: "No" },
                                { value: 1, label: "Yes" },
                              ]}
                              label="Hip arthritis ?*"
                              value={dominant}
                              onChange={handleDominant}
                              placeholder={translation.Select}
                              ref={dominantRef}
                              validators={[
                                {
                                  check: Validators.required,
                                  message: translation.This_field_is_required,
                                },
                              ]}
                              styleClass="br_10 mxw_100"
                            />
                          </div> */}
                          </div>
                        )}

                        {  formIds !== "33" && (
                          <div className="row">
                            <div className="col-sm-5 col-md-5 mb_20">
                              <DropdownOption
                                data={[
                                  {
                                    value: "1",
                                    label: translation.Right,
                                  },
                                  {
                                    value: "2",
                                    label: translation.Left,
                                  },
                                  {
                                    value: "3",
                                    label: translation.Both,
                                  },
                                ]}
                                placeholder={translation.Select}
                                label={translation.Evaluated_sdfs}
                                value={evaluated}
                                ref={evaluatedRef}
                                onChange={handleEvaluated}
                                validators={[
                                  {
                                    check: Validators.required,
                                    message: translation.This_field_is_required,
                                  },
                                ]}
                                styleClass="br_10 mxw_100"
                              />
                            </div>
                          </div>
                        )}

                        {formIds === "4" &&
                          formIds !== "33" &&
                      
                          evalShow !== "3" &&
                          evalShow !== "" && (
                            <div className="row">
                              <div className="col-sm-5 col-12 mt-3">
                                {" "}
                                <label>
                                  {translation.Is_your_unaffected_shoulder_pain}
                                </label>
                              </div>

                              <div className="col-sm-5 col-md-5 mb_20">
                                <RadioSwitch
                                  labelLeft={translation.No}
                                  labelRight={translation.Yes}
                                  idLeft={"unaffected_shoulderL"}
                                  idRight={"unaffected_shoulderR"}
                                  name={"unaffected_shoulder"}
                                  title={``}
                                  styleClass=""
                                  value={unaffected_shoulder}
                                  onChange={handle_unaffected_shoulder}
                                  placeholder={translation.Select}
                                />
                              </div>
                            </div>
                          )}
                        {formIds !== "4" &&
                          formIds !== "33" &&
                          evalShow !== "3" &&
                          evalShow !== "" && (
                            <div className="row">
                              <div className="col-sm-5 col-12 mt-3">
                                {" "}
                                <label>
                                  {formIds === "6"
                                    ? translation.Is_your_unaffected_hip_pain
                                    : formIds === "21"
                                      ? translation.Is_your_unaffected_foot_and_ankle_pain
                                      : formIds === "5"
                                        ? translation.Is_your_unaffected_knee_pain
                                        : formIds === "20"
                                          ? translation.Is_your_unaffected_knee_pain
                                          : formIds === "22"
                                            ? translation.Is_your_unaffected_elbow_pain
                                            : formIds === "23"
                                              ? translation.Is_your_unaffected_hand_pain
                                              : formIds === "24"
                                                ? translation.Is_your_unaffected_knee_pain
                                                : ""}{" "}

                                </label>
                              </div>

                              <div className="col-sm-5 col-md-5 mb_20">
                                <RadioSwitch
                                  labelLeft={translation.No}
                                  labelRight={translation.Yes}
                                  idLeft={"unaffected_hipL"}
                                  idRight={"unaffected_hipR"}
                                  name={"unaffected_hip"}
                                  title={``}
                                  styleClass=""
                                  value={unaffected_shoulder}
                                  onChange={handle_unaffected_shoulder}
                                  placeholder={translation.Select}
                                />
                              </div>
                            </div>
                          )}

                        {/* {visit === "0" && (
                          <div className="row">
                            <div className="col-sm-5 col-md-5 mb_20">
                              <DropdownOption
                                data={previousVisitsdata}
                                label={
                                  translation.Please_Select_previous_visits
                                }
                                value={previous}
                                placeholder={translation.Select}
                                onChange={handlePrevious}
                                ref={previousVisitsRef}
                                validators={[
                                  {
                                    check: Validators.required,
                                    message: translation.This_field_is_required,
                                  },
                                ]}
                                styleClass="br_10 mxw_100"
                              />
                            </div>
                            <div className="col-sm-5 col-md-5 mb_20">
                              <InputField
                                label={translation.Follow_Up}
                                value={follow}
                                type="text"
                                placeholder={`0 ${translation.year_pdata}, 0 ${translation.month_pdata}, 0 ${translation.day_pdata}`}
                                onChange={handleFollow}
                                styleClass="br_10 mxw_100"
                                disabled
                              />
                            </div>
                          </div>
                        )} */}
                        <div className="row">
                          <div className="col-sm-5 col-md-5 col-12 mb_20">
                            <Form.Group
                              className="mb-3"
                              // style={{ display: "inline-grid", width: "262px" }}
                              controlId="fromDate"
                            >
                              <Form.Label>
                                {translation.Assessment_Date}
                              </Form.Label>
                              <InputField
                                styleClass="br_10 mxw_100"
                                value={date||formattedDate1}
                                // value={DateFormatWithTimeZone(dateformat,timeZone)}
                         
                                disabled={true}
                              />
                              {/* <DatePicker
                                disabled={true}
                                dateFormat="dd/MM/yyyy"
                                className="form-control mb_0 br_10 disabled_color"
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                validators={[
                                  {
                                    check: Validators.required,
                                    message: translation.This_field_is_required,
                                  },
                                ]}
                                styleClass="br_10 mxw_100"
                              /> */}
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6 col-6">
                    <span onClick={() => Back()} className="assm_back_btn">
                      <img src={backIcon} alt="" />
                      <span>{translation.Close}</span>
                    </span>
                  </div>
                  <div className="col-md-6 col-sm-6 col-6 text-end">
                    {formIds === "33" ? (
                      <>
                        {isApiPending ? (
                          <button className="next_btn btn_disabled">
                            {translation.next_button}
                            <span>
                              {/* <img src={nextIcon} alt="" /> */}
                              <img src={nextWhiteIcon} alt="" />
                            </span>
                          </button>
                        ) : (
                          <button
                            className="next_btn btn_fill"
                            onClick={() => continueRedirect2()}
                            disabled={isApiPending}
                          >
                            {translation.next_button}
                            <span>
                              <img src={nextIcon} alt="" />
                              <img src={nextWhiteIcon} alt="" />
                            </span>
                          </button>
                        )}
                      </>
                    ) : (
                      <>
                        {isApiPending ? (
                          <button className="next_btn btn_disabled">
                            {translation.next_button}
                            <span>
                              {/* <img src={nextIcon} alt="" /> */}
                              <img src={nextWhiteIcon} alt="" />
                            </span>
                          </button>
                        ) : (
                          <button
                            className="next_btn btn_fill"
                            onClick={() => continueRedirect()}
                            disabled={isApiPending}
                          >
                            {translation.next_button}
                            <span>
                              <img src={nextIcon} alt="" />
                              <img src={nextWhiteIcon} alt="" />
                            </span>
                          </button>
                        )}
                      </>

                    )}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
